import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Box } from "@mui/material";

import TableAllSup from "./TableAllSup";

const AllSupplier = () => {
  const navigate = useNavigate();

  // Sample data array
  const data = [
    { id: 1, name: 'Supplier A', items: '5', extractlineitems: 'Yes', category: 'Category A', customer: 'Customer A' },
    { id: 2, name: 'Supplier B', items: '3', extractlineitems: 'No', category: 'Category B', customer: 'Customer B' },
    // Add more data as needed
  ];


  const [selected, setSelected] = React.useState([]);

  return (
    <>

      <Box display="flex" flexDirection="column">

      </Box>
      <Box>
        <Box display="flex">


          <Box display="block">
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              sx={{
                alignSelf: "flex-start",
                margin: 2,
                background: "none",
                color: "black",
                boxShadow: "none",
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  bottom: '-1px',
                  width: '100%',
                  height: '2px',
                  backgroundColor: '#1976d2',
                },
                '&:hover': {
                  backgroundColor: 'initial',
                  color: 'initial',
                  boxShadow: 'none',
                  '&::after': {
                    backgroundColor: '#1976d2',
                  },
                },
              }}

            >
              All Suppliers
            </Button>
            <TableAllSup data={data} selected={selected} setSelected={setSelected} sx={{ fontSize: "12px" }} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AllSupplier;
