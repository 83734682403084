import React, { useState } from 'react';
import { baseURL } from '../Config';
import axios from 'axios';


const validateFile = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const fileSizeMB = file.size / (1024 * 1024);
    const validImageExtensions = ["jpg", "jpeg", "png"];
    const validFileExtension = ["pdf", "zip"];
    const fileType = 'file'

    if (fileType === "file") {
        if (validImageExtensions.includes(fileExtension)) {
            if (fileSizeMB > 6) {
                return {
                    valid: false,
                    message: "Image files must be 6MB or smaller.",
                };
            }
        } else if (fileExtension === "zip") {
            if (fileSizeMB > 100) {
                return {
                    valid: false,
                    message: "ZIP files must be 100MB or smaller.",
                };
            }
        } else if (fileExtension !== "pdf") {
            return {
                valid: false,
                message: "Only PDF, JPG, PNG, and ZIP files are allowed.",
            };
        }
    } 
    
    return { valid: true, message: "" };
};

const AddBankPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [bankName, setBankName] = useState('');
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
        setBankName('');
        setFile(null);
        setFileName('');
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        }
    };
    const token = localStorage.getItem("token");

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Here you would handle the submission logic
        console.log('Submitted:', { bankName, file });

        const files = [file] // Get all selected files as an array

        // Validate each file and append to FormData

        const validation = await validateFile(file);

        if (!validation.valid) {
            alert(validation.message);
            return;
        }

        //   setLoading(true);
        let formData = new FormData();
        formData.append("file", file);

        const uploadUrl = `${baseURL}/upload`;

        // Upload all files at once
        const response = await axios.post(uploadUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        });

        console.log("Files uploaded successfully:", response.data);
        const uploadedFileURLs = response.data.locations || [
            response.data.location,
        ]; // Support single or multiple URLs

        // Loop through each uploaded file URL and create a corresponding record
        //   for (let uploadedFileURL of uploadedFileURLs) {
        const fileUrl = uploadedFileURLs[0][0]
        console.log(fileUrl)
        // const createUrl =
        //   selectedTab === 1 ? `${baseURL}/cost` : `${baseURL}/sales`;
        let body = {
            bankName, fileUrl, status:'pending' 
        }

        await axios.post(
          `${baseURL}/bankName`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log('bank name added successfully')
        //   }


        try {
            // setTimeout(() => {
            //     window.location.reload();
            // }, 500);

            //   setLoading(false);
            closePopup();
        } catch (error) {
            console.error("Error uploading file or creating record:", error);
            //   setLoading(false); // Ensure loading state is reset in case of error
        }


        // Close the popup after submission

    };

    return (
        <div className="relative">
            {/* Add New Bank Button */}
            <button
                onClick={openPopup}
                className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
                + Add New Bank
            </button>

            {/* Popup Overlay */}
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    {/* Popup Content */}
                    <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4">
                        <div className="p-4 border-b border-gray-200">
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl font-semibold text-gray-800">Add New Bank Name</h2>
                                <button
                                    onClick={closePopup}
                                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                                >
                                    <span className="text-2xl">&times;</span>
                                </button>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit} className="p-4">
                            <div className="mb-4">
                                <label htmlFor="bankName" className="block text-sm font-medium text-gray-700 mb-1">
                                    Bank Name
                                </label>
                                <input
                                    type="text"
                                    id="bankName"
                                    value={bankName}
                                    onChange={(e) => setBankName(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Enter bank name"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Upload File
                                </label>
                                <div className="flex items-center justify-center w-full">
                                    <label className="flex flex-col w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:bg-gray-50">
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            {fileName ? (
                                                <p className="text-sm text-gray-600 text-center">
                                                    Selected: <span className="font-medium">{fileName}</span>
                                                </p>
                                            ) : (
                                                <>
                                                    <svg className="w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                                    </svg>
                                                    <p className="text-sm text-gray-500 text-center mt-2">
                                                        Drag and drop or click to upload
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                        <input
                                            type="file"
                                            className="hidden"
                                            onChange={handleFileChange}
                                            required
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    className="mr-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
                                    onClick={closePopup}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddBankPopup;