import { useState, createContext } from "react";

export const BusinessContext = createContext()

export const BusinessProvider = ({ children }) => {
    const [selectedBusiness, setSelectedBusiness] = useState("my practice");
    return (
        <BusinessContext.Provider value={{ selectedBusiness, setSelectedBusiness }}>
            {children}
        </BusinessContext.Provider>
    )

}

