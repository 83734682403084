// import React, { useState } from 'react';
// import { Document, Page } from 'react-pdf';

// const MyPdfViewer = ({ fileUrl }) => {
//     const [page, setPage] = useState(1);
//     const [pages, setPages] = useState(null);

//     const onDocumentLoad = ({ numPages }) => {
//         setPages(numPages);
//     };

//     return (
//         <div>
//             <Document file={fileUrl} onLoadSuccess={onDocumentLoad}>
//                 <Page pageNumber={page} />
//             </Document>
//         </div>
//     );
// };

// export default MyPdfViewer;
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewer = ({ pdfUrl }) => {


    return (
        <div style={{ height: '500px' }}>
            {/* <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js`}>
                <Viewer fileUrl={pdfUrl} />
            </Worker> */}
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js`}>
                <Viewer fileUrl={pdfUrl} />
            </Worker>
        </div>
    );
};

export default PDFViewer;
