import React from 'react';
import { AppBar, Toolbar, Typography, InputBase, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';

const HeaderBusiness = () => {
  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#000000', height: '80px', boxShadow: 'none' }}>
      <Toolbar className="bg-dark h-full flex items-center justify-between">
        <div className="flex items-center">
          <img className="w-[80px]" src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
        </div>
        <div style={{display:"flex"}}><Typography variant="h6" component="div" sx={{ color: '#ffffff', marginRight: '22px', fontWeight:"bold"}}>
            Francais
          </Typography>
        <div className="flex items-center bg-white rounded-md px-2">
          <IconButton>
            <Search />
          </IconButton>
          <InputBase
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search' }}
            sx={{ ml: 1, flex: 1 }}
          />
        </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBusiness;
