import React from 'react'
import { Route, Routes } from 'react-router-dom';
import AllSupplier from '../listComponents/suppliers/Allsuppliers';
import Suppliers from '../listComponents/Suppliers';

const SuppplierPage = () => {
    return (
        <div className='p-1 h-full'>
            <Routes>
                <Route path=":id" element={<AllSupplier />} />
                <Route path="" element={<Suppliers />} />
            </Routes>

        </div>
    )
}

export default SuppplierPage
