import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import axios from 'axios';
import { baseURL } from '../Config';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
const ForgotPassword = ({ close }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [error, setError] = useState();

  const sendForgotPasswordApiCall = async () => {
    // const token = localStorage.getItem('token')
    // const userToken = localStorage.getItem('userToken');
    try {
      const response = await axios.post(`${baseURL}/auth/forgot-password`, {
        email: email,
      });
      if (response.OK) {
        alert('Reset password email sent to given email Id ');
        navigate('/');
      } else {
        // set error here
        alert(response?.data?.message);
      }
      //  console.log(response.data.token+"sass")
      //  setSupplierData(response.data);
      //   localStorage.setItem("token", response.data.token)
      //   console.log("Change Suppliers Data:", response.data); // Console log the fetched data
      //   window.location.reload();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSend = () => {
    const isValid = validateSend();
    if (isValid) {
      sendForgotPasswordApiCall();
      // alert(`Soon! You will recieve the password on the ${email} `)
    } else {
      alert('Incorrect Email! Enter Valid Email.');
      setEmail('');
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const validateSend = () => {
    let emailError = '';

    if (!email) {
      emailError = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailError = 'Email address is invalid';
    }

    if (emailError) {
      setError({ email: emailError });
      return false;
    }
    return true;
  };
  return (
    <Dialog open={true}>
      <DialogTitle className='flex justify-end'>
        <IconButton onClick={() => close()}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className='p-5'>
        <div className='flex justify-center items-center'>
          <div className='flex flex-col justify-center'>
            {/* <div className='flex justify-center'>
              <img
                className='w-[200px] h-[200px]'
                src='https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png'
                alt='Logo'
              />
            </div> */}
            <div className='flex flex-col gap-3 items-center rounded-md'>
              <div className='w-[15%]'>
                <img src='./Lock.jpeg' alt='Forgot Password' />
              </div>
              <span className='text-lg font-bold'>Forgot Your Password?</span>
              <p className='text-center text-[#575757] text-[15px]'>
                Please enter the e-mail address you used to register with Zoom,
                <br /> and we'll send you a password reset link.
              </p>
              <TextField
                className='w-3/4 rounded-sm'
                type='text'
                placeholder='Email'
                value={email}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogContent className='flex justify-center'>
        <Button
          onClick={handleSend}
          startIcon={<LockOpenIcon />}
          className='shadow-md text-lg active:translate-y-1 hover:shadow-2xl mt-6'
        >
          Send
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ForgotPassword;
