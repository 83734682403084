 export default  {
  banks: [
    "Bank of China",
    "Barclays",
    "Mitsubishi UFJ Financial Group (MUFG)",
    "Citi",
    "Credit Suisse",
    "Deutsche Bank",
    "Goldman Sachs",
    "HSBC",
    "JPMorgan Chase",
    "Société Générale",
    "Belfius",
    "ING",
    "Intesa Sanpaolo",
    "Sumitomo Mitsui Banking Corporation (SMBC)",
    "Mizuho Financial Group",
    "Westpac",
    "UBS",
    "Standard Chartered",
    "KBC",
    "BBVA",
    "Morgan Stanley",
    "Nedbank",
    "Rabobank",
    "Royal Bank of Canada (RBC)",
    "NatWest Group",
    "Scotiabank",
    "China Construction Bank",
    "Industrial and Commercial Bank of China (ICBC)",
    "State Bank of India",
    "Standard Bank",
    "Crédit Agricole",
    "UniCredit",
    "Banco Galicia",
    "Bank of Montreal (BMO)",
    "Triodos Bank",
    "Banca Etica",
    "Alternative Bank Schweiz",
    "ASN Bank",
    "GLS Bank",
    "Macquarie",
    "The Co-operative Bank",
    "RSF Social Finance",
    "Caixa Econômica Federal",
    "CaixaBank",
    "Banco Popular Español",
    "Banco Espirito Santo",
    "Desjardins Group",
    "Ekobanken",
    "China Development Bank",
    "Export-Import Bank of China",
    "China Everbright Bank",
    "Canadian Imperial Bank of Commerce (CIBC)",
    "Manulife",
    "Nordea",
    "Zurich Kantonalbank",
    "Toronto-Dominion Bank (TD Bank)",
    "Danske Bank",
    "Erste Group Bank",
    "Wells Fargo",
    "Commonwealth Bank",
    "National Australia Bank (NAB)",
    "DekaBank",
    "Natixis",
    "Commerzbank",
    "Industrial Bank",
    "Bangkok Bank",
    "Kasikorn Bank",
    "Absa Group",
    "Access Bank",
    "Arab African International Bank",
    "Banco de la República Oriental del Uruguay",
    "Bancolombia",
    "Bank Muscat",
    "Itaú Corpbanca",
    "DNB",
    "Export Development Canada",
    "FirstRand",
    "FMO",
    "KfW IPEX-Bank",
    "Lloyds Banking Group",
    "Skandinaviska Enskilda Banken (SEB)",
    "BNP Paribas Fortis",
    "Garanti BBVA",
    "Akbank",
    "BMCE Bank of Africa",
    "Agricultural Bank of China",
    "PNC",
    "NIBC",
    "Ahli United Bank",
    "Vnesheconombank",
    "Sberbank",
    "Bank of Communications",
    "Hua Xia Bank",
    "China Merchants Bank",
    "Shanghai Pudong Development Bank",
    "China Minsheng Bank",
    "CITIC Bank International",
    "Axis Bank",
    "Industrial Development Bank of India (IDBI)",
    "Alfa Bank",
    "HDFC Bank",
    "ICICI Bank",
    "China CITIC Bank",
    "Ping An Bank",
    "Bank of Beijing",
    "Bank of Ningbo",
    "Bank of Nanjing",
    "VTB",
    "Maybank",
    "CIMB",
    "DBS",
    "OCBC Bank",
    "United Overseas Bank (UOB)",
    "Bank Negara Indonesia (BNI)",
    "Bank Rakyat Indonesia (BRI)",
    "IDFC",
    "Otkritie Bank",
    "Banco de Crédito del Perú (BCP)",
    "DZ Bank",
    "BNY Mellon",
    "U.S. Bancorp",
    "Suncorp",
    "La Banque Postale",
    "Allianz",
    "BayernLB",
    "Helaba",
    "HSH Nordbank",
    "LBBW",
    "NORD/LB",
    "Mediobanca",
    "Raiffeisen Bank International",
    "Swedbank",
    "Bank of India",
    "Power Finance Corporation (PFC)",
    "Life Insurance Corporation of India",
    "PKO Bank Polski",
    "Bank Gospodarstwa Krajowego (BGK)",
    "Getin Noble Bank",
    "Bank Millennium",
    "Bank Central Asia",
    "Bank Danamon",
    "Bank Gospodarki Żywnościowej",
    "Crédit Mutuel",
    "BNDES",
    "Bankia",
    "Norges Bank",
    "KfW",
    "BPCE",
    "Wing Lung Bank",
    "Cathay United Bank",
    "Sumitomo Mitsui Trust Holdings",
    "Banco Sabadell",
    "Bank Hapoalim",
    "Bank Leumi",
    "Israel Discount Bank",
    "Mizrahi Tefahot Bank",
    "First International Bank of Israel (FIBI)",
    "Mauritius Commercial Bank",
    "CIBanco",
    "Fidelity Bank",
    "Ecobank",
    "Finnfund",
    "India Exim Bank",
    "Export-Import Bank of the United States (EXIM)",
    "Truist Financial Corporation",
    "CIFI",
    "Banco Mercantil del Norte (Banorte)",
    "Banco Votorantim",
    "UK Export Finance",
    "Svenska Handelsbanken",
    "Bankinter",
    "E.SUN Commercial Bank",
    "Export Finance Australia",
    "Denmarks Export Credit Agency",
    "Export Credit Norway",
    "Instituto de Crédito Oficial (ICO)",
    "First Abu Dhabi Bank (FAB)",
    "OP Financial Group",
    "BTG Pactual",
    "Green Investment Group",
    "Banco del Estado de Chile",
    "Bank of Jiangsu",
    "Credicorp",
    "Swedish Export Credit Corporation (SEK)",
    "Korea Development Bank (KDB)",
    "Norinchukin Bank",
    "Fubon Financial Holding",
    "De Volksbank",
    "Nykredit Bank",
    "Jyske Bank",
    "Sydbank",
    "SpareBank 1 SR-Bank",
    "O-Bank",
    "Postal Savings Bank of China",
    "Shinkin Central Bank",
    "National Bank of Egypt",
    "Banque Misr",
    "Zenith Bank",
    "Commercial International Bank (CIB)",
    "Attijariwafa Bank",
    "Banque Populaire",
    "Shinhan Financial Group",
    "Yuanta Commercial Bank",
    "Shinsei Bank",
    "Mianyang City Commercial Bank",
    "CTBC Bank",
    "Chongqing Rural Commercial Bank",
    "Bank SinoPac",
    "Bank of Huzhou",
    "First Commercial Bank",
    "KB Financial Group",
    "Bank of Guizhou",
    "Taishin International Bank",
    "Mega Bank",
    "Woori Bank",
    "Hana Financial Group",
    "Bank of Chongqing",
    "NongHyup Bank"
  ]
}
