// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .MuiPaper-root.MuiPaper-elevation{
    height: 510px;
    margin: 7rem 0rem 0rem 1rem;
    border-radius:20px;
    width: 70%;
}
.MuiPaper-root.MuiPaper-elevation {
    height: auto;
    margin: 7rem 0rem 0rem 1rem;
    border-radius: 20px;
    opacity: 1;
    transform: none;
    transition: opacity 359ms cubic-bezier(0.4, 0, 0.2, 1), transform 239ms cubic-bezier(0.4, 0, 0.2, 1);
    top: -50px ;
    left: 19px  ;
    transform-origin: 320px 16.5469px;
} */

.overflow-y-auto{
    height: 81vh;
    padding: 1rem;
    margin: 0rem;
}

.react-datepicker__input-container input{
    height: 30px !important;
}`, "",{"version":3,"sources":["webpack://./src/costComponents/utils/inboxtablestyle.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;GAgBG;;AAEH;IACI,YAAY;IACZ,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["/* .MuiPaper-root.MuiPaper-elevation{\n    height: 510px;\n    margin: 7rem 0rem 0rem 1rem;\n    border-radius:20px;\n    width: 70%;\n}\n.MuiPaper-root.MuiPaper-elevation {\n    height: auto;\n    margin: 7rem 0rem 0rem 1rem;\n    border-radius: 20px;\n    opacity: 1;\n    transform: none;\n    transition: opacity 359ms cubic-bezier(0.4, 0, 0.2, 1), transform 239ms cubic-bezier(0.4, 0, 0.2, 1);\n    top: -50px ;\n    left: 19px  ;\n    transform-origin: 320px 16.5469px;\n} */\n\n.overflow-y-auto{\n    height: 81vh;\n    padding: 1rem;\n    margin: 0rem;\n}\n\n.react-datepicker__input-container input{\n    height: 30px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
