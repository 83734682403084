import { baseURL } from '../Config';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Toolbar,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Box,
  Checkbox,
  Popover,
  OutlinedInput,
} from '@mui/material';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TransparentBtn } from '../components/common/TransparentBtn';
import { Delete, DeleteOutline } from '@mui/icons-material';
import { useStyles } from '../components/common/useStyles';

const token = localStorage.getItem('token');
console.log('Token:', token);

function EnhancedTableToolbar({ numSelected, onImportClick, onDeleteClick }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [selected, setSelected] = useState(null);

  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openFilter = Boolean(anchorEl);
  const id1 = openFilter ? 'simple-popover' : undefined;

  const SettingHandleClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const SettingHandleClose = () => {
    setAnchor(null);
  };

  const openSetting = Boolean(anchor);
  const id2 = openSetting ? 'simple-popover' : undefined;

  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <div className='flex justify-between items-center w-full'>
        <div className='flex gap-2 items-center'>
          <TransparentBtn
            label={'Import from CSV'}
            border
            onClick={onImportClick}
          />
          <TransparentBtn
            disabled={numSelected === 0}
            label={'Delete'}
            startIcon={<DeleteOutline color='error' fontSize='small' />}
            border
            onClick={onDeleteClick}
          />
          {/* <Button
            variant='contained'
            sx={{
              fontSize: '12px',
              padding: '6px 15px',
              marginRight: '10px',
              background: '#1976d2',
              color: '#ffff',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#1976d2',
              },
            }}
            onClick={onImportClick}
          >
            Import from CSV
          </Button> */}
          {/* <Button
            disabled={numSelected === 0}
            variant='contained'
            sx={{
              fontSize: '12px',
              padding: '6px 15px',
              background: '#ff5a02',
              color: 'black',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#ff5a02',
                color: 'black',
              },
            }}
            onClick={onDeleteClick}
          >
            Delete
          </Button> */}
        </div>
        <div className='flex items-center gap-2'>
          <OutlinedInput
            className={classes.root}
            placeholder='Filter by name'
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon sx={{ color: '#dbdbd9' }} />{' '}
              </InputAdornment>
            }
          />
          {/* <div className='border-4 h-10 border-[#dbdbd9] '>
            <SearchIcon sx={{ color: '#dbdbd9', width: '40px' }} />
            <input
              className=' h-8 border-none focus:outline-none focus:border-none text-[#757574] focus:ring-0 pl-0'
              type='text'
              placeholder='Filter by name'
            />
          </div> */}

          <Tooltip title='Filter list'>
            <div>
              <button
                className='relative active:translate-y-1'
                onClick={handleClick}
              >
                <IconButton>
                  <FilterAltOutlinedIcon sx={{ color: '#647890' }} />
                </IconButton>
              </button>
              <Popover
                id={id1}
                open={openFilter}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className=' flex flex-col p-5  gap-5'>
                  <div className='font-semibold'>FILTERS</div>
                  <div className=' w-full flex justify-between items-center gap-11'>
                    <span>Filter by</span>
                    <div className='flex gap-2'>
                      <button
                        onClick={() => setSelected(1)}
                        className={`border-2  text-[#647890] text-sm rounded-md cursor-pointer hover:bg-[#fae396]  hover:border-[#ffe11f] flex justify-center items-center  p-1 ${
                          selected === 1
                            ? 'bg-[#f7f4dc] border-4 border-[#ffe11f]'
                            : ''
                        }`}
                      >
                        All Customers
                      </button>
                      <button
                        onClick={() => setSelected(2)}
                        className={`border-2  text-[#647890] text-sm rounded-md cursor-pointer hover:bg-[#fae396]  hover:border-[#ffe11f] flex justify-center items-center  p-1 ${
                          selected === 2
                            ? 'bg-[#f7f4dc] border-4 border-[#ffe11f]'
                            : ''
                        }`}
                      >
                        Customers with items in the box
                      </button>
                    </div>
                  </div>
                  <div className='flex justify-end'>
                    <Button
                      variant='contained'
                      // onClick={onImportCSVClick}
                      sx={{
                        fontSize: '12px',
                        padding: '6px 15px',
                        marginRight: '10px',
                        background: '#1976d2',
                        color: '#ffff',
                        fontWeight: 'bold',
                        '&:hover': {
                          backgroundColor: '#1976d2',
                        },
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
          </Tooltip>
          {/* <Tooltip title="setting">
                        <div>
                            <button className="relative active:translate-y-1" onClick={SettingHandleClick}>
                                <IconButton >
                                    <SettingsIcon sx={{ color: '#647890' }} />
                                </IconButton>
                            </button>
                            <Popover
                                id={id2}
                                open={openSetting}
                                anchorEl={anchor}
                                onClose={SettingHandleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <div className=' flex flex-col p-5  gap-7'>
                                    <div >
                                        <div className='font-semibold' >PRIMARY COLUMNS</div>
                                        <div className=' w-full flex justify-between items-center gap-11 mt-1'>

                                            <span> <input type="checkbox" id='extract-supplier-statements' name='extract-supplier-statements' /> <label htmlFor="extract-supplier-statements">
                                                Extract Supplier Statements</label></span>
                                            <span>  <input type="checkbox" id='category' name='category' /> <label htmlFor="category">
                                                Category</label></span>
                                            <span>   <input type="checkbox" id='customer' name='customer' /> <label htmlFor="customer">
                                                Customer</label></span>
                                        </div>
                                    </div>
                                    <div >
                                        <div className='font-semibold' >ADDITIONALCOLUMNS</div>
                                        <div className='mt-1'> <input type="checkbox" id='tax-rates' name='tax-rates' /> <label htmlFor="tax-rates">
                                            Tax Rate</label></div>
                                    </div>
                                    <div className='flex justify-end'>
                                        <Button
                                            variant="contained"
                                            // onClick={onImportCSVClick}
                                            sx={{
                                                fontSize: '12px',
                                                padding: '6px 15px',
                                                marginRight: '10px',
                                                background: '#1976d2',
                                                color: '#ffff',
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    backgroundColor: '#1976d2',
                                                }
                                            }}
                                        >
                                            Apply
                                        </Button></div>
                                </div>

                            </Popover>
                        </div>
                    </Tooltip> */}
        </div>
      </div>
    </Toolbar>
  );
}

const ImportCsvDialog = ({ open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth='lg'
    fullWidth
    sx={{ position: 'absolute', left: 150 }}
  >
    <DialogTitle
      sx={{
        color: 'orange',
        boxShadow: '0 4px 2px -2px gray',
        fontSize: '16px',
        fontWeight: 'bold',
        padding: '1',
      }}
    >
      CSV Upload
    </DialogTitle>
    <DialogContent>
      <p
        sx={{
          fontSize: '12px',
          padding: '4',
        }}
      >
        Upload the CSV file representing your Customers list. The first row of
        your CSV should be the header containing the column name. See the
        example below.
      </p>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Column 1</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Column 2</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Column 3</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Column 4</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Column 5</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Column 6</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Data 1</TableCell>
            <TableCell>Data 2</TableCell>
            <TableCell>Data 3</TableCell>
            <TableCell>Data 4</TableCell>
            <TableCell>Data 5</TableCell>
            <TableCell>Data 6</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Data 7</TableCell>
            <TableCell>Data 8</TableCell>
            <TableCell>Data 9</TableCell>
            <TableCell>Data 10</TableCell>
            <TableCell>Data 11</TableCell>
            <TableCell>Data 12</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Data 13</TableCell>
            <TableCell>Data 14</TableCell>
            <TableCell>Data 15</TableCell>
            <TableCell>Data 16</TableCell>
            <TableCell>Data 17</TableCell>
            <TableCell>Data 18</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button
          variant='contained'
          component='label'
          sx={{
            backgroundColor: '#1976d2',
            color: 'black',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#1976d2',
              color: 'white',
              fontWeight: 'bold',
            },
          }}
        >
          Select
          <input type='file' accept='.csv' hidden />
        </Button>
        <p style={{ fontSize: 14, marginTop: 3 }}>(CSV only, 2MB max)</p>
      </div>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        sx={{
          color: 'black',
          '&:hover': {
            backgroundColor: 'lightgrey',
          },
        }}
      >
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

const TableCus = ({ data, selected, onSelect }) => {
  const navigate = useNavigate();

  const handleSelect = (id) => {
    onSelect((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };
  const handleNameClick = (data) => {
    // console.log(data);
    navigate(`/dashboard/customers/${data?._id}`);
  };

  return (
    <Table>
      <TableHead className='bg-[#ebf0ec]'>
        <TableRow>
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={
                selected.length > 0 && selected.length < data.length
              }
              checked={data.length > 0 && selected.length === data.length}
              onChange={() => {
                const newSelected =
                  selected.length === data.length ? [] : data.map((n) => n._id);
                onSelect(newSelected);
              }}
            />
          </TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Currency</TableCell>
          <TableCell>Category</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((customer) => (
          <TableRow
            key={customer._id}
            selected={selected.includes(customer._id)}
          >
            <TableCell padding='checkbox'>
              <Checkbox
                color='primary'
                checked={selected.includes(customer._id)}
                onChange={() => handleSelect(customer._id)}
              />
            </TableCell>
            <TableCell
              style={{ cursor: 'pointer' }}
              onClick={() => handleNameClick(customer)}
            >
              {customer.name}
            </TableCell>
            <TableCell>{customer.currency}</TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Select
                  value={customer.category ? customer.category._id : ''}
                  displayEmpty
                  fullWidth
                  variant='outlined'
                  sx={{
                    fontSize: '13px',
                    padding: '0',
                    width: '250px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '4px',
                    height: '30px',
                  }}
                >
                  <MenuItem value=''>
                    <em>No Category</em>
                  </MenuItem>
                  {customer.category && (
                    <MenuItem value={customer.category._id}>
                      {customer.category.name}
                    </MenuItem>
                  )}
                </Select>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const Customers = () => {
  const [data, setData] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/customer`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
        console.log('Fetched Customers Data:', response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleImportClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDeleteClick = () => {
    setOpenDeleteConfirm(true);
  };

  const handleDeleteCustomers = async () => {
    try {
      const deletePromises = selectedCustomers.map((customerId) =>
        axios.delete(`${baseURL}/customer/${customerId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );

      await Promise.all(deletePromises);

      const filteredCustomers = data.filter(
        (customer) => !selectedCustomers.includes(customer._id)
      );
      setData(filteredCustomers);
      setSelectedCustomers([]);
      setOpenDeleteConfirm(false);
      console.log('Deleted and Updated Customers Data:', filteredCustomers);
    } catch (error) {
      console.error('Error deleting customers:', error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirm(false);
  };

  return (
    <>
      <div className='overflow-y-auto'>
        <EnhancedTableToolbar
          numSelected={selectedCustomers.length}
          onImportClick={handleImportClick}
          onDeleteClick={handleDeleteClick}
        />
        <TableCus
          data={data}
          selected={selectedCustomers}
          onSelect={setSelectedCustomers}
        />
        <ImportCsvDialog open={isDialogOpen} onClose={handleDialogClose} />
        <Dialog open={openDeleteConfirm} onClose={handleCloseDeleteDialog}>
          <DialogTitle sx={{ color: '#1976d2' }}>
            Delete Confirmation
          </DialogTitle>
          <DialogContent>
            <p>
              Are you sure you want to delete {selectedCustomers.length}{' '}
              customers?
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDeleteDialog}
              sx={{
                background: 'none',
                border: 'none',
                borderRadius: '5px',
                '&:hover': {
                  backgroundColor: 'lightgrey',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteCustomers}
              sx={{
                color: 'black',
                border: 'none',
                borderRadius: '5px',
                '&:hover': {
                  backgroundColor: '#ff5a02',
                },
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Customers;
