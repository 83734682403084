import React, { useContext, useEffect, useState } from "react";
import {
    Button, Toolbar, Tooltip, IconButton, Tabs, Tab, Box, TextField, Select, MenuItem, Switch, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle,Modal,FormControl,Typography,Autocomplete
} from "@mui/material";
import { Textarea } from "flowbite-react";
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import MyPdfViewer from "../components/PDFViewer";
import dayjs from "dayjs";
import { baseURL } from '../Config';
import { BusinessContext } from "../contexts/Business";
// import historyData from './historyData';

const token = localStorage.getItem('token');

const historyData = [{ details: 'Note was changed to blank by Gautam Goswami', time: "Today at 23:34" }, { details: 'Note was changed to blank by Gautam Goswami', time: "Today at 23:34" }]

function EnhancedTableToolbar({ numSelected, onBackClick, onDeleteClick, onSplitClick,onArchiveClick, onCovertClick, onReviewclick }) {
 const navigate=useNavigate()
    const {list,selectedItem,
        setSelectedItem ,backRoute} = useContext(BusinessContext)
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                height: '10%'
            }}
        >
            <div className="flex justify-between items-center w-full">
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px'
                        }}
                        // onClick={onBackClick}
                        onClick={()=>navigate(backRoute)}

                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px'
                        }}
                        onClick={onReviewclick}
                    >
                        Move To Review
                    </Button>
                    <Button
                        disabled={true}
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px'
                        }}
                    >
                        Add To Expense Report
                    </Button>
                    <Button
                        // disabled={true}
                        onClick={onSplitClick}
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px'
                        }}
                    >
                        Split
                    </Button>
                    <Button
                        // disabled={numSelected === 0}
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px'
                        }}
                        onClick={onArchiveClick}
                    >
                        Archive
                    </Button>
                    <Button
                        //disabled={numSelected === 0}
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px'
                        }}
                        onClick={onCovertClick}
                    >
                        Convert To Costs
                    </Button>
                    <Button
                        //disabled={numSelected === 0}
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px'
                        }}
                        onClick={onDeleteClick}
                    >
                        Delete
                    </Button>
                </div>
                <div className="flex items-center">
                    <Button
                        // variant="contained"
                        color="primary"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            color: 'black',
                            fontWeight: '600'
                        }}
                        disabled={selectedItem===0}
                        onClick={()=>{
                            if(selectedItem>0){

                                setSelectedItem((prev)=>prev-1)
                                navigate(`/dashboard/costs/edit/${list[selectedItem-1]?._id}`)
                            }
                        }}
                    >
                        {"<"} Previous
                    </Button>
                    <div style={{ marginRight: '10px' }} className="font-semibold">1/1</div>
                    <Button
                        // variant="contained"
                        color="primary"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            color: 'black',
                            fontWeight: '600'
                        }}
                        disabled={selectedItem===(list?.length-1)}
                        onClick={()=>{
                            if(selectedItem<list?.length){

                                setSelectedItem((prev)=>prev+1)
                                navigate(`/dashboard/costs/edit/${list[selectedItem+1]?._id}`)
                            }
                        }}
                    >
                        Next {">"}
                    </Button>
                </div>
            </div>
        </Toolbar>
    );
}
const ConvertToCosts = ({ open, onClose, selectedCount, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onClose} sx={{ padding: '40px' }}>
            <DialogTitle sx={{ color: "#1976D2", fontWeight: "bold" }}>Convert to Costs Item(s)</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to convert to costs {selectedCount} item(s)?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ background: "none", border: "none", fontWeight: "bold", color: "#000099", '&:hover': { bgcolor: "#f0f0f0" } }}>
                    Cancel
                </Button>
                <Button onClick={onConfirm} sx={{ background: "#ff5a02", border: "none", color: "#ffff", fontWeight: "bold", '&:hover': { bgcolor: "#ff5a02" } }}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const SaleEdit = ({ costId }) => {
    const [selected, setSelected] = useState(1);
    const [openDelete, setOpenDelete] = useState(false);
        const [openSplit, setOpenSplit] = useState(false);
    const [openArchive, setOpenArchive] = useState(false);
    const [currency, setCurrency] = useState('');
    const [description, setDescription] = useState('');
    const [user, setUser] = useState({ name: "None" });
    const [saleData, setSaleData] = useState({});
    const [categoryData, setCategoryData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [isConvertToCostsOpen, setIsConvertToCostsOpen] = useState(false);
    const [isConvertToReviewOpen, setIsConvertToReviewOpen] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)

        const [currentItem, setCurrentItem] = useState({
            category: 'other',
            totalAmount: '1342.52',
            taxAmount: '223.76',
        });
    

    const [newItem, setNewItem] = useState({
            category: '',
            totalAmount: '',
            taxAmount: '',
        });

        const handleCurrentItemChange = (e) => {
            setCurrentItem({
                ...currentItem,
                [e.target.name]: e.target.value,
            });
        };
        const handleNewItemChange = (e) => {
            setNewItem({
                ...newItem,
                [e.target.name]: e.target.value,
            });
        };
    const ZoomIn = (evnt) => {

        setScale((previous) => Math.min(previous + 0.2, 3));
        console.log(scale)
        evnt.stopPropagation();

    }

    const ZoomOut = (evnt) => {

        setScale((previous) => Math.max(previous - 0.2, 0.5));
        console.log(scale)
        evnt.stopPropagation();
    }

    const Rotation = (evnt) => {

        setRotate((previous) => previous + 90);
        console.log(rotate)
        evnt.stopPropagation();
    }

    const handleDownload = (imageURL, evnt) => {
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = 'image.png'; // Optional: Specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        evnt.stopPropagation();
    };

    const handleClickBack = () => {
        navigate(-1);
    }
    const handleDeleteOpen = () => {
        setOpenDelete(true);
    };

    const handleDeleteClose = () => {
        setOpenDelete(false);
    };

    const handleArchiveOpen = () => {
        setOpenArchive(true);
    };

    const handleArchiveClose = () => {
        setOpenArchive(false);
    };

    const handleSplitClick = () => {
       
        setOpenSplit(!openSplit)
}

const categories = [
    { label: 'Accommodation', value: 'accommodation' },
    { label: 'Food', value: 'food' },
    { label: 'IT & Technology', value: 'it_technology' },
    { label: 'Motor Expenses', value: 'motor_expenses' },
    { label: 'Office Supplies', value: 'office_supplies' },
    { label: 'Other', value: 'other' },
    { label: 'Postage', value: 'postage' },
    { label: 'Telecoms & Internet', value: 'telecoms_internet' },
];

    const handleDelete = () => {
        ///${costId}
        axios.delete(`${baseURL}/sales/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                console.log("Deleted successfully", response);
                handleDeleteClose();
                navigate('/dashboard/sales/inbox')
            })
            .catch(error => {
                console.error("There was an error deleting the item!", error);
            });
    };

    const handleArchive = () => {
        ///${costId}
        axios.patch(`${baseURL}/sales/${id}`, { status: 'archive' }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                console.log("Archived successfully", response);
                handleArchiveClose();
                navigate('/dashboard/sales/inbox');
            })
            .catch(error => {
                console.error("There was an error archiving the item!", error);
            });
    };

    const handleEdit = async (e, fieldName) => {
        try {
            let value, name;

            // If fieldName is provided (e.g., for DatePicker), use it; otherwise, extract from event
            if (fieldName) {
                name = fieldName; // Name explicitly passed for DatePicker
                value = e; // DatePicker directly passes the selected date value
            } else if (e.target) {
                name = e.target.name; // For standard inputs like checkbox, text fields
                value = name === 'paid' ? e.target.value === 'on' : e.target.value;
            }

            if (!name) {
                console.error('No field name provided');
                return;
            }

            // Update saleData state
            setSaleData((prevValue) => ({
                ...prevValue,
                [name]: value,
            }));

            // Send patch request
            const response = await axios.patch(`${baseURL}/sales/${id}`, {
                ...saleData,
                [name]: value,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log(response.data);
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    useEffect(() => {
        fetchSupplier();
        fetchCustomer();
        fetchData();
        fetchCategory();
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo.name) {
            setUser(userInfo);
        } else {
            setUser({ name: "None" });
        }
    }, [])

    const fetchData = async () => {
        try {
            ///${costId}
            const response = await axios.get(`${baseURL}/sales/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSaleData({ ...response.data })
            console.log(response.data);

            // const response = await axios.get(`${baseURL}/cost/${id}`, {
            //     headers: {
            //         Authorization: `Bearer ${token}`
            //     }
            // });
            // setsaleData({ ...response.data})
            // console.log(response.data);
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    const fetchSupplier = async () => {
        try {
            ///${costId}
            const response = await axios.get(`${baseURL}/supplier`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSupplierData(response.data);
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    const fetchCustomer = async () => {
        try {
            ///${costId}
            const response = await axios.get(`${baseURL}/customer`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setCustomerData(response.data);
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    const fetchCategory = async () => {
        try {
            ///${costId}
            const response = await axios.get(`${baseURL}/category/getAll`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setCategoryData(response.data);
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleEdit();
        }
    };
    const handleConvertClick = () => {
        setIsConvertToCostsOpen(true);
    };
    const handleReviewClick = () => {
        setIsConvertToReviewOpen(true);
    };
    const handleReview = () => {
        ///${costId}
        axios.patch(`${baseURL}/sales/${id}`, { status: 'Review' }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                handleReviewClose();
                navigate('/dashboard/sales/inbox');
            })
            .catch(error => {
                console.error("There was an error archiving the item!", error);
            });
    };

    const handleReviewClose = () => {
        setIsConvertToReviewOpen(false);
    };
    const handleConvertCancel = () => {
        setIsConvertToCostsOpen(false);
    };
    const handleConvertConfirm = async () => {
        await axios.post(`${baseURL}/sales/convert/${id}`, {}, { // Empty object for data
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        setIsConvertToCostsOpen(false);
        navigate('/dashboard/sales/inbox')
    };

    const handleSplit=async()=>{
        await axios.post(`${baseURL}/sales/split/${id}`,newItem ,{
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        })
            .then(response => {
                console.log("Split Successfully", response);
                // handleDeleteClose();
                setOpenSplit(false)
                navigate('/dashboard/sales/inbox')
            })
            .catch(error => {
                console.error("There was an error spliting the item!", error);
            });
    }
    const style = {
        position: 'absolute',
        top: '42%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'fit-content',
        height: '75vh',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        borderRadius: '1rem'


    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <EnhancedTableToolbar numSelected={0}
                onBackClick={handleClickBack}
                onDeleteClick={handleDeleteOpen}
                onArchiveClick={handleArchiveOpen}
                onCovertClick={handleConvertClick}
                onReviewclick={handleReviewClick} 
                onSplitClick={handleSplitClick}
                />
            <ConvertToCosts open={isConvertToCostsOpen} onClose={handleConvertCancel} selectedCount={selected.length} onConfirm={handleConvertConfirm} />
            <div className="px-4 w-full flex bg-slate-100 justify-around py-4 h-[90%]">
                <div className="w-[48%] bg-white overflow-y-auto">
                    {saleData?.image?.includes('pdf') ?
                    
                    <MyPdfViewer fileUrl={saleData?.image} /> : 
                    <>
                    
                    <img style={{
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                        transition: "transform 0.3s ease-in-out",
                    }}
                        src={saleData?.image} />
                    
                    <div className="flex gap-5 absolute z-50 left-[35%] top-[90vh] transform -translate-x-1/2 -translate-y-1/2">
                        <button className="bg-black rounded-sm text-white p-2" onClick={(e) => ZoomIn(e)} ><ZoomInOutlinedIcon /></button>
                        <button className="bg-black rounded-sm text-white p-2" onClick={(e) => ZoomOut(e)}><ZoomOutOutlinedIcon /></button>
                        <button className="bg-black rounded-sm text-white p-2" onClick={(e) => Rotation(e)}><RotateRightOutlinedIcon /></button>
                        <button className="bg-black rounded-sm text-white p-2" onClick={(e) => handleDownload(saleData?.image, e)}><FileDownloadOutlinedIcon /></button>
                    </div>
                    </>
                    
                    // <img src={saleData?.image} />
                    }
                </div>
                <div className="w-[48%] bg-white h-full">
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs onChange={(e, value) => setSelected(value)} sx={{ fontSize: '14px', fontWeight: "bold", backgroundColor: '#ebf0ec' }} aria-label="basic tabs example">
                            <Tab sx={{ fontSize: '12px', fontWeight: "bold", backgroundColor: selected === 1 ? 'white' : '' }} value={1} label="Details" />
                            <Tab sx={{ fontSize: '12px', fontWeight: "bold", backgroundColor: selected === 2 ? 'white' : '' }} value={2} label="Note" />
                            <Tab sx={{ fontSize: '12px', fontWeight: "bold", backgroundColor: selected === 3 ? 'white' : '' }} value={3} label="History" />
                        </Tabs>
                    </Box>
                    {selected === 1 && <div className="p-4 overflow-y-scroll max-h-[88%]">
                        <div className="text-[13px] font-semibold mb-2">
                            ITEM DETAILS
                        </div>
                        <div class="flex flex-col gap-2">
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Item ID</div>
                                <div class="text-[13px] font-bold"><TextField disabled
                                    value={saleData?.itemId}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: "35px"
                                        }
                                        , width: "200px"
                                    }} size="small" /></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Document Owner</div>
                                <div class="text-[13px] text-[#546478]"><select
                                    style={{ height: "35px", width: "200px", borderColor: "#E5E7EB", padding: "0 10px", borderRadius: '3px' }}
                                    value={saleData?.documentOwner || "none"}
                                    name="documentOwner"
                                    onChange={handleEdit}
                                >
                                    <option value={user.name}>{user.name}</option>
                                </select></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Type</div>
                                <div class="text-[13px] text-[#546478] "><select
                                    style={{ height: "35px", width: "200px", borderColor: "#E5E7EB", padding: "0 10px", borderRadius: '3px' }}
                                    value={saleData?.type}
                                    name="type"
                                    onChange={handleEdit}
                                >
                                    <option value={"None"}>None</option>
                                </select></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Date</div>
                                <div class="text-[13px] font-bold">
                                    <DatePicker value={saleData?.date ? dayjs(saleData.date) : null}
                                        name="date"
                                          format="DD/MM/YYYY"
                                        onChange={(newValue) => handleEdit(newValue, 'date')}
                                        sx={{
                                            width: "200px",
                                            "& .MuiOutlinedInput-root": {
                                                height: "35px",
                                                "& fieldset": {
                                                    borderColor: "#E5E7EB",
                                                },
                                                "&:hover fieldset": {
                                                    borderColor: "black",
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "transparent",
                                                    borderWidth: "1px",
                                                },
                                            },
                                            '& input::-webkit-calendar-picker-indicator': {
                                                filter: 'invert(50%)',
                                            },
                                        }}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                            },
                                        }} /></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Due Date</div>
                                <div class="text-[13px] font-bold">
                                    <DatePicker value={saleData?.dueDate ? dayjs(saleData.dueDate) : null}
                                        name="date"
                                          format="DD/MM/YYYY"
                                        onChange={(newValue) => handleEdit(newValue, 'dueDate')}
                                        sx={{
                                            width: "200px",
                                            "& .MuiOutlinedInput-root": {
                                                height: "35px",
                                                "& fieldset": {
                                                    borderColor: "#E5E7EB",
                                                },
                                                "&:hover fieldset": {
                                                    borderColor: "black",
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "transparent",
                                                    borderWidth: "1px",
                                                },
                                            },
                                            '& input::-webkit-calendar-picker-indicator': {
                                                filter: 'invert(50%)',
                                            },
                                        }}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                            },
                                        }} /></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Supplier</div>
                                <div class="text-[13px] text-[#546478]"><select
                                    style={{ height: "35px", width: "200px", borderColor: "#E5E7EB", padding: "0 10px", borderRadius: '3px' }}
                                    value={saleData?.supplier}
                                    name="supplier"
                                    onChange={handleEdit}
                                >
                                    {supplierData.map(({ name }, index) => {
                                        return <option sx={{ fontSize: "13px" }} value={name}>{name}</option>
                                    })}
                                </select></div>
                            </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Purchase Order Number</div>
                                <div class="text-[13px] font-bold border-[#E5E7EB]"><TextField
                                    value={saleData?.purchaseOrderNumber}
                                    name="purchaseOrderNumber"
                                    onChange={handleEdit}
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            color: '#546478',

                                        },
                                        "& .MuiInputBase-root": {
                                            height: "35px"
                                        }
                                        , width: "200px",


                                        '& .MuiOutlinedInput-root': {
                                            '&:hover fieldset': {
                                                borderColor: 'black', // Remove border on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'transparent', // Remove blue outline when focused

                                            },
                                        },
                                    }
                                    } size="small" /></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Document Reference</div>
                                <div class="text-[13px] font-bold"><TextField
                                    value={saleData?.documentReference}
                                    name="documentReference"
                                    onChange={handleEdit}
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            color: '#546478'
                                        },
                                        "& .MuiInputBase-root": {
                                            height: "35px"
                                        }
                                        , width: "200px"
                                        ,
                                        '& .MuiOutlinedInput-root': {
                                            '&:hover fieldset': {
                                                borderColor: 'black', // Remove border on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'transparent', // Remove blue outline when focused

                                            },
                                        },
                                    }
                                    } size="small" /></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Category</div>
                                <div class="text-[13px] text-[#546478]"><select
                                    style={{ height: "35px", width: "200px", borderColor: "#E5E7EB", padding: "0 10px", borderRadius: '3px' }}
                                    value={saleData?.category}
                                    name="category"
                                    onChange={handleEdit}
                                >
                                    {categoryData.map(({ name }, index) => {
                                        return <option sx={{ fontSize: "13px" }} value={name}>{name}</option>
                                    })}
                                </select></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Customer</div>
                                <div class="text-[13px] text-[#546478] "><select
                                    style={{ height: "35px", width: "200px", borderColor: "#E5E7EB", padding: "0 10px", borderRadius: '3px' }}
                                    value={saleData?.customer}
                                    name="customer"
                                    onChange={handleEdit}
                                >
                                    {customerData.map(({ name }) => {
                                        return <option sx={{ fontSize: "13px" }} value={name}>{name}</option>
                                    })}
                                </select></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Description</div>
                                <div class="text-[13px] font-bold"><Textarea value={saleData?.description}
                                    name="description"
                                    onChange={handleEdit}
                                    onKeyDown={handleKeyDown} /></div> </div>
                        </div>
                        <div className="text-[13px] font-semibold mt-4 mb-2">
                            AMOUNT
                        </div>
                        <div class="flex flex-col gap-2">
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Currency</div>
                                <div class="text-[13px] text-[#546478] "><select
                                    style={{ height: "40px", width: "200px", borderColor: "#E5E7EB", padding: "0 10px", borderRadius: '3px' }}
                                    value={saleData?.currency}
                                    name="currency"
                                    onChange={handleEdit}
                                    onKeyDown={handleKeyDown}
                                >
                                    <option value={"None"}>None</option>
                                    <option value="USD">USD</option>
                                </select></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                {/* <div class="text-[13px]">Total Amount</div> */}
                                <div class="text-[13px]">Net Amount</div>
                                <div class="text-[13px] font-bold">
                                    <TextField disabled
                                        value={saleData?.totalAmount}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                height: "35px"
                                            }
                                            , width: "200px"
                                        }} size="small" /></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Tax</div>
                                <div class="text-[13px] text-[#546478] "><select
                                    style={{ height: "35px", width: "200px", borderColor: "#E5E7EB", padding: "0 10px", borderRadius: '3px' }}
                                    value={saleData?.tax}
                                    name="tax"
                                    onChange={handleEdit}
                                >
                                    <option value={"None"}>None</option>
                                </select></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                {/* <div class="text-[13px]">Tax Amount</div> */}
                                <div class="text-[13px]">Tax Amount</div>
                                <div class="text-[13px] font-bold"><TextField disabled
                                    value={saleData?.taxAmount}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: "35px"
                                        }
                                        , width: "200px"
                                    }} size="small" /></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Total Amount</div>
                                <div class="text-[13px] font-bold"><TextField disabled
                                    value={saleData?.totalAmount + saleData?.taxAmount}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: "35px"
                                        }
                                        , width: "200px"
                                    }} size="small" /></div> </div>
                        </div>
                        <div className="text-[13px] font-semibold mt-4 mb-2">
                            PAYMENT
                        </div>
                        <div class="flex flex-col gap-2">
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Paid</div>
                                <div class="text-[13px] font-bold"><Switch defaultChecked={saleData?.paid}
                                    name="paid"
                                    onChange={handleEdit} /></div> </div>
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-[13px]">Payment Method</div>
                                <div class="text-[13px] text-[#546478] "><select
                                    style={{ height: "35px", width: "200px", borderColor: "#E5E7EB", padding: "0 10px", borderRadius: '3px' }}
                                    value={saleData?.paymentMethod}
                                    name="paymentMethod"
                                    onChange={handleEdit}
                                >
                                    <option value={"None"}>None</option>
                                </select></div> </div>
                        </div>
                        <div className="text-[13px] font-semibold mt-4 mb-2">
                            LINE ITEMS
                        </div>
                        <div className="w-full h-[70px] flex justify-center items-center border border-slate-300">
                            <Button

                                variant="contained"
                                color="primary"
                                sx={{
                                    fontSize: '12px',
                                    padding: '6px 15px',
                                    marginRight: '10px'
                                }}
                            >
                                Create line items
                            </Button>
                        </div>
                        <div className="mt-6 mb-4">


                            <Button

                                variant="contained"
                                color="primary"
                                sx={{
                                    fontSize: '12px',
                                    padding: '6px 15px',
                                    marginRight: '10px'
                                }}
                                onClick={handleReviewClick}
                            >
                                Move To Review
                            </Button>
                            <Button
                                disabled={true}
                                variant="contained"
                                color="primary"
                                sx={{
                                    fontSize: '12px',
                                    padding: '6px 15px',
                                    marginRight: '10px'
                                }}
                            >
                                Add To Expense Report
                            </Button>
                            <Button

                                variant="contained"
                                color="primary"
                                sx={{
                                    fontSize: '12px',
                                    padding: '6px 15px',
                                    marginRight: '10px'
                                }}
                                onClick={handleArchiveOpen}
                            >
                                Archive
                            </Button>
                            <Button

                                variant="contained"
                                color="primary"
                                sx={{
                                    fontSize: '12px',
                                    padding: '6px 15px',
                                    marginRight: '10px'
                                }}
                            >
                                More
                            </Button>
                        </div>
                    </div>}
                    {selected === 2 && <div className="p-4">
                        <Textarea onChange={handleEdit} name="note" value={saleData?.note} />
                    </div>}
                    {selected === 3 && <div className="p-4">
                        {historyData.map(data => {
                            return (
                                <div className="flex mb-4">
                                    <div className="w-[20px] h-[20px] bg-blue-200 relative rounded-full mr-3">
                                        <div className="w-[10px] h-[10px] bg-blue-500 absolute top-[25%] left-[25%] rounded-full">
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-[13px] font-semibold">
                                            {data.details}
                                        </div>
                                        <div className="text-slate-500 text-[13px]">
                                            {data.time}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>}
                </div>

                {/* Delete Confirmation Dialog */}
                <Dialog
                    open={openDelete}
                    onClose={handleDeleteClose}
                >
                    <DialogTitle sx={{ color: '#1976d2' }}>Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this item?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleDeleteClose}
                            sx={{
                                background: 'none',
                                border: 'none',
                                borderRadius: '5px',
                                '&:hover': { background: 'lightgrey' }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDelete}
                            sx={{
                                background: '#ff5a02',
                                border: 'none',
                                color: 'black',
                                borderRadius: '5px',
                                '&:hover': { boxShadow: '0 0 5px #ff5a02', background: '#ff5a02', }
                            }}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Archive Confirmation Dialog */}
                <Dialog
                    open={openArchive}
                    onClose={handleArchiveClose}
                >
                    <DialogTitle sx={{ color: '#1976d2' }}>Archive</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to archive this item?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleArchiveClose}
                            sx={{
                                background: 'none',
                                border: 'none',
                                borderRadius: '5px',
                                '&:hover': { background: 'lightgrey' }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleArchive}
                            sx={{
                                background: '#ff5a02',
                                border: 'none',
                                color: 'black',
                                borderRadius: '5px',
                                '&:hover': { boxShadow: '0 0 5px #ff5a02', background: '#ff5a02', }
                            }}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isConvertToReviewOpen}
                    onClose={handleReviewClose}
                >
                    <DialogTitle sx={{ color: '#1976d2' }}>Review</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to move this item to review?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleReviewClose}
                            sx={{
                                background: 'none',
                                border: 'none',
                                borderRadius: '5px',
                                '&:hover': { background: 'lightgrey' }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleReview}
                            sx={{
                                background: '#ff5a02',
                                border: 'none',
                                color: 'black',
                                borderRadius: '5px',
                                '&:hover': { boxShadow: '0 0 5px #ff5a02', background: '#ff5a02', }
                            }}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>


            {/*====================== split Modal =========================== */}
            <Modal
                open={openSplit}
                onClose={() => setOpenSplit(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="flex justify-between fixed shadow-lg w-full z-10 bg-white p-2">
                        <Typography sx={{ color: '#1976d2' }} id="modal-modal-title" variant="h6" component="h2">
                            Split items
                        </Typography>
                        <Button
                            // variant="contained"
                            color="primary"
                            sx={{
                                fontSize: '12px',
                                padding: '6px 15px',
                                color: '#1976d2',
                                fontWeight: '600'

                            }}
                            onClick={() => setOpenSplit(false)}
                        >
                            <div className="p-1 border-2 border-[#737373] active:translate-y-1">
                                <CloseIcon />
                            </div>

                        </Button>
                    </div>
                    <div className="flex h-full overflow-y-auto z-0">
                        <div className="w-[50vw]" >
                            <img src={saleData?.image} />
                        </div>
                        <div className="flex flex-col items-center p-4 text-sm gap-3 mt-16 ">
                            <div className="border-2 border-[#b6b6b8] bg-white text-[#878787]  tracking-wide p-6  ">
                                <InfoOutlinedIcon /> Splitting creates a new item with the same image as the  <br />
                                current item. You can apply different categories, total and tax  <br />
                                All other fields of the new item will be copied from the current <br />
                                item.
                            </div>
                            <Box sx={{ width: '100 %', margin: 'auto', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Typography variant="h6">CURRENT ITEM</Typography>
                                {/* Current Item Category */}
                                <div className="flex justify-between items-center w-full gap-[10rem] ">
                                    <div>Category</div>
                                    <FormControl sx={{
                                        width: '16rem'
                                    }}>

                                        <Autocomplete
                                            options={categories}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Search"
                                                    variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
                                                                {/* Add search icon here if needed */}
                                                            </Box>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            disableClearable
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props}>
                                                    {option.label}
                                                </Box>
                                            )}
                                            disablePortal
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                        />
                                    </FormControl>
                                </div>

                                {/* Current Item Total Amount */}
                                <div className="flex justify-between items-center w-full gap-[12rem] ">
                                    <div>Total Amount</div>
                                    <TextField
                                        label="Total Amount"
                                        name="totalAmount"
                                        value={currentItem.totalAmount}
                                        onChange={handleCurrentItemChange}
                                        fullWidth
                                    />
                                </div>

                                {/* Current Item Tax Amount */}
                                <div className="flex justify-between items-center w-full gap-[12rem] ">
                                    <div>Tax Amount

                                    </div>
                                    <TextField
                                        label="Tax Amount"
                                        name="taxAmount"
                                        value={currentItem.taxAmount}
                                        onChange={handleCurrentItemChange}
                                        fullWidth
                                    />
                                </div>

                                <Typography variant="h6" sx={{ mt: 3 }}>NEW ITEM</Typography>
                                {/* New Item Category */}
                                <div className="flex justify-between items-center w-full gap-[10rem] "><div>Category</div>
                                    <FormControl sx={{
                                        width: '16rem'
                                    }}>

                                        <Select
                                            name="category"
                                            value={newItem.category}
                                            onChange={handleNewItemChange}
                                            label="Category"
                                        >
                                            {categories.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                {/* New Item Total Amount */}
                                <div className="flex justify-between items-center w-full gap-[12rem] ">
                                    <div>
                                        Total Amount
                                    </div>
                                    <TextField
                                        label="Total Amount"
                                        name="totalAmount"
                                        value={newItem.totalAmount}
                                        onChange={handleNewItemChange}
                                        fullWidth
                                    />
                                </div>


                                {/* New Item Tax Amount */}
                                <div className="flex justify-between items-center w-full gap-[12rem]">
                                    <span>Tax Amount</span>
                                    <TextField
                                        label="Tax Amount"
                                        name="taxAmount"
                                        value={newItem.taxAmount}
                                        onChange={handleNewItemChange}
                                        fullWidth

                                    />
                                </div>

                            </Box>

                        </div>

                    </div>
                    <div className="flex justify-end gap-6 p-6 items-center shadow-lg w-full bg-white mb-4">
                        <button className="p-1 border-2 rounded-sm border-[#737373] active:translate-y-1" onClick={() => setOpenSplit(!openSplit)}>Cancel</button>
                        <button className="text-white border-2 border-[#1976d2] active:translate-y-1 shadow-lg bg-[#1976d2] rounded-sm p-1 px-2 " onClick={handleSplit}>Split</button>
                    </div>

                </Box>
            </Modal>
        </LocalizationProvider>
    )
}

export default SaleEdit