import React, { useContext, useEffect, useState } from 'react';
import { baseURL } from '../Config';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Menu,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Autocomplete
} from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import {
  Search,
  MoreVert,
  AdminPanelSettings,
  CloudUpload,
} from '@mui/icons-material';
import { data } from 'autoprefixer';
import { BusinessContext } from '../contexts/Business';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const token = localStorage.getItem('token');
const userToken = localStorage.getItem('userToken');

const MyClients = () => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    name: '',
    industry: '',
    practicecode: '',
    userRole: '',
    verification: false,
  });
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [colleagues, setColleagues] = useState([
    // { name: 'Will West', access: false },
    // { name: 'Sally Smith', access: false },
    // { name: 'Jack Smith', access: false },
    // { name: 'Zara Smith', access: false },
    { name:JSON.parse(localStorage.getItem('userInfo')).name, access: false },
  ]);
  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [showTable, setShowTable] = useState(true);
  const [currSelectedBusiness, setCurrSelectedBusiness] = useState();

  const { selectedBusiness, setSelectedBusiness, businessInfo } =
    useContext(BusinessContext);

  const businessType = JSON.parse(
    localStorage.getItem('userInfo')
  ).businessType;
  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
    setStep(1);
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      name: '',
      industry: '',
      practicecode: '',
      userRole: '',
      verification: false,
    });
    setShowTable(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await fetch(`${baseURL}/business`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    });

    let data = await response.json();
    data =data.filter((us)=> us.name !==selectedBusiness)
    setUsers(data);
  };

  console.log(users, 'data of user');
  const handleNext = async () => {
    if(step==1){
      
      if(formData.name=='' || formData.industry=='' || formData.practicecode=='') return
    }
    if (step < 3) setStep(step + 1);
    else {
      const newBusinessRes = await fetch(`${baseURL}/business`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
          Accept: 'application/json',
        },
        body: JSON.stringify(formData),
      });
      // console.log("newBusinessRes",newBusinessRes)
      const newBusinessData = await newBusinessRes.json(); // Parse response JSON

      console.log('newBusinessRes', newBusinessData);
      fetchData();
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        name: '',
        industry: '',
        userRole: '',
        practiceCode: '',
        verification: false,
      });

      const response = await fetch(`${baseURL}/client/send-invitation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          clientName: formData?.firstName,
          email: formData?.email,
          userId: newBusinessData?.userDetail?._id,
        }),
      });

      // handleClose();
    }
  };

  const handleMenuClick = (event, business) => {
    setAnchorEl(event.currentTarget);
    setCurrSelectedBusiness(business);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (e) => {
    setSearchBoxValue(e.target.value);
    setShowTable(true);
  };

  const handleColleagueCheckboxChange = (index) => {
    const updatedColleagues = [...colleagues];
    updatedColleagues[index].access = !updatedColleagues[index].access;
    setColleagues(updatedColleagues);
  };
  const handleSkipInvitation = async () => {
    console.log(users);
    setUsers([...users, formData]);
    await fetch(`${baseURL}/business`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(formData),
    });
    fetchData();
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      name: '',
      industry: '',
      userRole: '',
      practiceCode: '',
      verification: false,
    });
    handleClose();
  };

  const handleContinueInvitation = async () => {
    // alert(`Invitation has been sent to ${formData.email}`);

    console.log(users);
    // setUsers([...users, formData]);

    // const response =  await fetch(`${baseURL}/client/send-invitation`, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${userToken}`,
    //     },
    //     body: JSON.stringify({
    //         clientName:formData?.firstName,
    //         email:formData?.email
    //     }),
    // });

    // console.log("response ==",response)
    // if(response?.ok){

    await handleNext();
    handleClose();
    // }
    // fetchData();
    // setFormData({
    //     firstName: '',
    //     lastName: '',
    //     email: '',
    //     mobile: '',
    //     name: '',
    //     industry: '',
    //     userRole: '',
    //     practiceCode: '',
    //     verification: false
    // });
  };

  const marks = [
    {
      value: 1,
      label: (
        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
          Client Details
        </span>
      ),
    },
    {
      value: 2,
      label: (
        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
          Account Access
        </span>
      ),
    },
    {
      value: 3,
      label: (
        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
          Add User(optional)
        </span>
      ),
    },
  ];

  const navigate = useNavigate();

  const changeAccess = async () => {
    const token = localStorage.getItem('token');
    const userToken = localStorage.getItem('userToken');
    const id = currSelectedBusiness?._id;

    try {
      const response = await axios.get(`${baseURL}/business/access/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      //  console.log(response.data.token+"sass")
      //  setSupplierData(response.data);
      console.log('business name id', id);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('selectedBusiness', currSelectedBusiness?.name);
      console.log('Change Suppliers Data:', response.data); // Console log the fetched data
      setSelectedBusiness('currSelectedBusiness?.name');
      navigate(`/dashboard/editUser/?id=${currSelectedBusiness?._id}`);
      window.location.reload();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div style={{ padding: '16px' }}>
      <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
        <Button
          // variant="contained"
          onClick={handleClickOpen}
          // style={{
          //     fontWeight: "bold",
          //     boxShadow: "none",
          //     fontSize: "12px",
          //     color: "#ffff",
          //     textTransform: "none",
          //     backgroundColor: "#1976d2",
          // }}
        >
          Add Client
        </Button>
        {/* <Button
                    variant="contained"
                    component="label"
                    style={{
                        fontWeight: "bold",
                        boxShadow: "none",
                        fontSize: "12px",
                        color: "#ffff",
                        textTransform: "none",
                        backgroundColor: "#1976d2",
                    }}
                >
                    Export Client List
                    <input type="file" hidden />
                </Button>
                <Button
                    variant="contained"
                    component="label"
                    startIcon={<CloudUpload />}
                    style={{
                        fontWeight: "bold",
                        boxShadow: "none",
                        fontSize: "12px",
                        color: "#ffff",
                        textTransform: "none",
                        backgroundColor: "#1976d2",
                    }}
                >
                    Upload Practice Codes
                    <input type="file" hidden />
                </Button> */}
        <div
          style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}
        >
          <TextField
            variant='outlined'
            size='small'
            placeholder='Name'
            InputProps={{ endAdornment: <Search /> }}
            style={{ marginLeft: '16px', height: '30px' }}
            className='!focus:outline-none !focus:border-none !focus:ring-0'
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:focus': {
                  outline: 'none',
                },
                '&.Mui-focused': {
                  outline: 'none',
                  boxShadow: 'none', // Remove focus shadow
                  borderColor: 'inherit', // Maintain default border color
                },
              },
            }}
          />
          {/* <Typography
                        variant="h6"
                        style={{
                            color: "#1976d2",
                            fontSize: "13px",
                            marginLeft: "5px",
                            marginTop: "5px",
                        }}
                    >
                        Auto Account Settings
                    </Typography> */}
        </div>
      </div>
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '14px',
        }}
      >
        <Typography
          variant='h6'
          style={{
            color: '#ff5a02',
            fontWeight: 'bold',
            fontSize: '14px',
            cursor: 'pointer',
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.textDecoration = 'underline')
          }
          onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
        >
          Active Clients
        </Typography>
        <Typography
          variant='h6'
          style={{
            color: '#ff5a02',
            fontWeight: 'bold',
            fontSize: '13px',
            cursor: 'pointer',
            marginRight: '80%',
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.textDecoration = 'underline')
          }
          onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
        >
          Deactivated Clients
        </Typography>
      </header>

      <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#1976d2', fontWeight: 'bold' }}>
    Create a client account
    <IconButton onClick={handleClose} size="small">
      <CloseIcon />
    </IconButton>
  </DialogTitle>
        <DialogContent
          style={{ padding: '24px', overflowX: 'hidden', margin: '10px' }}
        >
          <Slider
            value={step}
            marks={marks}
            step={null}
            max={3}
            min={1}
            disabled
            style={{
              color: '#1976d2',
              marginBottom: '30px',
              width: '50%',
              marginLeft: '140px',
              marginTop: '-27px',
            }}
          />
          {step === 1 && (
            <div>
              <Typography
                variant='h6'
                style={{
                  color: '#546478',
                  marginTop: '50x',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                1. Enter client details
              </Typography>
              <form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '8px',
                    marginTop: '18px',
                  }}
                >
                  <Typography
                    style={{
                      marginRight: '8px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Client name:
                  </Typography>
                  <FormControl
                    variant='outlined'
                    size='small'
                    style={{ marginLeft: '130px', width: '55%' }}
                  >
                    <TextField
                      required
                      label='Client Name'
                      name='name'
                      onChange={handleChange}
                      variant='outlined'
                      style={{ height: '40px', width: '90%' }}
                    />
                  </FormControl>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '8px',
                    justifyContent: 'space-between',
                    marginTop: '18px',
                  }}
                >
                  <Typography
                    style={{
                      marginRight: '8px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Practice Code:
                  </Typography>
                  <FormControl
                    variant='outlined'
                    size='small'
                    style={{ marginLeft: '130px', width: '55%' }}
                  >
                    <TextField
                      required
                      label='Practice Code '
                      name='practicecode'
                      value={formData.practicecode}
                      onChange={handleChange}
                      variant='outlined'
                      style={{ height: '40px', width: '90%' }}
                    />
                  </FormControl>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '15px',
                    marginRight: '35px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    style={{
                      marginRight: '8px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Industry:
                  </Typography>
                  <FormControl
                    variant='outlined'
                    size='small'
                    style={{ marginLeft: '130px', width: '54%' }}
                  >
                    <InputLabel htmlFor='industry'></InputLabel>
                    <Autocomplete
  fullWidth
  className={'!w-full'}
  value={formData.industry ||  { label: '', value: '' }}
  onChange={(event, value) => handleChange({ target: { name: 'industry', value } })}
  options={[
    { label: 'Accounting and Bookkeeping', value: 'Accounting and Bookkeeping' },
    { label: 'Advertising, Communications and Public Relations', value: 'Advertising, Communications and Public Relations' },
    { label: 'Agriculture, Forestry and Fishing', value: 'Agriculture, Forestry and Fishing' },
    { label: 'Arts, Entertainment and Recreation (including Photography and Lit/Culture)', value: 'Arts, Entertainment and Recreation (including Photography and Lit/Culture)' },
    { label: 'Automotive Sales and Repair', value: 'Automotive Sales and Repair' },
    { label: 'Construction Works (including Demolition, Site Works, Plumbing, Painting and Plastering)', value: 'Construction Works (including Demolition, Site Works, Plumbing, Painting and Plastering)' },
    { label: 'Consulting (including Coaching, Legal and Recruitment Services)', value: 'Consulting (including Coaching, Legal and Recruitment Services)' },
    { label: 'Crafts, Wholesale and Retail Trade', value: 'Crafts, Wholesale and Retail Trade' },
    { label: 'Design, Architecture and Engineering', value: 'Design, Architecture and Engineering' },
    { label: 'Education', value: 'Education' },
    { label: 'Electricity & Gas Supply', value: 'Electricity & Gas Supply' },
    { label: 'Financial and Insurance Activities', value: 'Financial and Insurance Activities' },
    { label: 'Hair, Beauty and Wellness', value: 'Hair, Beauty and Wellness' },
    { label: 'IT and Computer Services', value: 'IT and Computer Services' },
    { label: 'Manufacturing (including Processing, Production and Repair)', value: 'Manufacturing (including Processing, Production and Repair)' },
    { label: 'Medical, Dental, and Health Services', value: 'Medical, Dental, and Health Services' },
    { label: 'Mining and Quarrying', value: 'Mining and Quarrying' },
    { label: 'Non-Profit, Sports Clubs, Teams and Associations', value: 'Non-Profit, Sports Clubs, Teams and Associations' },
    { label: 'Online Retail and Commerce (including Online Retail and Online Marketplaces)', value: 'Online Retail and Commerce (including Online Retail and Online Marketplaces)' },
    { label: 'Professional, Scientific and Technical Activities', value: 'Professional, Scientific and Technical Activities' },
    { label: 'Public Administration and Defence; Compulsory Social Security', value: 'Public Administration and Defence; Compulsory Social Security' },
    { label: 'Real Estate Activities (including Property Rental)', value: 'Real Estate Activities (including Property Rental)' },
    { label: 'Restaurant, Catering, and Service', value: 'Restaurant, Catering, and Service' },
    { label: 'Transportation (including Trucking, Delivery and Storage)', value: 'Transportation (including Trucking, Delivery and Storage)' },
    { label: 'Water Supply, Sewerage, Waste Management and Remediation Activities', value: 'Water Supply, Sewerage, Waste Management and Remediation Activities' }
  ]}
  getOptionLabel={(option) => option.label}
  renderInput={(params) => (
    <TextField
      {...params}
      variant='outlined'
      fullWidth
      className={'!w-full'}
      label='Select Industry'
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position='start'>
            <Search />
          </InputAdornment>
        ),
      }}
    />
  )}
  disableClearable
/>

                  </FormControl>
                </div>
              </form>
            </div>
          )}
          {step === 2 && (
            <div>
              <Typography
                variant='h6'
                style={{
                  color: '#546478',
                  marginTop: '50x',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                2. Share this client's account with your colleagues
              </Typography>
              <TextField
                placeholder='Search by name or email address'
                variant='outlined'
                value={searchBoxValue}
                onChange={handleSearchChange}
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: '10px', width: '100%' }}
                onClick={() => setShowTable(true)}
              />
              {showTable && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Item</TableCell>
                      <TableCell
                        padding='checkbox'
                        style={{ fontWeight: 'bold' }}
                      >
                        Access
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {colleagues
                      .filter((colleague) =>
                        colleague.name
                          .toLowerCase()
                          .includes(searchBoxValue.toLowerCase())
                      )
                      .map((colleague, index) => (
                        <TableRow key={index}>
                          <TableCell>{colleague.name}</TableCell>
                          <TableCell padding='checkbox'>
                            <Checkbox
                              checked={colleague.access}
                              onChange={() =>
                                handleColleagueCheckboxChange(index)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </div>
          )}
          {step === 3 && (
            <div>
              <Typography
                variant='h6'
                style={{ color: '#546478', marginTop: '50x', fontSize: '17px' }}
              >
                3. Invite your client to use zoom Prepare
              </Typography>
              <Typography>
                <ul
                  style={{
                    listStyleType: 'disc',
                    marginLeft: '10px',
                    color: '#546478',
                    fontSize: '14px',
                    padding: '10px',
                    lineHeight: '26px',
                  }}
                >
                  <li>They can upload their financial paperwork</li>
                  <li>They ll have visibility of bussiness transactions</li>
                  <li>You can easily advise and help your client</li>
                </ul>
              </Typography>
              <form style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '8px',
                    marginTop: '18px',
                  }}
                >
                  <Typography style={{ marginRight: '8px', fontSize: '14px' }}>
                    First Name:
                  </Typography>
                  <TextField
                    required
                    label=''
                    name='firstName'
                    value={formData.firstName}
                    onChange={handleChange}
                    variant='outlined'
                    size='small'
                    style={{
                      marginBottom: '8px',
                      height: '30px',
                      marginLeft: '130px',
                      width: '55%',
                    }}
                  />
                </div>
                <div style={{ display: 'flex', marginTop: '15px' }}>
                  <Typography style={{ marginRight: '8px', fontSize: '14px' }}>
                    Last Name:
                  </Typography>
                  <TextField
                    required
                    label=''
                    name='lastName'
                    value={formData.lastName}
                    onChange={handleChange}
                    variant='outlined'
                    size='small'
                    style={{
                      marginBottom: '8px',
                      height: '30px',
                      marginLeft: '130px',
                      width: '55%',
                    }}
                  />
                </div>
                <div style={{ display: 'flex', marginTop: '15px' }}>
                  <Typography
                    style={{
                      marginRight: '8px',
                      fontSize: '14px',
                      marginTop: '10px',
                    }}
                  >
                    Email address:
                  </Typography>
                  <TextField
                    required
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                    variant='outlined'
                    size='small'
                    style={{
                      height: '30px',
                      width: '55%',
                      marginLeft: '110px',
                      marginTop: '10px',
                    }}
                  />
                </div>
              </form>
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ padding: '16px' }}>
          {step > 1 && (
            <Button
              onClick={handleBack}
              style={{
                backgroundColor: '#1976d2',
                color: '#ffff',
                fontWeight: 'bold',
                fontSize: '12px',
                boxShadow: 'none',
              }}
            >
              Back
            </Button>
          )}
          {step < 3 ? (
            <Button
              onClick={handleNext}
              style={{
                backgroundColor: '#1976d2',
                color: '#ffff',
                fontWeight: 'bold',
                fontSize: '12px',
                boxShadow: 'none',
              }}
            >
              Next
            </Button>
          ) : (
            <>
              <Button
                onClick={handleSkipInvitation}
                style={{
                  backgroundColor: '#1976d2',
                  color: '#ffff',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'none',
                }}
              >
                Skip Invitation
              </Button>
              <Button
                onClick={handleContinueInvitation}
                style={{
                  backgroundColor: '#1976d2',
                  color: '#ffff',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'none',
                }}
              >
                Continue Invitation
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <div style={{ marginTop: '16px' }}>
        {users.length > 0 ? (
          users.map((user, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px',
                borderBottom: '1px solid #ddd',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <div className='flex gap-2 items-center'>
                    <AccountCircleIcon
                      style={{ marginRight: '8px', color: '#546478' }}
                    />
                    <div className='text-base font-bold text-prm'>
                      {user.name}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div style={{ marginRight: "20%", fontSize: "12px", color: "#546478", cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                            <AdminPanelSettings />
                            Administrator
                        </div> */}
              <div>
                <Button
                  // endIcon={<MoreVert />}
                  //   style={{
                  //     border: 'none',
                  //     backgroundColor: '#D8D8D8',
                  //     borderRadius: '5px',
                  //     height: '35px',
                  //     color: '#546478',
                  //     paddingLeft: '10px',
                  //     fontWeight: 'bold',
                  //     textTransform: 'none',
                  //     display: 'flex',
                  //     alignItems: 'center',
                  //   }}
                  onClick={async (e) => {
                    await handleMenuClick(e, user); // Ensure handleMenuClick executes before changeAccess
                    await changeAccess();
                  }}
                >
                  {' '}
                  Manage
                  {/* <IconButton >
                    <MoreVert />
                  </IconButton> */}
                  {/* <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem
                      onClick={async () => {
                        await changeAccess();
                      }}
                    >
                      Edit User
                    </MenuItem>
                 
                    <MenuItem onClick={handleMenuClose}>
                      Change Password
                    </MenuItem>
                  </Menu> */}
                </Button>
              </div>
            </div>
          ))
        ) : (
          <Typography variant='body2' style={{ color: '#999' }}>
            No users found or loading...
          </Typography>
        )}
      </div>
    </div>
  );
};

export default MyClients;
