import React, { useContext, useEffect, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HeaderUserInfo from './HeaderUserInfo';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PersonIcon from '@mui/icons-material/Person';
import RedeemIcon from '@mui/icons-material/Redeem';
import img2 from './../assets/img2.jpg';
import { baseURL } from '../../Config';
import { HeaderStepContext } from './context';

const Role = () => {
    const navigate = useNavigate();
    const [selectedBox, setSelectedBox] = useState(null);
    const [errors, setErrors] = useState({});
    const { setActiveStep } = useContext(HeaderStepContext);

    useEffect(() => {
        setActiveStep(2)
    }
        , []);

    const handleContinue = async () => {
        if (selectedBox === null) {
            setErrors({ apiError: 'Please select a box.' });
            return;
        }

        const payload = {
            company_role: boxes[selectedBox]?.text,
        };


        try {

            const response = await fetch(`${baseURL}/user/profile/${JSON.parse(localStorage.getItem('userInfo')).userId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                navigate('/problem');
            } else {
                const errorData = await response.json();
                setErrors({ apiError: errorData.message || 'Update failed' });
                console.error('Update failed:', errorData);
            }
        } catch (error) {
            setErrors({ apiError: 'An error occurred while updating. Please try again.' });
            console.error('Error during update:', error);
        }
    };

    const handleBoxClick = (index) => {
        setSelectedBox(index);
    };
    const handleBack = () => {
        navigate('/about');
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
    };

    const leftSectionStyle = {
        flex: 1,
        padding: '20px',
        marginLeft: '50px',
        zIndex: '10',
    };

    const rightSectionStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        position: 'relative',
        zIndex: '5',
        marginTop: '-173px',
    };

    const imgStyle = {
        maxWidth: '100%',
        height: '99vh',
        objectFit: 'cover',
        borderRadius: '10px',
    };
    const boxes = [
        { content: '', icon: <ApartmentIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: 'Owner/Partner' },
        { content: '', icon: <ManageAccountsIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: 'Accounting manager' },
        { content: '', icon: <BusinessCenterIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: 'Accountant' },
        { content: '', icon: <LibraryBooksIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: 'Bookkeeper' },
        { content: '', icon: <PersonIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: 'Trainee Account' },
        { content: '', icon: <RedeemIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: 'Other' },
    ];

    // const boxStyle = (index) => ({
    //     height: '120px',
    //     width: '190px',
    //     border: `1px solid ${selectedBox === index ? '#ff5a02' : '#ccc'}`,
    //     backgroundColor: selectedBox === index ? '#ffd8b8' : '#fff',
    //     margin: '8px',
    //     padding: '10px',
    //     fontSize: '14px',
    //     cursor: 'pointer',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     borderRadius: '5px',
    // });

    const boxStyle = (index) => ({
        height: '120px',
        width: '195px',
        border: `2px solid #bababa `,
        backgroundColor: selectedBox === index ? '#f7efad' : '#f5f5f2',
        margin: '8px',
        padding: '10px',
        fontSize: '14px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        textAlign: 'center',
    });

    return (
        <div>
            <HeaderUserInfo />
            <div style={containerStyle}>
                <div style={leftSectionStyle}>
                    <h1 style={{ fontWeight: 'bold', fontSize: '40px', marginBottom: '9px' }}>What's your role?</h1>

                    <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '-8px' }}>
                        {boxes.map((box, index) => (
                            <div
                                key={index}
                                style={boxStyle(index)}
                                onClick={() => handleBoxClick(index)}
                            >
                                {box.icon}
                                <span>{box.content}</span>
                                <span style={{ fontSize: '12px', marginTop: '5px' }}>{box.text}</span>
                            </div>
                        ))}
                    </div>

                    <Button variant="contained" sx={{
                        marginTop: "25px",
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "black",
                        textTransform: "none",
                        padding: '0.5rem',
                        '&:hover': {
                            backgroundColor: "#1976d2",
                            color: 'white'
                        }
                    }} onClick={handleBack}>
                        Back
                    </Button>

                    <Button
                        variant="contained"
                        sx={{
                            marginTop: "25px",
                            marginLeft: "10px",
                            backgroundColor: "#1976d2",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "white",
                            textTransform: "none",
                            padding: '0.5rem'

                        }}
                        onClick={handleContinue}
                    >
                        Continue
                    </Button>
                </div>
                <div style={rightSectionStyle}>
                    {/* <img src={img2} alt="img2" style={imgStyle} /> */}
                </div>
            </div>
        </div>
    );
};

export default Role;
