import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';



const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? '#2684FF' : '#ccc',
        boxShadow: state.isFocused ? 'none' : 'none', // Remove default shadow
        '&:hover': {
            borderColor: '#2684FF',
        },
    }),
    input: (provided) => ({
        ...provided,
        boxShadow: 'none', // Remove outline from the input box
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            border: 'none'
        },
    }),
    // To remove the outline for the options when focused
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#e6f7ff' : '#fff',
        '&:focus': {
            outline: 'none',
        },
    }),
};


const categoryOptions = [
    { value: 'Accomodation', label: 'Accomodation' },
    { value: 'Food', label: 'Food' },
    { value: 'IT & Technology', label: 'IT & Technology' },
    { value: 'Motor Expenses', label: 'Motor Expenses' },
    { value: 'Office Supplies', label: 'Office Supplies' },
    { value: 'Other', label: 'Other' },
    { value: 'Postage', label: 'Postage' },
    { value: 'Telecoms & Internet', label: 'Telecoms & Internet' },
    { value: 'To Be Allocated', label: 'To Be Allocated' },
    { value: 'Training & Education', label: 'Training & Education' },
    { value: 'Travel', label: 'Travel' },
    { value: 'Utilites & Bills', label: 'Utilites & Bills' },
];
const options = [
    { value: 'Accomodation', label: 'Accomodation' },
    { value: 'Food', label: 'Food' },

];

const submissionOptions = [
    { value: 'via_mobile', label: 'Via mobile' },
    { value: 'via_web', label: 'Via web' },
    { value: 'via_email', label: 'Via email' },
    { value: 'via_supplier_email', label: 'Via supplier email' },
    { value: 'via_fetch', label: 'Via fetch' },
    { value: 'via_paper', label: 'Via paper' },
    { value: 'via_dropbox', label: 'Via dropbox' },
    { value: 'via_system', label: 'Via system' },
    { value: 'created_in_zoom', label: 'Created in Zoom' },
    { value: 'via_paypal', label: 'Via paypal' },
    { value: 'via_tripcatcher', label: 'Via tripcatcher' },
    { value: 'via_zapier', label: 'Via zapier' },
    { value: 'via_jefacture', label: 'Via facture' },
    { value: 'via_einvoicong', label: 'Via eInvoicing' },
    { value: 'via_dextension', label: 'Via dextension' },
];
const currencyOptions = [
    { value: 'CAD', label: 'CAD - Canada Dollars' },
    { value: 'AUD', label: 'AUD - Australia Dollars' },
    { value: 'EUR', label: 'EUR - Euro' },
    { value: 'NZD', label: 'NZD - New Zealand Dollars' },
    { value: 'HKD', label: 'HKD - Hongkong Dollars' },
    { value: 'GBP', label: 'GBP - United Kingdom Pounds' },
    { value: 'USD', label: 'USD - United States Dollars' },
    { value: 'INR', label: 'INR - Indian Rupees' },
    { value: 'JPY', label: 'JPY - Japanese Yen' },
    { value: 'CNY', label: 'CNY - Chinese Yuan' },
    { value: 'BRL', label: 'BRL - Brazilian Reals' },
    { value: 'ZAR', label: 'ZAR - South African Rand' },
    { value: 'MXN', label: 'MXN - Mexican Pesos' },
    { value: 'RUB', label: 'RUB - Russian Rubles' },
    { value: 'CHF', label: 'CHF - Swiss Francs' },
    { value: 'SGD', label: 'SGD - Singapore Dollars' },
    { value: 'KRW', label: 'KRW - South Korean Won' },
    { value: 'MYR', label: 'MYR - Malaysian Ringgit' },
    { value: 'THB', label: 'THB - Thai Baht' },
    { value: 'IDR', label: 'IDR - Indonesian Rupiah' },
    { value: 'PHP', label: 'PHP - Philippine Pesos' },
    { value: 'SAR', label: 'SAR - Saudi Riyal' },
    { value: 'AED', label: 'AED - United Arab Emirates Dirham' },
    { value: 'TRY', label: 'TRY - Turkish Lira' },
    { value: 'SEK', label: 'SEK - Swedish Krona' },
    { value: 'NOK', label: 'NOK - Norwegian Krone' },
    { value: 'DKK', label: 'DKK - Danish Krone' },
    { value: 'PLN', label: 'PLN - Polish Zloty' },
    { value: 'HUF', label: 'HUF - Hungarian Forint' },
    { value: 'CZK', label: 'CZK - Czech Koruna' },
    { value: 'EGP', label: 'EGP - Egyptian Pound' },
    { value: 'ARS', label: 'ARS - Argentine Pesos' },
    { value: 'CLP', label: 'CLP - Chilean Pesos' },
    { value: 'VND', label: 'VND - Vietnamese Dong' },
    { value: 'PKR', label: 'PKR - Pakistani Rupees' },
    { value: 'BDT', label: 'BDT - Bangladeshi Taka' },
    { value: 'NGN', label: 'NGN - Nigerian Naira' },
    { value: 'ILS', label: 'ILS - Israeli New Shekel' },
    { value: 'KWD', label: 'KWD - Kuwaiti Dinar' },
    { value: 'QAR', label: 'QAR - Qatari Riyal' },
    { value: 'COP', label: 'COP - Colombian Pesos' },
    { value: 'TWD', label: 'TWD - Taiwan Dollars' },
    { value: 'MAD', label: 'MAD - Moroccan Dirham' },
    { value: 'Other', label: 'Other - Other Currencies' },
];


const typeOptions = [
    { value: 'Reciept', label: 'Reciept' },
    { value: 'ATM withdrawal', label: 'ATM withdrawal' },
    { value: 'other', label: 'other' },
    { value: 'Invoice', label: 'Invoice' },
    { value: 'Statement/Remmitance advice', label: 'Statement/Remmitance advice' },
    { value: 'Expense Statement', label: 'Expense Statement' },
    { value: 'Credit Note/Refund', label: 'Credit Note/Refund' },
    { value: 'Delivery Note', label: 'Delivery Note' },
    { value: 'Mileage', label: 'Mileage' }
];



const InboxTableAdvance = ({ rowsData, formData, setFormData }) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const [customerOptions, setCustomerOptions] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([])
    const [submittedByOptions, setSubmittedByOptions] = useState([])

    const handleChange = (selectedOption, name) => {
        setFormData({ ...formData, [name]: selectedOption })
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (date, name) => {
        setFormData({ ...formData, [name]: date });
    };

    const handleCheckboxChange = (e) => {
        setFormData({ ...formData, addedToExpenseClaim: e.target.checked });
    };
    // console.log("Advance", rowsData)

    const fetchSelectsData = async (data) => {
        let customers = [];
        let suppliers = [];
        let submittedBy = [];
        for (let row of data) {
            if (!customers.includes(row.customer)) {
                let opt = { value: row.customer, label: row.customer }
                customers.push(opt);
            }
            if (!suppliers.includes(row.supplier)) {
                let opt = { value: row.supplier, label: row.supplier }
                suppliers.push(opt);
            }
            if (!submittedBy.includes(row.submittedBy)) {
                let opt = { value: row.submittedBy, label: row.submittedBy }
                suppliers.push(opt);
            }
        }
        return { customers, suppliers, submittedBy };
    }


    useEffect(() => {
        const loadSelectData = async () => {
            const { customers, suppliers, submittedBy } = await fetchSelectsData(rowsData);

            setCustomerOptions(customers);
            setSupplierOptions(suppliers);
            setSubmittedByOptions(submittedBy)
        };

        loadSelectData();
    }, [rowsData]);

    return (
        <div className="advanced-search-form flex flex-col gap-4 mb-2">
            <h3 className='text-black text-base font-semibold'>Advanced Search</h3>

            <div className="form-group flex gap-24 ">
                <label className='w-[30%]'>Search term</label>
                <div className='w-[80%] flex justify-start'><input
                    className='w-full border-[#ccc]'
                    type="text"
                    name="searchTerm"
                    value={formData.searchTerm}
                    onChange={handleInputChange}
                /></div>

            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%]'>Supplier</label>
                <div className='w-[80%] flex justify-start'>
                    <Select
                        className='w-full '
                        value={formData.supplier}
                        onChange={(selectedOption) => handleChange(selectedOption, 'supplier')}
                        options={supplierOptions}
                        styles={customStyles}
                        isSearchable={true} // Enable search functionality
                        placeholder="Select Supplier"
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%]'>Amount</label>
                <div className='w-[80%] flex items-center justify-evenly '>
                    <input
                        className='w-[47%] border-[#ccc]'
                        type="number"
                        name="amountFrom"

                        value={formData.amountFrom}
                        onChange={handleInputChange}
                    />
                    <span>to</span>
                    <input
                        className='w-[47%] border-[#ccc]'
                        type="number"
                        name="amountTo"

                        value={formData.amountTo}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%]'>Date</label>
                <div className='w-[80%] flex items-center justify-evenly '>
                    <DatePicker
                        className='w-[98%] border-[#ccc]'
                        selected={formData.dateFrom}
                        datedateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date, 'dateFrom')}
                        placeholderText="From"
                    />
                    <DatePicker
                        className='w-[98%] border-[#ccc]'
                        selected={formData.dateTo}
                          dateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date, 'dateTo')}
                        placeholderText="To"
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%]' >Due Date</label>
                <div className='w-[80%] flex items-center justify-evenly '>
                    <DatePicker
                        className='w-[98%] border-[#ccc]'
                        selected={formData.dueDateFrom}
                          dateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date, 'dueDateFrom')}
                        placeholderText="From"
                    />
                    <DatePicker
                        className='w-[98%] border-[#ccc]'
                        selected={formData.dueDateTo}
                          dateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date, 'dueDateTo')}
                        placeholderText="To"
                    />
                </div>

            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%]'>Upload Date</label>
                <div className='w-[80%] flex items-center justify-evenly '>
                    <DatePicker
                        className='w-[98%] border-[#ccc]'
                        selected={formData.uploadDateFrom}
                          dateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date, 'uploadDateFrom')}
                        placeholderText="From"
                    />
                    <DatePicker
                        className='w-[98%] border-[#ccc]'
                        selected={formData.uploadDateTo}
                          dateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date, 'uploadDateTo')}
                        placeholderText="To"
                    />
                </div>
            </div>



            <div className="form-group flex gap-24 ">
                <label className='w-[30%]'>Document Reference</label>
                <div className='w-[80%] flex justify-start'><input
                    className='w-full border-[#ccc]'
                    type="text"
                    name="documentReference"
                    value={formData.documentReference}
                    onChange={handleInputChange}
                />
                </div>
            </div>

            <div className="form-group flex gap-24 ">
                <label className='w-[30%]'>Item ID</label>
                <div className='w-[80%] flex justify-start'> <input
                    className='w-full border-[#ccc]'
                    type="text"
                    name="itemId"
                    value={formData.itemId}
                    onChange={handleInputChange}
                />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%]'>Type</label>
                <div className="w-[80%]">
                    <Select
                        className='w-full '
                        value={formData.type}
                        onChange={(selectedOption) => handleChange(selectedOption, 'type')}
                        options={typeOptions}
                        styles={customStyles}
                        isSearchable={true} // Enable search functionality
                        placeholder="Select Type"
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%]'>Currency Code</label>
                <div className="w-[80%]">
                    <Select
                        className='w-full'
                        value={formData.currencyCode}
                        onChange={(selectedOption) => handleChange(selectedOption, 'currencyCode')}
                        options={currencyOptions}
                        styles={customStyles}
                        isSearchable={true} // Enable search functionality
                        placeholder="Select Currency"
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%]'>Submitted By</label>
                <div className="w-[80%]">
                    <Select
                        className='w-full'
                        value={formData.submittedBy}
                        onChange={(selectedOption) => handleChange(selectedOption, 'submittedBy')}
                        options={submittedByOptions}
                        styles={customStyles}
                        isSearchable={true} // Enable search functionality
                        placeholder="Select"
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%]'>Submission Method</label>
                <div className="w-[80%]">
                    <Select
                        className='w-full'
                        value={formData.submissionMethod}
                        onChange={(selectedOption) => handleChange(selectedOption, 'submissionMethod')}
                        options={submissionOptions}
                        styles={customStyles}
                        isSearchable={true} // Enable search functionality
                        placeholder="Select"
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%]'>Customer</label>
                <div className="w-[80%]">
                    <Select
                        className='w-full'
                        value={formData.customer}
                        onChange={(selectedOption) => handleChange(selectedOption, 'customer')}
                        options={customerOptions}
                        styles={customStyles}
                        isSearchable={true} // Enable search functionality
                        placeholder="Select Customer"
                    />
                </div>
            </div>

            <div className="form-group flex gap-24 ">
                <label className='w-[30%]'>Category</label>
                <div className="w-[80%]">
                    <Select
                        className='w-full'
                        value={formData.category}
                        onChange={(selectedOption) => handleChange(selectedOption, 'category')}
                        options={categoryOptions}
                        styles={customStyles}
                        isSearchable={true}
                        placeholder="Select Category"
                    />
                </div>
            </div>



            <div className="form-group flex gap-24">
                <label className='w-[30%]'>Expense Claim</label>
                <select
                    className='w-[80%] border-[#ccc] text-[#ccc]'
                    name="expenseClaim"
                    value={formData.expenseClaim}
                    onChange={handleInputChange}
                >
                    <option value="">Select Expense Claim</option>
                    <option value="claim1">Claim 1</option>
                    <option value="claim2">Claim 2</option>
                </select>
            </div>

            <div className="form-group flex justify-end pr-7 items-center gap-1">
                <input
                    type="checkbox"
                    name="addedToExpenseClaim"
                    checked={formData.addedToExpenseClaim}
                    onChange={handleCheckboxChange}
                />
                <label>Added to an Expense Claim</label>
            </div>
        </div>
    )
}

export default InboxTableAdvance

