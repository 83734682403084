import { baseURL } from '../Config';
import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, FormControl, Select, MenuItem, FormControlLabel, Checkbox, Button, Snackbar, Alert } from '@mui/material';

const AccountDetails = () => {
    const [accountData, setAccountData] = useState(null);
    const [editedData, setEditedData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        // Fetch data from the API
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            if (!token) return;
            const user = JSON.parse(localStorage.getItem('userInfo'))
            console.log(user.userId);
            try {
                const response = await fetch(`${baseURL}/user/profile/${user.userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const data = await response.json();
                setAccountData(data || {});
                setEditedData(data || {});
            } catch (error) {
                console.error('Error fetching account data:', error);
            }
        };

        fetchData();
    }, []);

    const handleChange = (field, value) => {
        setEditedData(prev => ({ ...prev, [field]: value }));
    };

    const handleCheckboxChange = (field) => {
        setEditedData(prev => ({ ...prev, [field]: !prev[field] }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updateData = {
            name:editedData.name,
            country:editedData.country,
            extractTax:editedData.extractTax,
            currency:editedData.editedData
        }

        console.log('xyz',updateData)
        
        const token = localStorage.getItem('token');
        if (!token) return;
        const user = JSON.parse(localStorage.getItem('userInfo'));
        
        try {
            const response = await fetch(`${baseURL}/user/profile/${user.userId}`, {
                method: 'PATCH', // Changed from PUT to PATCH to match the router endpoint
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updateData),
            });

            // console.log('herre',editedData)

            if (response.ok) {
                setAccountData(editedData);
                setIsEditing(false);
                setNotification({
                    open: true,
                    message: 'Account details updated successfully!',
                    severity: 'success'
                });
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update account details');
            }
        } catch (error) {
            console.error('Error updating account data:', error);
            setNotification({
                open: true,
                message: error.message || 'Failed to update account details',
                severity: 'error'
            });
        }
    };

    const handleCancel = () => {
        setEditedData(accountData);
        setIsEditing(false);
    };

    const handleCloseNotification = () => {
        setNotification({ ...notification, open: false });
    };

    if (!accountData) {
        return <Typography>Loading...</Typography>;
    }
    console.log(accountData, "dasd")

    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', padding: '1rem', backgroundColor: "#f9fafb" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px', marginBottom: '10px' }}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'left', marginBottom: '0' }}>
                    Account Details
                </Typography>
                {!isEditing ? (
                    <Button 
                        variant="contained" 
                        style={{ backgroundColor: '#546478', color: 'white' }}
                        onClick={() => setIsEditing(true)}
                    >
                        Edit
                    </Button>
                ) : (
                    <div>
                        <Button 
                            variant="outlined" 
                            style={{ marginRight: '10px', borderColor: '#546478', color: '#546478' }}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="contained" 
                            style={{ backgroundColor: '#546478', color: 'white' }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </div>
                )}
            </div>
            <form onSubmit={handleSubmit}>
                <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px', textAlign: 'left', fontWeight: 'bold' }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', fontSize: '14px', margin: '0px 0px 10px 10', color: '#546478' }}>
                        BASICS
                    </Typography>
                    <div style={{ margin: '0 20px 0 0' }}>
                        <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', marginTop: "3px" }}>
                            <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px', fontSize: '14px', color: '#546478' }}>
                                CRN
                            </Typography>
                            <Typography variant="body2" style={{ fontSize: '14px', color: '#000', marginLeft: '100px' }}>
                            {accountData.crnNumber || 'Not Available'}
                        </Typography>
                            {/* <FormControl fullWidth>
                                <div className="base-Input-root">
                                    <input
                                        className="base-Input-input"
                                        style={{ 
                                            width: '35%', 
                                            height: "30px", 
                                            fontSize: '14px', 
                                            marginLeft: '100px', 
                                            border: "1px solid #B8B8B8", 
                                            borderRadius: "4px", 
                                            paddingLeft: "8px",
                                            backgroundColor: isEditing ? 'white' : '#f5f5f5'
                                        }}
                                        value={editedData.crnNumber || ''}
                                        onChange={(e) => handleChange('crnNumber', e.target.value)}
                                        readOnly={!isEditing}
                                    />
                                </div>
                            </FormControl> */}
                        </div>
                        <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', marginTop: "3px" }}>
                            <Typography variant="body2" style={{ marginRight: '8px', minWidth: '120px', fontSize: '14px', color: '#546478' }}>
                                Company Name <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <FormControl fullWidth>
                                <div className="base-Input-root">
                                    <input
                                        className="base-Input-input"
                                        style={{ 
                                            width: '35.5%', 
                                            height: "30px", 
                                            fontSize: '14px', 
                                            marginLeft: '80px', 
                                            border: "1px solid #B8B8B8", 
                                            borderRadius: "4px", 
                                            paddingLeft: "8px",
                                            backgroundColor: isEditing ? 'white' : '#f5f5f5'
                                        }}
                                        value={editedData.name || ''}
                                        onChange={(e) => handleChange('name', e.target.value)}
                                        readOnly={!isEditing}
                                        required
                                    />
                                </div>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', marginTop: "3px" }}>
                            <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px', fontSize: '14px', color: '#546478' }}>
                                Base currency
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="baseCurrency"
                                    fullWidth
                                    variant="outlined"
                                    style={{ 
                                        width: '35%', 
                                        height: "30px", 
                                        fontSize: '14px', 
                                        marginLeft: '100px',
                                        backgroundColor: isEditing ? 'white' : '#f5f5f5'
                                    }}
                                    value={editedData.baseCurrency || 'GBP'}
                                    onChange={(e) => handleChange('baseCurrency', e.target.value)}
                                    disabled={!isEditing}
                                >
                                    <MenuItem value="GBP">GBP</MenuItem>
                                    {/* <MenuItem value="USD">USD</MenuItem>
                                    <MenuItem value="EUR">EUR</MenuItem>
                                    <MenuItem value="CAD">CAD</MenuItem>
                                    <MenuItem value="AUD">AUD</MenuItem> */}
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', marginTop: "3px" }}>
                            <Typography
                                variant="body2"
                                style={{ marginRight: '8px', minWidth: '100px', fontSize: '14px', color: '#546478' }}
                            >
                                Extract Tax
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="extractTax"
                                        checked={editedData.extractTax || false}
                                        onChange={() => handleCheckboxChange('extractTax')}
                                        disabled={!isEditing}
                                        style={{ marginLeft: '100px', color: '#546478' }}
                                    />
                                }
                                label="Enabled"
                            />
                        </div>
                        <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', marginTop: "3px" }}>
                            <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px', fontSize: '14px', color: '#546478' }}>
                                Account Country
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="accountCountry"
                                    fullWidth
                                    variant="outlined"
                                    style={{ 
                                        width: '35%', 
                                        height: "30px", 
                                        fontSize: '14px', 
                                        marginLeft: '100px',
                                        backgroundColor: isEditing ? 'white' : '#f5f5f5'
                                    }}
                                    value={editedData.accountCountry || 'United Kingdom'}
                                    onChange={(e) => handleChange('accountCountry', e.target.value)}
                                    disabled={!isEditing}
                                >
                                    <MenuItem value="United Kingdom">United Kingdom</MenuItem>
                                    {/* <MenuItem value="United States">United States</MenuItem>
                                    <MenuItem value="Canada">Canada</MenuItem>
                                    <MenuItem value="Australia">Australia</MenuItem>
                                    <MenuItem value="France">France</MenuItem>
                                    <MenuItem value="Germany">Germany</MenuItem> */}
                                </Select>
                            </FormControl>
                        </div>
                        {/* <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', marginTop: "3px" }}>
                            <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px', fontSize: '14px', color: '#546478' }}>
                                Industry
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="industry"
                                    fullWidth
                                    variant="outlined"
                                    style={{ 
                                        width: '35%', 
                                        height: "30px", 
                                        fontSize: '14px', 
                                        marginLeft: '100px',
                                        backgroundColor: isEditing ? 'white' : '#f5f5f5'
                                    }}
                                    value={editedData.industry || 'Accounting and Bookkeeping'}
                                    onChange={(e) => handleChange('industry', e.target.value)}
                                    disabled={!isEditing}
                                >
                                    <MenuItem value="Accounting and Bookkeeping">Accounting and Bookkeeping</MenuItem>
                                    <MenuItem value="Technology">Technology</MenuItem>
                                    <MenuItem value="Healthcare">Healthcare</MenuItem>
                                    <MenuItem value="Education">Education</MenuItem>
                                    <MenuItem value="Manufacturing">Manufacturing</MenuItem>
                                    <MenuItem value="Retail">Retail</MenuItem>
                                </Select>
                            </FormControl>
                        </div> */}
                    </div>
                </Paper>
                {/* <Paper variant="outlined" style={{ padding: '1rem', marginBottom: '10px', textAlign: 'left', fontWeight: 'bold' }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', fontSize: '14px', margin: '0px 0px 10px 0' }}>
                        EMAIL-IN
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center', width: '55%', marginLeft: '0' }}>
                        <Typography variant="body2" style={{ fontSize: '14px', color: '#546478' }}>
                            <ul style={{ paddingLeft: '0.5rem', wordSpacing: "3px", lineHeight: "18px", listStyleType: "disc" }}>
                                <li>Add documents to your Dext account by emailing them to the email addresses below.</li>
                                <li>Give the Costs email to your suppliers so they'll send their invoices straight to Prepare.</li>
                            </ul>
                        </Typography>
                    </div>
                </Paper> */}
            </form>

            <Snackbar 
                open={notification.open} 
                autoHideDuration={6000} 
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
                    {notification.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AccountDetails;