// import React from "react";

// const Subscription = () =>{
//     return(
//         <>
//        Subscription
//         </>
//     )
// }
// export default Subscription;
import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
} from "@mui/material";

const Subscription = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [plan, setPlan] = useState('');
  const [number, setNumber] = useState('');
  const steps = ['Select Plan', 'Select Number', 'Review Purchase'];

  const planDetails = {
    basic: {
      name: "Basic",
      price: "$10/month",
      features: ["Access to basic features", "Email support", "Limited storage"],
    },
    premium: {
      name: "Premium",
      price: "$20/month",
      features: ["Access to all features", "Priority email support", "Unlimited storage"],
    },
    pro: {
      name: "Pro",
      price: "$30/month",
      features: ["All Premium features", "24/7 phone support", "Advanced analytics"],
    },
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePlanChange = (event) => {
    setPlan(event.target.value);
  };

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  return (


    <Paper
      elevation={3}
      sx={{
        padding: 4,
        maxWidth: 600,
        textAlign: 'center',
        borderRadius: 2,
        position: 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%,-50%)'
      }}
    >
      <Stepper activeStep={activeStep} sx={{ marginBottom: 3 }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <Box>
          <Typography variant="h6" gutterBottom>
            Select Your Plan
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="plan-label">Plan</InputLabel>
            <Select
              labelId="plan-label"
              id="plan"
              value={plan}
              onChange={handlePlanChange}
              label="Plan"
              required
            >
              <MenuItem value="basic">Basic - $10/month</MenuItem>
              <MenuItem value="premium">Premium - $20/month</MenuItem>
              <MenuItem value="pro">Pro - $30/month</MenuItem>
            </Select>
          </FormControl>

          {plan && (
            <Card sx={{ marginTop: 2 }}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {planDetails[plan].name} Plan
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Price: {planDetails[plan].price}
                </Typography>
                <Typography variant="body1" component="div" sx={{ marginTop: 1 }}>
                  Features:
                </Typography>
                <ul>
                  {planDetails[plan].features.map((feature, index) => (
                    <li key={index}>
                      <Typography variant="body2">{feature}</Typography>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={!plan}
            sx={{ marginTop: 2 }}
          >
            Next
          </Button>
        </Box>
      )}

      {activeStep === 1 && (
        <Box>
          <Typography variant="h6" gutterBottom>
            Select Number for Client
          </Typography>
          <input
            style={{ width: '90%', padding: '0.8rem 1rem', fontSize: '18px' }}
            type="number"
            value={number}
            onChange={handleNumberChange}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            <Button onClick={handleBack} sx={{ marginRight: 1 }}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={!number}
            >
              Next
            </Button>
          </Box>
        </Box>
      )}

      {activeStep === 2 && (
        <Box>
          <Typography variant="h6" gutterBottom>
            Review Your Purchase
          </Typography>
          <Typography variant="body1">
            <strong>Plan:</strong> {planDetails[plan].name}
          </Typography>
          <Typography variant="body1">
            <strong>Price:</strong> {planDetails[plan].price}
          </Typography>
          <Typography variant="body1">
            <strong>Client Number:</strong> {number}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            <Button onClick={handleBack} sx={{ marginRight: 1 }}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
            >
              Confirm and Subscribe
            </Button>
          </Box>
        </Box>
      )}
    </Paper>

  );
};

export default Subscription;
