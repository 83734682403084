import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root.Mui-focused': {
      outline: 'none', // Removes outline
      boxShadow: 'none', // Removes any shadow
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: 'transparent', // Removes the border color for focused state
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: 'transparent', // Removes the border color when focused
    },
    '& .MuiInputBase-root.Mui-focused': {
      outline: 'none', // Removes the default focus outline
      boxShadow: 'none', // Removes any box-shadow applied when focused
    },
    '& .MuiOutlinedInput-root': {
      height: '40px',
      // If you want to adjust the input padding to ensure the text is vertically centered, you can adjust the padding here.
      '& input': {
        padding: '10px 12px', // Adjusting padding for vertical alignment within the height
      },
    },
    // Removing the blue box (focus outline) for focused state
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: 'transparent', // Removes border when focused
    },
    '& .MuiInputBase-root.Mui-focused': {
      outline: 'none', // Removes outline when focused
      boxShadow: 'none', // Removes any box-shadow on focus
    },
  },
});
