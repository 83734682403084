
import axios from 'axios';
//export const baseURL = "${baseURL}";
export const baseURL = "https://server.zoomaccounts.co.uk/api";
// export const baseURL = "http://localhost:8000/api";
const token = localStorage.getItem('token');
const userToken = localStorage.getItem('userToken');

const Config = axios.create({
  baseURL,
  headers: {
    'Authorization': `Bearer ${token}`,
    'X-Second-Authorization': `Bearer ${userToken}`
  },
});

export default Config;
