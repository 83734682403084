import React, { useState, useEffect } from 'react';
import {
  Button,
  Toolbar,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import TableSales from './TableSales';
import axios from 'axios';
import { baseURL } from '../Config';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import TableExtendedToolTip from '../costComponents/utils/TableExtendedToolTip';
import ExportDialog from './ExportDiaolog';
import { TransparentBtn } from '../components/common/TransparentBtn';
const token = localStorage.getItem('token');

function EnhancedTableToolbar(props) {
  const {
    rowsData,
    numSelected,
    onMoveToReady,
    onArchiveClick,
    onExportClick,
  } = props;

  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <div className='flex justify-between items-center w-full'>
        <div className='flex gap-2 items-center'>
          <TransparentBtn
            border
            // disabled={numSelected === 0}
            onClick={onExportClick}
            label={numSelected === 0 ? 'Export All' : 'Export'}
          />
          {/* <Button
            variant='contained'
            sx={{
              fontSize: '12px',
              padding: '6px 15px',
              marginRight: '10px',
              fontWeight: 'bold',
              backgroundColor: '2196f3',
              color: 'white',
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
            }}
            onClick={onExportClick}
          >
            {numSelected === 0 ? 'Export All' : 'Export'}
          </Button> */}
          <TransparentBtn
            border
            disabled={numSelected === 0}
            onClick={onMoveToReady}
            label={'Move To Review'}
          />
          {/* <Button
            disabled={numSelected === 0}
            variant='contained'
            sx={{
              fontSize: '12px',
              padding: '6px 15px',
              marginRight: '10px',
              fontWeight: 'bold',
              backgroundColor: '2196f3',
              color: 'white',
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
            }}
            onClick={onMoveToReady}
          >
            Move To Review
          </Button> */}
          <TransparentBtn
            border
            disabled={numSelected === 0}
            onClick={onArchiveClick}
            label={'Archive'}
          />
          {/* <Button
            disabled={numSelected === 0}
            variant='contained'
            color='primary'
            onClick={onArchiveClick}
            sx={{
              fontSize: '12px',
              padding: '6px 15px',
              marginRight: '10px',
              fontWeight: 'bold',
              backgroundColor: '2196f3',
              color: 'white',
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
            }}
          >
            Archive
          </Button> */}
          {/* <Button
                        disabled={numSelected === 0}
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            fontWeight: "bold",
                            backgroundColor: "2196f3",
                            color: "white",
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',


                        }}
                    >
                        Add To Expense Report
                    </Button> */}
          <Button
            disabled={numSelected === 0}
            variant='contained'
            color='primary'
            sx={{
              fontSize: '12px',
              padding: '6px 15px',
              marginRight: '10px',
              fontWeight: 'bold',
              backgroundColor: '2196f3',
              color: 'white',
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
            }}
          >
            Tools
          </Button>
        </div>
        <TableExtendedToolTip rowsData={rowsData} />
      </div>
    </Toolbar>
  );
}

const SalesReady = () => {
  const [selected, setSelected] = useState([]);
  const [sales, setSales] = useState([]);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    fetchCosts();
  }, []);

  const handleArchiveClick = () => {
    setIsArchiveDialogOpen(true);
  };

  const handleArchiveCancel = () => {
    setIsArchiveDialogOpen(false);
  };

  const handleExportClick = () => {
    setIsExportDialogOpen(true);
  };

  const handleExportCancel = () => {
    setIsExportDialogOpen(false);
  };

  const handleMoveToReady = async () => {
    try {
      await axios
        .patch(
          `${baseURL}/sales/status/${selected}`,
          { status: 'Review', id: selected },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log('Ready successfully', response);
          setSelected([]);
          setSales(sales.filter((sales) => !selected.includes(sales._id)));
        })
        .catch((error) => {
          console.error('There was an error archiving the item!', error);
        });
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const ArchiveDialog = ({ open, onClose, selectedCount, onConfirm }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{ color: '#1976D2' }}>Add Item(s)</DialogTitle>
        <DialogContent>
          <Typography>
            You have selected {selectedCount} item(s) to archive.
          </Typography>
          <DialogContentText>
            Are you sure you want to archive the selected items?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            sx={{
              background: 'none',
              border: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            variant='contained'
            color='primary'
            sx={{
              background: '#1976D2',
              border: 'none',
              '&:hover': {
                border: '1px solid #1976D2',
                background: '#1976D2',
              },
              fontWeight: 'bold',
            }}
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleArchiveConfirm = async () => {
    try {
      await axios.patch(
        `${baseURL}/sales/status/${selected}`,
        { status: 'archive', id: selected },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('Selected sales archived successfully');
      fetchCosts();
      setSales((prevCosts) =>
        prevCosts.filter((sales) => !selected.includes(sales._id))
      );
      setSelected([]);
      setIsArchiveDialogOpen(false);
    } catch (error) {
      console.error('Error archiving items:', error);
    }
  };

  const fetchCosts = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/sales/all/by-status`,
        {
          status: 'Ready',
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const dataArray = Array.isArray(response.data)
        ? response.data
        : response.data.data;

      setSales(Array.isArray(dataArray) ? dataArray : []);
      // console.log(response);
      // setSales(response.data);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };
  return (
    <>
      <EnhancedTableToolbar
        rowsData={sales}
        numSelected={selected.length}
        onMoveToReady={handleMoveToReady}
        onArchiveClick={handleArchiveClick}
        onExportClick={handleExportClick}
      />
      <TableSales data={sales} selected={selected} setSelected={setSelected} />
      <ArchiveDialog
        open={isArchiveDialogOpen}
        onClose={handleArchiveCancel}
        selectedCount={selected.length}
        onConfirm={handleArchiveConfirm}
      />
      <ExportDialog
        open={isExportDialogOpen}
        onClose={handleExportCancel}
        data={
          selected.length > 0
            ? sales.filter((sale) => {
                const isMatched = selected?.some((item) => item == sale._id);
                return isMatched;
              })
            : sales
        }
      />
    </>
  );
};

export default SalesReady;
