import React from 'react';
import AppRoutes from './routes';
import './styles/index.css';
import { HeaderStepProvider } from './business/userinfo/context';

const App = () => {
  return (
    <div className="App">
      <HeaderStepProvider>
        <AppRoutes />
      </HeaderStepProvider>
    </div>
  );
};

export default App;
