import React from 'react';
import { Container, Grid, Breadcrumbs, Typography, Divider, Tooltip, IconButton, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeaderBusiness from './HeaderBusiness';
import ErrorIcon from '@mui/icons-material/Error';
import Footer from '../components/Footer';

const AddClient = () => {
    return (
        <>
            <HeaderBusiness />
            <Container style={{ width: '100%', marginTop: '100px' }}>
                {/* Breadcrumb */}
                <Box mb={4} display="flex">
                    <Breadcrumbs
                        separator=">"
                        aria-label="breadcrumb"
                        sx={{
                            maxWidth: '100%',
                            marginTop: "10px",
                            marginLeft: "5px",
                            '& ol': {
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap',
                                width: '100%',
                            },
                        }}
                    >
                        {['Home', 'Dext Prepare', 'Getting Started', 'Accountants & Bookkeepers', 'Account Set Up', 'How to Add a Client Account'].map((label, index) => (
                            <Typography
                                key={index}
                                sx={{
                                    color: 'blue',
                                    textDecoration: 'none',
                                    cursor: 'default',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    '&:hover': {
                                        textDecoration: 'none',
                                        color: 'blue !important',
                                        backgroundColor: "transparent"
                                    }
                                }}
                            >
                                {label}
                            </Typography>
                        ))}
                    </Breadcrumbs>
                </Box>


                <Grid container spacing={5}>
                    {/* Left Section */}
                    <Grid item xs={3}>
                        <Box display="flex" alignItems="center" mb={2}>
                            <img className='w-[80px]' src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
                            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                            <Typography variant="h6" fontWeight="bold" sx={{ fontSize: '2rem' }}>Prepare</Typography>
                        </Box>
                        <Typography variant="body1" fontWeight="bold" mb={2} mt={3} sx={{ fontSize: '1.30rem' }}>Accounts & Bookkeepers</Typography>

                        {[
                            {
                                title: 'The Web App',
                                lines: ['Switching Between Organisations & Clients', 'How to Use the Inline Calculator', 'The Dext Homepage', 'The "Client View" Page', 'The "Practice Insights" Page', 'The "Costs" Workspace', 'The "Sales" Workspace', 'The "Inbox" Page', 'The "item Details" Page', 'The "Bank" Workspace', 'The "Supplier" Page', 'Submission History', 'The "Archive" Page', 'The "System Settings" Page', 'The "Filter" Menu', 'The "Tools" Menu', 'Keyboard Shortcuts'],
                            },
                            {
                                title: 'The Mobile App',
                                lines: ['How to set up Biometric Authentication on the Moblie App', 'How to Log In to the Moblie App', 'How to Activate Push Notifications', 'How to Insert Your Company Logo', 'The New Version of the Android App', 'How to Log in to Multiple Accounts on the Dext Mobile App', 'How to Switch Between Different Accounts on the Android Mobile App', 'How to Remove Accounts from the Mobile App'],
                            },
                            {
                                title: 'Introduction to Dext Prepare',
                                lines: ['What Dext Prepare Products are Available?', 'How Does Dext Prepare Work?', 'Does Dext Prepare Comply with Tax Authorities?', 'What Technology Does Dext Prepare Use?', 'What Data is Extracted ny Dext Prepare?', 'Is There a Dext Mobile App?', 'How Does Dext Prepare Convert Currency', 'Is There a Dext Community Resource?', 'How to Sign Up to Dext as an Accountant or Bookkeeper', 'How to Process your Sales Invoices', 'How to Get Rewarded For Referring Your Peers To Dext'],
                            },
                            {
                                title: 'Account Set Up',
                                lines: ['How to Set Up Tax rates In an Unintegrated Account', 'Using Intuit Single Sign On with Dext', 'Multi-accounts Management for Accountants & Bookkeepers', 'Getting Started with Dext Prepare for Accountants & Bookkeepers', 'How To Add a Client Account', 'How to Add Multiple Client Accounts', 'Your New Dext Prepare Account', 'How to Add a User to Your Account', 'How to Add Users to Dext Prepare', 'How to Invite Someone to Use Dext Prepare', 'How to Upload Your Suppliers List into Dext Prepare', 'How to Set Up Yourn Customer List', 'How to Set Up Your Category List'],
                            }
                        ].map((section, index) => (
                            <Accordion key={index} elevation={0} sx={{ border: 'none', marginTop: '7px' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography fontWeight="bold">{section.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {section.lines.map((line, i) => (
                                        <Typography
                                            key={i}
                                            sx={{
                                                padding: '12px 0',
                                                '&:hover': {
                                                    color: 'blue',
                                                    cursor: 'pointer'
                                                }
                                            }}
                                        >
                                            {line}
                                        </Typography>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        ))}

                    </Grid>


                    {/* Right Section */}
                    <Grid item xs={9}>
                        <Typography variant="h4" mb={2} style={{ fontSize: '2rem', textAlign: 'justify', fontWeight: "bold" }}>
                            How to Add a Client Account
                        </Typography>
                        <Typography variant="body1" mb={3} mt={4} style={{ fontSize: '1.5rem', textAlign: 'justify', fontWeight: "bold" }}>
                            Creating a Client Account will add a dedicated space to receive review and manage the financial documentation for one of your clients’ businesses.  </Typography>
                        <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            <Box border={1} padding={2} borderRadius={1} marginBottom={1} style={{ display: 'flex', alignItems: 'center', color: '#546478', fontSize: '1rem' }}>
                                <Tooltip title="Help">
                                    <IconButton style={{ marginBottom: '10px' }}>
                                        <ErrorIcon />
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="body2">
                                    Important: To be able to add a client account you must have Administrator level User Privilege access. If you don’t see the <strong>My Team</strong> menu, you don’t have access and must contact your account administrator/accountant/bookkeeper to gain access or request removal of the user.                 </Typography>
                            </Box>
                        </Typography>
                        <Typography variant="body1" mb={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            This functionality is only available for Bookkeeper and Accountant Partner Accounts. Find out more about the different Dext Prepare products here.  </Typography>
                        <Typography variant="body1" mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            You can also create additional client accounts for existing clients under one email address with Multi-account Management.
                        </Typography>
                        <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            If you wish to invite a client who already has a Dext Prepare account, the client will need to invite you to access their account by following these steps.  </Typography>
                        <Typography variant="body1" mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            <ul>
                                <li style={{ margin: "10px", color: "#4991e9", textDecoration: 'underline', listStyleType: "disc" }}><a href="#"> Creating a Client Account</a></li>
                                <li style={{ margin: "10px", color: "#4991e9", textDecoration: 'underline', listStyleType: "disc" }}><a href="#"> Editing Client Account Settings</a></li>
                            </ul>
                        </Typography>

                        <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            <Box border={1} padding={2} borderRadius={1} marginBottom={1} style={{ display: 'flex', alignItems: 'center', color: '#546478', fontSize: '1rem' }}>
                                <Tooltip title="Help">
                                    <IconButton style={{ marginBottom: '10px' }}>
                                        <ErrorIcon />
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="body2">
                                    Note: From the 24th of October you will not be able to add clients if you are over your client bundle plan limit. Access to your existing clients will not be affected. Visit your subscription page to view your plan details and upgrade your client bundle.
                                </Typography>
                            </Box>
                        </Typography>
                        <Typography variant="h4" mb={3} mt={3} style={{ fontSize: '1.5rem', textAlign: 'justify', fontWeight: "bold", color: "#4991e9", textDecoration: 'underline' }}>
                            <a href="#">Creating a Client Account</a>
                        </Typography>
                        <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            To create a new client account:
                            <ul style={{ marginTop: "30px" }}>
                                <li style={{ margin: "12px" }}>1. Log in to the<a href="#" style={{ color: "#4991e9", textDecoration: 'underline', listStyleType: "disc" }}> Dext Prepare</a> web app.</li>
                                <li style={{ margin: "12px", }}>2. Go to My <strong>Clients</strong> in the sidebar.</li>
                                <li style={{ margin: "12px", }}>3. Click the green <strong>Add Client</strong> button at the top of the page.</li>

                                <img src="/Screenshot11.png" alt="Screenshot" style={{ border: '7px solid lightgrey', marginBottom: '16px', marginLeft: "300px" }} />
                                <li style={{ margin: "12px", }}>4.The ‘Create a client account’ window will then appear. Enter the client’s name or their business’ name, then select an industry category for the client if desired. When ready click <strong>Next</strong>.</li>
                                <img src="/Screenshot10.png" alt="Screenshot" style={{ border: '7px solid lightgrey', marginBottom: '16px', marginLeft: "150px", width: "500px" }} />
                                <li style={{ margin: "12px", }}>5.On the next screen, assign the members of your team access to the new client account. To do this tick the box next to the colleagues you want to give access to the new client account. To select all colleagues, select ‘Give all your colleagues access to this client’ at the top of the list. When ready click <strong>Next</strong>.</li>
                                <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                                    <Box border={1} padding={1} borderRadius={1} marginBottom={1} style={{ display: 'flex', alignItems: 'center', color: '#546478', fontSize: '1rem' }}>
                                        <Tooltip title="Help">
                                            <IconButton style={{ marginBottom: '10px' }}>
                                                <ErrorIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Typography variant="body2">
                                            Please note – if you have no other colleagues on your account then this step will be skipped.
                                        </Typography>
                                    </Box>
                                </Typography>
                                <img src="/Screensho9.png" alt="Screenshot" style={{ border: '7px solid lightgrey', marginBottom: '16px', marginLeft: "150px", width: "470px" }} />
                                <li style={{ margin: "12px", }}>6. Next, assign the client account manager(s).</li>
                                <img src="/Screensho9.png" alt="Screenshot" style={{ border: '7px solid lightgrey', marginBottom: '16px', marginLeft: "150px", width: "470px" }} />
                                <li style={{ margin: "12px", }}>7. Next, choose whether to invite the new client to Dext Prepare immediately via SMS and/or email OR skip this step.</li>

                            
                        <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            If you click <strong>Skip Invitation</strong>, a client account will be created and the client will be able to submit documents via their unique email in address, as well as be assigned to a document as a document owner, however they will not be able to access the Dext Prepare account themselves via the mobile or web app.</Typography>


                        <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                        If you DO want to invite the client to access the Dext prepare account to have full visibility of their transaction data and use the various submission methods available via the web and mobile apps to add documents; add the user’s name and email address, then click <strong>Next</strong>.</Typography>

                        <img src="/Screenshot8.png" alt="Screenshot" style={{ border: '7px solid lightgrey', marginBottom: '16px', marginLeft: "150px", width: "500px" }} />

                        <li style={{ margin: "12px", }}>8. Finally, on the confirmation page, confirm that you’ll be adding the new client account to your client list and that an invitation will be sent to the listed email address by clicking <strong>Create</strong>.</li>
                        <img src="/Screenshot8.png" alt="Screenshot" style={{ border: '7px solid lightgrey', marginBottom: '16px', marginLeft: "150px", width: "500px" }} />
                        
                        </ul>
                        </Typography>
                        <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                                    <Box border={1} padding={1} borderRadius={1} marginBottom={1} style={{ display: 'flex', alignItems: 'center', color: '#546478', fontSize: '1rem' }}>
                                        <Tooltip title="Help">
                                            <IconButton style={{ marginBottom: '10px' }}>
                                                <ErrorIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Typography variant="body2">
                                        Once you’ve clicked ‘Create’, the new client account will then be automatically added to your client list. The new client will have the same default values (base currency, account country) as your practice and be given email-in addresses to start submitting.                                        </Typography>
                                    </Box>
                                </Typography>
                        
                                <Typography variant="body1" mb={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                                If the email address you entered is already being used by another client account, you will see a message warning you of this.</Typography>
                                 
                                <img src="/Screenshot8.png" alt="Screenshot" style={{ border: '7px solid lightgrey', marginBottom: '16px', marginLeft: "150px", width: "500px" }} />
                                <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
            
              <ul style={{ marginTop: "30px" }}>
                <li style={{ margin: "12px",listStyleType:"disc" }}> If you don’t wish to create a new account, but simply access their existing account, reach out to your client and ask them to invite you to their account.</li>
                <li style={{ margin: "12px", listStyleType:"disc" }} >If the client has multiple businesses and you wish to create an additional account under the same email address, click Invite and create another account for the client under the one email address.</li>
               
              </ul>
            </Typography>
            <Typography variant="h4" mb={3} mt={3} style={{ fontSize: '1.5rem', textAlign: 'justify', fontWeight: "bold", color: "#4991e9", textDecoration: 'underline' }}>
              <a href="#">Editing Client Account Settings</a>
            </Typography>
            <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
            After you’ve created a new client account you can edit the account’s settings as needed.
                </Typography>
            <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
            To do this:
              
            <ul style={{ marginTop: "30px" }}>
                <li style={{ margin: "12px"}}>1. Go to <strong>My Clients</strong> in the sidebar.</li>
                <li style={{ margin: "12px"}}>2. Click <strong>Manage</strong> next to the relevant client.</li>
                <li style={{ margin: "12px"}}>2. Then click <strong>Edit</strong>.</li>

              </ul>
            </Typography>
            <img src="/Screenshot8.png" alt="Screenshot" style={{ border: '7px solid lightgrey', marginBottom: '16px', marginLeft: "20px", width: "440px" }} />

                    </Grid>

                </Grid>
            </Container>
            <Footer />
        </>
    );
}

export default AddClient;
