import React from 'react';
import { Container, Grid, Breadcrumbs, Typography, Divider, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeaderBusiness from './HeaderBusiness';
import Footer from '../components/Footer';

const AddAccounts = () => {
  return (
    <>
      <HeaderBusiness />
      <Container style={{ width: '100%', marginTop: '100px' }}>
        {/* Breadcrumb */}
        <Box mb={4} display="flex">
  <Breadcrumbs
    separator=">"
    aria-label="breadcrumb"
    sx={{
      maxWidth: '100%',
      marginTop: "10px",
      marginLeft: "5px",
      '& ol': {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        width: '100%',
      },
    }}
  >
    {['Home', 'Dext Prepare', 'Getting Started', 'Accountants & Bookkeepers', 'Account Set Up', 'How to Set Up Your Cayegory List'].map((label, index) => (
      <Typography
        key={index}
        sx={{
          color: 'blue',
          textDecoration: 'none',
          cursor: 'default',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&:hover': {
            textDecoration: 'none',
            color: 'blue !important',
            backgroundColor:"transparent"
          }
        }}
      >
        {label}
      </Typography>
    ))}
  </Breadcrumbs>
</Box>


        <Grid container spacing={5}>
          {/* Left Section */}
          <Grid item xs={3}>
  <Box display="flex" alignItems="center" mb={2}>
    <img className='w-[80px]' src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
    <Typography variant="h6" fontWeight="bold" sx={{ fontSize: '2rem' }}>Prepare</Typography>
  </Box>
  <Typography variant="body1" fontWeight="bold" mb={2} mt={3} sx={{ fontSize: '1.30rem' }}>Accounts & Bookkeepers</Typography>

  {[
  {
    title: 'The Web App',
    lines: ['Switching Between Organisations & Clients', 'How to Use the Inline Calculator', 'The Dext Homepage', 'The "Client View" Page', 'The "Practice Insights" Page', 'The "Costs" Workspace', 'The "Sales" Workspace', 'The "Inbox" Page', 'The "item Details" Page', 'The "Bank" Workspace', 'The "Supplier" Page', 'Submission History', 'The "Archive" Page', 'The "System Settings" Page', 'The "Filter" Menu', 'The "Tools" Menu', 'Keyboard Shortcuts'],
  },
  {
    title: 'The Mobile App',
    lines: ['How to set up Biometric Authentication on the Moblie App', 'How to Log In to the Moblie App', 'How to Activate Push Notifications', 'How to Insert Your Company Logo', 'The New Version of the Android App', 'How to Log in to Multiple Accounts on the Dext Mobile App', 'How to Switch Between Different Accounts on the Android Mobile App', 'How to Remove Accounts from the Mobile App'],
  },
  {
    title: 'Introduction to Dext Prepare',
    lines: ['What Dext Prepare Products are Available?', 'How Does Dext Prepare Work?', 'Does Dext Prepare Comply with Tax Authorities?', 'What Technology Does Dext Prepare Use?', 'What Data is Extracted ny Dext Prepare?', 'Is There a Dext Mobile App?', 'How Does Dext Prepare Convert Currency', 'Is There a Dext Community Resource?', 'How to Sign Up to Dext as an Accountant or Bookkeeper', 'How to Process your Sales Invoices', 'How to Get Rewarded For Referring Your Peers To Dext'],
  },
  {
    title: 'Account Set Up',
    lines: ['How to Set Up Tax rates In an Unintegrated Account', 'Using Intuit Single Sign On with Dext', 'Multi-accounts Management for Accountants & Bookkeepers', 'Getting Started with Dext Prepare for Accountants & Bookkeepers', 'How To Add a Client Account', 'How to Add Multiple Client Accounts', 'Your New Dext Prepare Account', 'How to Add a User to Your Account', 'How to Add Users to Dext Prepare', 'How to Invite Someone to Use Dext Prepare', 'How to Upload Your Suppliers List into Dext Prepare', 'How to Set Up Yourn Customer List', 'How to Set Up Your Category List'],
  }
].map((section, index) => (
  <Accordion key={index} elevation={0} sx={{ border: 'none', marginTop: '7px' }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography fontWeight="bold">{section.title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      {section.lines.map((line, i) => (
        <Typography
          key={i}
          sx={{
            padding: '12px 0',
            '&:hover': {
              color: 'blue',
              cursor: 'pointer'
            }
          }}
        >
          {line}
        </Typography>
      ))}
    </AccordionDetails>
  </Accordion>
))}

</Grid>


          {/* Right Section */}
          <Grid item xs={9}>
  <Typography variant="h4" mb={2} style={{ fontSize: '2rem', textAlign: 'justify', fontWeight:"bold" }}>
    How to Set Up Your Category List
  </Typography>
  <Typography variant="body1" mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify', fontWeight:"bold" }}>
    Applying the correct nominal code to your purchases and expenses is an essential part of accounting. Dext Prepare makes it easy to set up your Chart of Accounts, whether you’re working with a cloud or desktop accounting solution.
  </Typography>
  <Typography variant="body1" mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
    In Dext Prepare we refer to each section of your Chart of Accounts as ‘Categories’. You can set them up differently depending on if you’re using the application with an <a href='#' style={{color:"blue"}}>integrated accounting software.</a>
  </Typography>
  <Typography variant="body1" mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
    Learn how to set up your Category Lists:
    <ol>
      <li  style={{ margin:"10px", color:"blue" }}> <a href="#">1. With an integration</a></li>
      <li style={{ margin:"10px", color:"blue" }}><a href="#">2. Without an integration</a></li>
    </ol>
  </Typography>
  <hr style={{ marginTop:"50px" }}/>

  <Typography variant="h4" mb={3} mt={3} style={{ fontSize: '1.5rem', textAlign: 'justify' , fontWeight:"bold"   }}>
    With an integration
  </Typography>
  <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify'}}>
    When you integrate a Dext Prepare account with cloud accounting software, we’ll automatically pull across your Chart of Accounts. To view these click on <strong>Lists</strong> then <strong>Categories</strong>.
  </Typography>
  <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
    Any edits you make to your Chart of Accounts within your accounting package will also take effect within Dext Prepare. We check and renew your Chart of Accounts every 24 hours, but you can also manually update these lists by pressing the green <strong>Reload </strong>button.
  </Typography>
  <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
    If you don’t want a certain nominal code to be available to view or assign to transactions, you can hide specific Categories by turning the toggle in the <strong>Visible</strong> column to <strong>OFF</strong>.
  </Typography>
  <img src="/Screenshot1.png" alt="Screenshot" style={{ border: '7px solid grey', marginBottom: '16px' }} />

  <hr style={{ marginBottom: '16px' }}/>

  <Typography variant="h4" mb={3} mt={3} style={{ fontSize: '1.5rem', textAlign: 'justify' , fontWeight:"bold"   }}>
    With an integration
  </Typography>
  <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
    There are two default lists of nominal codes; simple and comprehensive. You can use these and chose to import or create your own Chart of Accounts:
  </Typography>
  <Typography variant="h6" mb={3} mt={3} style={{ fontSize: '1.2rem', textAlign: 'justify' , fontWeight:"bold"   }}>
    Import your Chart of Accounts
  </Typography>
  <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
    Download your Chart of Accounts from your accounting software as a .CSV file.
  </Typography>
  <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
    To do this go to <strong>Manage Groups</strong> at the top of the page, then click <strong>Import Group</strong>.
  </Typography>
  <img src="/Screenshot2.png" alt="Screenshot" style={{ border: '7px solid grey', marginBottom: '16px' }} />
  <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
    From there simply select the CSV file you wish to upload.
  </Typography>
  <Typography variant="h6" mb={3} mt={3} style={{ fontSize: '1.2rem', textAlign: 'justify' , fontWeight:"bold"   }}>
    Manually Create your Chart of Accounts
  </Typography>
  <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
    1. Go to <strong>Manage groups</strong> at the top of the page, then click <strong>Add group</strong>. You can also duplicate an existing category if desired by clicking <strong>Duplicate group</strong>:
  </Typography>
  <img src="/Screenshot3.png" alt="Screenshot" style={{ border: '7px solid grey', marginBottom: '16px' }} />
  <Typography mb={3} mt={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
    2. Enter the name of the categories and their codes, then click <strong>Save</strong>:
  </Typography>
  <img src="/Screenshot4.png" alt="Screenshot" style={{ border: '7px solid grey', marginBottom: '16px', height: '400px', width: 'auto', marginLeft:"90px" }} />

  <Typography mb={3} mt={3}style={{ fontSize: '1rem', textAlign: 'justify' }}>
    You can edit your categories at any time from the Category page by clicking on the category <strong>Name</strong> or <strong>Code</strong> and making the necessary changes.
  </Typography>
  <img src="/Screenshot5.png" alt="Screenshot" style={{ border: '7px solid grey', marginBottom: '16px' }} />
  <Typography mb={3} mt={3}style={{ fontSize: '1rem', textAlign: 'justify' }}>
    If you don’t want a certain nominal code to be available to view or assign to transactions, you can hide specific Categories by turning the toggle in the <strong>visible</strong> column to <strong>OFF</strong>.
  </Typography>
  <Typography mb={3} mt={3}style={{ fontSize: '1rem', textAlign: 'justify' }}>
    You can also remove a category if desired by clicking <strong>Delete</strong> next to the category.
  </Typography>
</Grid>

        </Grid>
      </Container>
      <Footer/>
    </>
  );
}

export default AddAccounts;
