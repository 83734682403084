import React, { useEffect, useState } from "react";
import { Button, TextField, FormControl, Box, IconButton, InputAdornment, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { baseURL } from '../Config';
import { FaChartPie, FaBuilding } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import SignupStepOne from "../components/register/SignupStepOne";
import SignupStepTwo from "../components/register/SignupStepTwo";



// const Register = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     password: "",
//     phoneNumber: "",
//     role: "",
//     countryCode: "+44",
//     country: "United Kingdom"
//   });



//   const [errors, setErrors] = useState({});
//   const [showPassword, setShowPassword] = useState(false);
//   const handleClickShowPassword = () => {
//     setShowPassword(prev => !prev);
//   };

//   const navigate = useNavigate();

//   useEffect(() => {
//     const userInfo = localStorage.getItem("userInfo");
//     if (userInfo) {
//       navigate("/");
//     }
//   }, [navigate]);

//   const validateRegisterForm = () => {
//     let newErrors = {};

//     if (!formData.name) newErrors.name = "Name is required";
//     if (!formData.email) newErrors.email = "Email is required";
//     else if (!/\S+@\S+\.\S+/.test(formData.email))
//       newErrors.email = "Email address is invalid";
//     if (!formData.password) newErrors.password = "Password is required";
//     else if (formData.password.length < 6)
//       newErrors.password = "Password must be at least 6 characters";
//     if (!formData.phoneNumber)
//       newErrors.phoneNumber = "Phone Number is required";
//     if (!formData.country)
//       newErrors.country = "Country is required";
//     if (!formData.countryCode)
//       newErrors.countryCode = "Code required";
//     if (!formData.role) newErrors.role = "Role is required";

//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSignUp = async () => {
//     if (validateRegisterForm()) {
//       const payload = {
//         name: formData.name,
//         email: formData.email,
//         password: formData.password,
//         phone: formData.phoneNumber,
//         role: formData.role,
//         countryCode: formData.countryCode,
//         country: formData.country,
//         currency:''
//       };

//       try {
//         const response = await fetch(`${baseURL}/auth/signup`, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(payload),
//         });

//         if (response.ok) {
//           const data = await response.json();
//           const userInfo = {
//             name: formData.name,
//             email: formData.email,
//             ...data,
//           };
//           localStorage.setItem("userInfo", JSON.stringify(userInfo));
//           navigate("/email-sent");
//         } else {
//           const errorData = await response.json();
//           setErrors({ apiError: errorData.message || "Signup failed" });
//           console.error("Signup failed:", errorData);
//         }
//       } catch (error) {
//         setErrors({
//           apiError: "An error occurred while signing up. Please try again.",
//         });
//         console.error("Error during signup:", error);
//       }
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   return (
//     <>
//     {
//       !formData?.role ?

//       <WelcomeRolePage setFormData={setFormData} />
      
//       :
// <div className=" h-[100vh]">
//   <div className="px-3 pt-0 pb-0 bg-[#b0b1b0] ">
//     <div>
//       <img className="w-[130px]" src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
//     </div>

//   </div>
//   <div className="p-8  bg-white  flex justify-center items-start ">
//     <div style={{width:'60px', cursor:'pointer'}} onClick={()=>{
//       setFormData((prev)=>({...prev,role:''}))
//     }} > <IoMdArrowRoundBack  size={30} />
//     </div>
//     <div className="w-[35%] flex flex-col justify-center items-center">
//       <h1 className="text-[45px] text-[#515251] mb-1 font-bold">
//         Start Your Free Trial
//       </h1>
//       <div className="text-[18px] text-[#1976d2] mb-[30px] font-bold">
//         No payment details required.
//       </div>
//       <div className="w-[40%] flex mb-6">
//         <div className="w-[50%] h-[5px] bg-[#1976d2]"></div>
//         <div
//           className={
//             formData.name &&
//               formData.email &&
//               formData.password &&
//               formData.phoneNumber
//               ? "w-[50%] h-[5px] bg-[#1976d2] ml-2"
//               : "w-[50%] h-[5px] bg-[#c3cbd5] ml-2"
//           }
//         ></div>
//       </div>
//       <div className="w-full ">
//         <div className="w-full mb-5">
//           <FormControl sx={{ width: "100%" }}>
//             <TextField
//               required
//               size="small"
//               fullWidth
//               placeholder="Name"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//               error={!!errors.name}
//               helperText={errors.name}


//             />
//           </FormControl>
//         </div>
//         <div className="w-full mb-5">
//           <FormControl sx={{ width: "100%" }}>
//             <TextField
//               required
//               size="small"
//               fullWidth
//               placeholder="Email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               error={!!errors.email}
//               helperText={errors.email}
//             />
//           </FormControl>
//         </div>
//         <div className="w-full mb-5">
//           <FormControl sx={{ width: "100%" }}>
//             <TextField
//               required
//               size="small"
//               fullWidth
//               placeholder="Password"
//               type={showPassword ? 'text' : 'password'}
//               name="password"
//               value={formData.password}
//               onChange={handleChange}
//               error={!!errors.password}
//               helperText={errors.password}
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <IconButton
//                       aria-label={showPassword ? 'Hide password' : 'Show password'}
//                       onClick={handleClickShowPassword}
//                       edge="end"
//                     >
//                       {showPassword ? <Visibility /> : <VisibilityOff />}
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </FormControl>
//         </div>
//         <div className="w-full mb-8">
//           <FormControl sx={{ width: "100%" }}>
//             <div style={{ display: 'flex', gap: '10px' }}>
//               <FormControl sx={{ width: '20%' }} error={!!errors.countryCode}>
//                 <Select
//                   required
//                   size="small"
//                   name="countryCode"
//                   value={formData.countryCode}
//                   onChange={handleChange}
//                   disabled={true}
//                   displayEmpty
//                   renderValue={(selected) => selected ? selected : "+"}
//                 >
//                   <MenuItem value="" disabled>
//                     Select Code
//                   </MenuItem>
//                   <MenuItem value="+1">USA (+1)</MenuItem>
//                   <MenuItem value="+91">India (+91)</MenuItem>
//                   <MenuItem value="+44">UK (+44)</MenuItem>
//                 </Select>
//                 {errors.countryCode && (
//                   <FormHelperText>{errors.countryCode}</FormHelperText>
//                 )}
//               </FormControl>
//               <TextField
//                 required
//                 size="small"
//                 sx={{ width: '80%' }}
//                 placeholder="Phone Number"
//                 name="phoneNumber"
//                 value={formData.phoneNumber}
//                 onChange={handleChange}
//                 error={!!errors.phoneNumber}
//                 helperText={errors.phoneNumber}
//               />
//             </div>
//           </FormControl>
//         </div>
//         <div className="w-full mb-8">
//           <FormControl sx={{ width: "100%" }}>
//             <div style={{ display: 'flex', gap: '10px' }}>
//               <FormControl sx={{ width: '100%' }} error={!!errors.country}>
//                 <Select
//                   required
//                   size="small"
//                   name="country"
//                   value={formData.country}
//                   // onChange={handleChange}
//                   disabled={true}
//                   displayEmpty
//                   renderValue={(selected) => selected ? selected : "Select Country"}
//                 >


//                 </Select>
//                 {errors.country && (
//                   <FormHelperText>{errors.country}</FormHelperText>
//                 )}
//               </FormControl>
//             </div>
//           </FormControl>
//         </div>

//         <div className="w-full mb-8">
//           <FormControl sx={{ width: "100%" }}>
//             <div style={{ display: 'flex', gap: '10px' }}>
//               <FormControl sx={{ width: '100%' }} error={!!errors.role}>
//                 <Select
//                   size="small"
//                   fullWidth
//                   name="role"
//                   value={formData.role}
//                   onChange={handleChange}
//                   displayEmpty
//                   renderValue={(selected) => selected ? selected : "Select Role"}
//                 >
//                   <MenuItem value="" disabled>
//                     Select Role
//                   </MenuItem>
//                   <MenuItem value="account">Accountant</MenuItem>
//                   <MenuItem value="business">Business</MenuItem>
//                 </Select>
//                 {errors.role && (
//                   <FormHelperText>{errors.role}</FormHelperText>
//                 )}
//               </FormControl>
//             </div>
//           </FormControl>
//         </div>

//       </div>
//       {errors.apiError && (
//         <div className="text-red-500 mb-4">{errors.apiError}</div>
//       )}
//       <div className="w-full flex flex-col gap-1">
//         <Button
//           variant="contained"
//           sx={{
//             backgroundColor: '#1976d2',
//             color: '#fff',
//             '&:hover': {
//               boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'

//             },
//           }}
//           onClick={handleSignUp}
//           className="w-full mb-2"
//         >
//           Sign Up
//         </Button>
//         <Button
//           variant="text"
//           color="primary"
//           href="/"
//           className="w-full "
//         >
//           I have an account? Login
//         </Button>
//       </div>
//     </div>
//   </div>
// </div>
//     }

//     </>

//   );
// };


const WelcomeRolePage = ({setFormData,handleNext}) => {
const handleRoleChange = (role)=>{
  setFormData((prev)=>({...prev,role:role}))
  handleNext()
}
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      {/* Logo */}
      <div className="text-blue-500 text-3xl font-bold mb-6">Zoom Account</div>

      {/* Heading */}
      <div className="text-center mb-8">
        <h1 className="text-2xl font-bold mb-2">Welcome to Zoom accounts</h1>
        <p className="text-gray-600">
          Choosing the right software is paramount. To help you with that, please tell us how you would describe your company.
        </p>
      </div>

      {/* Options */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col items-center justify-center p-6 border rounded-lg hover:shadow-lg cursor-pointer"
        onClick={()=>handleRoleChange('account')}
        >
          <FaChartPie className="text-blue-500 text-4xl mb-4" />
          <p className="text-gray-700 font-medium">
            An accounting or bookkeeping practice
          </p>
        </div>
        <div className="flex flex-col items-center justify-center p-6 border rounded-lg hover:shadow-lg cursor-pointer"
        onClick={()=>handleRoleChange('business')}
        
        >
          <FaBuilding className="text-blue-500 text-4xl mb-4" />
          <p className="text-gray-700 font-medium">
            A business e.g. retail, construction, florist etc.
          </p>
        </div>
      </div>
    </div>
  );
};



const Register = () => {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({});
  const [erros,setErrors]=useState()

  const navigate = useNavigate();

  const handleNext = (data) => {
    setFormData({ ...formData, ...data });
    setPage(2);
  };

  const handleBack = () => {
    setPage(1);
  };

  const handleSubmit =async (data) => {
    console.log({ ...formData, ...data });
    // alert("Sign up successful!");
    const payload = {
      name: `${formData.firstName} ${formData?.lastName}`,
      email: formData.email,
      password: formData.password,
      phone: formData.phoneNumber,
      role: formData.role,
      countryCode: formData.countryCode,
      country: formData.country,
      currency:''
    };

    try {
      const response = await fetch(`${baseURL}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        const userInfo = {
          name: formData.name,
          email: formData.email,
          ...data,
        };
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        navigate("/email-sent");
      } else {
        const errorData = await response.json();
        setErrors({ apiError: errorData.message || "Signup failed" });
        console.error("Signup failed:", errorData);
      }
    } catch (error) {
      setErrors({
        apiError: "An error occurred while signing up. Please try again.",
      });
      console.error("Error during signup:", error);
    }
  };

  return (
    <div>
      {page===0 && <WelcomeRolePage setFormData={setFormData} handleNext={()=>setPage(1)}/>}
      {page === 1 && <SignupStepOne nextStep={handleNext} />}
      {page === 2 && <SignupStepTwo prevStep={handleBack} onComplete={handleSubmit} />}
    </div>
  );
};




const SignUpPageOne = ({ onNext }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name cannot be blank";
    if (!formData.lastName) newErrors.lastName = "Last name cannot be blank";
    if (!formData.email.includes("@")) newErrors.email = "Please enter a valid email";
    if (!formData.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9@#$%^&+=]).{8,}$/)) {
      newErrors.password = "Password must meet the criteria";
    }
    // if (!formData.phone.match(/^\+\d{1,4}\s\d{6,}$/)) newErrors.phone = "Please enter a valid phone number";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validate()) onNext(formData);
  };

  return (
    <div className="signup-container">
      <h1>Start your free trial</h1>
      <p>No payment details required.</p>
      <form className="signup-form">
        <div className="form-group">
          <label>First Name *</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
          {errors.firstName && <span className="error">{errors.firstName}</span>}
        </div>

        <div className="form-group">
          <label>Last Name *</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
          {errors.lastName && <span className="error">{errors.lastName}</span>}
        </div>

        <div className="form-group">
          <label>Work Email *</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>

        <div className="form-group">
          <label>Password *</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          {errors.password && <span className="error">{errors.password}</span>}
          <small>Password must: contain lowercase letters, be at least 8 characters long, and have 2 uppercase letters/numbers/symbols.</small>
        </div>

        <div className="form-group">
          <label>Mobile Number *</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="+44"
          />
          {errors.phone && <span className="error">{errors.phone}</span>}
        </div>

        <button type="button" onClick={handleNext} className="next-btn">
          Next
        </button>
      </form>
    </div>
  );
};

const SignUpPageTwo = ({ onBack, onSubmit }) => {
  const [formData, setFormData] = useState({
    country: "United Kingdom",
    practiceName: "",
    termsAccepted: false,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.practiceName) newErrors.practiceName = "Please enter a company name";
    if (!formData.termsAccepted) newErrors.termsAccepted = "Please accept our Terms and Conditions and Privacy Policy";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) onSubmit(formData);
  };

  return (
    <div className="signup-container">
      <h1>Start your free trial</h1>
      <p>No payment details required.</p>
      <form className="signup-form">
        <div className="form-group">
          <label>Country *</label>
          <select name="country" value={formData.country} onChange={handleChange}>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
            <option value="Canada">Canada</option>
          </select>
        </div>

        <div className="form-group">
          <label>Practice Name *</label>
          <input
            type="text"
            name="practiceName"
            value={formData.practiceName}
            onChange={handleChange}
          />
          {errors.practiceName && <span className="error">{errors.practiceName}</span>}
        </div>

        <div className="form-group checkbox-group">
          <input
            type="checkbox"
            name="termsAccepted"
            checked={formData.termsAccepted}
            onChange={handleChange}
          />
          <label>
            I accept Dext’s <a href="#">Terms and Conditions</a> & <a href="#">Privacy Policy</a>
          </label>
          {errors.termsAccepted && <span className="error">{errors.termsAccepted}</span>}
        </div>

        <div className="form-buttons">
          <button type="button" onClick={onBack} className="back-btn">
            Back
          </button>
          <button type="button" onClick={handleSubmit} className="submit-btn">
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
};


// const Register = () => {
//   const [page, setPage] = useState(1);
//   const [formData, setFormData] = useState({});
//   const [errors,setErrors]=useState()

//   const navigate=useNavigate()

//   const handleNext = (data) => {
//     setFormData({ ...formData, ...data });
//     setPage(2);
//   };

//   const handleBack = () => {
//     setPage(1);
//   };

//   const handleSubmit = async(data) => {
//     console.log({ ...formData, ...data });
//     // alert("Sign up successful!");
//     const payload = {
//       name: formData.name,
//       email: formData.email,
//       password: formData.password,
//       phone: formData.phoneNumber,
//       role: formData.role,
//       countryCode: formData.countryCode,
//       country: formData.country,
//       currency:''
//     };

//     try {
//       const response = await fetch(`${baseURL}/auth/signup`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(payload),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         const userInfo = {
//           name: formData.name,
//           email: formData.email,
//           ...data,
//         };
//         localStorage.setItem("userInfo", JSON.stringify(userInfo));
//         navigate("/email-sent");
//       } else {
//         const errorData = await response.json();
//         setErrors({ apiError: errorData.message || "Signup failed" });
//         console.error("Signup failed:", errorData);
//       }
//     } catch (error) {
//       setErrors({
//         apiError: "An error occurred while signing up. Please try again.",
//       });
//       console.error("Error during signup:", error);
//     }
//   };

//   return (
//     <div>
//       {page === 1 && <SignUpPageOne onNext={handleNext} />}
//       {page === 2 && <SignUpPageTwo onBack={handleBack} onSubmit={handleSubmit} />}
//     </div>
//   );
// };

export default Register;
