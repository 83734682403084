import React, { useEffect, useState } from 'react';
import { Table, TablePagination, TextareaAutosize, Button, Select, MenuItem, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TableSortLabel, Box, Toolbar, Typography, Tooltip, IconButton, TextField } from "@mui/material";
import {  HelpOutline } from '@mui/icons-material';
import { Textarea } from 'flowbite-react';
import CusAddModal from './CusAddModal';
import { useParams } from 'react-router-dom';
import { baseURL } from '../../Config';
import axios from 'axios';

const TableAllCus =  () => {
  const {id:slugId} = useParams();
    const [isTaxDropdownOpen, setIsTaxDropdownOpen] = useState(false);
    const [isCostDropdownOpen, setIsCostDropdownOpen] = useState(false);
    const [category, setCategory] = useState('');
    const [customerData,setCustomerData] = useState(null);
    const [taxRate, setTaxRate] = useState('');
    const [open, setOpen] = useState(false);
    const [categoriesData,setCategoriesData] = useState(null);
  const token = localStorage.getItem('token');



    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await axios.get(`${baseURL}/customer/${slugId}`, {
                  headers: {
                      Authorization: `Bearer ${token}`
                  }
              });
              // setSupplierData(response.data);
              setCustomerData(response.data);
              console.log("Fetched customer Data:", response.data); 
          } catch (error) {
              console.error("Error fetching data:", error);
          }
      };
      const fetchAllCategories = async () => {
        try {
            const response = await axios.get(`${baseURL}/category/getAll`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setCategoriesData(response.data);
            console.log("Fetched Categories Data:", response.data); // Console log the fetched data
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
   
  
  
      fetchData();
      fetchAllCategories();
     
  }, []);
  

    const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
    const toggleTaxDropdown = () => {
      setIsTaxDropdownOpen(!isTaxDropdownOpen);
    };
  
    const toggleCostDropdown = () => {
      setIsCostDropdownOpen(!isCostDropdownOpen);
    };
  
    return (
      <TableContainer 
      component="div" 
    sx={{ 
      boxShadow: 'none', 
      padding: 2,
      marginTop:-4,
    }}>
        <Table sx={{ borderCollapse: 'collapse', width: '100%'}}>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} sx={{ border: 'none' }}>
                <h3 style={{ color: '#1976d2' , fontSize:"18px", fontWeight:"bold" }}>{customerData?.name}</h3>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ border: 'none' , fontSize:"9px"}}>
                <Typography sx={{ fontSize: "13px", marginTop: '-12px' }}>
                  Total spent: GBP 52.17 | Total Items: 0 | Inbox: <span style={{ textDecoration: 'underline', color: '#1976d2', cursor: 'pointer' }}>0 View</span> | Archive: <span style={{ textDecoration: 'underline', color: '#1976d2', cursor: 'pointer' }}>0 View</span>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ border: 'none', }}>
                <h3 style={{fontWeight:"bold"}}>Rules</h3>
              </TableCell>
            </TableRow>
            <TableRow sx={{ marginTop: '-20px'}}>
              <TableCell style={{ width: '20%', border: 'none'}}>
                <span>Name <span style={{color:"red"}}>*</span></span>
              </TableCell>
              <TableCell sx={{ border: 'none'}}>
                <input type="text" value={customerData?.name} readOnly style={{ width: "220px", fontSize: "13px", borderRadius:"5px"}} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ border: 'none' }}>
                <h3 style={{fontWeight:"bold"}}>Customer Rules</h3>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ border: 'none' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <div style={{ display: 'flex', alignItems: 'center' , fontSize:"13px"}}>
                    Currency
                    {/* <Select
                      sx={{ height: "30px", width: "300px", fontSize: "13px", marginLeft: 2 }}
                      value={customerData.currency}
                      onChange={(e) => setCategory(e.target.value)}
                      open={isCostDropdownOpen}
                      onClose={toggleCostDropdown}
                      onOpen={toggleCostDropdown}
                      displayEmpty
                    >
                      <MenuItem value="">
                      </MenuItem>
                      <MenuItem value={10}> Follow Systems Settings</MenuItem>
                      <MenuItem value={20}>Automatic</MenuItem>
                      <MenuItem value={30}>Review</MenuItem>
                      <MenuItem value={40}>Off</MenuItem>
                    </Select> */}
                    <input type="text" value={customerData?.currency} readOnly style={{ width: "300px",border:"1px solid gray", marginLeft:"20px",fontSize: "13px",height:"30px", borderRadius:"5px"}} />
                    <IconButton size="small" onClick={toggleCostDropdown}>
                    </IconButton>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', fontSize:"13px" }}>
                    Category
                    <Select
                      sx={{ height: "30px", width: "300px", fontSize: "13px", marginLeft: 2 }}
                      value={taxRate}
                      onChange={(e) => setTaxRate(e.target.value)}
                      open={isTaxDropdownOpen}
                      onClose={toggleTaxDropdown}
                      onOpen={toggleTaxDropdown}
                      displayEmpty
                    >
                      <MenuItem value="">
                      </MenuItem>
                      {/* <MenuItem value={5}>5%</MenuItem>
                      <MenuItem value={10}>10%</MenuItem>
                      <MenuItem value={20}>20%</MenuItem> */}
                    </Select>
                    <IconButton size="small" onClick={toggleTaxDropdown}>
                    </IconButton>
                  </div>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ border: 'none' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <div style={{ display: 'flex', alignItems: 'center', fontSize:"13px" }}>
                  Due Date
                    <Select
                      sx={{ height: "30px", width: "300px", fontSize: "13px", marginLeft: 2 }}
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      open={isCostDropdownOpen}
                      onClose={toggleCostDropdown}
                      onOpen={toggleCostDropdown}
                      displayEmpty
                    >
                      
                      {
                        categoriesData?.map((item)=>{
                          return (
                            <MenuItem value={item?._id}>{item?.name}</MenuItem>

                          )
                        })
                      }
                    
                    </Select>
                    <IconButton size="small" onClick={toggleCostDropdown}>
                    </IconButton>
                  </div>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ border: 'none' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <div style={{ display: 'flex', alignItems: 'center', fontSize:"13px" }}>
                    Description
                  <Textarea style={{marginLeft:5, width:'300px', height: '70px'}}>

                  </Textarea>
                  </div>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ border: 'none' }}>
                <h3 style={{fontWeight:"bold"}}>Smart Split Rules</h3>
                <p >Create line item rules which will be automatically applied to all items for this Supplier in the Inbox and for future submissions.

                <Tooltip title="">
                      <IconButton size="small">
                        <HelpOutline fontSize="small" />
                      </IconButton>
                    </Tooltip>
</p>
              </TableCell>
            </TableRow>
          </TableBody>
          <CusAddModal open={open} handleClose={handleClose} handleOpen={handleOpen} />
          <Button
            variant="contained"
            sx={{
              fontSize: '12px',
              padding: '2px 10px',
              marginLeft: '12px',
              border:"none",
              fontWeight:"bold",
              color:"#ffff",
              backgroundColor: '#1976d2',
              boxShadow:"none", 
              width:"100%",
            }}
            onClick={handleOpen}
           
          >
             Add Smart Split Rule
          </Button>
        </Table>
      </TableContainer>
    );
  };
export default TableAllCus;
