export function TransparentBtn({
  label,
  onClick,
  startIcon,
  border,
  disabled,
  endIcon,
}) {
  return (
    <div
      onClick={onClick}
      className={`flex justify-center items-center gap-1 py-2 px-3  text-sm font-bold rounded-sm ${
        border ? 'bg-slate-100' : ''
      } ${
        !disabled
          ? `hover:bg-slate-400 cursor-pointer text-black ${
              border ? 'bg-slate-300' : ''
            }`
          : 'text-gray-500 cursor-not-allowed'
      }`}
    >
      <div>{startIcon}</div>
      <div>{label}</div>
      <div>{endIcon}</div>
    </div>
  );
}
