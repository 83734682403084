import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
const ForgotPassword = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState()
    const [error, setError] = useState()

    const handleSend = () => {
        const isValid = validateSend();
        if (isValid) {
            alert(`Soon! You will recieve the password on the ${email} `)
            navigate('/')

        }
        else {
            alert('Incorrect Email! Enter Valid Email.')
            setEmail("")
        }

    }

    const handleChange = (e) => {
        setEmail(e.target.value)
    }
    const validateSend = () => {
        let emailError = '';

        if (!email) {
            emailError = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            emailError = 'Email address is invalid';
        }

        if (emailError) {
            setError({ email: emailError });
            return false;
        }
        return true;
    };
    return (
        <div className='realtive h-screen w-screen bg-[#fcfcfc]'>
            <div className='flex flex-col items-center absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%]'>
                <img className='w-[35%] h-[200px] ' src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
                <div className='  z-10 px-6 py-7 bg-white border-2 border-[#d1d1d1] flex flex-col items-center rounded-md '>
                    <div className='w-[15%]'><img src="./Lock.jpeg" alt="Forgot Password" /></div>
                    <span className='mt-4 text-lg font-bold'>Forgot Your Password?</span>
                    <p className='text-center text-[#575757] text-[15px]'>Please enter the e-mail address you used to register with Zoom,<br /> and we'll send you a password reset link.</p>
                    <input className='mt-6 w-3/4 rounded-sm' type="text" placeholder='Email' value={email} onChange={handleChange} />
                    <button onClick={handleSend} className='flex items-center gap-3 mt-14 bg-[#1976d2] rounded-md text-white shadow-md px-4 py-2 text-lg active:translate-y-1 hover:shadow-2xl'><LockOpenIcon className='p-0' /> Send</button>
                </div>

            </div>



        </div>
    )
}

export default ForgotPassword

