import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import './inboxtablestyle.css'
import { height } from '@mui/system';


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? '#2684FF' : '#ccc',
        boxShadow: state.isFocused ? 'none' : 'none', // Remove default shadow
        '&:hover': {
            borderColor: '#2684FF',
        },
        height:'30px',
    }),
    input: (provided) => ({
        ...provided,
        boxShadow: 'none', // Remove outline from the input box
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            border: 'none'
        },
    }),
    // To remove the outline for the options when focused
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#e6f7ff' : '#fff',
        '&:focus': {
            outline: 'none',
        },
    }),
};

const categoryOptions = [
    { value: 'Accomodation', label: 'Accomodation' },
    { value: 'Food', label: 'Food' },
    { value: 'IT & Technology', label: 'IT & Technology' },
    { value: 'Motor Expenses', label: 'Motor Expenses' },
    { value: 'Office Supplies', label: 'Office Supplies' },
    { value: 'Other', label: 'Other' },
    { value: 'Postage', label: 'Postage' },
    { value: 'Telecoms & Internet', label: 'Telecoms & Internet' },
    { value: 'To Be Allocated', label: 'To Be Allocated' },
    { value: 'Training & Education', label: 'Training & Education' },
    { value: 'Travel', label: 'Travel' },
    { value: 'Utilites & Bills', label: 'Utilites & Bills' },
];

const options = [
    { value: 'Accomodation', label: 'Accomodation' },
    { value: 'Food', label: 'Food' },
];

const submissionOptions = [
    { value: 'via_mobile', label: 'via_mobile' },
    { value: 'via_web', label: 'via_web' },
    { value: 'via_email', label: 'via_email' },
    { value: 'via_supplier_email', label: 'via_supplier_email' },
    { value: 'via_fetch', label: 'via_fetch' },
    { value: 'via_paper', label: 'via_paper' },
    { value: 'via_dropbox', label: 'via_dropbox' },
    { value: 'via_system', label: 'via_system' },
    { value: 'created_in_zoom', label: 'created_in_zoom' },
    { value: 'via_paypal', label: 'via_paypal' },
    { value: 'via_tripcatcher', label: 'via_tripcatcher' },
    { value: 'via_zapier', label: 'via_zapier' },
    { value: 'via_jefacture', label: 'via_jefacture' },
    { value: 'via_einvoicong', label: 'via_einvoicong' },
    { value: 'via_dextension', label: 'via_dextension' },
];

const currencyOptions = [
    { value: 'CAD', label: 'CAD' },
    { value: 'AUD', label: 'AUD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'NZD', label: 'NZD' },
    { value: 'HKD', label: 'HKD' },
    { value: 'GBP', label: 'GBP' },
    { value: 'USD', label: 'USD' },
    { value: 'INR', label: 'INR' },
    { value: 'JPY', label: 'JPY' },
    { value: 'CNY', label: 'CNY' },
    { value: 'BRL', label: 'BRL' },
    { value: 'ZAR', label: 'ZAR' },
    { value: 'MXN', label: 'MXN' },
    { value: 'RUB', label: 'RUB' },
    { value: 'CHF', label: 'CHF' },
    { value: 'SGD', label: 'SGD' },
    { value: 'KRW', label: 'KRW' },
    { value: 'MYR', label: 'MYR' },
    { value: 'THB', label: 'THB' },
    { value: 'IDR', label: 'IDR' },
    { value: 'PHP', label: 'PHP' },
    { value: 'SAR', label: 'SAR' },
    { value: 'AED', label: 'AED' },
    { value: 'TRY', label: 'TRY' },
    { value: 'SEK', label: 'SEK' },
    { value: 'NOK', label: 'NOK' },
    { value: 'DKK', label: 'DKK' },
    { value: 'PLN', label: 'PLN' },
    { value: 'HUF', label: 'HUF' },
    { value: 'CZK', label: 'CZK' },
    { value: 'EGP', label: 'EGP' },
    { value: 'ARS', label: 'ARS' },
    { value: 'CLP', label: 'CLP' },
    { value: 'VND', label: 'VND' },
    { value: 'PKR', label: 'PKR' },
    { value: 'BDT', label: 'BDT' },
    { value: 'NGN', label: 'NGN' },
    { value: 'ILS', label: 'ILS' },
    { value: 'KWD', label: 'KWD' },
    { value: 'QAR', label: 'QAR' },
    { value: 'COP', label: 'COP' },
    { value: 'TWD', label: 'TWD' },
    { value: 'MAD', label: 'MAD' },
    { value: 'Other', label: 'Other' },
];

const typeOptions = [
    { value: 'Reciept', label: 'Reciept' },
    { value: 'ATM withdrawal', label: 'ATM withdrawal' },
    { value: 'other', label: 'other' },
    { value: 'Invoice', label: 'Invoice' },
    { value: 'Statement/Remmitance advice', label: 'Statement/Remmitance advice' },
    { value: 'Expense Statement', label: 'Expense Statement' },
    { value: 'Credit Note/Refund', label: 'Credit Note/Refund' },
    { value: 'Delivery Note', label: 'Delivery Note' },
    { value: 'Mileage', label: 'Mileage' }
];


const InboxTableAdvance = ({ rowsData, formData, setFormData }) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const [customerOptions, setCustomerOptions] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([])
    const [submittedByOptions, setSubmittedByOptions] = useState([])

    const handleChange = (selectedOption, name) => {
        console.log(selectedOption)
        setFormData({ ...formData, [name]: selectedOption })
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (date, name) => {
        setFormData({ ...formData, [name]: date });
    };

    const handleCheckboxChange = (e) => {
        setFormData({ ...formData, addedToExpenseClaim: e.target.checked });
    };
    // console.log("Advance", rowsData)

    const fetchSelectsData = async (data) => {
        let customers = [];
        let suppliers = [];
        let submittedBy = [];
        for (let row of data) {
            if (!customers.includes(row.customer)) {
                let opt = { value: row.customer, label: row.customer }
                customers.push(opt);
            }
            if (!suppliers.includes(row.supplier)) {
                let opt = { value: row.supplier, label: row.supplier }
                suppliers.push(opt);
            }
            if (!submittedBy.includes(row.submittedBy)) {
                let opt = { value: row.submittedBy, label: row.submittedBy }
                suppliers.push(opt);
            }
        }
        return { customers, suppliers, submittedBy };
    }


    useEffect(() => {
        const loadSelectData = async () => {
            const { customers, suppliers, submittedBy } = await fetchSelectsData(rowsData);

            setCustomerOptions(customers);
            setSupplierOptions(suppliers);
            setSubmittedByOptions(submittedBy)
        };

        loadSelectData();
    }, [rowsData]);

    return (
        <div className="advanced-search-form flex flex-col gap-4 mb-2 p-4">
            <h3 className='text-black text-base font-semibold' style={{fontSize:'14px'}} >Advanced Search </h3>

            <div className="form-group flex gap-24 ">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}} >Search term</label>
                <div className='w-[80%] flex justify-start'><input
                    className='w-full border-[#ccc]'
                    type="text"
                    name="searchTerm"
                    value={formData.searchTerm}
                    onChange={handleInputChange}
                    style={{height:'30px !important',}}
                /></div>

            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Supplier</label>
                <div className='w-[80%] flex justify-start'>
                    <Select
                        className='w-full '
                        value={supplierOptions.find(option => option.value === formData.supplier) || null}
                        onChange={(selectedOption) => handleChange(selectedOption.value, 'supplier')}
                        options={supplierOptions}
                        styles={customStyles}
                        isSearchable={true} // Enable search functionality
                        placeholder="Select Supplier"
                        style={{height:'30px !important',}}
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Amount</label>
                <div className='w-[80%] flex items-center justify-evenly '>
                    <input
                        className='w-[47%] border-[#ccc]'
                        type="number"
                        name="amountFrom"

                        value={formData.amountFrom}
                        onChange={handleInputChange}
                        style={{height:'30px !important',}}
                    />
                    <span>to</span>
                    <input
                        className='w-[47%] border-[#ccc]'
                        type="number"
                        name="amountTo"

                        value={formData.amountTo}
                        onChange={handleInputChange}
                        style={{height:'30px !important',}}
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Date</label>
                <div className='w-[80%] flex items-center justify-evenly '>
                    <DatePicker
                        className='w-[98%] border-[#ccc]'
                        selected={formData.dateFrom}
                        datedateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date, 'dateFrom')}
                        placeholderText="From"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                          height: '30px !important',
                        }}}                    />
                    <DatePicker
                        className='w-[98%] border-[#ccc]'
                        selected={formData.dateTo}
                          dateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date, 'dateTo')}
                        placeholderText="To"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                          height: '30px !important',
                        }}}
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}} >Due Date</label>
                <div className='w-[80%] flex items-center justify-evenly '>
                    <DatePicker
                        className='w-[98%] border-[#ccc]'
                        selected={formData.dueDateFrom}
                          dateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date, 'dueDateFrom')}
                        placeholderText="From"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                          height: '30px !important',
                        }}}
                    />
                    <DatePicker
                        className='w-[98%] border-[#ccc]'
                        selected={formData.dueDateTo}
                          dateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date, 'dueDateTo')}
                        placeholderText="To"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                          height: '30px !important',
                        }}}
                    />
                </div>

            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Upload Date</label>
                <div className='w-[80%] flex items-center justify-evenly '>
                    <DatePicker
                        className='w-[98%] border-[#ccc]'
                        selected={formData.uploadDateFrom}
                          dateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date, 'uploadDateFrom')}
                        placeholderText="From"   sx={{
                            '& .MuiOutlinedInput-root': {
                          height: '30px !important',
                        }}}
                    />
                    <DatePicker
                        className='w-[98%] border-[#ccc]'
                        selected={formData.uploadDateTo}
                          dateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date, 'uploadDateTo')}
                        placeholderText="To"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                          height: '30px !important',
                        }}}
                    />
                </div>
            </div>



            <div className="form-group flex gap-24 ">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Document Reference</label>
                <div className='w-[80%] flex justify-start'><input
                    className='w-full border-[#ccc]'
                    type="text"
                    name="documentReference"
                    value={formData.documentReference}
                    onChange={handleInputChange}
                    style={{height:'30px !important'}}
                />
                </div>
            </div>

            <div className="form-group flex gap-24 ">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Item ID</label>
                <div className='w-[80%] flex justify-start'> <input
                    className='w-full border-[#ccc]'
                    type="text"
                    name="itemId"
                    value={formData.itemId}
                    onChange={handleInputChange}
                    style={{height:'30px !important'}}
                />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Type</label>
                <div className="w-[80%]">
                    <Select
                        className='w-full '
                        value={typeOptions.find(option => option.value === formData.type) || null}
                        onChange={(selectedOption) => handleChange(selectedOption.value, 'type')}
                        options={typeOptions}
                        styles={customStyles}
                        isSearchable={true} // Enable search functionality
                        placeholder="Select Type"
                        style={{height:'30px !important'}}
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Currency Code</label>
                <div className="w-[80%]">
                    <Select
                        className='w-full'
                        value={currencyOptions.find(option => option.value === formData.currencyCode) || null}
                        onChange={(selectedOption) => handleChange(selectedOption.value, 'currencyCode')}
                        options={currencyOptions}
                        styles={customStyles}
                        isSearchable={true} // Enable search functionality
                        placeholder="Select Currency"
                        style={{height:'30px !important'}}
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Submitted By</label>
                <div className="w-[80%]">
                    <Select
                        className='w-full'
                        value={submittedByOptions.find(option => option.value === formData.submittedBy) || null}
                        onChange={(selectedOption) => handleChange(selectedOption.value, 'submittedBy')}
                        options={submittedByOptions}
                        styles={customStyles}
                        isSearchable={true} // Enable search functionality
                        placeholder="Select"
                        style={{height:'30px !important'}}
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Submission Method</label>
                <div className="w-[80%]">
                    <Select
                        className='w-full'
                        value={submissionOptions.find(option => option.value === formData.submissionMethod) || null}
                        onChange={(selectedOption) => handleChange(selectedOption.value, 'submissionMethod')}
                        options={submissionOptions}
                        styles={customStyles}
                        isSearchable={true} // Enable search functionality
                        placeholder="Select"
                        style={{height:'30px !important'}}
                    />
                </div>
            </div>

            <div className="form-group flex gap-24">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Customer</label>
                <div className="w-[80%]">
                    <Select
                        className='w-full'
                        value={customerOptions.find(option => option.value === formData.customer) || null}
                        onChange={(selectedOption) => handleChange(selectedOption.value, 'customer')}
                        options={customerOptions}
                        styles={customStyles}
                        isSearchable={true} // Enable search functionality
                        placeholder="Select Customer"
                        style={{height:'30px !important'}}
                    />
                </div>
            </div>

            <div className="form-group flex gap-24 ">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Category</label>
                <div className="w-[80%]">
                    <Select
                        className='w-full'
                        value={categoryOptions.find(option => option.value === formData.category) || null}
                        onChange={(selectedOption) => handleChange(selectedOption.value, 'category')}
                        options={categoryOptions}
                        styles={customStyles}
                        isSearchable={true}
                        placeholder="Select Category"
                    />
                </div>
            </div>



            <div className="form-group flex gap-24">
                <label className='w-[30%] font-normal  ' style={{fontSize:'12px'}}>Expense Claim</label>
                <select
                    className='w-[80%] border-[#ccc] text-[#ccc]'
                    name="expenseClaim"
                    value={formData.expenseClaim}
                    onChange={handleInputChange}
                >
                    <option value="">Select Expense Claim</option>
                    <option value="claim1">Claim 1</option>
                    <option value="claim2">Claim 2</option>
                </select>
            </div>

            <div className="form-group flex justify-end pr-7 items-center gap-1">
                <input
                    type="checkbox"
                    name="addedToExpenseClaim"
                    checked={formData.addedToExpenseClaim}
                    onChange={handleCheckboxChange}
                />
                <label>Added to an Expense Claim</label>
            </div>
        </div>
    )
}

export default InboxTableAdvance

