import React, { useEffect, useState } from "react";
import { Button, Toolbar, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Box } from "@mui/material";

import TableSales from "./TableSales";
import { Dropdown } from "flowbite-react";
import axios from 'axios';
import { baseURL } from '../Config';
import ExportDialog from "./ExportDiaolog";

import TableExtendedToolTip from "../costComponents/utils/TableExtendedToolTip";
const token = localStorage.getItem('token');

function EnhancedTableToolbar({ rowsData, salesAvailable, numSelected, onArchiveClick, onDeleteClick, onExportClick, onCovertClick }) {
    const [openSetting, setOpenSetting] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)
    const [openAdvanceSearch, setOpenAdvanceSearch] = useState(false)
    const [selectedTab, setSelectedTab] = useState(1);
    const [selectedTabFilter, setSelectedTabFilter] = useState({
        'Ready': null,
        'Tax': null,
        'Tax Rate': null,
        'Category': null,
        'Current': null,
        'Read': null,
        'Publishing': null,
        'Flag': null,
        'Merged': null,
        'Duplicates': null,
        'Paid': null,
        'Has Match': null,
        'Auto-categorised': null
    });
    const primaryColumns = ['Date', 'Supplier', 'Category', 'Total', 'Tax', 'Tax Rate', 'Match']
    const additionalColumns = ['Submitted By', 'Due Date', 'Document reference', 'Base Total', 'Description', 'Paid', 'Payment', 'Type', 'Item Id', 'Submissin Method', 'Note/Message', 'Upload Date']
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div className="flex items-center">
                <Button
                    disabled={salesAvailable === 0 ? true : false}
                    variant="contained"
                    sx={{
                        fontSize: '12px',
                        padding: '8px 15px',
                        marginRight: '10px',
                        fontWeight: 'bold',
                        backgroundColor: '#1976D2',
                        color: '#fff',
                        '&:hover': {
                            bgcolor: '#1976D2',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                        },
                    }}
                    onClick={onExportClick}
                >
                    {numSelected === 0 ? 'Export All' : 'Export'}
                </Button>
                <Button
                    disabled={numSelected === 0}
                    variant="contained"
                    sx={{
                        fontSize: '12px',
                        padding: '8px 15px',
                        marginRight: '10px',
                        fontWeight: 'bold',
                        backgroundColor: '#1976D2',
                        color: '#fff',
                        '&:hover': {
                            bgcolor: '#1976D2',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                        },
                    }}
                    onClick={onArchiveClick}
                >
                    Archive
                </Button>
                <Button
                    disabled={true}
                    variant="contained"
                    sx={{
                        fontSize: '12px',
                        padding: '8px 15px',
                        marginRight: '10px',
                        fontWeight: 'bold',
                        backgroundColor: '#1976D2',
                        color: '#fff',
                        '&:hover': {
                            bgcolor: '#1976D2',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                        },
                    }}
                >
                    Add To Expense Report
                </Button>
                <Dropdown
                    disabled={numSelected === 0}
                    style={{
                        borderRadius: '3px',
                        background: '#1976D2',
                        padding: '0',
                        color: '#fff',
                        fontWeight: 'bold',
                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
                    }}
                    label="Tools"
                    dismissOnClick={false}
                >
                    <Dropdown.Item style={{ filter: 'blur(2px)' }}>Merge</Dropdown.Item>
                    {/* <Dropdown.Item style={{ filter: 'blur(2px)' }}>Bulk edit</Dropdown.Item>
                    <Dropdown.Item style={{ filter: 'blur(2px)' }}>Send by email</Dropdown.Item> */}
                    <Dropdown.Item onClick={onCovertClick}>Convert to Costs</Dropdown.Item>
                    {/* <Dropdown.Item style={{ filter: 'blur(2px)' }}>Flag</Dropdown.Item>
                    <Dropdown.Item style={{ filter: 'blur(2px)' }}>Unflag</Dropdown.Item> */}
                    <Dropdown.Item onClick={onDeleteClick}>Delete</Dropdown.Item>
                </Dropdown>
            </div>
            <TableExtendedToolTip rowsData={rowsData} />
        </Toolbar>


    );
}

const ArchiveDialog = ({ open, onClose, selectedCount, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle sx={{ color: "#1976D2" }}>Add Item(s)</DialogTitle>
            <DialogContent>
                <Typography>
                    You have selected {selectedCount} item(s) to archive.
                </Typography>
                <DialogContentText>
                    Are you sure you want to archive the selected items?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ background: "none", border: "none", '&:hover': { textDecoration: "underline" } }}>
                    Cancel
                </Button>
                <Button
                    onClick={onConfirm}
                    variant="contained"
                    color="primary"
                    sx={{
                        background: "#1976D2",
                        border: "none",
                        '&:hover': {
                            border: "1px solid #1976D2",
                            background: "#1976D2"
                        },
                        fontWeight: 'bold'
                    }}
                >
                    Archive
                </Button>
            </DialogActions>
        </Dialog>
    );
};


const DeleteDialog = ({ open, onClose, selectedCount, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onClose} sx={{ padding: '40px' }}>
            <DialogTitle sx={{ color: "#1976D2", fontWeight: "bold" }}>Delete Item(s)</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete the selected {selectedCount} item(s)?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ background: "none", border: "none", fontWeight: "bold", color: "#000099", '&:hover': { bgcolor: "#f0f0f0" } }}>
                    Cancel
                </Button>
                <Button onClick={onConfirm} sx={{ background: "#ff5a02", border: "none", color: "#ffff", fontWeight: "bold", '&:hover': { bgcolor: "#ff5a02" } }}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ConvertToCosts = ({ open, onClose, selectedCount, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onClose} sx={{ padding: '40px' }}>
            <DialogTitle sx={{ color: "#1976D2", fontWeight: "bold" }}>Convert to Cost Item(s)</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to convert to costs {selectedCount} item(s)?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ background: "none", border: "none", fontWeight: "bold", color: "#000099", '&:hover': { bgcolor: "#f0f0f0" } }}>
                    Cancel
                </Button>
                <Button onClick={onConfirm} sx={{ background: "#ff5a02", border: "none", color: "#ffff", fontWeight: "bold", '&:hover': { bgcolor: "#ff5a02" } }}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const SalesInbox = ({ fileURL }) => {
    const [selected, setSelected] = useState([]);
    const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
    const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [sales, setSales] = useState([]);
    const [expenseDialogOpen, setExpenseDialogOpen] = useState(false);
    const [isConvertToCostsOpen, setIsConvertToCostsOpen] = useState(false);
    const [addToExpenseOpen, setAddToExpenseOpen] = useState(false);
    const [existingClaims] = useState([]);
    const [user, setUser] = useState({ name: "" });

    useEffect(() => {
        fetchSales();
    }, [fileURL, !sales])

    const fetchSales = async () => {
        try {
            const response = await axios.get(`${baseURL}/sales`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const dataArray = Array.isArray(response.data) ? response.data : response.data.data;
            setSales(dataArray);
        } catch (error) {
            console.error('Error fetching sales:', error);
        }
    };

    const handleArchiveClick = () => {
        setIsArchiveDialogOpen(true);
    };
    const handleExportClick = () => {
        setIsExportDialogOpen(true);
    };

    const handleArchiveConfirm = async () => {
        try {
            await axios.patch(`${baseURL}/sales/status/${selected}`, { status: "archive", id: selected }, {
                headers: { Authorization: `Bearer ${token}` }
            })

            console.log('Selected sales archived successfully');
            fetchSales();
            setSales(prevCosts => prevCosts.filter(sale => !selected.includes(sale._id)));
            setSelected([]);
            setIsArchiveDialogOpen(false);
        } catch (error) {
            console.error('Error archiving items:', error);
        }
    };

    const handleArchiveCancel = () => {
        setIsArchiveDialogOpen(false);
    };


    const handleExpenseClick = () => {
        setExpenseDialogOpen(true);
    };

    const handleExpenseConfirm = (expenseData) => {
        setExpenseDialogOpen(false);
    };


    const handleExportCancel = () => {
        setIsExportDialogOpen(false);
    };



    const handleConvertClick = () => {
        setIsConvertToCostsOpen(true);
    };

    const handleAddToExpenseClick = () => {
        setAddToExpenseOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (Array.isArray(selected) && selected.length > 0) {
            console.log("Selected items:", selected);
            deleteSales(selected);
        } else {
            console.error("Error: selected items should be an array of IDs.");
        }
        setIsDeleteDialogOpen(false);
    };

    const deleteSales = async (selectedItems) => {
        try {
            const deletePromises = selectedItems.map(salesId => {
                return axios.delete(`${baseURL}/sales/${salesId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                })
            });
            await Promise.all(deletePromises);
            setSales(sales.filter(sale => !selectedItems.includes(sale._id)));
            // setSelected([]);
            // setIsDeleteDialogOpen(false);
        } catch (error) {
            console.error('Error deleting items:', error);
        }
    };

    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleConvertCancel = () => {
        setIsConvertToCostsOpen(false);
    };

    const handleConvertConfirm = () => {
        if (Array.isArray(selected) && selected.length > 0) {
            console.log("Selected items Yashu:", selected);
            convertToCosts(selected);
        } else {
            console.error("Error: selected items should be an array of IDs.");
        }
        setIsConvertToCostsOpen(false);
    };

    const convertToCosts = async (selectedItems) => {
        try {
            const deletePromises = selectedItems.map(costId => {
                console.log(`Converting cost with ID: ${costId}`);
                return axios.post(`${baseURL}/sales/convert/${costId}`, {}, { // Empty object for data
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
            });
            await Promise.all(deletePromises);
            setSales(sales.filter(cost => !selectedItems.includes(cost._id)));
        } catch (error) {
            console.error('Error converting sales:', error);
        }
    };

    const handleDeleteClick = () => {
        setIsDeleteDialogOpen(true);
    };
    console.log(sales, "sales data");
    return (
        <>
            <EnhancedTableToolbar rowsData={sales} salesAvailable={sales.length} numSelected={selected.length} onArchiveClick={handleArchiveClick} onDeleteClick={handleDeleteClick} onExportClick={handleExportClick} onCovertClick={handleConvertClick} />
            <TableSales data={sales} selected={selected} setSelected={setSelected} fileURL={fileURL} />
            <ArchiveDialog
                open={isArchiveDialogOpen}
                onClose={handleArchiveCancel}
                selectedCount={selected.length}
                onConfirm={handleArchiveConfirm}
            />
            <ConvertToCosts open={isConvertToCostsOpen} onClose={handleConvertCancel} selectedCount={selected.length} onConfirm={handleConvertConfirm} />
            <ExportDialog
                open={isExportDialogOpen}
                onClose={handleExportCancel}
                data={selected.length > 0 
                    ?
                      
            sales.filter((sale)=>{
                const isMatched = selected?.some((item)=>item==sale._id)
                return isMatched
              }) 
                     : sales}
            />
            <DeleteDialog open={isDeleteDialogOpen} onClose={handleDeleteCancel} selectedCount={selected.length} onConfirm={handleDeleteConfirm} />

        </>
    )
}

export default SalesInbox;