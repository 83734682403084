import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Box } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import axios from 'axios';
import { baseURL } from '../Config';
const ExportDialog = ({ open, onClose, data }) => {
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [showError, setShowError] = useState(false);

    const handleGroupClick = (group) => {
        setSelectedGroup(group);
        setShowError(false);
    };



    const handleExportClick = async (group) => {
        try {
            setShowError(false);
            // console.log("yashu123"+{selected})
            const dataToExport = { data: data, format: group };
            const response = await axios.post(`${baseURL}/export`, dataToExport, {
                responseType: 'blob',
            });


            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `export.${group}`);
            document.body.appendChild(link);
            link.click();
            link.remove();

        } catch (error) {
            console.error("Error exporting data:", error);
            setShowError(true);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{ color: "#1976D2", fontWeight: 'bold' }}>Export Options</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Button
                        onClick={() => handleGroupClick("group1")}
                        sx={{
                            border: selectedGroup === "group1" ? "2px solid #1976D2" : "none",
                            background: selectedGroup === "group1" ? "#1976D2" : "none",
                            color: selectedGroup === "group1" ? "white" : "black",
                            marginRight: "8px"
                        }}
                    >
                        CSV
                    </Button>
                    <Button
                        onClick={() => handleGroupClick("group2")}
                        sx={{
                            border: selectedGroup === "group2" ? "2px solid #1976D2" : "none",
                            background: selectedGroup === "group2" ? "#1976D2" : "none",
                            color: selectedGroup === "group2" ? "white" : "black",
                            marginRight: "8px"
                        }}
                    >
                        PDF
                    </Button>
                    <Button
                        onClick={() => handleGroupClick("group3")}
                        sx={{
                            border: selectedGroup === "group3" ? "2px solid #1976D2" : "none",
                            background: selectedGroup === "group3" ? "#1976D2" : "none",
                            color: selectedGroup === "group3" ? "white" : "black"
                        }}
                    >
                        ZIP
                    </Button>
                </Box>

                {selectedGroup === "group1" && (
                    <>
                        <DialogContentText>Please Click Below to Export CSV</DialogContentText>
                        <Button variant="contained" color="primary" onClick={() => handleExportClick("csv")}>
                            Export CSV
                        </Button>
                    </>
                )}
                {selectedGroup === "group2" && (
                    <>
                        <DialogContentText>Please Click Below to Export PDF</DialogContentText>
                        <Button variant="contained" color="primary" onClick={() => handleExportClick("pdf")}>
                            Export PDF
                        </Button>
                    </>
                )}
                {selectedGroup === "group3" && (
                    <>
                        <DialogContentText>Please Click Below to Export ZIP</DialogContentText>
                        <Button variant="contained" color="primary" onClick={() => handleExportClick("zip")}>
                            Export ZIP
                        </Button>
                    </>
                )}


                {showError && (
                    <Box sx={{ border: "1px solid #ff5a02", borderRadius: "5px", p: 1, mt: 2, backgroundColor: "#ffe5d1", display: "flex", alignItems: "center" }}>
                        <WarningIcon sx={{ color: "#ff5a02", mr: 1 }} />
                        <DialogContentText>Error exporting data. Please try again later.</DialogContentText>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ExportDialog;

