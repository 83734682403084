import React from 'react';
import { Container, Typography, Box, Paper, IconButton, Tooltip, colors } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const Mydetails = () => {
    return (
        <Container maxWidth="md" style={{ fontFamily: 'Roboto, sans-serif', paddingTop: '20px', marginLeft: '0', backgroundColor:"#f9fafb", paddingBottom:'20px'}}>
            <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', fontSize: '21px', textAlign: 'left', padding: '20px', marginBottom: '0' }}>
                My Details
            </Typography>
            <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px', textAlign: 'left', fontWeight: 'bold' }}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', fontSize: '15px', margin: '10px 0px 10px 20px'}}>
                    EMAIL-IN
                </Typography>
                <Box border={1} padding={2} borderRadius={1} marginBottom={1} style={{ display: 'flex', alignItems: 'center', width: '60%', marginLeft: '20px', fontSize: '12px', color:"#546478"}}>
                    <Tooltip title="Help">
                        <IconButton style={{ marginRight: '10px', marginBottom: '73px' }}>
                            <ErrorIcon sx={{color:"#1976d2"}}/>
                        </IconButton>
                    </Tooltip>
                    <Typography variant="body2">
                        <ul style={{ wordSpacing:"3px", lineHeight:"25px", listStyleType:"disc",  color:'#546478'}}>
                            <li>Add documents to your Dext account by emailing them to the email addresses below</li>
                            <li>Give the Costs email to your suppliers so they'll send their invoices straight to Prepare</li>
                        </ul>
                    </Typography>
                </Box>
            </Paper>
        </Container>
    );
}

export default Mydetails;
