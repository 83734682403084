
import React, { useState, useEffect, useContext } from 'react';
import { IconButton, Button, Popover, Box, List, ListItem, ListItemIcon, ListItemText, Divider, Select, MenuItem } from '@mui/material';
import { MdOutlinePersonOutline, MdPerson, MdSecurity, MdBusiness, MdExitToApp } from 'react-icons/md';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, useNavigate } from 'react-router-dom';
import AddModal from './AddModal';
import { baseURL } from '../Config';
import axios from 'axios';
import { BusinessTwoTone } from '@mui/icons-material';
import { BusinessContext } from '../contexts/Business';

const style = {
  width: 320,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  fontSize: 15,
};


const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectModalopen, setselectModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState(null);
  const [bgColor, setBgColor] = useState('#197602');
  const [charColor, setCharColor] = useState('#FFFF');
  // const [selectedBusiness, setSelectedBusiness] = useState('Select');
  const [allBusiness, setAllBusiness] = useState([]);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? 'select-popover' : undefined;
  const { selectedBusiness, setSelectedBusiness,businessInfo } = useContext(BusinessContext)

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);

  };
  console.log("selected business--",selectedBusiness)
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  useEffect(() => {
    const savedBusiness = localStorage.getItem('selectedBusiness');
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    
    if (userInfo && userInfo.name) {
      setUser(userInfo);
      fetchData();
    } else {
      setUser(null);
    }
  }, []);

  useEffect(() => {
    const savedBusiness = localStorage.getItem('selectedBusiness');
    if (savedBusiness) {
      setSelectedBusiness(savedBusiness);
    } else if (allBusiness.length) {
      setSelectedBusiness("allBusiness[0].name"); // Optional: Set a default value if no selection exists
    }
    
  }, [allBusiness]);

  useEffect(() => {
    console.log("selectedBusiness updated:", selectedBusiness);
  }, [selectedBusiness]);


  const fetchData = async () => {
    const token = localStorage.getItem('token');
    const userToken = localStorage.getItem('userToken');
    if (!token) return;

    try {
      const response = await fetch(`${baseURL}/business`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
      });

      const data = await response.json();
      // const initialBusiness = [{ ...JSON.parse(localStorage.getItem('userInfo')) }];
      setAllBusiness(Array.isArray(data) ? data : []);
      const defaultItem = data.find(item => item.hasOwnProperty('default'));
      // console.log("yashu" + defaultItem.name)
      //setSelectedBusiness(defaultItem.name)
    } catch (error) {
      console.error('Error fetching business data:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLoginLogout = () => {

    // console.log('aaaaaaaaaaaaaaaa');

    if (user) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('userToken');
      setUser(null);
      navigate('/');
    } else {
      navigate('/');
    }
    setAnchorEl(null);
  };

  const menuItems = [
    { icon: <MdPerson />, text: 'Personal Details' },
    { icon: <MdSecurity />, text: 'Security' },
    { icon: <MdBusiness />, text: 'Buy a Plan for a New Business' },
    { icon: <MdExitToApp />, text: user ? 'Logout' : 'Login' },
  ];

  const changeAccess = async (id) => {
    const token = localStorage.getItem('token')
    const userToken = localStorage.getItem('userToken');
    try {
      const response = await axios.get(`${baseURL}/business/access/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      //  console.log(response.data.token+"sass")
      //  setSupplierData(response.data);
      localStorage.setItem("token", response.data.token)
      console.log("Change Suppliers Data:", response.data); // Console log the fetched data
      window.location.reload();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (item) => {
    const selectedName = item?.name;
    setSelectedBusiness(selectedName);
    const selectedBusinessObject = allBusiness.find(business => business.name === selectedName);

    if (selectedBusinessObject) {
      handleAccessButton(selectedBusinessObject);  // Call handleAccessButton when a business is selected
    }
    localStorage.setItem('selectedBusiness', selectedName); // Save to localStorage
  };

  const handleAccessButton = (item) => {
    console.log("here", item)


    changeAccess(item?._id || item?.userId);
  }

  const handlePersonalDetails = () => {
    navigate('/dashboard/accountdetails')
  }
  const handleSubscription = () => {
    navigate('/dashboard/subscription')
  }
  const handleSecurity = () => {
    // navigate('/dashboard/subscription')
    console.log("subscription")
  }
  console.log("selected business======",selectedBusiness)
  return (
    <>
      <AddModal open={open} handleClose={handleClose} handleOpen={handleOpen} user={user} />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={style}>
          <List>
            {menuItems.map((item, index) => (
              <div key={index}>
                <ListItem
                  onClick={index === 3 && handleLoginLogout || index === 0 && handlePersonalDetails || index === 2 && handleSubscription || index === 1 && handleSecurity}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '40px',
                      '& svg': {
                        fontSize: '1.5rem',
                      },
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
                {index < menuItems.length - 1 && <Divider />}
              </div>
            ))}
          </List>
        </Box>
      </Popover>
      <div className="bg-[#ebf0ec] h-full flex items-center px-5 justify-between border-b border-[rgba(0, 0, 0, 0.3)]">
        <div>
          <img className="w-[150px]" src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
        </div>
        <div className='cursor-default' style={{ display: 'flex', fontWeight: '790', color: '#647890', marginRight: '50%', alignItems: 'center', gap: '4px' }}>
          {user && (
            <>
              <div
                style={{
                  width: 25,
                  height: 25,
                  borderRadius: '50%',
                  backgroundColor: bgColor,
                  color: charColor,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 4,
                  padding: '1rem',
                  fontSize: '1.3rem'
                }}
              >
                {user.name?.charAt(0)?.toUpperCase() ?? ''}
              </div>
              <span style={{ marginRight: '10px', fontSize: '18px' }}>{user.name.split(" ").map((n) => n.charAt(0).toUpperCase() + n.slice(1)).join(" ")}</span>
            </>
          )}
          <span className='text-3xl font-light'>|</span>
          {/* <Select

            id="123"
            sx={{
              height: '40px', width: '200px', fontSize: '16px', marginLeft: '20px',

            }}
            value={selectedBusiness}
            onChange={handleChange}
          >
            {
              allBusiness.map((item, index) => {

                const isDefault = item.hasOwnProperty('default'); // Check if it's the default profile
                const isSelectedBusiness = selectedBusiness === item.name;
                return (
                  <MenuItem key={index} value={item?.name}>
                    <div className='font-semibold' style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <span>{item?.name}</span>
                      {!isSelectedBusiness && (
                        <Button
                          variant="outlined"
                          sx={{
                            fontSize: '10px',
                            minWidth: '30px',
                            padding: '2px 5px',
                            marginLeft: '10px',
                            fontWeight: '600'

                          }}
                          onClick={() => handleAccessButton(item)} // Trigger "Access" functionality
                        >
                          Access
                        </Button>
                      )}
                    </div>
                  </MenuItem>
                );
              })}
          </Select> */}

          <button onClick={handleClick1} className=' active:border-[3px] rounded-md active:border-[#8f8f8f] p-1 px-3 hover:bg-[#d1d1d1] active:translate-y-1' ><span className='flex justify-between gap-3 text-[17px]'>{selectedBusiness.split(" ").map((n) => n.charAt(0).toUpperCase() + n.slice(1)).join(" ")}<ArrowDropDownIcon /></span></button>

          <Popover
            id={id1}
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div className='p-1'>
              {
                allBusiness.map((item, index) => {

                  const isDefault = item.hasOwnProperty('default'); // Check if it's the default profile
                  const isSelectedBusiness = selectedBusiness === item.name;
                  return (

                    <div onClick={() => handleChange(item)} className={`${isSelectedBusiness ? "bg-[#f0eded] font-semibold p-3  py-2 w-[16vw] my-1 hover:bg-[#f0eded] cursor-pointer" : ""}font-semibold p-3  py-2 w-[16vw] my-1 hover:bg-[#f0eded] cursor-pointer `} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span className=''>{item?.name.split(" ").map((n) => n.charAt(0).toUpperCase() + n.slice(1)).join(" ")}</span>
                      {!isSelectedBusiness && (
                        <Button
                          variant="outlined"
                          sx={{
                            fontSize: '10px',
                            minWidth: '30px',
                            padding: '2px 5px',
                            marginLeft: '10px',
                            fontWeight: '600'

                          }}
                          onClick={() => handleAccessButton(item)} // Trigger "Access" functionality
                        >
                          Access
                        </Button>
                      )}
                    </div>

                  );
                })}
            </div>

          </Popover>


        </div>
        <div className="flex items-center">
          <Button
            variant="contained"
            sx={{
              fontSize: '12px',
              padding: '6px 15px',
              marginRight: '10px',
              background: '#ededed',
              fontWeight: '800',
              border: '3px solid #1976D2',
              color: '#1976D2',
              '&.MuiButton-root:hover': { color: '#ffff', bgcolor: '#1976D2', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' },
            }}
            onClick={handleOpen}
          >
            + Add Documents
          </Button>
          <IconButton
            sx={{
              alignSelf: 'center',
            }}
            onClick={toggleMenu}
          >
            <MdOutlinePersonOutline />
          </IconButton>
        </div>
      </div>
    </>
  );

};

export default Header;
