import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField,
  IconButton, Box, Typography, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';
import { Search, Image as ImageIcon, GetApp, InsertDriveFile } from '@mui/icons-material';
import { baseURL } from '../Config';
import axios from 'axios';

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  // ...other films
];

const SubmissionHistory = () => {
  const [rows, setRows] = useState([]);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    const fetchSubmissionHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        console.log('Token:', token);
        const response = await axios.get(`${baseURL}/user/submission-history`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        console.log('Fetched Data:', data);
        setRows(data);
      } catch (error) {
        console.error('Error fetching submission history:', error);
      }
    };
    fetchSubmissionHistory();
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenImageDialog(true);
  };

  const handleDownloadClick = (image) => {
    const link = document.createElement('a');
    link.href = image;
    link.download = image.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  return (
    <Box sx={{ p: 3, backgroundColor: "#f9fafb" }}>
      <Typography variant="h1" gutterBottom style={{ fontSize: "24px", fontWeight: "bold" }}>
        Submission History
      </Typography>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={1}>
        <IconButton>
          <Search />
        </IconButton>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={top100Films}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              InputProps={{ ...params.InputProps, sx: { height: 40, width: 280 } }}
            />
          )}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell><strong style={{ color: "#546478", fontSize: "13px" }}>Item ID</strong></TableCell>
              <TableCell><strong style={{ color: "#546478", fontSize: "13px" }}>Submission Date</strong></TableCell>
              <TableCell><strong style={{ color: "#546478", fontSize: "13px" }}>Submission By</strong></TableCell>
              <TableCell><strong style={{ color: "#546478", fontSize: "13px" }}>Submission Method</strong></TableCell>
              <TableCell><strong style={{ color: "#546478", fontSize: "13px" }}>Owned By</strong></TableCell>
              <TableCell><strong style={{ color: "#546478", fontSize: "13px" }}>Date</strong></TableCell>
              <TableCell><strong style={{ color: "#546478", fontSize: "13px" }}>Supplier</strong></TableCell>
              <TableCell><strong style={{ color: "#546478", fontSize: "13px" }}>Total Amount</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <IconButton onClick={() => handleImageClick(row.image)}>
                    <ImageIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDownloadClick(row.image)}>
                    <GetApp />
                  </IconButton>
                  <IconButton>
                    <InsertDriveFile />
                  </IconButton>
                </TableCell>
                <TableCell>{row.itemId}</TableCell>
                <TableCell>{new Date(row.created_at).toLocaleDateString()}</TableCell>
                <TableCell>{row.submissionBy || 'N/A'}</TableCell>
                <TableCell>{row.submissionMethod || 'N/A'}</TableCell>
                <TableCell>{row.ownedBy || 'N/A'}</TableCell>
                <TableCell>{new Date(row.created_at).toLocaleDateString()}</TableCell>
                <TableCell>{row.supplier || '---'}</TableCell>
                <TableCell>{row.totalAmount ? `GBP ${row.totalAmount}` : '---'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
        <DialogTitle>Image Preview</DialogTitle>
        <DialogContent>
          <img src={selectedImage} alt="Preview" style={{ width: '100%', height: 'auto' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImageDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubmissionHistory;