import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import TableAllCus from './TableAllCus';
import { useNavigate } from "react-router-dom";


const AllCustomer = () => {
  const navigate = useNavigate();
  const data = [
    { id: 1, name: 'Customer A' },
    { id: 2, name: 'Customer B' },
  ];

  const [selected, setSelected] = useState([]);

  return (
    <>
      <Box display="flex" flexDirection="column">

      </Box>
      <Box>
        <Box display="flex">


          <Box display="block">
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              sx={{
                alignSelf: "flex-start",
                margin: 2,
                background: "none",
                color: "black",
                boxShadow: "none",
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  bottom: '-1px',
                  width: '100%',
                  height: '2px',
                  backgroundColor: '#1976d2',
                },
                '&:hover': {
                  backgroundColor: 'initial',
                  color: 'initial',
                  boxShadow: 'none',
                  '&::after': {
                    backgroundColor: '#1976d2',
                  },
                },
              }}
            >
              All Customer
            </Button>

            <TableAllCus data={data} selected={selected} setSelected={setSelected} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AllCustomer;
