import React from 'react';
import AppRoutes from './routes';
import './styles/index.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { HeaderStepProvider } from './business/userinfo/context';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <HeaderStepProvider>
          <AppRoutes />
        </HeaderStepProvider>
      </div>
    </ThemeProvider>
  );
};

export default App;
