import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Snackbar,
  Alert,
  Typography
} from '@mui/material';
import { useStyles } from '../components/common/useStyles';
import { useEffect, useState } from 'react';
import { baseURL } from '../Config';
import axios from 'axios';

export function UserDetails() {
  const [openDialog, setOpenDialog] = useState('');
  const [value, setValue] = useState('');
  const [userData, setUserData] = useState({
    crn: '',
    firstName: '',
    lastName: '',
    mobile: '',
    email: ''
  });
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    type: 'success'
  });
  
  const classes = useStyles();
  const tabs = ['Personal Details', 'Login Details', 'Security'];
  const details = [
    { key: 'crn', label: 'CRN' },
    // { key: 'firstName', label: 'First Name' },
    // { key: 'lastName', label: 'Last Name' },
    { key: 'name', label: 'Name' },
    { key: 'mobile', label: 'Mobile Number' }
  ];
  const token = localStorage.getItem('userToken');
  
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/auth/user-details`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      
      if (response.data) {
        console.log(response.data)
        setUserData({
          userId: response.data._id || "67ce7f02978331936a7df8f7",
          crn: response.data.crn || '',
          name: response.data.name || '',
          mobile: response.data.mobile.replace("Mobile number: ", "") || '',
          email: response.data.email || ''
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      showNotification('Failed to load user data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    setUserData({
      ...userData,
      [field]: value
    });
  };

  const handlePersonalDetailsSubmit = async () => {
    setLoading(true);
    try {
      const personalData = {
        name: userData.name,
      
        phone: userData.mobile
      };
      
      await axios.patch(`${baseURL}/user/profile/${userData.userId}`, personalData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      
      showNotification('Personal details updated successfully', 'success');
    } catch (error) {
      console.error('Error updating personal details:', error);
      showNotification('Failed to update personal details', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailUpdate = async () => {
    if (!value) {
      showNotification('Please enter a valid email', 'error');
      return;
    }
    
    setLoading(true);
    try {
      await axios.patch(`${baseURL}/auth/update-email`, { newEmail: value }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      
      setUserData({
        ...userData,
        email: value
      });
      
      showNotification('Email updated successfully', 'success');
      setOpenDialog('');
      setValue('');
    } catch (error) {
      console.error('Error updating email:', error);
      showNotification('Failed to update email', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordUpdate = async () => {
    if (!value) {
      showNotification('Please enter a valid password', 'error');
      return;
    }
    
    setLoading(true);
    try {
      await axios.patch(`${baseURL}/auth/update-password`, { newPassword: value }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      
      showNotification('Password updated successfully', 'success');
      setOpenDialog('');
      setValue('');
    } catch (error) {
      console.error('Error updating password:', error);
      showNotification('Failed to update password', 'error');
    } finally {
      setLoading(false);
    }
  };

  const showNotification = (message, type = 'success') => {
    setNotification({
      open: true,
      message,
      type
    });
  };

  const handleCloseNotification = () => {
    setNotification({
      ...notification,
      open: false
    });
  };

  const handleDialogValueChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <section className='py-5 px-20'>
      <div className='text-xl font-bold flex justify-start uppercase pb-3'>
        Profile
      </div>
      <section className='flex flex-col gap-7 items-center'>
        {tabs.map((tab, index) => (
          <div
            className='bg-white border border-slate-500 shadow-lg w-full rounded-sm'
            key={tab}
          >
            <div className='text-base font-bold text-black text-left p-3 border-b border-b-slate-500 shadow-sm'>
              {tab}
            </div>
            <div className='p-3 py-7'>
              <div className='flex flex-col gap-5'>
                {index === 0 ? (
                  <>
                    {details.map((item) => (
                      <div
                        className='flex justify-between items-center my-2'
                        key={item.key}
                      >
                        <div className='w-1/3 text-base'>{item.label}</div>
                        <div className='w-2/3'>
                        {
                          item.key == 'crn' ?
                              <Typography variant="body2" style={{ fontSize: '14px', color: '#000', marginLeft: '100px' }}>
                                                      {userData[item.key] || 'Not Available'}
                                                  </Typography>
                          : <TextField
                          fullWidth
                          className={classes.root}
                          sx={{ width: '500px', height: '15px' }}
                          value={userData[item.key]}
                          onChange={(e) => handleChange(item.key, e.target.value)}
                        />
                        }
                         
                        </div>
                      </div>
                    ))}
                    <div className='flex justify-end mt-4'>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handlePersonalDetailsSubmit}
                        disabled={loading}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </>
                ) : index === 1 ? (
                  <div className='flex justify-items-center'>
                    <div className='w-1/3 text-base'>Your current email is</div>
                    <div className='w-2/3 flex justify-between'>
                      <span>{userData.email}</span>
                      <Button 
                        onClick={() => {
                          setOpenDialog('email');
                          setValue(userData.email);
                        }}
                      >
                        Change
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className='flex justify-items-center'>
                    <div className='w-1/3 text-base'>
                      Password
                      <div className='text-sm'>
                        Update your password to keep your account safe
                      </div>
                    </div>
                    <div className='w-2/3 flex justify-end'>
                      <div>
                        <Button onClick={() => {
                          setOpenDialog('password');
                          setValue('');
                        }}>
                          Change
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </section>

      {openDialog !== '' && (
        <Dialog open={true} fullWidth>
          <DialogTitle>Change {openDialog}</DialogTitle>
          <DialogContent
            className='py-5'
            style={{ height: openDialog === 'password' ? '100px' : '50px', overflow: 'hidden' }}
          >
            <section className='flex items-center justify-between'>
              <div className='uppercase text-base font-semibold'>
                {openDialog === 'password' ? 'New Password' : openDialog}
              </div>
              <div>
                <TextField
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '40px',
                    },
                    width: '330px',
                    '& .MuiInputBase-input': {
                      color: '#546478',
                    },
                  }}
                  size='small'
                  value={value}
                  onChange={handleDialogValueChange}
                  type={openDialog === 'password' ? 'password' : 'text'}
                />
              </div>
            </section>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog('')}>Cancel</Button>
            <Button 
              onClick={openDialog === 'email' ? handleEmailUpdate : handlePasswordUpdate}
              disabled={loading}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar 
        open={notification.open} 
        autoHideDuration={6000} 
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.type}>
          {notification.message}
        </Alert>
      </Snackbar>
    </section>
  );
}