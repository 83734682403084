import { createContext, useState } from "react";

export const HeaderStepContext = createContext()

export const HeaderStepProvider = ({ children }) => {
    const [activeStep, setActiveStep] = useState(1);
    return (
        <HeaderStepContext.Provider value={{ activeStep, setActiveStep }}>
            {children}
        </HeaderStepContext.Provider>
    );
};