import { BorderAllRounded, Margin } from "@mui/icons-material";
import React, { useContext, useState } from "react";
import { baseURL } from '../../Config';
import { HeaderStepContext } from './context';
const HeaderUserInfo = () => {
  const { activeStep, setActiveStep } = useContext(HeaderStepContext);

  const headerStyle = {
    display: 'flex',
    padding: ' 0 4.5rem',
    flexDirection: 'column',
    textAlign: 'left',


  };

  const logoStyle = {
    marginBottom: '25px',
    height: '90px',
    bottom: '1.5rem'
  };

  const stepperContainerStyle = {
    display: 'flex',
    bottom: '-2rem',
    gap: '0.8rem'
  };

  const stepStyle = (step) => ({
    width: '27px',
    height: '5px',
    borderRadius: '2px',
    backgroundColor: activeStep === step ? '#1976d2' : '#ccc',
    marginRight: '6px',
    cursor: 'pointer'
  });

  return (
    <div className="bg-[#b0b1b0]" style={headerStyle}>
      <div className="relative" style={logoStyle}>
        <img className='w-[150px] ' src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
        <div className="absolute  left-5" style={stepperContainerStyle}>
          <div style={stepStyle(1)} ></div>
          <div style={stepStyle(2)} ></div>
          <div style={stepStyle(3)} ></div>
        </div>
      </div>

    </div>
  );
};

export default HeaderUserInfo;
