import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Popover,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { filterFields } from "./InboxTableFilterFields";
import InboxTableAdvance from "./InboxTableAdvance";
import { TooltipContext } from "../../contexts/TableTooltip";
import { useStyles } from "../../components/common/useStyles";

const initialFormData = {
  searchTerm: "",
  supplier: null,
  amountFrom: "",
  amountTo: "",
  dateFrom: null,
  dateTo: null,
  dueDateFrom: null,
  dueDateTo: null,
  uploadDateFrom: null,
  uploadDateTo: null,
  documentReference: "",
  itemId: "",
  type: null,
  currencyCode: null,
  submittedBy: null,
  submissionMethod: null,
  customer: null,
  category: null,
  expenseClaim: "",
  addedToExpenseClaim: false,
};

const TableExtendedToolTip = ({ rowsData, onDataFiltered }) => {
  const filterTabs = {
    Tax: null,
    Category: null,
    Currency: null,
    Read: null,
    Flag: null,
    Paid: null,
  };
  const primaryColumns = [
    { id: "status", numeric: false, disablePadding: false, label: " Status" },
    { id: "date", numeric: true, disablePadding: false, label: "Date" },
    { id: "supplier", numeric: true, disablePadding: false, label: "Supplier" },
    { id: "category", numeric: true, disablePadding: false, label: "Category" },
    { id: "total", numeric: true, disablePadding: false, label: "Total" },
    { id: "tax", numeric: true, disablePadding: false, label: "Tax" },
  ];

  const additionalColumns = [
    {
      id: "submittedBy",
      numeric: true,
      disablePadding: false,
      label: "Submitted By",
    },
    { id: "dueDate", numeric: true, disablePadding: false, label: "Due Date" },
    {
      id: "documentReference",
      numeric: true,
      disablePadding: false,
      label: "Document Reference ",
    },
    {
      id: "baseTotal",
      numeric: true,
      disablePadding: false,
      label: "Base Total",
    },
    { id: "paid", numeric: true, disablePadding: false, label: "Paid" },
    { id: "type", numeric: true, disablePadding: false, label: "Type" },
    { id: "itemId", numeric: true, disablePadding: false, label: "Item Id" },
    {
      id: "submissionMethod",
      numeric: true,
      disablePadding: false,
      label: "Submission Method",
    },
    {
      id: "uploadDate",
      numeric: true,
      disablePadding: false,
      label: "Upload Date",
    },
  ];

  const settingReset = {
    status: true,
    date: true,
    supplier: true,
    category: true,
    total: true,
    tax: true,
    taxRate: true,
    match: null,
    submittedBy: null,
    dueDate: null,
    documentReference: null,
    baseTotal: null,
    description: null,
    paid: null,
    payment: null,
    type: null,
    itemId: null,
    submissionMethod: null,
    note: null,
    uploadDate: null,
  };
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedTabFilter, setSelectedTabFilter] = useState(filterTabs);
  const {
    tableColumns,
    setTableColumns,
    filters,
    setFilters,
    formData,
    setFormData,
  } = useContext(TooltipContext);
  const [tempCheckedColumns, setTempCheckedColumns] = useState({});
  const [tempfilters, setTempFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(rowsData || []);

  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "advance-popover" : undefined;
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "filter-popover" : undefined;
  const [anchorEl3, setAnchorEl3] = useState(null);
  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? "setting-popover" : undefined;

  const handleCheckBox = (e) => {
    const { id } = e.target;
    setTempCheckedColumns((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    setTempCheckedColumns(tableColumns);
  }, [tableColumns]);

  const classes = useStyles();

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  // Initialize form data
  useEffect(() => {
    setFormData(initialFormData);
    return () => {
      setTableColumns(settingReset);
      setFilters(filterTabs);
      setFormData(initialFormData);
    };
  }, []);

  // Apply search term filtering
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    // if (!rowsData) return;

    const term = e.target.value;
    setFormData((prev) => ({ ...prev, searchTerm: term }));
    // if (term === '') {
    //   applyAllFilters(rowsData);
    // } else {
    //   const filtered = rowsData.filter((row) => {
    //     // Search in all text fields
    //     return Object.keys(row).some(key => {
    //       const value = row[key];
    //       return typeof value === 'string' && value.toLowerCase().includes(term);
    //     });
    //   });
    //   applyAllFilters(filtered);
    // }
  };

  // Apply all filters (combined function)
  const applyAllFilters = (data = rowsData) => {
    if (!data) return;

    // First apply search term
    let filtered = data;
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter((row) => {
        return Object.keys(row).some((key) => {
          const value = row[key];
          return (
            typeof value === "string" && value.toLowerCase().includes(term)
          );
        });
      });
    }

    // Then apply advanced filters
    filtered = filtered.filter((row) => {
      let passes = true;

      // Check supplier

      if (formData.supplier && row.supplier !== formData.supplier) {
        passes = false;
      }
      // console.log('applying advance feature',formData.supplier,row.supplier,passes)

      // Check amount range
      if (
        formData.amountFrom &&
        parseFloat(row.total) < parseFloat(formData.amountFrom)
      ) {
        passes = false;
      }
      if (
        formData.amountTo &&
        parseFloat(row.total) > parseFloat(formData.amountTo)
      ) {
        passes = false;
      }

      // Check date ranges
      if (
        formData.dateFrom &&
        new Date(row.date) < new Date(formData.dateFrom)
      ) {
        passes = false;
      }
      if (formData.dateTo && new Date(row.date) > new Date(formData.dateTo)) {
        passes = false;
      }

      // Check due date range
      if (
        formData.dueDateFrom &&
        new Date(row.dueDate) < new Date(formData.dueDateFrom)
      ) {
        passes = false;
      }
      if (
        formData.dueDateTo &&
        new Date(row.dueDate) > new Date(formData.dueDateTo)
      ) {
        passes = false;
      }

      // Check upload date range
      if (
        formData.uploadDateFrom &&
        new Date(row.uploadDate) < new Date(formData.uploadDateFrom)
      ) {
        passes = false;
      }
      if (
        formData.uploadDateTo &&
        new Date(row.uploadDate) > new Date(formData.uploadDateTo)
      ) {
        passes = false;
      }

      // Check document reference
      if (
        formData.documentReference &&
        !row.documentReference?.includes(formData.documentReference)
      ) {
        passes = false;
      }

      // Check item ID
      if (formData.itemId && row.itemId !== formData.itemId) {
        passes = false;
      }

      // Check type
      if (formData.type && row.type !== formData.type) {
        passes = false;
      }

      // Check currency code
      if (formData.currencyCode && row.currencyCode !== formData.currencyCode) {
        passes = false;
      }

      // Check submitted by
      if (formData.submittedBy && row.submittedBy !== formData.submittedBy) {
        passes = false;
      }

      // Check submission method
      if (
        formData.submissionMethod &&
        row.submissionMethod !== formData.submissionMethod
      ) {
        passes = false;
      }

      // Check customer
      if (formData.customer && row.customer !== formData.customer) {
        passes = false;
      }

      // Check category
      if (formData.category && row.category !== formData.category) {
        passes = false;
      }

      // Check expense claim
      if (formData.expenseClaim && row.expenseClaim !== formData.expenseClaim) {
        passes = false;
      }

      // Check added to expense claim
      if (formData.addedToExpenseClaim && !row.addedToExpenseClaim) {
        passes = false;
      }

      return passes;
    });

    // Then apply filter tabs
    filtered = filtered.filter((row) => {
      let passes = true;

      // Apply each filter in filters
      Object.keys(filters).forEach((key) => {
        if (filters[key] === "true") {
          // Filter for presence of property
          if (!row[key.toLowerCase()]) passes = false;
        } else if (filters[key] === "false") {
          // Filter for absence of property
          if (row[key.toLowerCase()]) passes = false;
        }
      });

      return passes;
    });

    console.log(filtered);

    setFilteredData(filtered);

    // Notify parent component if callback exists
    if (onDataFiltered) {
      console.log("here");
      onDataFiltered(filtered);
    }
  };

  // Apply advanced filters
  const applyAdvancedFilters = () => {
    applyAllFilters();
    setAnchorEl1(null);
  };

  // Apply filter tabs
  const applyFilterTabs = () => {
    console.log("filter tab ");
    setFilters(tempfilters);
    applyAllFilters();
    setAnchorEl2(null);
  };

  // Update filtered data when rowsData changes
  useEffect(() => {
    if (rowsData) {
      setFilteredData(rowsData);
    }
  }, [rowsData]);

  // Apply filters when any filter criteria changes
  useEffect(() => {
    applyAllFilters();
  }, [filters]);

  return (
    <div className="flex gap-1 items-center">
      <OutlinedInput
        className={classes.root}
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{ color: "#dbdbd9" }} />{" "}
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <div
              className="cursor-pointer flex items-center text-lg font-bold"
              onClick={handleClick1}
            >
              Advanced
              <div>{open1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</div>
            </div>
          </InputAdornment>
        }
      />

      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "right",
        // }}
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "right",
        // }}
        anchorOrigin={{
          vertical: "bottom", // Attach to the bottom of anchor
          horizontal: "left", // Align left (change to 'center' or 'right' as needed)
        }}
        transformOrigin={{
          vertical: "top", // Popover starts from its top
          horizontal: "left",
        }}
        disablePortal // Ensures it renders within the parent DOM tree
        PaperProps={{
          sx: {
            mt: 2, // Adjust margin to fine-tune baseline positioning
            mr: 2,
          },
        }}
      >
        <div className="p-2">
          <div className=" max-h-[400px] overflow-y-auto text-[#647890] text-sm">
            <InboxTableAdvance
              rowsData={rowsData}
              formData={formData}
              setFormData={setFormData}
              setIsModalOpen={true}
            />
          </div>
          <div
            className="mt-2 flex p-1 justify-end items-center gap-6"
            style={{ borderTop: "1px solid rgb(202 222 247)" }}
          >
            <button
              className="font-semibold text-sm text-[#647890] active:translate-y-1 "
              onClick={() => {
                setFormData(initialFormData);
              }}
            >
              Reset
            </button>
            <button
              className="p-2 font-semibold text-sm bg-[#1976D2] text-white shadow-[60px_35px_60px_-15px_rgba(0,0,0,0.3)] rounded-sm active:translate-y-1 "
              onClick={applyAdvancedFilters}
            >
              APPLY
            </button>
          </div>
        </div>
      </Popover>

      {/*//////////////////// Filter List /////////////////////////////////////////////*/}
      <IconButton onClick={handleClick2}>
        <FilterAltOutlinedIcon
          className="relative active:translate-y-1"
          sx={{ color: "#647890" }}
        />
      </IconButton>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "right",
        // }}
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "right",
        // }}
        anchorOrigin={{
          vertical: "bottom", // Attach to the bottom of anchor
          horizontal: "left", // Align left (change to 'center' or 'right' as needed)
        }}
        transformOrigin={{
          vertical: "top", // Popover starts from its top
          horizontal: "left",
        }}
        disablePortal // Ensures it renders within the parent DOM tree
        PaperProps={{
          sx: {
            mt: 2, // Adjust margin to fine-tune baseline positioning
            mr: 2,
          },
        }}
      >
        <div className=" p-4 " style={{width:'400px'}}>
          <div className="">
            <div className="font-bold text-sm text-[#647890] mb-3">FILTER</div>
            <div className="flex flex-col gap-4 mb-1">
              {filterFields.map((item, index) => {
                let field = Object.keys(item);
                field = field[0];
                return (
                  <div key={index} className="flex  justify-between gap-8">
                    <span  style={{fontSize:'12px'}}>{field}</span>
                    <div className="flex w-[70%] justify-start gap-3 shadow-[60px_35px_60px_-15px_rgba(0,0,0,0.3)]">
                      <button
                        onClick={() => {
                          setSelectedTabFilter((prev) => ({
                            ...prev,
                            [field]: 1,
                          }));
                          setTempFilters((prev) => ({
                            ...prev,
                            [field]: "true",
                          }));
                        }}
                        className={`border-2 w-fit text-[#647890] text-xs rounded-md cursor-pointer hover:bg-[#fae396]  hover:border-[#ffe11f] flex justify-center items-center  p-1 ${
                          selectedTabFilter[field] === 1
                            ? "bg-[#fae396] border-[#ffe11f] text-black "
                            : "border-[#647890]"
                        }`}
                      >
                        {item[field][0]}
                      </button>
                      <button
                        onClick={() => {
                          setSelectedTabFilter((prev) => ({
                            ...prev,
                            [field]: 2,
                          }));
                          setTempFilters((prev) => ({
                            ...prev,
                            [field]: "false",
                          }));
                        }}
                        className={`border-2  w-fit  text-[#647890] text-xs rounded-md cursor-pointer hover:bg-[#fae396]  hover:border-[#ffe11f] flex justify-center items-center  p-1 ${
                          selectedTabFilter[field] === 2
                            ? "bg-[#fae396] border-[#ffe11f] text-black "
                            : "border-[#647890]"
                        }`}
                      >
                        {item[field][1]}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-2 flex p-1 justify-end items-center gap-6"  style={{ borderTop: "1px solid rgb(202 222 247)" }}>
            <button
              className="font-semibold text-sm text-[#647890] active:translate-y-1 "
              onClick={() => {
                setSelectedTabFilter(filterTabs);
                setTempFilters({});
              }}
            >
              Reset
            </button>
            <button
              className="p-2 font-semibold text-sm bg-[#2196f3] text-white shadow-[60px_35px_60px_-15px_rgba(0,0,0,0.3)] rounded-sm active:translate-y-1 "
              onClick={applyFilterTabs}
            >
              Apply
            </button>
          </div>
        </div>
      </Popover>

      {/*//////////////////// Setting List /////////////////////////////////////////////*/}
      <IconButton onClick={handleClick3}>
        <SettingsOutlinedIcon
          className="relative active:translate-y-1"
          sx={{ color: "#647890" }}
        />
      </IconButton>
      <Popover
        id={id3}
        open={open3}
        anchorEl={anchorEl3}
        onClose={handleClose3}
        anchorOrigin={{
          vertical: "bottom", // Attach to the bottom of anchor
          horizontal: "left", // Align left (change to 'center' or 'right' as needed)
        }}
        transformOrigin={{
          vertical: "top", // Popover starts from its top
          horizontal: "left",
        }}
        disablePortal // Ensures it renders within the parent DOM tree
        PaperProps={{
          sx: {
            mt: 2, // Adjust margin to fine-tune baseline positioning
            mr: 2,
          },
        }}
      >
        <div className="p-4" style={{width:'400px'}}>
          <div className="">
            <div className="font-bold text-s text-[#647890] mb-3">
              PRIMARY COLUMNS
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4 text-xs">
              {primaryColumns.map((item, index) => (
                <div key={index} className="flex items-center">
                  <input
                    type="checkbox"
                    id={item.id}
                    checked={Boolean(tempCheckedColumns[item.id])}
                    onChange={handleCheckBox}
                    className="mr-2 "
                  />
                  <label className=" font-normal " style={{fontWeight:'normal' , fontSize: '12px' }} htmlFor={item.id}>{item.label}</label>
                </div>
              ))}
            </div>

            <div className="font-bold text-s text-[#647890] mb-3">
              ADDITIONAL COLUMNS
            </div>
            <div className="grid grid-cols-2 gap-4 text-xs">
              {additionalColumns.map((item, index) => (
                <div key={index} className="flex items-center">
                  <input
                    type="checkbox"
                    id={item.id}
                    checked={Boolean(tempCheckedColumns[item.id])}
                    onChange={handleCheckBox}
                    className="mr-2"
                  />
                  <label style={{fontWeight:'normal' , fontSize: '12px' }} htmlFor={item.id}>{item.label}</label>
                </div>
              ))}
            </div>
            <div className="font-bold text-xs text-[#647890] my-3 ">
              TABLE DENSITY
            </div>
            <div className="w-full flex mt-5">
              <div
                onClick={() => setSelectedTab(1)}
                className={`border-2  text-[#647890] text-sm font-semibold cursor-pointer hover:bg-[#dbdbd9]  flex justify-center items-center w-1/2 py-1 ${
                  selectedTab === 1
                    ? "bg-[#fae396] border-[#ffe11f] text-black "
                    : ""
                }`}
              >
                Wide
              </div>
              <div
                onClick={() => setSelectedTab(2)}
                className={` border-2  text-[#647890] text-sm font-semibold cursor-pointer  hover:bg-[#dbdbd9] flex  justify-center items-center w-1/2 py-1 ${
                  selectedTab === 2 ? "bg-[#fae396] border-[#ffe11f] " : ""
                }`}
              >
                Medium
              </div>
              <div
                onClick={() => setSelectedTab(3)}
                className={` border-2  text-[#647890] text-sm font-semibold cursor-pointer  hover:bg-[#dbdbd9] flex  justify-center items-center w-1/2 py-1 ${
                  selectedTab === 3
                    ? "bg-[#fae396] border-[#ffe11f] text-black"
                    : ""
                }`}
              >
                Narrow
              </div>
            </div>
          </div>
          <div className="mt-2 flex p-1 justify-end items-center gap-6"  style={{ borderTop: "1px solid rgb(202 222 247)" }}>
            <button
              className="font-semibold text-sm text-[#647890] active:translate-y-1 "
              onClick={() => {
                setTempCheckedColumns(settingReset);
              }}
            >
              Reset
            </button>
            <button
              className="p-2 font-semibold text-sm bg-[#2196f3] text-white shadow-lg rounded-sm active:translate-y-1 "
              onClick={() => {
                setTableColumns(tempCheckedColumns);
                setAnchorEl3(null);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default TableExtendedToolTip;
