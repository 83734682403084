import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Toolbar,
  Tooltip,
  FormControl,
  IconButton,
  InputLabel,
  Tabs,
  Tab,
  Box,
  TextField,
  Select,
  MenuItem,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";
import { Textarea } from "flowbite-react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import MyPdfViewer from "../components/PDFViewer";
import { baseURL } from "../Config";
import dayjs from "dayjs";
import "./CreateLineItemsModal.css";
import StickyColumnsTable from "./createNewLineTableModal";
import { BusinessContext } from "../contexts/Business";
import { TransparentBtn } from "../components/common/TransparentBtn";
import {
  ArrowBack,
  ArrowBackIos,
  Flag,
  FlagOutlined,
  NavigateNext,
} from "@mui/icons-material";
// import historyData from './historyData';
import "./Edit.css";
import { AddToExpense } from "./Inbox";
import LineItemsTable from "./LineItemsTable";

const token = localStorage.getItem("token");

const historyData = [
  {
    details: "Note was changed to blank by Gautam Goswami",
    time: "Today at 23:34",
  },
  {
    details: "Note was changed to blank by Gautam Goswami",
    time: "Today at 23:34",
  },
];

function EnhancedTableToolbar({
  numSelected,
  onBackClick,
  onDeleteClick,
  onSplitClick,
  onArchiveClick,
  onCovertClick,
  onReviewclick,
  status,
  handleAddToExpenseClick,
}) {
  const navigate = useNavigate();
  const { list, selectedItem, setSelectedItem, backRoute } =
    useContext(BusinessContext);

  // console.log('welcome',selectedItem)

  // const handleFlag = async (rowId) => {
  //   const newFlagStatus = !flagged.includes(rowId);

  //   row.flagged = newFlagStatus;
  //   try {
  //     await axios.patch(
  //       `${baseURL}/cost/${rowId}`,
  //       { flagged: newFlagStatus },
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     );
  //     // console.log('Flag status updated successfully on backend.');
  //   } catch (error) {
  //     console.error(
  //       'Error updating flag status on backend:',
  //       error.response ? error.response.data : error.message
  //     );
  //   }
  // };
  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          height: "10%",
        }}
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center gap-1">
            <TransparentBtn
              label={"Back"}
              onClick={() => navigate(backRoute)}
              startIcon={<ArrowBackIos color="disabled" fontSize="small" />}
            />
            {/* <TransparentBtn label={<FlagOutlined />
          
          }   onClick={() => navigate(backRoute)} /> */}

            {/* 
  <span
                          className='active:translate-y-1 cursor-pointer'
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFlag(row, row._id);
                          }}
                        >
                          {flagged.includes(row._id) || row.flagged ? (
                            <FlagIcon className='text-[#44a0fc]' />
                          ) : (
                            <FlagOutlinedIcon className='text-[#b8b9ba]' />
                          )}
                        </span> */}
            {/* <div>hello</div> */}
            <TransparentBtn
              border
              onClick={onReviewclick}
              label={`Move To ${status ? status : "Review"}`}
            />
            <TransparentBtn
              border
              label={"Add To Expense Report"}
              onClick={handleAddToExpenseClick}
            />
            <TransparentBtn border onClick={onSplitClick} label={"Split"} />
            <TransparentBtn border onClick={onArchiveClick} label={"Archive"} />
            <TransparentBtn
              border
              onClick={onCovertClick}
              label={"Convert To Sales"}
            />
            <TransparentBtn border onClick={onDeleteClick} label={"Delete"} />
            {/* <Button
              disabled={numSelected === 0}
              //   variant='contained'
              //   color='primary'
              //   sx={{
              //     fontSize: '12px',
              //     padding: '6px 15px',
              //     marginRight: '10px',
              //   }}
            >
              Add To Expense Report
            </Button> */}
            {/* <Button
              // disabled={numSelected === 0}
              variant='contained'
              color='primary'
              onClick={onSplitClick}
              sx={{
                fontSize: '12px',
                padding: '6px 15px',
                marginRight: '10px',
              }}
            >
              Split
            </Button> */}
            {/* <Button
              // disabled={numSelected === 0}
              variant='contained'
              color='primary'
              sx={{
                fontSize: '12px',
                padding: '6px 15px',
                marginRight: '10px',
              }}
              onClick={onArchiveClick}
            >
              Archive
            </Button> */}
            {/* <Button
              //disabled={numSelected === 0}
              variant='contained'
              color='primary'
              sx={{
                fontSize: '12px',
                padding: '6px 15px',
                marginRight: '10px',
              }}
              onClick={onCovertClick}
            >
              Convert To Sales
            </Button> */}
            {/* <Button
              //disabled={numSelected === 0}
              variant='contained'
              color='primary'
              sx={{
                fontSize: '12px',
                padding: '6px 15px',
                marginRight: '10px',
              }}
              onClick={onDeleteClick}
            >
              Delete
            </Button> */}
          </div>
          <div className="flex items-center gap-1">
            <TransparentBtn
              disabled={selectedItem === 0}
              onClick={() => {
                if (selectedItem > 0) {
                  setSelectedItem((prev) => prev - 1);
                  navigate(
                    `/dashboard/costs/edit/${list[selectedItem - 1]?._id}`
                  );
                }
              }}
              label={"Previous"}
              startIcon={<ArrowBackIos color="disabled" fontSize="small" />}
            />
            <div style={{ marginRight: "10px" }} className="font-semibold">
              {selectedItem + 1}/{list?.length}
            </div>
            <TransparentBtn
              disabled={selectedItem === list?.length - 1}
              onClick={() => {
                if (selectedItem < list?.length - 1) {
                  if (selectedItem < list?.length) {
                    setSelectedItem((prev) => prev + 1);
                    navigate(
                      `/dashboard/costs/edit/${list[selectedItem + 1]?._id}`
                    );
                  }
                }
              }}
              label={"Next"}
              endIcon={
                <ArrowBackIos
                  color="disabled"
                  fontSize="small"
                  className="rotate-180"
                />
              }
            />
            {/* <Button
              // variant="contained"
              //   sx={{
              //     fontSize: '12px',
              //     padding: '6px 15px',
              //     marginRight: '10px',
              //     color: 'black',
              //     fontWeight: '600',
              //   }}
              disabled={selectedItem === 0}
              onClick={() => {
                if (selectedItem > 0) {
                  setSelectedItem((prev) => prev - 1);
                  navigate(
                    `/dashboard/costs/edit/${list[selectedItem - 1]?._id}`
                  );
                }
              }}
            >
              {'<'} Previous
            </Button> */}

            {/* <Button
              // variant="contained"
              //   sx={{
              //     fontSize: '12px',
              //     padding: '6px 15px',
              //     color: 'black',
              //     fontWeight: '600',
              //   }}
              disabled={selectedItem === list?.length - 1}
              onClick={() => {
                if (selectedItem < list?.length) {
                  setSelectedItem((prev) => prev + 1);
                  navigate(
                    `/dashboard/costs/edit/${list[selectedItem + 1]?._id}`
                  );
                }
              }}
            >
              Next {'>'}
            </Button> */}
          </div>
        </div>
      </Toolbar>
    </>
  );
}
const ConvertToSales = ({ open, onClose, selectedCount, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} sx={{ padding: "40px" }}>
      <DialogTitle sx={{ color: "#1976D2", fontWeight: "bold" }}>
        Convert to Sale Item(s)
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to convert to sales {selectedCount} item(s)?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            background: "none",
            border: "none",
            // fontWeight: "bold",
            color: "#000099",
            "&:hover": { bgcolor: "#f0f0f0" },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          sx={{
            background: "#ff5a02",
            border: "none",
            color: "#ffff",
            // fontWeight: "bold",
            "&:hover": { bgcolor: "#ff5a02" },
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CostEdit = ({ costId }) => {
  const [selected, setSelected] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);
  const [openArchive, setOpenArchive] = useState(false);
  const [currency, setCurrency] = useState("");
  const [description, setDescription] = useState("");
  const [costData, setCostData] = useState({});
  const [supplierData, setSupplierData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [user, setUser] = useState({ name: "None" });
  const [isConvertToSalesOpen, setIsConvertToSalesOpen] = useState(false);
  const [isConvertToReviewOpen, setIsConvertToReviewOpen] = useState(false);
  const [newLineModal, setNewLineModal] = useState(false);
  const [openSplit, setOpenSplit] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [history, setHistory] = useState([]);

  const { list, selectedItem, setSelectedItem } = useContext(BusinessContext);

  const ZoomIn = (evnt) => {
    setScale((previous) => Math.min(previous + 0.2, 3));
    console.log(scale);
    evnt.stopPropagation();
  };

  const ZoomOut = (evnt) => {
    setScale((previous) => Math.max(previous - 0.2, 0.5));
    console.log(scale);
    evnt.stopPropagation();
  };

  const Rotation = (evnt) => {
    setRotate((previous) => previous + 90);
    console.log(rotate);
    evnt.stopPropagation();
  };

  const handleDownload = (imageURL, evnt) => {
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "image.png"; // Optional: Specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    evnt.stopPropagation();
  };
  const [currentItem, setCurrentItem] = useState({
    category: "other",
    totalAmount: "1342.52",
    taxAmount: "223.76",
  });

  const [newItem, setNewItem] = useState({
    category: "",
    totalAmount: "",
    taxAmount: "",
  });

  const handleCurrentItemChange = (e) => {
    setCurrentItem({
      ...currentItem,
      [e.target.name]: e.target.value,
    });
  };

  const handleNewItemChange = (e) => {
    setNewItem({
      ...newItem,
      [e.target.name]: e.target.value,
    });
  };

  const style = {
    position: "absolute",
    top: "42%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    height: "75vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: "1rem",
  };

  const handleClickBack = () => {
    navigate(-1);
  };
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleConvertCancel = () => {
    setIsConvertToSalesOpen(false);
  };

  const handleConvertConfirm = async () => {
    await axios.post(
      `${baseURL}/cost/convert/${id}`,
      {},
      {
        // Empty object for data
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setIsConvertToSalesOpen(false);
    navigate("/dashboard/costs/inbox");
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleArchiveOpen = () => {
    setOpenArchive(true);
  };

  const handleArchiveClose = () => {
    setOpenArchive(false);
  };

  const handleReviewClose = () => {
    setIsConvertToReviewOpen(false);
  };

  const handleSplitClick = () => {
    setOpenSplit(!openSplit);
  };

  const handleDelete = () => {
    ///${costId}
    axios
      .delete(`${baseURL}/cost/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Deleted successfully", response);
        handleDeleteClose();
        navigate("/dashboard/costs/inbox");
      })
      .catch((error) => {
        console.error("There was an error deleting the item!", error);
      });
  };

  const handleArchive = () => {
    ///${costId}
    axios
      .patch(
        `${baseURL}/cost/${id}`,
        { status: "archive" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Archived successfully", response);
        handleArchiveClose();
        navigate("/dashboard/costs/inbox");
      })
      .catch((error) => {
        console.error("There was an error archiving the item!", error);
      });
  };

  const handleReview = () => {
    ///${costId}
    axios
      .patch(
        `${baseURL}/cost/${id}`,
        {
          status: costData && costData.status === "Review" ? "Ready" : "Review",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Archived successfully", response);
        handleReviewClose();
        navigate("/dashboard/costs/inbox");
      })
      .catch((error) => {
        console.error("There was an error archiving the item!", error);
      });
  };

  // const handleEdit = async (e, fieldName) => {
  //   try {
  //     let value, name;
  //     // console.log(e)
  //     // If fieldName is provided (e.g., for DatePicker), use it; otherwise, extract from event
  //     if (fieldName) {
  //       name = fieldName; // Name explicitly passed for DatePicker
  //       value = e; // DatePicker directly passes the selected date value
  //     }
  //     if (e.target) {
  //       // console.log('here')
  //       name = e.target.name; // For standard inputs like checkbox, text fields
  //       value = name === "paid" ? e.target.value === "on" : e.target.value;
  //     }

  //     if (!name) {
  //       console.error("No field name provided");
  //       return;
  //     }

  //     // console.log('these',value,name)

  //     // Update history **state** immediately so UI updates without refresh
  //     setHistory((prevHistory) => [...prevHistory, logEntry]);

  //     // Update costData state
  //     setCostData((prevValue) => ({
  //       ...prevValue,
  //       [name]: value,
  //     }));
  //     console.log("costData");
  //     console.log(setCostData);

  //     // Send patch request
  //     const response = await axios.patch(
  //       `${baseURL}/cost/${id}`,
  //       {
  //         ...costData,
  //         [name]: value,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     console.log(response.data);
  //   } catch (error) {
  //     console.error("Error updating item:", error);
  //   }
  // };

  const handleEdit = async (e, fieldName) => {
    try {
      let value, name;

      if (fieldName) {
        name = fieldName;
        value = e;
      } else if (e.target) {
        name = e.target.name;
        value = name === "paid" ? e.target.value === "on" : e.target.value;
      }

      if (!name) {
        console.error("No field name provided");
        return;
      }

      // Get current timestamp
      const timestamp = new Date().toISOString();

      // Get current user info
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      // Create a new history entry
      const newHistoryEntry = {
        message: `Updated ${name} to ${value}`,
        created_at: timestamp,
        updated_by: userInfo.name,
      };

      // Update state with new value and history entry
      setCostData((prevValue) => ({
        ...prevValue,
        [name]: value,
        history: [...(prevValue.history || []), newHistoryEntry], // Ensure history updates
      }));

      // Send patch request
      const response = await axios.patch(
        `${baseURL}/cost/${id}`,
        {
          ...costData,
          [name]: value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const handleConvertClick = () => {
    setIsConvertToSalesOpen(true);
  };

  const handleReviewClick = () => {
    setIsConvertToReviewOpen(true);
  };

  useEffect(() => {
    fetchSupplier();
    fetchCustomer();
    fetchData();
    fetchCategory();
    fetchTax();
    fetchPayment();

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo && userInfo.name) {
      setUser(userInfo);
    } else {
      setUser({ name: "None" });
    }
  }, [id]);

  const fetchData = async () => {
    try {
      ///${costId}
      const response = await axios.get(`${baseURL}/cost/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCostData({ ...response.data });
      fetchLineItem(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error("Error updating item:", error);
    }
    console.log(costData);
  };

  const [lineItemData, setLineItemData] = useState([]);

  const fetchLineItem = async (costData) => {
    try {
      const response = await axios.get(
        `${baseURL}/line-item/get-by/costId/${costData._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLineItemData(response.data);
    } catch (error) {
      console.error("Error fetching line items:", error);
    }
  };

  const fetchSupplier = async () => {
    try {
      ///${costId}
      const response = await axios.get(`${baseURL}/supplier`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSupplierData(response.data);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const fetchCategory = async () => {
    try {
      ///${costId}

      // console.log('calling')
      const response = await axios.get(`${baseURL}/category/get-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log('here',response)
      setCategoryData(response.data);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const fetchTax = async () => {
    try {
      ///${costId}
      const response = await axios.get(`${baseURL}/tax`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTaxData(response.data);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const fetchCustomer = async () => {
    try {
      ///${costId}
      const response = await axios.get(`${baseURL}/customer`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomerData(response.data);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const fetchPayment = async () => {
    try {
      ///${costId}
      const response = await axios.get(`${baseURL}/payment-method`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPaymentData(response.data);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleEdit();
    }
  };

  const categories = [
    { label: "Accommodation", value: "accommodation" },
    { label: "Food", value: "food" },
    { label: "IT & Technology", value: "it_technology" },
    { label: "Motor Expenses", value: "motor_expenses" },
    { label: "Office Supplies", value: "office_supplies" },
    { label: "Other", value: "other" },
    { label: "Postage", value: "postage" },
    { label: "Telecoms & Internet", value: "telecoms_internet" },
  ];

  const handleSplit = async () => {
    await axios
      .post(`${baseURL}/cost/split/${id}`, newItem, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Split Successfully", response);
        // handleDeleteClose();
        setOpenSplit(false);
        navigate("/dashboard/costs/inbox");
      })
      .catch((error) => {
        console.error("There was an error spliting the item!", error);
      });
  };

  const [selectedCost, setSelectedCost] = useState([]);
  const [addToExpenseOpen, setAddToExpenseOpen] = useState(false);

  useEffect(() => {
    setSelectedCost(costData);
  }, [costData]);
  const handleAddToExpenseClick = () => {
    setAddToExpenseOpen(true);
  };
  const handleAddToExpenseCancel = () => {
    setAddToExpenseOpen(false);
  };
  const handleAddToExpenseCofo = async (report) => {
    if (Array.isArray(selectedCost) && selectedCost.length > 0) {
      const userToken = localStorage.getItem("userToken");
      console.log("selectedCost items:", selectedCost);
      await axios.patch(
        `${baseURL}/cost/status/${selectedCost}`,
        { status: "Expense Claim", id: selectedCost, report: report },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      console.log("Selected costs expense claim successfully");

      setSelectedCost([]);
    } else {
      console.error("Error: selected items should be an array of IDs.");
    }
    setAddToExpenseOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AddToExpense
        open={addToExpenseOpen}
        onClose={handleAddToExpenseCancel}
        selectedCount={selectedCost.length}
        onConfirm={handleAddToExpenseCofo}
      />
      <EnhancedTableToolbar
        numSelected={0}
        onBackClick={handleClickBack}
        onDeleteClick={handleDeleteOpen}
        onArchiveClick={handleArchiveOpen}
        onCovertClick={handleConvertClick}
        onReviewclick={handleReviewClick}
        status={costData.status === "Review" ? "Ready" : "Review"}
        onSplitClick={handleSplitClick}
        handleAddToExpenseClick={handleAddToExpenseClick}
      />
      <ConvertToSales
        open={isConvertToSalesOpen}
        onClose={handleConvertCancel}
        selectedCount={selected.length}
        onConfirm={handleConvertConfirm}
      />
      <div className="px-4 w-full flex bg-slate-100 justify-around py-4 h-[90%]">
        <div className="w-[48%] bg-white overflow-auto">
          {
            costData?.image?.includes("pdf") ? (
              <MyPdfViewer fileUrl={costData?.image} />
            ) : (
              <>
                <img
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                    transition: "transform 0.3s ease-in-out",
                  }}
                  src={costData?.image}
                />

                <div className="flex gap-5 absolute z-10 left-[35%] top-[90vh] transform -translate-x-1/2 -translate-y-1/2">
                  <button
                    className="bg-black rounded-sm text-white p-2"
                    onClick={(e) => ZoomIn(e)}
                  >
                    <ZoomInOutlinedIcon />
                  </button>
                  <button
                    className="bg-black rounded-sm text-white p-2"
                    onClick={(e) => ZoomOut(e)}
                  >
                    <ZoomOutOutlinedIcon />
                  </button>
                  <button
                    className="bg-black rounded-sm text-white p-2"
                    onClick={(e) => Rotation(e)}
                  >
                    <RotateRightOutlinedIcon />
                  </button>
                  <button
                    className="bg-black rounded-sm text-white p-2"
                    onClick={(e) => handleDownload(costData?.image, e)}
                  >
                    <FileDownloadOutlinedIcon />
                  </button>
                </div>
              </>
            )

            //    <img src={costData?.image} />
          }
        </div>
        <div className="w-[48%] bg-white h-full">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selected}
              onChange={(e, value) => setSelected(value)}
              sx={{
                fontSize: "12px !important",
                fontWeight: "normal",
                backgroundColor: "#ebf0ec",
              }}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{
                  fontSize: "12px !important",
                  fontWeight: "normal",
                  backgroundColor: selected === 1 ? "white" : "",
                }}
                value={1}
                label="Details"
              />
              <Tab
                sx={{
                  fontSize: "12px !important",
                  fontWeight: "normal",
                  backgroundColor: selected === 2 ? "white" : "",
                }}
                value={2}
                label="Note"
              />
              <Tab
                sx={{
                  fontSize: "12px !important",
                  fontWeight: "normal",
                  backgroundColor: selected === 3 ? "white" : "",
                }}
                value={3}
                label="History"
              />
            </Tabs>
          </Box>
          {selected === 1 && (
            <div className="p-4 overflow-y-scroll max-h-[88%]">
              <div className="text-sm font-semibold mb-2">ITEM DETAILS</div>
              <div class="gap-3">
                <div class="grid grid-cols-12" style={{ height: "44px" }}>
                  <div class="text-sm col-span-5" style={{ fontSize: "12px" }}>
                    Item ID
                  </div>
                  <div class="col-span-7 text-sm font-bold">
                    <TextField
                      disabled
                      fullWidth
                      value={costData?.itemId}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "30px",
                        },
                        width: "220px",
                      }}
                      size="small"
                    />
                  </div>{" "}
                </div>
                <div class="grid grid-cols-12 mb-1">
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Document Owner
                  </div>
                  <div
                    className="col-span-7"
                    style={{
                      height: "30px",
                      width: "220px",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 10px",
                      border: "1px solid #E5E7EB",
                      borderRadius: "3px",
                      fontSize: "12px",
                      marginBottom: "0.8rem",
                    }}
                  >
                    {costData?.documentOwner || user.name}
                  </div>
                </div>
                <div class="grid grid-cols-12 mb-4">
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Type
                  </div>
                  <div class="col-span-7 text-sm font-normal text-black">
                    <Select
                      style={{
                        height: "30px",
                        width: "220px",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent !important",
                          },
                          "&.Mui-focused": {
                            boxShadow: "none !important",
                          },
                        },
                      }}
                      value={costData?.type}
                      key={costData?.type}
                      name="type"
                      onChange={handleEdit}
                    >
                      <MenuItem value={"Reciept"}>Reciept</MenuItem>
                      <MenuItem value={"ATM withdrawal"}>
                        ATM withdrawal
                      </MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                      <MenuItem value={"Invoice"}>Invoice</MenuItem>
                      <MenuItem value={"Statement/remittance advice"}>
                        Statement/remittance advice
                      </MenuItem>
                      <MenuItem value={"Expense Statement"}>
                        Expense Statement
                      </MenuItem>
                      <MenuItem value={"Credit Note/Refund"}>
                        Credit Note/Refund
                      </MenuItem>
                      <MenuItem value={"Delivery note"}>Delivery note</MenuItem>
                      <MenuItem value={"Mileage"}>Mileage</MenuItem>
                    </Select>
                  </div>{" "}
                </div>
                <div class="grid grid-cols-12 " style={{ height: "44px" }}>
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Supplierr
                  </div>
                  <div class="col-span-7 text-sm font-normal text-black">
                    <TextField
                      value={costData?.supplier}
                      // key={costData?.supplier}
                      name="supplier"
                      onChange={handleEdit}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "30px",
                        },
                        width: "220px",
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "black", // Change border on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent !important", // Remove blue outline on focus
                          },
                          "&.Mui-focused": {
                            boxShadow: "none !important", // Remove focus shadow
                          },
                        },
                      }}
                      size="small"
                    />
                  </div>{" "}
                </div>
                <div class="grid grid-cols-12 mb-3">
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Date
                  </div>
                  <div class="col-span-7 text-sm font-normal font-bold text-black">
                    <DatePicker
                      value={costData?.date ? dayjs(costData.date) : null}
                      onChange={(newValue) => handleEdit(newValue, "date")}
                      format="DD/MM/YYYY"
                      sx={{
                        width: "220px",
                        "& .MuiOutlinedInput-root": {
                          height: "30px",
                        },
                        //   '& fieldset': {
                        //     borderColor: '#E5E7EB',
                        //   },
                        //   '&:hover fieldset': {
                        //     borderColor: 'black',
                        //   },
                        //   '&.Mui-focused fieldset': {
                        //     borderColor: 'transparent',
                        //     borderWidth: '1px',
                        //   },
                        // },
                        // Change text color of input
                        // '& input': {
                        //   color: '#9e9e9e',
                        // },
                        // Change calendar icon color
                        // '& .MuiSvgIcon-root': {
                        //   color: '#9e9e9e',
                        // },
                        // Optional: Style the calendar picker indicator
                        // '& input::-webkit-calendar-picker-indicator': {
                        //   filter: 'invert(50%)',
                        //   color: '#9e9e9e', // This will only work for webkit-based browsers
                        // },
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                    />
                  </div>
                </div>

                <div class="grid grid-cols-12 mb-1">
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    {" "}
                    Due Date
                  </div>
                  <div class="col-span-7 text-sm font-normal font-bold text-black">
                    <DatePicker
                      value={costData?.dueDate ? dayjs(costData.dueDate) : null}
                      name="date"
                      format="DD/MM/YYYY"
                      onChange={(newValue) => handleEdit(newValue, "dueDate")}
                      sx={{
                        height: "30px",
                        width: "220px",
                        "& .MuiOutlinedInput-root": {
                          height: "30px",
                          // '& fieldset': {
                          //   borderColor: '#E5E7EB',
                        },
                        //   '&:hover fieldset': {
                        //     borderColor: 'black',
                        //   },
                        //   '&.Mui-focused fieldset': {
                        //     borderColor: 'transparent',
                        //     borderWidth: '1px',
                        //   },
                        // },
                        // // Change text color of input
                        // '& input': {
                        //   color: '#9e9e9e',
                        // },
                        // // Change calendar icon color
                        // '& .MuiSvgIcon-root': {
                        //   color: '#9e9e9e',
                        // },
                        // // Optional: Style the calendar picker indicator
                        // '& input::-webkit-calendar-picker-indicator': {
                        //   filter: 'invert(50%)',
                        //   color: '#9e9e9e', // This will only work for webkit-based browsers
                        // },
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                    />
                  </div>{" "}
                </div>
                <div class="grid grid-cols-12 " style={{ height: "44px" }}>
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Purchase Order Number
                  </div>
                  <div class="col-span-7 text-sm font-normal font-bold ">
                    <TextField
                      value={costData?.purchaseOrderNumber}
                      name="purchaseOrderNumber"
                      onChange={handleEdit}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "30px",
                        },
                        width: "220px",
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "black", // Change border on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent !important", // Remove blue outline on focus
                          },
                          "&.Mui-focused": {
                            boxShadow: "none !important", // Remove focus shadow
                          },
                        },
                      }}
                      size="small"
                    />
                  </div>{" "}
                </div>
                <div class="grid grid-cols-12  mb-1">
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Document Reference
                  </div>
                  <div class="col-span-7 text-sm font-normal font-bold text-black">
                    <TextField
                      value={costData?.documentReference}
                      name="documentReference"
                      onChange={handleEdit}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "30px",
                        },
                        width: "220px",
                        "& .MuiInputBase-input": {
                          color: "#546478",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "black", // Remove border on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent", // Remove blue outline when focused
                          },
                        },
                      }}
                      size="small"
                    />
                  </div>{" "}
                </div>
                <div class="grid grid-cols-12 mb-4">
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Category
                  </div>
                  {categoryData && (
                    <div class="col-span-7 text-sm font-normal text-black">
                      <Select
                        key={costData.category}
                        style={{
                          height: "30px",
                          width: "220px",
                          // borderRadius: '3px',
                          // borderColor: '#E5E7EB',
                          // fontSize: '15px',
                          // padding: '2px 6px',
                        }}
                        value={costData.category}
                        name="category"
                        onChange={handleEdit}
                      >
                        {categoryData.map(({ name }, index) => {
                          return (
                            <MenuItem sx={{ fontSize: "12px" }} value={name}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  )}{" "}
                </div>
                {customerData && customerData.length > 0 && (
                  <div class="grid grid-cols-12 mb-4">
                    <div
                      class="col-span-5 text-sm font-normal"
                      style={{ fontSize: "12px" }}
                    >
                      Customer
                    </div>
                    <div class=" col-span-7 text-[10px] text-black">
                      <Select
                        style={{
                          height: "30px",
                          width: "220px",
                          // borderRadius: '3px',
                          // borderColor: '#E5E7EB',
                          // fontSize: '15px',
                          // padding: '2px 6px',
                        }}
                        value={costData?.customer}
                        key={costData?.customer}
                        name="customer"
                        onChange={handleEdit}
                      >
                        {customerData.map(({ name }) => {
                          return (
                            <MenuItem sx={{ fontSize: "11px" }} value={name}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>{" "}
                  </div>
                )}
                <div class="grid grid-cols-12 ">
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Description
                  </div>
                  <div class="col-span-7 text-sm font-normal  ">
                    <TextField
                      value={costData?.description}
                      multiline
                      minRows={1}
                      maxRows={2}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "30px",
                        },
                        width: "220px",
                      }}
                      name="description"
                      onChange={handleEdit}
                      onKeyDown={handleKeyDown}
                    />
                  </div>{" "}
                </div>
              </div>
              <div className="text-sm font-normal font-semibold mt-4 ">
                AMOUNT
              </div>
              <div class="flex flex-col gap-2">
                <div class="grid grid-cols-12 mb-3">
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Currency
                  </div>
                  <div class="col-span-7 text-sm font-normal text-black">
                    <Select
                      style={{
                        height: "30px",
                        width: "220px",
                        // borderRadius: '3px',
                        // borderColor: '#E5E7EB',
                      }}
                      value={costData?.currency}
                      key={costData?.currency}
                      name="currency"
                      onChange={handleEdit}
                      onKeyDown={handleKeyDown}
                    >
                      {/* <MenuItem value={'None'}>None</MenuItem> */}
                      {/* <MenuItem value='USD'>USD</MenuItem> */}
                      <MenuItem value="GBP">GBP</MenuItem>
                      {/* <MenuItem value='EUR'>EUR</MenuItem> */}
                      {/* <MenuItem value='CAD'>CAD</MenuItem> */}
                      {/* <MenuItem value='JPY'>JPY</MenuItem> */}
                    </Select>
                  </div>{" "}
                </div>
                <div class="grid grid-cols-12 ">
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Net Amount
                  </div>
                  <div class="col-span-7 text-sm font-normal font-bold text-black">
                    <TextField
                      onChange={handleEdit}
                      value={costData?.totalAmount}
                      name="totalAmount"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "30px",
                        },
                        width: "220px",
                        "& .MuiInputBase-input": {
                          color: "#546478",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "black", // Remove border on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent", // Remove blue outline when focused
                          },
                        },
                      }}
                      size="small"
                    />
                  </div>{" "}
                </div>

                <div class="grid grid-cols-12 ">
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Tax Amount
                  </div>
                  <div class="col-span-7 text-sm font-normal font-bold ">
                    <TextField
                      onChange={handleEdit}
                      name="taxAmount"
                      value={costData?.taxAmount}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "30px",
                        },
                        width: "220px",
                        "& .MuiInputBase-input": {
                          color: "#546478",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "black", // Remove border on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent", // Remove blue outline when focused
                          },
                        },
                      }}
                      size="small"
                    />
                  </div>{" "}
                </div>
                <div class="grid grid-cols-12 ">
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Total Amount
                  </div>
                  <div class="col-span-7 text-sm font-normal font-bold">
                    <TextField
                      disabled
                      value={
                        Number(costData?.totalAmount) +
                        Number(costData?.taxAmount)
                      }
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "30px",
                        },
                        width: "220px",
                        "& .MuiInputBase-input": {
                          color: "#546478",
                        },
                      }}
                      size="small"
                    />
                  </div>{" "}
                </div>
              </div>
              <div className="text-sm font-normal font-semibold mt-4 ">
                PAYMENT
              </div>
              <div class="flex flex-col gap-2">
                <div class="grid grid-cols-12 ">
                  <div
                    class="col-span-5 text-sm font-normal"
                    style={{ fontSize: "12px" }}
                  >
                    Paid
                  </div>
                  <div class="col-span-7 text-sm font-normal font-bold">
                    <Switch
                      defaultChecked={costData?.paid}
                      name="paid"
                      onChange={handleEdit}
                      className="!accent-prm"
                    />
                  </div>{" "}
                </div>
                {paymentData && paymentData.length > 0 && (
                  <div class="grid grid-cols-12 ">
                    <div class="col-span-5 text-sm font-normal">
                      Payment Method
                    </div>
                    <div class="col-span-7 text-sm font-normal text-black">
                      <Select
                        style={{
                          height: "30px",
                          width: "200px",
                          borderRadius: "3px",
                          borderColor: "#E5E7EB",
                          padding: "0 10px",
                        }}
                        value={costData?.paymentMethod || "None"}
                        name="paymentMethod"
                        onChange={handleEdit}
                      >
                        {" "}
                        <MenuItem value={"None"}>None</MenuItem>
                        {paymentData.map(({ name }) => {
                          return (
                            <MenuItem sx={{ fontSize: "12px" }} value={name}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>{" "}
                  </div>
                )}
              </div>
              <div className="text-sm font-normal font-semibold mt-4 ">
                LINE ITEMS
              </div>
              <div className="w-full flex justify-center items-center border border-slate-300 p-4">
                <div className="w-full">
                  <div className="w-full h-[70px] flex justify-center items-center gap-4 border border-slate-300">
                    {lineItemData.length == 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          fontSize: "12px",
                          padding: "6px 15px",
                        }}
                        onClick={() => {
                          setNewLineModal(true);
                        }}
                      >
                        Create line items
                      </Button>
                    )}
                    {lineItemData.length != 0 && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        sx={{
                          fontSize: "12px",
                          padding: "6px 15px",
                        }}
                        onClick={() => {
                          setNewLineModal({ show: true, data: lineItemData });
                        }}
                      >
                        Edit line items
                      </Button>
                    )}
                  </div>

                  {lineItemData.length != 0 && (
                    <LineItemsTable
                      lineItemData={lineItemData}
                      itemTotalNet={Number(costData?.totalAmount)}
                      itemTotalTax={Number(costData?.taxAmount)}
                      itemTotalTotal={
                        Number(costData?.totalAmount) +
                        Number(costData?.taxAmount)
                      }
                    />
                  )}

                  {/* <div className='overflow-x-auto'>
          <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
              <tr>
                <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Description</th>
                <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Category</th>
                <th scope='col' className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>Net</th>
                <th scope='col' className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>Tax</th>
                <th scope='col' className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>Total</th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200'>
              {lineItemData && lineItemData.length > 0 ? (
                lineItemData.map((item, index) => (
                  <tr key={index} className='hover:bg-gray-50'>
                    <td className='px-6 py-4 whitespace-nowrap col-span-5 text-sm text-gray-900'>{item.description || '-'}</td>
                    <td className='px-6 py-4 whitespace-nowrap col-span-5 text-sm text-gray-500'>{item.category || '-'}</td>
                    <td className='px-6 py-4 whitespace-nowrap col-span-5 text-sm text-gray-900 text-right'>{item.net ? item.net.toFixed(2) : '-'}</td>
                    <td className='px-6 py-4 whitespace-nowrap col-span-5 text-sm text-gray-900 text-right'>{item.tax ? item.tax.toFixed(2) : '-'}</td>
                    <td className='px-6 py-4 whitespace-nowrap col-span-5 text-sm font-medium text-gray-900 text-right'>{item.total ? item.total.toFixed(2) : '-'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className='px-6 py-4 text-center col-span-5 text-sm text-gray-500'>No line items found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div> */}
                </div>
              </div>
              <div className="mt-6 mb-4">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontSize: "12px",
                    padding: "6px 15px",
                    marginRight: "10px",
                  }}
                  onClick={handleReviewClick}
                >
                  Move To Review
                </Button>
                <Button
                  disabled={false}
                  onClick={handleAddToExpenseClick}
                  variant="contained"
                  color="primary"
                  sx={{
                    fontSize: "12px",
                    padding: "6px 15px",
                    marginRight: "10px",
                  }}
                >
                  Add To Expense Report
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontSize: "12px",
                    padding: "6px 15px",
                    marginRight: "10px",
                  }}
                  onClick={handleArchiveOpen}
                >
                  Archive
                </Button>
                {/* <Button

                                variant="contained"
                                color="primary"
                                sx={{
                                    fontSize: '12px',
                                    padding: '6px 15px',
                                    marginRight: '10px'
                                }}
                            >
                                More
                            </Button> */}
              </div>
            </div>
          )}
          {selected === 2 && (
            <div className="p-4">
              <Textarea
                onChange={handleEdit}
                name="note"
                value={costData?.note}
              />
            </div>
          )}
          {selected === 3 && (
            <div className="p-4 h-5/6 overflow-auto">
              {costData?.history
                ?.slice()
                .reverse()
                .map((data, index) => (
                  <div className="flex mb-4" key={index}>
                    <div className="w-[20px] h-[20px] bg-blue-200 relative rounded-full mr-3">
                      <div className="w-[10px] h-[10px] bg-blue-500 absolute top-[25%] left-[25%] rounded-full"></div>
                    </div>
                    <div>
                      <div className="col-span-5 text-sm font-normal font-semibold">
                        {data.message} by{" "}
                        {JSON.parse(localStorage.getItem("userInfo")).name}
                      </div>
                      <div className="text-slate-500 col-span-5 text-sm font-normal">
                        {new Date(data.created_at).toLocaleString()}{" "}
                        {/* Format date properly */}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>

        {/* Delete Confirmation Dialog */}
        <Dialog open={openDelete} onClose={handleDeleteClose}>
          <DialogTitle className="text-black text-lg font-bold">
            Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDeleteClose}
              sx={{
                background: "none",
                border: "none",
                borderRadius: "5px",
                "&:hover": { background: "lightgrey" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              //   sx={{
              //     background: '#ff5a02',
              //     border: 'none',
              //     color: 'black',
              //     borderRadius: '5px',
              //     '&:hover': {
              //       boxShadow: '0 0 5px #ff5a02',
              //       background: '#ff5a02',
              //     },
              //   }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {/* Archive Confirmation Dialog */}
        <Dialog open={openArchive} onClose={handleArchiveClose}>
          <DialogTitle sx={{ color: "#1976d2" }}>Archive</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to archive this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleArchiveClose}
              sx={{
                background: "none",
                border: "none",
                borderRadius: "5px",
                "&:hover": { background: "lightgrey" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleArchive}
              sx={{
                background: "#ff5a02",
                border: "none",
                color: "black",
                borderRadius: "5px",
                "&:hover": {
                  boxShadow: "0 0 5px #ff5a02",
                  background: "#ff5a02",
                },
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isConvertToReviewOpen} onClose={handleReviewClose}>
          <DialogTitle sx={{ color: "#1976d2" }}>Review</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to move this item to{" "}
              {costData && costData.status === "Review" ? "ready" : "review"}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleReviewClose}
              sx={{
                background: "none",
                border: "none",
                borderRadius: "5px",
                "&:hover": { background: "lightgrey" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleReview}
              sx={{
                background: "#ff5a02",
                border: "none",
                color: "black",
                borderRadius: "5px",
                "&:hover": {
                  boxShadow: "0 0 5px #ff5a02",
                  background: "#ff5a02",
                },
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {newLineModal ? (
          <>
            <div className="modal-wrapper"></div>
            <div className="modal ">
              {/* Close Button to dismiss the modal*/}
              <button
                className="absolute top-4 right-4 bg-gray-300 hover:bg-gray-400 text-black px-3 py-1 rounded-full"
                onClick={() => setNewLineModal(false)}
              >
                ✕
              </button>
              <div className="w-full max-h-[50%]  bg-white overflow-auto ">
                <img
                  className="modal-img w-full h-auto "
                  src={costData?.image}
                />
              </div>
              <div className="w-full h-[50%] flex flex-col justify-end items-center  ">
                <StickyColumnsTable
                  className="overflowY-auto"
                  costID={costData._id}
                  lineItems={newLineModal == true ? [] : newLineModal.data}
                ></StickyColumnsTable>
              </div>
              <DialogActions className="flex justify-end">
                <Button
                  className="flex justify-end"
                  // className='text-white border-2 border-[#1976d2] active:translate-y-1 shadow-lg bg-[#1976d2] rounded-sm p-1 px-2 '
                  onClick={() => {
                    setNewLineModal(false);
                  }}
                >
                  Done
                </Button>
              </DialogActions>
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      {/*====================== split Modal =========================== */}
      <Modal
        open={openSplit}
        onClose={() => setOpenSplit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between fixed shadow-lg w-full h-{10vh} z-10 bg-white p-2">
            <Typography
              sx={{ color: "#1976d2" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Split items
            </Typography>
            {/* <Button
              
              color="primary"
              sx={{
                fontSize: "12px",
                padding: "6px 15px",
                color: "#1976d2",
                fontWeight: "600",
              }}
              onClick={() => setOpenSplit(false)}
            >
              <div className="p-1 border-2 border-[#737373] active:translate-y-1">
                <CloseIcon />
              </div>
            </Button> */}
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 bg-gray-300 hover:bg-gray-400 text-black px-3 py-1 rounded-full"
              onClick={() => setOpenSplit(false)}
            >
              ✕
            </button>
          </div>
          <div className="flex h-[80vh] overflow-y-auto z-0">
            <div className="w-[50vw]">
              <img src={costData?.image} />
            </div>
            <div className="flex flex-col items-center p-4 col-span-5 text-sm gap-3 mt-16 ">
              <div className="border-2 border-[#b6b6b8] bg-white text-[#878787]  tracking-wide p-6  ">
                <InfoOutlinedIcon /> Splitting creates a new item with the same
                image as the <br />
                current item. You can apply different categories, total and tax{" "}
                <br />
                All other fields of the new item will be copied from the current{" "}
                <br />
                item.
              </div>
              <Box
                sx={{
                  width: "100 %",
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Typography variant="h6">CURRENT ITEM</Typography>
                {/* Current Item Category */}
                <div className="flex justify-between items-center w-full gap-[10rem] ">
                  <div>Category</div>
                  <FormControl
                    sx={{
                      width: "16rem",
                    }}
                  >
                    <Autocomplete
                      options={categories}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: 1,
                                }}
                              >
                                {/* Add search icon here if needed */}
                              </Box>
                            ),
                          }}
                        />
                      )}
                      disableClearable
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.label}
                        </Box>
                      )}
                      disablePortal
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                    />
                  </FormControl>
                </div>

                {/* Current Item Total Amount */}
                <div className="flex justify-between items-center w-full gap-[12rem] ">
                  <div>Total Amount</div>
                  <TextField
                    label="Total Amount"
                    name="totalAmount"
                    value={currentItem.totalAmount}
                    onChange={handleCurrentItemChange}
                    fullWidth
                  />
                </div>

                {/* Current Item Tax Amount */}
                <div className="flex justify-between items-center w-full gap-[12rem] ">
                  <div>Tax Amount</div>
                  <TextField
                    label="Tax Amount"
                    name="taxAmount"
                    value={currentItem.taxAmount}
                    onChange={handleCurrentItemChange}
                    fullWidth
                  />
                </div>

                <Typography variant="h6" sx={{ mt: 3 }}>
                  NEW ITEM
                </Typography>
                {/* New Item Category */}
                <div className="flex justify-between items-center w-full gap-[10rem] ">
                  <div>Category</div>
                  <FormControl
                    sx={{
                      width: "16rem",
                    }}
                  >
                    <Select
                      name="category"
                      value={newItem.category}
                      onChange={handleNewItemChange}
                      label="Category"
                    >
                      {categories.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                {/* New Item Total Amount */}
                <div className="flex justify-between items-center w-full gap-[12rem] ">
                  <div>Total Amount</div>
                  <TextField
                    label="Total Amount"
                    name="totalAmount"
                    value={newItem.totalAmount}
                    onChange={handleNewItemChange}
                    fullWidth
                  />
                </div>

                {/* New Item Tax Amount */}
                <div className="flex justify-between items-center w-full gap-[12rem]">
                  <span>Tax Amount</span>
                  <TextField
                    label="Tax Amount"
                    name="taxAmount"
                    value={newItem.taxAmount}
                    onChange={handleNewItemChange}
                    fullWidth
                  />
                </div>
              </Box>
            </div>
          </div>
          <div className="flex justify-end gap-6 p-6 items-center shadow-lg w-full bg-white mb-4">
            <button
              className="p-1 border-2 rounded-sm border-[#737373] active:translate-y-1"
              onClick={() => setOpenSplit(!openSplit)}
            >
              Cancel
            </button>
            <button
              className="text-white border-2 border-[#1976d2] active:translate-y-1 shadow-lg bg-[#1976d2] rounded-sm p-1 px-2 "
              onClick={handleSplit}
            >
              {" "}
              Split
            </button>
          </div>
        </Box>
      </Modal>
    </LocalizationProvider>
  );
};

export default CostEdit;
