// import { shadow } from "pdfjs-dist";
// import React, { useState } from "react";
// import "./style.css";

// const SignupStepOne = ({ nextStep }) => {
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     password: "",
//     phoneNumber: "",
//   });
//   const [errors, setErrors] = useState({});

//   const validateForm = () => {
//     const newErrors = {};
//     if (!formData.firstName) newErrors.firstName = "First name is required.";
//     if (!formData.lastName) newErrors.lastName = "Last name is required.";
//     if (!formData.email) newErrors.email = "Valid email is required.";
//     if (
//       !formData.password ||
//       !/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.{8,})/.test(formData.password)
//     )
//       newErrors.password =
//         "Password must include uppercase, lowercase, and at least 8 characters.";
//     if (!formData.phoneNumber) newErrors.phoneNumber = "Valid phone number is required.";
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = () => {
//     if (validateForm()) nextStep(formData);
//   };

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-100">
//       <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-4xl">
//         <h2 className="text-2xl font-semibold mb-6 text-center">Start your free trial</h2>
//         <p className="text-sm text-gray-500 mb-8 text-center">No payment details required.</p>
//         <form>
//           {[
//             { label: "First Name", name: "firstName" },
//             { label: "Last Name", name: "lastName" },
//             { label: "Work Email", name: "email", type: "email" },
//             { label: "Password", name: "password", type: "password" },
//           ].map((field, idx) => (
//             <div key={idx} className="mb-4">
//               <label className="block text-sm font-medium text-gray-700 mb-1" >
//                 {field.label}
//               </label>
//               <input
//                 type={field.type || "text"}
//                 className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 sh ${
//                   errors[field.name] ? "border-red-500" : "border-gray-300"
//                 }`}
//                 value={formData[field.name]}
//                 onChange={(e) =>
//                   setFormData({ ...formData, [field.name]: e.target.value })
//                 }
//               />
//               {errors[field.name] && (
//                 <p className="text-sm text-red-500 mt-1">{errors[field.name]}</p>
//               )}
//             </div>
//           ))}
//           <div className="mb-4">
//             <label className="block text-sm font-medium text-gray-700 mb-1">Mobile Number</label>
//             <div className="flex">
//               <select className="border rounded-l-lg border-gray-300 p-2 bg-gray-100">
//                 <option value="+44">+44</option>
//                 <option value="+1">+1</option>
//               </select>
//               <input
//                 type="text"
//                 className={`w-full p-2 border-t border-b border-r rounded-r-lg focus:ring-2 focus:ring-blue-500 ${
//                   errors.phoneNumber ? "border-red-500" : "border-gray-300"
//                 }`}
//                 value={formData.phoneNumber}
//                 onChange={(e) =>
//                   setFormData({ ...formData, phoneNumber: e.target.value })
//                 }
//               />
//             </div>
//             {errors.phoneNumber && (
//               <p className="text-sm text-red-500 mt-1">{errors.phoneNumber}</p>
//             )}
//           </div>
//           <button
//             type="button"
//             className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
//             onClick={handleSubmit}
//           >
//             Next
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default SignupStepOne;

/** Enhanced Code For Signup Form */
import React, { useState } from 'react';
import './signup.css';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { Button } from 'flowbite-react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const SignupStepOne = ({ nextStep }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First name is required.';
    if (!formData.lastName) newErrors.lastName = 'Last name is required.';
    if (!formData.email) newErrors.email = 'Valid email is required.';
    if (
      !formData.password ||
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.{8,})/.test(
        formData.password
      )
    )
      newErrors.password =
        'Password must include uppercase, lowercase, and at least 8 characters.';
    if (!formData.phoneNumber || formData.phoneNumber.length < 10)
      newErrors.phoneNumber = 'Valid phone number is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) nextStep(formData);
  };

  // ...

  return (
    <div className='min-h-screen bg-gray-50 p-10'>
      <div className='text-[#0d417b] text-3xl font-black uppercase mb-6'>
        Zoom Account
      </div>
      <div className='flex gap-5 justify-center items-center p-10 bg-white shadow-lg rounded-lg w-full'>
        <div className='w-1/3'>
          <h2 className='text-3xl font-semibold mb-6 text-center'>
            Start your free trial
          </h2>
          <p className='text-lg font-medium text-gray-500 mb-8 text-center'>
            No payment details required.
          </p>
        </div>
        <div className='w-2/3'>
          <form>
            {[
              { label: 'First Name', name: 'firstName' },
              { label: 'Last Name', name: 'lastName' },
              { label: 'Work Email', name: 'email', type: 'email' },
              { label: 'Password', name: 'password', type: 'password' },
            ].map((field, idx) => (
              <div key={idx} className='mb-4'>
                {/* <label className='block text-sm font-medium text-gray-700 mb-1'>
                {field.label}
              </label> */}
                {field.type !== 'password' ? (
                  <TextField
                    placeholder={field.label}
                    type={field.type || 'text'}
                    fullWidth
                    // className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 ${
                    //   errors[field.name] ? 'border-red-500' : 'border-gray-300'
                    // }`}
                    error={errors[field.name]}
                    value={formData[field.name]}
                    onChange={(e) =>
                      setFormData({ ...formData, [field.name]: e.target.value })
                    }
                    helperText={errors[field.name] ? errors[field.name] : ''}
                  />
                ) : (
                  <FormControl fullWidth>
                    <OutlinedInput
                      id='password'
                      placeholder={field.label}
                      type={showPassword ? 'text' : field.type}
                      fullWidth
                      // className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 ${
                      //   errors[field.name] ? 'border-red-500' : 'border-gray-300'
                      // }`}
                      error={errors[field.name]}
                      value={formData[field.name]}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [field.name]: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label={
                              !showPassword
                                ? 'hide the password'
                                : 'display the password'
                            }
                            onClick={() => setShowPassword(!showPassword)}
                            // className='absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-600'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors[field.name] && (
                      <FormHelperText id='password' error={errors[field.name]}>
                        {errors[field.name]}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              </div>
            ))}
            <div className='mb-4'>
              {/* <label className='block text-sm font-medium text-gray-700 mb-1'>
                Mobile Number
              </label> */}
              <div className='flex'>
                <Select defaultValue={'+44'} error={errors.phoneNumber}>
                  <MenuItem value='+44'>+44</MenuItem>
                  {/* <MenuItem value='+1'>+1</MenuItem> */}
                </Select>
                <TextField
  error={!!errors.phoneNumber}
  type="text"
  fullWidth
  placeholder="Mobile Number"
  value={formData.phoneNumber}
  onChange={(e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (value.length <= 10) {
      setFormData({ ...formData, phoneNumber: value });
    }
  }}
  helperText={errors.phoneNumber}
/>
              </div>
            </div>
            <Button
              type='button'
              className='w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600'
              onClick={handleSubmit}
            >
              Next
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupStepOne;
