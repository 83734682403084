// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.modal-wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: rgba(24, 23, 23, 0.5);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    width: 90%;
    height: 90vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 50;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem 0;


}`, "",{"version":3,"sources":["webpack://./src/costComponents/CreateLineItemsModal.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,MAAM;IACN,OAAO;IACP,uCAAuC;IACvC,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IAEtB,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;;;AAGnB","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\n.modal-wrapper {\n    position: absolute;\n    z-index: 2;\n    top: 0;\n    left: 0;\n    background-color: rgba(24, 23, 23, 0.5);\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.modal {\n    width: 90%;\n    height: 90vh;\n    background-color: white;\n    display: flex;\n    flex-direction: column;\n    z-index: 4;\n    position: absolute;\n    z-index: 50;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    padding: 1rem 0;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
