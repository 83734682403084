// import React, { useState } from 'react';
// import {Accordion,AccordionDetails,AccordionSummary,Button,Table,TableBody,TableCell,TableHead,TableRow,TextField,Typography,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Checkbox,Box,Select,MenuItem,colors} from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ErrorIcon from '@mui/icons-material/Error';
// import {baseURL} from '../Config';
// import { IconButton, Tooltip } from '@mui/material';

// const PaymentMethods = () => {
//   const [name, setName] = useState('');
//   const [reference, setReference] = useState('');
//   const [data, setData] = useState([]);
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [accordionExpanded, setAccordionExpanded] = React.useState(false);
//   const [defaultAccordionExpanded, setdefaultAccordionExpanded] = React.useState(false);

//   const handleAddData = () => {
//     if (name && reference) {
//       const newData = { name, reference };
//       setData([...data, newData]);
//       setName('');
//       setReference('');
//     }
//   };

//   const handleDelete = () => {
//     const newData = data.filter((_, index) => !selectedRows.includes(index));
//     setData(newData);
//     setSelectedRows([]);
//     setOpenDialog(false);
//   };

//   const handleSelectRow = (index) => {
//     const newSelectedRows = [...selectedRows];
//     if (newSelectedRows.includes(index)) {
//       newSelectedRows.splice(newSelectedRows.indexOf(index), 1);
//     } else {
//       newSelectedRows.push(index);
//     }
//     setSelectedRows(newSelectedRows);
//   };

//   const handleDialogOpen = () => {
//     setOpenDialog(true);
//   };

//   const handleDialogClose = () => {
//     setOpenDialog(false);
//   };

//   const handleAccordionChange = (isExpanded) => {
//     setAccordionExpanded(isExpanded);
//   };

//   const handleDefaultAccordionExpanded = (isExpanded) => {
//     setdefaultAccordionExpanded(isExpanded);
//   };

//   return (
//     <>

//       <Accordion expanded={accordionExpanded}
//           onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}
//           sx={{ width: accordionExpanded ? '100%' : '50%' }}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography  sx={{ fontWeight: 'bold'}}>Payment Methods</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Typography
//             gutterBottom
//             border={1}
//             padding={1}
//             borderRadius={1}
//             fontSize="0.875rem"
//           >
//             <Tooltip title="Help">
//               <IconButton>
//                 <ErrorIcon fontSize="small" />
//               </IconButton>
//             </Tooltip>
//             <span>Use this page to manage and modify your list of payment methods; the ways that you pay for your purchases. Learn more about <a href="#" style={{color:"blue"}}>payment methods.</a></span>
//           </Typography>
//           <div>
//             <Typography
//               sx={{ fontWeight: 'bold', marginTop: 2, marginBottom: 2, fontSize: '1rem' }}
//             >
//               Add New Payment Method
//             </Typography>
//             <Box display="flex" alignItems="center" marginBottom="10px">
//               <Typography variant="body2" marginRight="10px">Name</Typography>
//               <TextField
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 style={{ width: '30%', marginLeft: 38 }}
//                 size="small"
//               />
//             </Box>
//             <Box display="flex" alignItems="center" marginBottom="10px">
//               <Typography variant="body2" marginRight="10px">Reference</Typography>
//               <TextField
//                 value={reference}
//                 onChange={(e) => setReference(e.target.value)}
//                 style={{ width: '30%', marginLeft: 10 }}
//                 size="small"
//               />
//             </Box>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={handleAddData}
//               disabled={!name || !reference}
//               style={{ marginLeft: 383, fontWeight: 'bold', background:'#1976d2', color:'white' }}
//             >
//               Add
//             </Button>
//             <Table style={{ marginTop: '20px' }}>
//               <TableHead>
//                 <TableRow>
//                   <TableCell></TableCell>
//                   <TableCell>Name</TableCell>
//                   <TableCell>Reference</TableCell>
//                   <TableCell>Delete</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {data.map((item, index) => (
//                   <TableRow key={index}>
//                     <TableCell>
//                       <Checkbox
//                         checked={selectedRows.includes(index)}
//                         onChange={() => handleSelectRow(index)}
//                       />
//                     </TableCell>
//                     <TableCell>{item.name}</TableCell>
//                     <TableCell>{item.reference}</TableCell>
//                     <TableCell>
//                       <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={handleDialogOpen}
//                         disabled={!selectedRows.includes(index)}
//                       >
//                         <DeleteIcon />
//                       </Button>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={handleDialogOpen}
//               disabled={selectedRows.length === 0}
//               style={{ marginTop: '10px' }}
//             >
//               Delete Selected
//             </Button>
//             <Dialog
//               open={openDialog}
//               onClose={handleDialogClose}
//             >
//               <DialogTitle>Delete Confirmation</DialogTitle>
//               <DialogContent>
//                 <DialogContentText>
//                   Are you sure you want to delete the selected items? This action is irreversible.
//                 </DialogContentText>
//               </DialogContent>
//               <DialogActions>
//                 <Button onClick={handleDialogClose} color="primary">
//                   Cancel
//                 </Button>
//                 <Button onClick={handleDelete} color="secondary">
//                   Delete
//                 </Button>
//               </DialogActions>
//             </Dialog>
//           </div>
//         </AccordionDetails>
//       </Accordion>
//       <Accordion expanded={defaultAccordionExpanded}
//           onChange={(event, isExpanded) =>handleDefaultAccordionExpanded(isExpanded)}
//           sx={{ width: defaultAccordionExpanded ? '100%' : '50%', mt: 2 }} >
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={{ fontWeight: 'bold' }}>Set User Defaults</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Typography
//             gutterBottom
//             border={1}
//             padding={1}
//             borderRadius={1}
//             fontSize="0.875rem"
//           >
//             <Tooltip title="Help">
//               <IconButton>
//                 <ErrorIcon fontSize="small" />
//               </IconButton>
//             </Tooltip>
//             Set a <a href="#" style={{color:"blue"}}>default payment method</a> for people using your account. When they upload a document, we’ll apply that payment method to it.
//           </Typography>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell><Typography fontWeight="bold">User Name</Typography></TableCell>
//                 <TableCell><Typography fontWeight="bold">Payment Method</Typography></TableCell>
//                 <TableCell><Typography fontWeight="bold">Default</Typography></TableCell>
//                 <TableCell><Typography fontWeight="bold">Application Rule</Typography></TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {data.map((item, index) => (
//                 <TableRow key={index}>
//                   <TableCell>{item.name}</TableCell>
//                   <TableCell>
//                     <Select
//                       defaultValue=""
//                       size="small"
//                       style={{ width: '100%' }}
//                     >
//                       <MenuItem value=""><em>None</em></MenuItem>
//                       <MenuItem value="Bank">Bank</MenuItem>
//                       <MenuItem value="Credit Card">Credit Card</MenuItem>
//                       <MenuItem value="PayPal">PayPal</MenuItem>
//                     </Select>
//                   </TableCell>
//                   <TableCell>
//                     <Checkbox />
//                   </TableCell>
//                   <TableCell>
//                     <Select
//                       defaultValue=""
//                       size="small"
//                       style={{ width: '100%' }}
//                     >
//                       <MenuItem value=""><em>None</em></MenuItem>
//                       <MenuItem value="Rule1">Rule1</MenuItem>
//                       <MenuItem value="Rule2">Rule2</MenuItem>
//                     </Select>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </AccordionDetails>
//       </Accordion>
//     </>
//   );
// };

// export default PaymentMethods;

import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  Box,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import { baseURL } from "../Config";
import { DeleteOutline } from "@mui/icons-material";

const PaymentMethods = () => {
  const [name, setName] = useState("");
  const [reference, setReference] = useState("");
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [defaultAccordionExpanded, setDefaultAccordionExpanded] =
    useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await fetch(`${baseURL}/payment-method`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    };

    fetchPaymentMethods();
  }, []);

  const handleAddData = async () => {
    if (name && reference) {
      const newData = { name, reference };

      try {
        const response = await fetch(`${baseURL}/payment-method`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newData),
        });
        const result = await response.json();
        setData([...data, result]);
        setName("");
        setReference("");
      } catch (error) {
        console.error("Error adding payment method:", error);
      }
    }
  };

  const handleDelete = async () => {
    try {
      for (const index of selectedRows) {
        const paymentMethod = data[index];
        const response = await fetch(
          `${baseURL}/payment-method/${paymentMethod._id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error deleting payment method");
        }
      }

      const newData = data.filter((_, index) => !selectedRows.includes(index));
      setData(newData);
      setSelectedRows([]);
      setOpenDialog(false);
    } catch (error) {
      console.error("Error deleting payment method:", error);
    }
  };

  const handleSetDefault = async (paymentMethodId) => {
    try {
      const response = await fetch(
        `${baseURL}/payment-method/default/${paymentMethodId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const updatedData = data.map((item) =>
          item._id === paymentMethodId
            ? { ...item, isDefault: true }
            : { ...item, isDefault: false }
        );
        setData(updatedData);
      }
    } catch (error) {
      console.error("Error setting default payment method:", error);
    }
  };

  const handleSelectRow = (index) => {
    const newSelectedRows = selectedRows.includes(index)
      ? selectedRows.filter((i) => i !== index)
      : [...selectedRows, index];
    setSelectedRows(newSelectedRows);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Accordion
        expanded={accordionExpanded}
        onChange={(event, isExpanded) => setAccordionExpanded(isExpanded)}
        sx={{ width: accordionExpanded ? "100%" : "50%" }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: "bold" }}>Payment Methods</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            gutterBottom
            border={1}
            padding={1}
            borderRadius={1}
            fontSize="0.875rem"
          >
            <Tooltip title="Help">
              <IconButton>
                <ErrorIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <span>
              Use this page to manage and modify your list of payment methods;
              the ways that you pay for your purchases. Learn more about{" "}
              <a href="#" style={{ color: "blue" }}>
                payment methods.
              </a>
            </span>
          </Typography>
          <div>
            <Typography
              sx={{
                fontWeight: "bold",
                marginTop: 2,
                marginBottom: 2,
                fontSize: "1rem",
              }}
            >
              Add New Payment Method
            </Typography>
            <Box padding="1rem">
              <Box display="flex" alignItems="center" gap="1rem">
                <Typography variant="body2" marginRight="10px">
                  Name
                </Typography>
                <TextField
                  value={name}
                  placeholder="Add payment method name"
                  onChange={(e) => setName(e.target.value)}
                  style={{ width: "30%", marginLeft: 38 }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "30px !important",
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                  size="small"
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                marginBottom="10px"
                gap="1rem"
              >
                <Typography variant="body2" marginRight="10px">
                  Reference
                </Typography>
                <TextField
                  placeholder="Add reference e.g. your card last 4 digits"
                  value={reference}
                  onChange={(e) => setReference(e.target.value)}
                  style={{ width: "30%", marginLeft: 10 }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "30px !important",
                    },
                  }}
                  size="small"
                />
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={handleAddData}
                disabled={!name || !reference}
                // style={{
                //   marginLeft: 470,
                //   fontWeight: 'bold',
                //   background: '#1976d2',
                //   color: 'white',
                //   marginTop: 10,
                // }}
              >
                Add
              </Button>
            </Box>
            <Table style={{ marginTop: "20px", borderRadius: 1 }}>
              <TableHead style={{ backgroundColor: "#dedede" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Reference</TableCell>
                  <TableCell>Default</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        checked={selectedRows.includes(index)}
                        onChange={() => handleSelectRow(index)}
                      />
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.reference}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={item.isDefault}
                        onChange={() => handleSetDefault(item._id)}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={handleDialogOpen}
                        disabled={!selectedRows.includes(index)}
                      >
                        <DeleteOutline
                          color="error"
                          disabled={!selectedRows.includes(index)}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDialogOpen}
              disabled={selectedRows.length === 0}
              style={{ marginTop: "10px" }}
            >
              Delete Selected
            </Button>
            <Dialog open={openDialog} onClose={handleDialogClose}>
              <DialogTitle>Delete Confirmation</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete the selected items? This
                  action is irreversible.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDelete} sx={{ color: "red" }}>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={defaultAccordionExpanded}
        onChange={(event, isExpanded) =>
          setDefaultAccordionExpanded(isExpanded)
        }
        sx={{ width: defaultAccordionExpanded ? "100%" : "50%", mt: 2 }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: "bold" }}>Set User Defaults</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            gutterBottom
            border={1}
            padding={1}
            borderRadius={1}
            fontSize="0.875rem"
          >
            <Tooltip title="Help">
              <IconButton>
                <ErrorIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            Set a{" "}
            <a href="#" style={{ color: "blue" }}>
              default payment method
            </a>{" "}
            for people using your account. When they upload a document, we’ll
            apply that payment method to it.
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="bold">User Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Payment Method</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Default</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Application Rule</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <Select
                      defaultValue=""
                      size="small"
                      style={{   height: "30px !important",width: "100%" }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "30px !important",
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {data.map((method) => (
                        <MenuItem key={method._id} value={method._id}>
                          {method.name} / {method.reference}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={item.isDefault}
                      onChange={() => handleSetDefault(item._id)}
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      defaultValue=""
                      size="small"
                      style={{   height: "30px !important",width: "100%" }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "30px !important",
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Rule1">Rule1</MenuItem>
                      <MenuItem value="Rule2">Rule2</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PaymentMethods;
