import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Select, MenuItem, TextField } from '@mui/material';
import axios from 'axios';

const ExpenseDialog = ({ open, onClose, onConfirm, user, existingClaims }) => {
    const [selectedClaim, setSelectedClaim] = useState('');
    const [endDate, setEndDate] = useState('');
    const [expenseName, setExpenseName] = useState('');
    const [selectedOption, setSelectedOption] = useState('');

    useEffect(() => {
        if (existingClaims.length === 0) {
            setSelectedOption('new');
        }
    }, [existingClaims]);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const handleAddClick = () => {
        onConfirm({ selectedClaim, endDate, expenseName });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle sx={{ color: "#ff5a02" }}>Add To Expense Report</DialogTitle>
            <DialogContent>
                {existingClaims.length > 0 ? (
                    <>
                        <Button
                            variant="outlined"
                            onClick={() => handleOptionChange('existing')}
                            sx={{ borderColor: selectedOption === 'existing' ? 'orange' : 'lightgrey', backgroundColor: selectedOption === 'existing' ? 'lightorange' : 'none', borderRadius: '5px', margin: '5px' }}
                        >
                            Existing Claim
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => handleOptionChange('new')}
                            sx={{ borderColor: selectedOption === 'new' ? 'orange' : 'lightgrey', backgroundColor: selectedOption === 'new' ? 'lightorange' : 'none', borderRadius: '5px', margin: '5px' }}
                        >
                            New Claim
                        </Button>
                    </>
                ) : null}
                {selectedOption === 'new' ? (
                    <>
                        <Typography variant="body1" sx={{ marginTop: '10px' }}>Expense Claim:</Typography>
                        <Typography variant="body1">{user.name}</Typography>
                        <Typography variant="body1" sx={{ marginTop: '10px' }}>End Date:</Typography>
                        <TextField
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            fullWidth
                            sx={{ marginBottom: '10px' }}
                        />
                        <Typography variant="body1">Name:</Typography>
                        <TextField
                            value={expenseName}
                            onChange={(e) => setExpenseName(e.target.value)}
                            fullWidth
                            sx={{ marginBottom: '10px' }}
                        />
                    </>
                ) : (
                    <>
                        <Typography variant="body1" sx={{ marginTop: '10px' }}>Expense Claim:</Typography>
                        <Select
                            value={selectedClaim}
                            onChange={(e) => setSelectedClaim(e.target.value)}
                            fullWidth
                            sx={{ marginBottom: '10px' }}
                        >
                            {existingClaims.map((claim) => (
                                <MenuItem key={claim.id} value={claim.id}>{claim.name}</MenuItem>
                            ))}
                        </Select>
                        <Typography variant="body1">{user.name}</Typography>
                        <Typography variant="body1" sx={{ marginTop: '10px' }}>End Date:</Typography>
                        <TextField
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            fullWidth
                            sx={{ marginBottom: '10px' }}
                        />
                        <Typography variant="body1">Name:</Typography>
                        <TextField
                            value={expenseName}
                            onChange={(e) => setExpenseName(e.target.value)}
                            fullWidth
                            sx={{ marginBottom: '10px' }}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ background: "none", '&:hover': { backgroundColor: 'lightgrey' } }}>
                    Cancel
                </Button>
                <Button onClick={handleAddClick} sx={{ borderRadius: '5px', backgroundColor: 'green', '&:hover': { borderColor: 'green' } }}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExpenseDialog;
