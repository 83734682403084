import React, { useState } from "react";
import { Button, Toolbar, Tooltip, IconButton } from "@mui/material";

import TableComponent from "../components/Table";
import { baseURL } from '../Config';
import TableExtendedToolTip from "../costComponents/utils/TableExtendedToolTip";
function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}
        >
            <div className="flex justify-between items-center w-full">
                <div>
                    <Button
                        disabled={numSelected === 0}
                        variant="contained"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            fontWeight: "bold",
                            backgroundColor: "#2196f3",
                            color: "white",
                            '&.MuiButton-root:hover': { bgcolor: '#46b99d', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' },

                        }}
                    >
                        Boost My Items
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            backgroundColor: "#2196f3",
                            color: "white",
                            fontWeight: "bold",
                            '&.MuiButton-root:hover': { bgcolor: '#46b99d', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' },
                            "&.Mui-disabled": {
                                background: "#46b99d",
                                color: "black"
                            }

                        }}
                    >
                        Move All Items To Inbox
                    </Button>
                </div>
                <TableExtendedToolTip />
            </div>
        </Toolbar>
    );
}

const SalesProcessing = () => {
    const [selected, setSelected] = useState([]);
    return (
        <>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableComponent selected={selected} setSelected={setSelected} />
        </>
    )
}

export default SalesProcessing