// import * as React from 'react';
// import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Box from '@mui/material/Box';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import { IconButton, Tooltip } from '@mui/material';
// import ErrorIcon from '@mui/icons-material/Error';
// import TableContainer from '@mui/material/TableContainer';
// import Table from '@mui/material/Table';
// import TableHead from '@mui/material/TableHead';
// import TableBody from '@mui/material/TableBody';
// import TableRow from '@mui/material/TableRow';
// import TableCell from '@mui/material/TableCell';
// import Paper from '@mui/material/Paper';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import { baseURL } from '../Config';

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
//     </div>
//   );
// }

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };


// export default function BankAccounts() {
//   const [value, setValue] = React.useState(0);
//   const [bankInfo, setBankInfo] = React.useState({
//     bank: '',
//     accountName: '',
//     accountNumber: '',
//     currency: '',
//   });
//   const [bankAccounts, setBankAccounts] = React.useState([]);
//   const [editIndex, setEditIndex] = React.useState(-1);
//   const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
//   const [deleteIndex, setDeleteIndex] = React.useState(-1);
//   const [accordionExpanded, setAccordionExpanded] = React.useState(false);
//   const [manuallyAccordionExpanded, setManuallyAccordionExpanded] = React.useState(false);


//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };


//   const handleInputChange = (prop) => (event) => {
//     setBankInfo({ ...bankInfo, [prop]: event.target.value });
//   };


//   const handleAddBankAccount = () => {

//     if (!bankInfo.bank || !bankInfo.accountName || !bankInfo.accountNumber || !bankInfo.currency) {
//       alert('Please fill in all details.');
//       return;
//     }


//     const newAccount = { ...bankInfo };
//     setBankAccounts([...bankAccounts, newAccount]);

//     setBankInfo({ bank: '', accountName: '', accountNumber: '', currency: '' });
//   };


//   const handleEditBankAccount = () => {
//     if (editIndex !== -1) {
//       const updatedAccounts = [...bankAccounts];
//       updatedAccounts[editIndex] = { ...bankInfo };
//       setBankAccounts(updatedAccounts);
//       setEditIndex(-1);
//       setBankInfo({ bank: '', accountName: '', accountNumber: '', currency: '' });
//     }
//   };


//   const handleDeleteBankAccount = () => {
//     if (deleteIndex !== -1) {
//       const updatedAccounts = bankAccounts.filter((_, index) => index !== deleteIndex);
//       setBankAccounts(updatedAccounts);
//       setDeleteIndex(-1);
//       setDeleteDialogOpen(false);
//     }
//   };


//   const handleOpenDeleteDialog = (index) => {
//     setDeleteIndex(index);
//     setDeleteDialogOpen(true);
//   };


//   const handleCloseDeleteDialog = () => {
//     setDeleteIndex(-1);
//     setDeleteDialogOpen(false);
//   };
//   const handleAccordionChange = (isExpanded) => {
//     setAccordionExpanded(isExpanded);
//   };

//   const handleManuallyAccordionChange = (isExpanded) => {
//     setManuallyAccordionExpanded(isExpanded);
//   };

//   return (
//     <Box sx={{ width: '100%' }}>

//       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//         <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
//           <Tab label="Manually Added Accounts"  sx={{fontWeight:"bold"}}/>

//         </Tabs>
//       </Box>


//       <CustomTabPanel value={value} index={0}>
//         <Typography gutterBottom sx={{ border: 1, padding: 0, borderRadius: 1, fontSize: 12, }}>
//           <Tooltip title="Help">
//             <IconButton>
//               <ErrorIcon sx={{color:"#1976d2"}}/>
//             </IconButton>
//           </Tooltip>
//           You can manage bank accounts for Bank Statement Extraction here.
//         </Typography>

//         <Accordion expanded={accordionExpanded}
//           onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}
//           sx={{ width: accordionExpanded ? '100%' : '50%' }}>
//           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//             <Typography sx={{fontWeight:"bold"}}>Add Bank Account</Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Box sx={{ width: '100%' }}>
//               <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                 <Typography sx={{ minWidth: 120, fontSize: 12 }}>Bank Name:</Typography>
//                 <TextField
//                   sx={{ width: '30%', fontSize: 12, height: '40px' }}
//                   select
//                   value={bankInfo.bank}
//                   onChange={handleInputChange('bank')}
//                   variant="outlined"
//                   SelectProps={{
//                     displayEmpty: true,
//                   }}
//                   size="small"
//                 >
//                   <MenuItem value="">Select Bank</MenuItem>
//                   <MenuItem value="bank1">Bank 1</MenuItem>
//                   <MenuItem value="bank2">Bank 2</MenuItem>
//                   <MenuItem value="bank3">Bank 3</MenuItem>
//                 </TextField>
//               </Box>
//               <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                 <Typography sx={{ minWidth: 120, fontSize: 12 }}>Account Name:</Typography>
//                 <TextField
//                   sx={{ width: '30%', fontSize: 12, height: '40px' }}
//                   value={bankInfo.accountName}
//                   onChange={handleInputChange('accountName')}
//                   variant="outlined"
//                   placeholder="Enter Account Name"
//                   size="small"
//                 />
//               </Box>
//               <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                 <Typography sx={{ minWidth: 120, fontSize: 12 }}>Account Number:</Typography>
//                 <TextField
//                   sx={{ width: '30%', fontSize: 12, height: '40px' }}
//                   value={bankInfo.accountNumber}
//                   onChange={handleInputChange('accountNumber')}
//                   variant="outlined"
//                   placeholder="Enter Account Number"
//                   size="small"
//                 />
//               </Box>
//               <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                 <Typography sx={{ minWidth: 120, fontSize: 12 }}>Currency:</Typography>
//                 <TextField
//                   sx={{ width: '30%', fontSize: 12, height: '40px' }}
//                   select
//                   value={bankInfo.currency}
//                   onChange={handleInputChange('currency')}
//                   variant="outlined"
//                   SelectProps={{
//                     displayEmpty: true,
//                   }}
//                   size="small"
//                 >
//                   <MenuItem value="">Select Currency</MenuItem>
//                   <MenuItem value="usd">USD</MenuItem>
//                   <MenuItem value="eur">EUR</MenuItem>
//                   <MenuItem value="gbp">GBP</MenuItem>
//                 </TextField>
//               </Box>
//               {editIndex === -1 ? (
//                 <Button variant="contained" color="primary" onClick={handleAddBankAccount} sx={{ marginLeft: 15 }}>
//                   Add
//                 </Button>
//               ) : (
//                 <Button variant="contained" color="primary" onClick={handleEditBankAccount}>
//                   Update
//                 </Button>
//               )}
//             </Box>
//           </AccordionDetails>
//         </Accordion>


//         <Accordion expanded={manuallyAccordionExpanded}
//           onChange={(event, isExpanded) => handleManuallyAccordionChange(isExpanded)}
//           sx={{ width: manuallyAccordionExpanded ? '100%' : '50%', mt: 2 }} >
//           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//             <Typography sx={{fontWeight:"bold"}}>Manually Added Accounts</Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <TableContainer component={Paper} sx={{ width: '100%' }}>
//               <Table size="small">
//                 <TableHead>
//                   <TableRow>
//                     <TableCell sx={{ fontWeight: 'bold' }}>Bank</TableCell>
//                     <TableCell sx={{ fontWeight: 'bold' }}>Account Name</TableCell>
//                     <TableCell sx={{ fontWeight: 'bold' }}>Account Number</TableCell>
//                     <TableCell sx={{ fontWeight: 'bold' }}>Currency</TableCell>
//                     <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {bankAccounts.map((account, index) => (
//                     <TableRow key={index}>
//                       <TableCell>{account.bank}</TableCell>
//                       <TableCell>{account.accountName}</TableCell>
//                       <TableCell>{account.accountNumber}</TableCell>
//                       <TableCell>{account.currency}</TableCell>
//                       <TableCell>
//                         <IconButton onClick={() => handleOpenDeleteDialog(index)} size="small">
//                           <DeleteIcon fontSize="small" sx={{color:"#ff5a02"}}/>
//                         </IconButton>
//                         <IconButton onClick={() => {
//                           setEditIndex(index);
//                           setBankInfo({
//                             bank: account.bank,
//                             accountName: account.accountName,
//                             accountNumber: account.accountNumber,
//                             currency: account.currency,
//                           });
//                         }} size="small">
//                           <EditIcon fontSize="small" sx={{color:"#1975d2"}}/>
//                         </IconButton>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </AccordionDetails>
//         </Accordion>
//       </CustomTabPanel>


//       <CustomTabPanel value={value} index={1}>
//         <Typography gutterBottom sx={{ border: 1, padding: 2, borderRadius: 1, fontSize: 12 }}>
//           <Tooltip title="Help">
//             <IconButton>
//               <ErrorIcon />
//             </IconButton>
//           </Tooltip>
//           The bank accounts on this page are automatically added/deleted when you connect/disconnect a Bank Feed.
//           <br />
//           <p>Note: These bank accounts cannot be used for Bank Statement Extraction. To upload statements, please create a bank account in the "Manually Added Accounts" section.</p>
//         </Typography>

//         <TableContainer component={Paper}>
//           <Table size="small">
//             <TableHead>
//               <TableRow>
//                 <TableCell sx={{ fontWeight: 'bold' }}>Bank</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold' }}>Account Name</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold' }}>Account Number</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold' }}>Currency</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {bankAccounts.map((account, index) => (
//                 <TableRow key={index}>
//                   <TableCell>{account.bank}</TableCell>
//                   <TableCell>{account.accountName}</TableCell>
//                   <TableCell>{account.accountNumber}</TableCell>
//                   <TableCell>{account.currency}</TableCell>
//                   <TableCell>
//                     <IconButton onClick={() => handleOpenDeleteDialog(index)} size="small">
//                       <DeleteIcon fontSize="small" />
//                     </IconButton>
//                     <IconButton onClick={() => {
//                       setEditIndex(index);
//                       setValue(0);
//                       setBankInfo({
//                         bank: account.bank,
//                         accountName: account.accountName,
//                         accountNumber: account.accountNumber,
//                         currency: account.currency,
//                       });
//                     }} size="small">
//                       <EditIcon fontSize="small" />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </CustomTabPanel>


//       <Dialog
//         open={deleteDialogOpen}
//         onClose={handleCloseDeleteDialog}
//       >
//         <DialogTitle>Delete Bank Account</DialogTitle>
//         <DialogContent>Are you sure you want to delete this bank account?</DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
//           <Button onClick={handleDeleteBankAccount} color="primary">Delete</Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// // }
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Tabs, Tab, Box, Accordion, AccordionSummary, AccordionDetails,
//   Typography, Button, TextField, MenuItem, IconButton, Tooltip,
//   TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Dialog,
//   DialogTitle, DialogContent, DialogActions
// } from '@mui/material';
// import {
//   ExpandMore as ExpandMoreIcon,
//   Error as ErrorIcon,
//   Delete as DeleteIcon,
//   Edit as EditIcon
// } from '@mui/icons-material';

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
//     </div>
//   );
// }

// export default function BankAccounts() {
//   const [value, setValue] = useState(0);
//   const [bankInfo, setBankInfo] = useState({
//     bank: '',
//     accountName: '',
//     accountNumber: '',
//     currency: '',
//   });
//   const [bankAccounts, setBankAccounts] = useState([]);
//   const [editIndex, setEditIndex] = useState(-1);
//   const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
//   const [deleteIndex, setDeleteIndex] = useState(-1);
//   const [accordionExpanded, setAccordionExpanded] = useState(false);
//   const [manuallyAccordionExpanded, setManuallyAccordionExpanded] = useState(false);

//   // Fetch bank accounts when the component mounts
//   useEffect(() => {
//     const fetchBankAccounts = async () => {
//       try {
//         const token = localStorage.getItem('token');

//         const response = await axios.get('${baseURL}/bank', {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         setBankAccounts(response.data);

//       } catch (error) {
//         console.error('Error fetching bank accounts:', error.response ? error.response.data : error.message);
//         alert('There was an error fetching the bank accounts. Please try again.');
//       }
//     };

//     fetchBankAccounts();
//   }, []); // Runs once when the component mounts

//   const handleChange = (event, newValue) => setValue(newValue);

//   const handleInputChange = (prop) => (event) => {
//     setBankInfo({ ...bankInfo, [prop]: event.target.value });
//   };

//   const handleAddBankAccount = async () => {
//     if (!bankInfo.bank || !bankInfo.accountName || !bankInfo.accountNumber || !bankInfo.currency) {
//       alert('Please fill in all details.');
//       return;
//     }

//     try {
//       const token = localStorage.getItem('token');

//       // Make the API request to add a new bank account
//       const response = await axios.post('${baseURL}/bank', {
//         bankName: bankInfo.bank,
//         accountName: bankInfo.accountName,
//         accountNumber: bankInfo.accountNumber,
//         currency: bankInfo.currency,
//       }, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       const newAccount = response.data;

//       // Update the state with the new bank account and re-render the table
//       setBankAccounts([...bankAccounts, newAccount]);

//       // Clear the form
//       setBankInfo({ bank: '', accountName: '', accountNumber: '', currency: '' });

//     } catch (error) {
//       console.error('Error adding bank account:', error.response ? error.response.data : error.message);
//       alert('There was an error adding the bank account. Please try again.');
//     }
//   };

//   const handleEditBankAccount = async () => {
//     if (editIndex === -1) return; // If no account is selected for editing, exit the function

//     try {
//       const token = localStorage.getItem('token');
//       const accountToEdit = bankAccounts[editIndex];

//       // Prepare the payload with only the updated fields
//       const payload = {
//         bankName: bankInfo.bank,
//         accountName: bankInfo.accountName,
//         accountNumber: bankInfo.accountNumber,
//         currency: bankInfo.currency,
//       };

//       // Send the PATCH request
//       const response = await axios.patch(`${baseURL}/bank/${accountToEdit._id}`, payload, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       // Update the bank accounts list with the edited account
//       const updatedAccounts = bankAccounts.map((account, index) =>
//         index === editIndex ? response.data : account
//       );
//       setBankAccounts(updatedAccounts);

//       // Clear the form and reset edit index
//       setBankInfo({ bank: '', accountName: '', accountNumber: '', currency: '' });
//       setEditIndex(-1);

//     } catch (error) {
//       console.error('Error editing bank account:', error.response?.data || error.message);
//       alert('Error editing bank account. Please try again.');
//     }
//   };

//   const handleDeleteBankAccount = async () => {
//     if (deleteIndex !== -1) {
//       try {
//         const token = localStorage.getItem('token');
//         const accountToDelete = bankAccounts[deleteIndex];

//         // Make the API request to delete the bank account
//         await axios.delete(`${baseURL}/bank/${accountToDelete._id}`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         // Update the state to remove the deleted bank account
//         const updatedAccounts = bankAccounts.filter((_, index) => index !== deleteIndex);
//         setBankAccounts(updatedAccounts);
//         setDeleteIndex(-1);
//         setDeleteDialogOpen(false);

//       } catch (error) {
//         console.error('Error deleting bank account:', error.response ? error.response.data : error.message);
//         alert('There was an error deleting the bank account. Please try again.');
//       }
//     }
//   };

//   const handleOpenDeleteDialog = (index) => {
//     setDeleteIndex(index);
//     setDeleteDialogOpen(true);
//   };

//   const handleCloseDeleteDialog = () => {
//     setDeleteIndex(-1);
//     setDeleteDialogOpen(false);
//   };

//   const handleAccordionChange = (isExpanded) => setAccordionExpanded(isExpanded);

//   const handleManuallyAccordionChange = (isExpanded) => setManuallyAccordionExpanded(isExpanded);

//   return (
//     <Box sx={{ width: '100%' }}>
//       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//         <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
//           <Tab label="Manually Added Accounts" sx={{ fontWeight: "bold" }} />
//         </Tabs>
//       </Box>

//       <CustomTabPanel value={value} index={0}>
//         <Typography gutterBottom sx={{ border: 1, padding: 1, borderRadius: 1, fontSize: 12 }}>
//           <Tooltip title="Help">
//             <IconButton>
//               <ErrorIcon sx={{ color: "#1976d2" }} />
//             </IconButton>
//           </Tooltip>
//           You can manage bank accounts for Bank Statement Extraction here.
//         </Typography>

//         <Accordion expanded={accordionExpanded}
//           onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}
//           sx={{ width: '100%' }}>
//           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//             <Typography sx={{ fontWeight: "bold" }}>Add Bank Account</Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Box sx={{ width: '100%' }}>
//               <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                 <Typography sx={{ minWidth: 120, fontSize: 12, textAlign: 'right', pr: 2 }}>Bank Name:</Typography>
//                 <TextField
//                   sx={{ width: '60%', fontSize: 12 }}
//                   select
//                   value={bankInfo.bank}
//                   onChange={handleInputChange('bank')}
//                   variant="outlined"
//                   size="small"
//                   SelectProps={{ displayEmpty: true }}
//                 >
//                   <MenuItem value="">Select Bank</MenuItem>
//                   {bankAccounts.map((account) => (
//                     <MenuItem key={account._id} value={account.bankName}>{account.bankName}</MenuItem>
//                   ))}
//                 </TextField>
//               </Box>
//               <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                 <Typography sx={{ minWidth: 120, fontSize: 12, textAlign: 'right', pr: 2 }}>Account Name:</Typography>
//                 <TextField
//                   sx={{ width: '60%', fontSize: 12 }}
//                   value={bankInfo.accountName}
//                   onChange={handleInputChange('accountName')}
//                   variant="outlined"
//                   placeholder="Enter Account Name"
//                   size="small"
//                 />
//               </Box>
//               <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                 <Typography sx={{ minWidth: 120, fontSize: 12, textAlign: 'right', pr: 2 }}>Account Number:</Typography>
//                 <TextField
//                   sx={{ width: '60%', fontSize: 12 }}
//                   value={bankInfo.accountNumber}
//                   onChange={handleInputChange('accountNumber')}
//                   variant="outlined"
//                   placeholder="Enter Account Number"
//                   size="small"
//                 />
//               </Box>
//               <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                 <Typography sx={{ minWidth: 120, fontSize: 12, textAlign: 'right', pr: 2 }}>Currency:</Typography>
//                 <TextField
//                   sx={{ width: '60%', fontSize: 12 }}
//                   select
//                   value={bankInfo.currency}
//                   onChange={handleInputChange('currency')}
//                   variant="outlined"
//                   size="small"
//                   SelectProps={{ displayEmpty: true }}
//                 >
//                   <MenuItem value="">Select Currency</MenuItem>
//                   {bankAccounts.map((account) => (
//                     <MenuItem key={account._id} value={account.currency}>{account.currency}</MenuItem>
//                   ))}
//                 </TextField>
//               </Box>
//               {editIndex === -1 ? (
//                 <Button variant="contained" color="primary" onClick={handleAddBankAccount} sx={{ ml: 14 }}>
//                   Add
//                 </Button>
//               ) : (
//                 <Button variant="contained" color="primary" onClick={handleEditBankAccount} sx={{ ml: 14 }}>
//                   Update
//                 </Button>
//               )}
//             </Box>
//           </AccordionDetails>
//         </Accordion>

//         <Accordion expanded={manuallyAccordionExpanded}
//           onChange={(event, isExpanded) => handleManuallyAccordionChange(isExpanded)}
//           sx={{ width: '100%', mt: 2 }}>
//           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//             <Typography sx={{ fontWeight: "bold" }}>Manually Added Accounts</Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <TableContainer component={Paper} sx={{ width: '100%' }}>
//               <Table size="small">
//                 <TableHead>
//                   <TableRow>
//                     <TableCell sx={{ fontWeight: 'bold' }}>Bank</TableCell>
//                     <TableCell sx={{ fontWeight: 'bold' }}>Account Name</TableCell>
//                     <TableCell sx={{ fontWeight: 'bold' }}>Account Number</TableCell>
//                     <TableCell sx={{ fontWeight: 'bold' }}>Currency</TableCell>
//                     <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {bankAccounts.map((account, index) => (
//                     <TableRow key={index}>
//                       <TableCell>{account.bankName}</TableCell>
//                       <TableCell>{account.accountName}</TableCell>
//                       <TableCell>{account.accountNumber}</TableCell>
//                       <TableCell>{account.currency}</TableCell>
//                       <TableCell>
//                         <IconButton onClick={() => {
//                           setEditIndex(index);
//                           setBankInfo({
//                             bank: account.bankName,
//                             accountName: account.accountName,
//                             accountNumber: account.accountNumber,
//                             currency: account.currency,
//                           });
//                         }} size="small">
//                           <EditIcon fontSize="small" sx={{ color: "#1975d2" }} />
//                         </IconButton>
//                         <IconButton onClick={() => handleOpenDeleteDialog(index)} size="small">
//                           <DeleteIcon fontSize="small" sx={{ color: "#ff5a02" }} />
//                         </IconButton>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </AccordionDetails>
//         </Accordion>
//       </CustomTabPanel>

//       <Dialog
//         open={deleteDialogOpen}
//         onClose={handleCloseDeleteDialog}
//       >
//         <DialogTitle>Delete Bank Account</DialogTitle>
//         <DialogContent>Are you sure you want to delete this bank account?</DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
//           <Button onClick={handleDeleteBankAccount} color="primary">Delete</Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, Accordion, AccordionSummary, AccordionDetails,
  Typography, Button, TextField, MenuItem, IconButton, Tooltip,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Dialog,
  DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Error as ErrorIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@mui/icons-material';

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

export default function BankAccounts() {
  const [bankInfo, setBankInfo] = useState({
    bankName: '',
    accountName: '',
    accountNumber: '',
    currency: '',
  });
  const [bankAccounts, setBankAccounts] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [manuallyAccordionExpanded, setManuallyAccordionExpanded] = useState(false);

  // Fetch bank accounts when the component mounts
  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${baseURL}/bank`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBankAccounts(response.data);
      } catch (error) {
        console.error('Error fetching bank accounts:', error.response ? error.response.data : error.message);
        alert('There was an error fetching the bank accounts. Please try again.');
      }
    };
    fetchBankAccounts();
  }, []); // Runs once when the component mounts

  // Handle input changes
  const handleInputChange = (event, type) => {
    console.log("event", event, type);
    setBankInfo({ ...bankInfo, [type]: event });
  };
  console.log("dasdasdsadsdas", bankInfo);
  // Handle selecting a bank from the dropdown
  const handleBankSelect = (event) => {
    const selectedBankName = event.target.value;
    const selectedBank = bankAccounts.find(account => account.bankName === selectedBankName);

    if (selectedBank) {
      setBankInfo({
        ...bankInfo,
        bank: selectedBank.bankName,
        currency: selectedBank.currency, // Automatically set currency based on bank selection
      });
    } else {
      setBankInfo({ ...bankInfo, bank: selectedBankName, currency: '' });
    }
  };

  // Handle adding a new bank account
  const handleAddBankAccount = async () => {
    if (!bankInfo.bankName || !bankInfo.accountName || !bankInfo.accountNumber || !bankInfo.currency) {
      alert('Please fill in all details.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${baseURL}/bank`, {
        bankName: bankInfo.bankName,
        accountName: bankInfo.accountName,
        accountNumber: bankInfo.accountNumber,
        currency: bankInfo.currency,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update state and clear form
      setBankAccounts([...bankAccounts, response.data]);
      setBankInfo({ bankName: '', accountName: '', accountNumber: '', currency: '' });

    } catch (error) {
      console.error('Error adding bank account:', error.response ? error.response.data : error.message);
      alert('There was an error adding the bank account. Please try again.');
    }
  };

  const handleEditBankAccount = async () => {
    if (editIndex === -1) return;

    try {
      const token = localStorage.getItem('token');
      const accountToEdit = bankAccounts[editIndex];

      const payload = {
        bankName: bankInfo.bankName,
        accountName: bankInfo.accountName,
        accountNumber: bankInfo.accountNumber,
        currency: bankInfo.currency,
      };

      const response = await axios.patch(`${baseURL}/bank/${accountToEdit._id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const updatedAccounts = bankAccounts.map((account, index) =>
        index === editIndex ? response.data : account
      );
      setBankAccounts(updatedAccounts);
      setBankInfo({ bank: '', accountName: '', accountNumber: '', currency: '' });
      setEditIndex(-1);

    } catch (error) {
      console.error('Error editing bank account:', error.response?.data || error.message);
      alert('Error editing bank account. Please try again.');
    }
  };

  const handleDeleteBankAccount = async () => {
    if (deleteIndex !== -1) {
      try {
        const token = localStorage.getItem('token');
        const accountToDelete = bankAccounts[deleteIndex];

        await axios.delete(`${baseURL}/bank/${accountToDelete._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const updatedAccounts = bankAccounts.filter((_, index) => index !== deleteIndex);
        setBankAccounts(updatedAccounts);
        setDeleteIndex(-1);
        setDeleteDialogOpen(false);

      } catch (error) {
        console.error('Error deleting bank account:', error.response ? error.response.data : error.message);
        alert('There was an error deleting the bank account. Please try again.');
      }
    }
    setBankInfo({ bankName: '', accountName: '', accountNumber: '', currency: '' });
    setEditIndex(-1);
  };

  const handleOpenDeleteDialog = (index) => {
    setDeleteIndex(index);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteIndex(-1);
    setDeleteDialogOpen(false);
  };

  const handleAccordionChange = (isExpanded) => setAccordionExpanded(isExpanded);
  const handleManuallyAccordionChange = (isExpanded) => setManuallyAccordionExpanded(isExpanded);

  return (
    <Box sx={{ width: '100%' }}>
      <CustomTabPanel value={0} index={0}>
        <Typography gutterBottom sx={{ border: 1, padding: 1, borderRadius: 1, fontSize: 14 }}>
          <Tooltip title="Help">
            <IconButton>
              <ErrorIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          </Tooltip>
          You can manage bank accounts for Bank Statement Extraction here.
        </Typography>

        <Accordion expanded={accordionExpanded} onChange={(event, isExpanded) => handleAccordionChange(isExpanded)} sx={{ width: '100%', padding: 3 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "bold" }}>Add Bank Account</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography sx={{ minWidth: 140, fontSize: 14, textAlign: 'left', pr: 2 }}>Bank Name:</Typography>
                <TextField
                  sx={{
                    width: '30%', fontSize: 12,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: 'black', // Remove border on hover
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent', // Remove blue outline when focused

                      },
                    },
                  }}
                  // select
                  value={bankInfo.bankName}
                  onChange={(e) => handleInputChange(e.target.value, 'bankName')} // Use the new handler
                  variant="outlined"
                  size="small"
                // SelectProps={{ displayEmpty: true }}
                >
                  {/* <MenuItem value="">Select Bank</MenuItem>
                  {bankAccounts.map((account) => (
                    <MenuItem key={account._id} value={account.bankName}>{account.bankName}</MenuItem>
                  ))} */}
                </TextField>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography sx={{ minWidth: 140, fontSize: 14, textAlign: 'left', pr: 2 }}>Currency:</Typography>
                <TextField
                  sx={{
                    width: '30%', fontSize: 12,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: 'black', // Remove border on hover
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent', // Remove blue outline when focused

                      },
                    },
                  }}
                  value={bankInfo.currency}
                  onChange={(e) => handleInputChange(e.target.value, 'currency')}
                  variant="outlined"
                  size="small"
                // disabled // Disable the currency field as it's auto-filled based on the selected bank
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography sx={{ minWidth: 140, fontSize: 14, textAlign: 'left', pr: 2 }}>Account Name:</Typography>
                <TextField
                  sx={{
                    width: '30%', fontSize: 12,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: 'black', // Remove border on hover
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent', // Remove blue outline when focused

                      },
                    },
                  }}
                  value={bankInfo.accountName}
                  onChange={(e) => handleInputChange(e.target.value, 'accountName')}
                  variant="outlined"
                  placeholder="Enter Account Name"
                  size="small"
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography sx={{ minWidth: 140, fontSize: 14, textAlign: 'left', pr: 2 }}>Account Number:</Typography>
                <TextField
                  sx={{
                    width: '30%', fontSize: 14,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: 'black', // Remove border on hover
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent', // Remove blue outline when focused

                      },
                    },
                  }}
                  value={bankInfo.accountNumber}
                  onChange={(e) => handleInputChange(e.target.value, 'accountNumber')}
                  variant="outlined"
                  placeholder="Enter Account Number"
                  size="small"
                />
              </Box>
              {editIndex === -1 ? (
                <Button variant="contained" color="primary" onClick={handleAddBankAccount} sx={{ mt: 1, ml: 18, padding: '0.5rem 1.5rem' }}>
                  Add
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={handleEditBankAccount} sx={{ ml: 18, mt: 1, padding: '0.5rem 1.5rem' }}>
                  Update
                </Button>
              )}
            </Box>
            <hr className='mt-6' />
            <Box sx={{ mt: 5 }}>
              <Typography sx={{ fontWeight: "bold" }}>Manually Added Accounts</Typography>
              <TableContainer component={Paper} sx={{ width: '100%', mt: 5 }}>
                <Table size="medium">
                  <TableHead >
                    <TableRow >
                      <TableCell sx={{ fontWeight: 'bold' }}>Bank</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Account Name</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Account Number</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Currency</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bankAccounts.map((account, index) => (
                      <TableRow key={index}>
                        <TableCell>{account.bankName}</TableCell>
                        <TableCell>{account.accountName}</TableCell>
                        <TableCell>{account.accountNumber}</TableCell>
                        <TableCell>{account.currency}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => {
                            setEditIndex(index);
                            setBankInfo({
                              bankName: account.bankName,
                              accountName: account.accountName,
                              accountNumber: account.accountNumber,
                              currency: account.currency,
                            });
                          }} size="small">
                            <EditIcon fontSize="small" sx={{ color: "#1975d2" }} />
                          </IconButton>
                          <IconButton onClick={() => handleOpenDeleteDialog(index)} size="small">
                            <DeleteIcon fontSize="small" sx={{ color: "#ff5a02" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion expanded={manuallyAccordionExpanded} onChange={(event, isExpanded) => handleManuallyAccordionChange(isExpanded)} sx={{ width: '100%', mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "bold" }}>Manually Added Accounts</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} sx={{ width: '100%' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Bank</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Account Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Account Number</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Currency</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bankAccounts.map((account, index) => (
                    <TableRow key={index}>
                      <TableCell>{account.bankName}</TableCell>
                      <TableCell>{account.accountName}</TableCell>
                      <TableCell>{account.accountNumber}</TableCell>
                      <TableCell>{account.currency}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => {
                          setEditIndex(index);
                          setBankInfo({
                            bank: account.bankName,
                            accountName: account.accountName,
                            accountNumber: account.accountNumber,
                            currency: account.currency,
                          });
                        }} size="small">
                          <EditIcon fontSize="small" sx={{ color: "#1975d2" }} />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteDialog(index)} size="small">
                          <DeleteIcon fontSize="small" sx={{ color: "#ff5a02" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion> */}
      </CustomTabPanel>

      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Bank Account</DialogTitle>
        <DialogContent>Are you sure you want to delete this bank account?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button className='hover:bg-[#1976D2]' onClick={handleDeleteBankAccount} color="primary">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
