import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HeaderUserInfo from './HeaderUserInfo';
import img1 from './../assets/img1.jpg';
import { baseURL } from '../../Config';
import { HeaderStepContext } from './context';


const token = localStorage.getItem('token');

const About = () => {
    const navigate = useNavigate();
    const [selectedBox, setSelectedBox] = useState(null);
    const [errors, setErrors] = useState({});
    const { setActiveStep } = useContext(HeaderStepContext);


    useEffect(() => {
        setActiveStep(1)
    }
        , []);

    const handleContinue = async () => {
        if (selectedBox === null) {
            setErrors({ apiError: 'Please select a box.' });
            return;
        }

        const payload = {
            company_size: selectedBox,
        };

        try {
            console.log(JSON.parse(localStorage.getItem('userInfo')));

            console.log(token, 'ttt');


            const response = await fetch(`${baseURL}/user/profile/${JSON.parse(localStorage.getItem('userInfo')).userId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                navigate('/role');
            } else {
                const errorData = await response.json();
                setErrors({ apiError: errorData.message || 'Update failed' });
                console.error('Update failed:', errorData);
            }
        } catch (error) {
            setErrors({ apiError: 'An error occurred while updating. Please try again.' });
            console.error('Error during update:', error);
        }
    };


    const handleBoxClick = (index) => {
        setSelectedBox(index);
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
    };

    const leftSectionStyle = {
        flex: 1,
        padding: '20px',
        marginLeft: "50px",
        zIndex: '10',
    };

    const rightSectionStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        position: 'relative',
        zIndex: '5',
        marginTop: '-152px',
        backgroundColor: '#edf8f5'
    };

    const imgStyle = {
        maxWidth: '100%',
        height: '91vh',
        objectFit: 'cover',
        borderRadius: '10px',
    };

    const boxes = [
        { content: '1' },
        { content: '2-9' },
        { content: '10-24' },
        { content: '25-49' },
        { content: '50-99' },
        { content: '100-250' },
        { content: '250+' },
    ];

    const boxStyle = (index) => ({
        height: '50px',
        width: '110px',
        border: `2px solid #bababa `,
        backgroundColor: selectedBox === index ? '#f7efad' : '#f5f5f2',
        margin: '8px',
        padding: '10px',
        fontSize: '20px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
    });

    return (
        <div>
            <HeaderUserInfo />
            <div style={containerStyle}>
                <div style={leftSectionStyle}>
                    <h1 style={{ fontWeight: 'bold', fontSize: '40px', marginBottom: '12px' }}>About you and your company</h1>
                    <p>Tell us a bit more so that we can personalize your experience.</p>
                    <h4 style={{ fontWeight: 'bold', fontSize: '24px', marginBottom: '5px', marginTop: '28px' }}>How many people work in your company?</h4>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '-8px' }}>
                        {boxes.map((box, index) => (
                            <div
                                key={index}
                                style={boxStyle(index)}
                                onClick={() => handleBoxClick(index)}
                            >
                                {box.content}
                            </div>
                        ))}
                    </div>
                    {errors.apiError && <div className='text-red-500 mb-4'>{errors.apiError}</div>}
                    <Button
                        variant="contained"
                        sx={{
                            marginTop: '35px',
                            backgroundColor: '#1976d2',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            color: 'white',
                            textTransform: 'none',

                        }}
                        onClick={handleContinue}
                    >
                        Continue
                    </Button>
                </div>
                {/* <div style={rightSectionStyle}>
                    <img src={img1} alt="img1" style={imgStyle} />
                </div> */}
            </div>
        </div>
    );
};

export default About;
