// theme.js
import { createTheme } from '@mui/material/styles';
import { height } from '@mui/system';

// Define your primary and secondary colors
const theme = createTheme({
  palette: {
    primary: {
      main: '#0d417b', // Set your primary color
    },
    secondary: {
      main: '#dc004e', // Set your secondary color
    },
  },
  root: {
    '& .MuiInputBase-root.Mui-focused': {
      outline: 'none', // Removes outline
      boxShadow: 'none', // Removes any shadow
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: 'transparent', // Removes the border color for focused state
    },
  },
  components: {
    MuiInputBase: {
      '& .MuiInputBase-root.Mui-focused': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#0d417b',
          padding: '6px 15px',
          fontSize: '12px',
          fontWeight: 'bold',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#1565c0',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          height: '40px',
          padding: 0,
          border: 0,
          outline: 'none',
          boxShadow: 'none',
          marginBottom: '10px',
        },
      },
    },
    '& .MuiInputBase-root': {
      height: '35px',
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '40px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: '40px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: '30px',
        },
      },
    },
  },
});

export default theme;
