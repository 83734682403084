import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HeaderUserInfo from './HeaderUserInfo';
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import RedeemIcon from '@mui/icons-material/Redeem';
import logo1 from '../assets/logo1.png';
import logo2 from '../assets/logo2.png';
import logo3 from '../assets/logo3.png';
import logo4 from '../assets/logo4.png';
import logo5 from '../assets/logo5.png';
import logo6 from '../assets/logo6.png';
import img4 from './../assets/img4.jpg';
import {baseURL} from '../../Config';

const token = localStorage.getItem('token');

const AccountSoft = () => {
    const navigate = useNavigate();
    const [selectedBoxes, setSelectedBoxes] = useState([]);
    const [errors, setErrors] = useState({});
  
  
    const handleContinue = async () => {
      if (selectedBoxes === null) {
          setErrors({ apiError: 'Please select a box.' });
          return;
      }

      const payload = {
        AccountingSoftware: '', 
    };
    
    try {
        const response = await fetch(`${baseURL}/user/profile/${JSON.parse(localStorage.getItem('userInfo')).userId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        });
    
        if (response.ok) {
            navigate('/welcome'); 
        } else {
            const errorData = await response.json();
            setErrors({ apiError: errorData.message || 'Update failed' });
            console.error('Update failed:', errorData);
        }
    } catch (error) {
        setErrors({ apiError: 'An error occurred while updating. Please try again.' });
        console.error('Error during update:', error);
    }
    };
    

    const handleBack = () => {
        navigate('/problem');
    };

    const handleBoxClick = (index) => {
        setSelectedBoxes((prevSelectedBoxes) => 
          prevSelectedBoxes.includes(index)
     ? prevSelectedBoxes.filter((i) => i !== index)
    : [...prevSelectedBoxes, index]
);
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'space-between', 
        marginTop: '10px', 
    };
    
    const leftSectionStyle = {
      flex:1,
      padding: '20px',
      marginLeft: '50px',
      zIndex: '10',
    };
    
    const rightSectionStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        position: 'relative',  
        zIndex: '5', 
        marginTop: '-173px', 
    };
    
    const imgStyle = {
        maxWidth: '100%', 
        height: '99vh',  
        objectFit: 'cover',  
        borderRadius: '10px',
    };
    
    

    const boxes = [
        { content: '', icon: <ExploreOffIcon sx={{ color: '#ff5a02', fontSize: 30 }} />, text: "I dont't use any" },
        { 
            content: '', 
            icon: <img src={logo1} alt="Set up bookkeeping" style={{ width: 55, height: 53, color: '#ff5a02' }} />, 
            text: 'Xero' 
        },
        { 
            content: '', 
            icon: <img src={logo2} alt="Capture costs & sales data" style={{ width: 38, height: 35, color: '#ff5a02' }} />, 
            text: "QBO" 
        },
        { 
            content: '', 
            icon: <img src={logo3} alt="Categorize and export data" style={{ width: 65, height: 27, color: '#ff5a02' }} />, 
            text: 'Saga' 
        },
        { 
            content: '', 
            icon: <img src={logo4} alt="Create workflows" style={{ width: 74, height: 25, color: '#ff5a02' }} />, 
            text: "FreeAgent" 
        },
        { 
            content: '', 
            icon: <img src={logo5} alt="Check deadlines" style={{ width: 90, height: 20, color: '#ff5a02' }} />, 
            text: "KashFlow" 
        },
        { 
            content: '', 
            icon: <img src={logo6} alt="Review client data health" style={{  width: 110, height: 27, color: '#ff5a02' }} />, 
            text: 'Surf' 
        },
        { content: '', icon: <RedeemIcon sx={{ color: '#ff5a02', fontSize: 30 }} />, text: 'Other' },
    ];

    const boxStyle = (index) => ({
        height: '120px',
        width: '190px',
        border: `1px solid ${selectedBoxes.includes(index) === index ? '#ff5a02' : '#ccc'}`,
        backgroundColor: selectedBoxes.includes(index) ? '#ffd8b8' : '#fff',
        margin: '8px',
        padding: '10px',
        fontSize: '14px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
    });


    return (
        <div>
            <HeaderUserInfo />
            <div style={containerStyle}>
                <div style={leftSectionStyle}>
                <h1 style={{ fontWeight: 'bold', fontSize:"24px", marginBottom:'9px' }}>Which accounting software do you use?</h1>
                <p>Select as many as you like</p>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '-8px' }}>
                        {boxes.map((box, index) => (
                            <div
                                key={index}
                                style={boxStyle(index)}
                                onClick={() => handleBoxClick(index)}
                            >
                                {box.icon}
                                <span>{box.content}</span>
                                <span style={{ fontSize: '12px', marginTop: '5px' }}>{box.text}</span>
                            </div>
                        ))}
                    </div>
                    <Button variant="contained" sx={{
                        marginTop: "35px",
                        backgroundColor: "#ffff",
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "black",
                        textTransform: "none",
                        '&:hover': {
                            backgroundColor: "#46b99d",
                        }
                    }} onClick={handleBack}>
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            marginTop: "35px",
                            marginLeft: "12px",
                            backgroundColor: "#46b99d",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "black",
                            textTransform: "none",
                            '&:hover': {
                                backgroundColor: "#46b99d",
                            }
                        }}
                        onClick={handleContinue}
                    >
                        Continue
                    </Button>
                </div>
                <div style={rightSectionStyle}>
                <img src={img4} alt="img4" style={imgStyle} />
                </div>
            </div>
        </div>
    );
};

export default AccountSoft;