import React, { useEffect, useState } from "react";
import { Button, TextField, FormControl, Box, IconButton, InputAdornment, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { baseURL } from '../Config';

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    role: "",
    countryCode: "+44",
    country: "United Kingdom"
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      navigate("/");
    }
  }, [navigate]);

  const validateRegisterForm = () => {
    let newErrors = {};

    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email address is invalid";
    if (!formData.password) newErrors.password = "Password is required";
    else if (formData.password.length < 6)
      newErrors.password = "Password must be at least 6 characters";
    if (!formData.phoneNumber)
      newErrors.phoneNumber = "Phone Number is required";
    if (!formData.country)
      newErrors.country = "Country is required";
    if (!formData.countryCode)
      newErrors.countryCode = "Code required";
    if (!formData.role) newErrors.role = "Role is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSignUp = async () => {
    if (validateRegisterForm()) {
      const payload = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        phone: formData.phoneNumber,
        role: formData.role,
        countryCode: formData.countryCode,
        country: formData.country,
      };

      try {
        const response = await fetch(`${baseURL}/auth/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const data = await response.json();
          const userInfo = {
            name: formData.name,
            email: formData.email,
            ...data,
          };
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          navigate("/email-sent");
        } else {
          const errorData = await response.json();
          setErrors({ apiError: errorData.message || "Signup failed" });
          console.error("Signup failed:", errorData);
        }
      } catch (error) {
        setErrors({
          apiError: "An error occurred while signing up. Please try again.",
        });
        console.error("Error during signup:", error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className=" h-[100vh]">
      <div className="px-3 pt-0 pb-0 bg-[#b0b1b0] ">
        <div>
          <img className="w-[130px]" src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
        </div>

      </div>
      <div className="p-8  bg-white  flex justify-center items-start ">
        <div className="w-[35%] flex flex-col justify-center items-center">
          <h1 className="text-[45px] text-[#515251] mb-1 font-bold">
            Start Your Free Trial
          </h1>
          <div className="text-[18px] text-[#1976d2] mb-[30px] font-bold">
            No payment details required.
          </div>
          <div className="w-[40%] flex mb-6">
            <div className="w-[50%] h-[5px] bg-[#1976d2]"></div>
            <div
              className={
                formData.name &&
                  formData.email &&
                  formData.password &&
                  formData.phoneNumber
                  ? "w-[50%] h-[5px] bg-[#1976d2] ml-2"
                  : "w-[50%] h-[5px] bg-[#c3cbd5] ml-2"
              }
            ></div>
          </div>
          <div className="w-full ">
            <div className="w-full mb-5">
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}


                />
              </FormControl>
            </div>
            <div className="w-full mb-5">
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </FormControl>
            </div>
            <div className="w-full mb-5">
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={showPassword ? 'Hide password' : 'Show password'}
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
            <div className="w-full mb-8">
              <FormControl sx={{ width: "100%" }}>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <FormControl sx={{ width: '20%' }} error={!!errors.countryCode}>
                    <Select
                      required
                      size="small"
                      name="countryCode"
                      value={formData.countryCode}
                      onChange={handleChange}
                      disabled={true}
                      displayEmpty
                      renderValue={(selected) => selected ? selected : "+"}
                    >
                      <MenuItem value="" disabled>
                        Select Code
                      </MenuItem>
                      <MenuItem value="+1">USA (+1)</MenuItem>
                      <MenuItem value="+91">India (+91)</MenuItem>
                      <MenuItem value="+44">UK (+44)</MenuItem>
                    </Select>
                    {errors.countryCode && (
                      <FormHelperText>{errors.countryCode}</FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    required
                    size="small"
                    sx={{ width: '80%' }}
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber}
                  />
                </div>
              </FormControl>
            </div>
            <div className="w-full mb-8">
              <FormControl sx={{ width: "100%" }}>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <FormControl sx={{ width: '100%' }} error={!!errors.country}>
                    <Select
                      required
                      size="small"
                      name="country"
                      value={formData.country}
                      // onChange={handleChange}
                      disabled={true}
                      displayEmpty
                      renderValue={(selected) => selected ? selected : "Select Country"}
                    >


                    </Select>
                    {errors.country && (
                      <FormHelperText>{errors.country}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </FormControl>
            </div>

            <div className="w-full mb-8">
              <FormControl sx={{ width: "100%" }}>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <FormControl sx={{ width: '100%' }} error={!!errors.role}>
                    <Select
                      size="small"
                      fullWidth
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                      displayEmpty
                      renderValue={(selected) => selected ? selected : "Select Role"}
                    >
                      <MenuItem value="" disabled>
                        Select Role
                      </MenuItem>
                      <MenuItem value="account">Accountant</MenuItem>
                      <MenuItem value="business">Business</MenuItem>
                    </Select>
                    {errors.role && (
                      <FormHelperText>{errors.role}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </FormControl>
            </div>

          </div>
          {errors.apiError && (
            <div className="text-red-500 mb-4">{errors.apiError}</div>
          )}
          <div className="w-full flex flex-col gap-1">
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#1976d2',
                color: '#fff',
                '&:hover': {
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'

                },
              }}
              onClick={handleSignUp}
              className="w-full mb-2"
            >
              Sign Up
            </Button>
            <Button
              variant="text"
              color="primary"
              href="/"
              className="w-full "
            >
              I have an account? Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
