import React, { useState, useEffect } from "react";
import { Button, Toolbar, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import TableExtendedToolTip from "./utils/TableExtendedToolTip";
import TableComponent from "../components/Table";
import axios from "axios";
import { baseURL } from '../Config';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ExportDialog from "./ExportDiaolog";
const token = localStorage.getItem('token');

function EnhancedTableToolbar(props) {
    const { rowsData, numSelected, onMoveToReady, onArchiveClick, onExportClick } = props;

    return (
        <Toolbar
            sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}
        >
            <div className="flex justify-between items-center w-full">
                <div>
                    <Button
                        variant="contained"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            fontWeight: "bold",
                            backgroundColor: "2196f3",
                            color: "white",
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',



                        }}
                        onClick={onExportClick}
                    >
                        {numSelected === 0 ? 'Export All' : 'Export'}
                    </Button>
                    <Button
                        disabled={numSelected === 0}
                        variant="contained"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            fontWeight: "bold",
                            backgroundColor: "2196f3",
                            color: "white",
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',



                        }}
                        onClick={onMoveToReady}
                    >
                        Move To Review
                    </Button>
                    <Button
                        disabled={numSelected === 0}
                        variant="contained"
                        color="primary"
                        onClick={onArchiveClick}
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            fontWeight: "bold",
                            backgroundColor: "2196f3",
                            color: "white",
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',



                        }}
                    >
                        Archive
                    </Button>
                    <Button
                        disabled={numSelected === 0}
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            fontWeight: "bold",
                            backgroundColor: "2196f3",
                            color: "white",
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',


                        }}
                    >
                        Add To Expense Report
                    </Button>
                    <Button
                        disabled={numSelected === 0}
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            fontWeight: "bold",
                            backgroundColor: "2196f3",
                            color: "white",
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',



                        }}
                    >
                        Tools
                    </Button>
                </div>
                <TableExtendedToolTip rowsData={rowsData} />
            </div>
        </Toolbar>
    );
}

const CostReady = () => {
    const [selected, setSelected] = useState([]);
    const [costs, setCosts] = useState([]);
    const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
    const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        fetchCosts();
    }, []);

    const handleArchiveClick = () => {
        setIsArchiveDialogOpen(true);
    };

    const handleArchiveCancel = () => {
        setIsArchiveDialogOpen(false);
    };

    const handleExportCancel = () => {
        setIsExportDialogOpen(false);
    };

    const handleExportClick = () => {
        setIsExportDialogOpen(true);
    };


    const handleMoveToReady = async () => {
        try {
            await axios.patch(`${baseURL}/cost/status/${selected}`, { status: 'Review', id: selected }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log("Ready successfully", response);
                    setSelected([]);
                    setCosts(costs.filter(cost => !selected.includes(cost._id)));
                })
                .catch(error => {
                    console.error("There was an error archiving the item!", error);
                });
        } catch (error) {
            console.error('Error fetching costs:', error);
        }
    };


    const ArchiveDialog = ({ open, onClose, selectedCount, onConfirm }) => {
        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle sx={{ color: "#1976D2" }}>Add Item(s)</DialogTitle>
                <DialogContent>
                    <Typography>
                        You have selected {selectedCount} item(s) to archive.
                    </Typography>
                    <DialogContentText>
                        Are you sure you want to archive the selected items?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} sx={{ background: "none", border: "none", '&:hover': { textDecoration: "underline" } }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={onConfirm}
                        variant="contained"
                        color="primary"
                        sx={{
                            background: "#1976D2",
                            border: "none",
                            '&:hover': {
                                border: "1px solid #1976D2",
                                background: "#1976D2"
                            },
                            fontWeight: 'bold'
                        }}
                    >
                        Archive
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleArchiveConfirm = async () => {
        try {
            await axios.patch(`${baseURL}/cost/status/${selected}`, { status: "archive", id: selected }, {
                headers: { Authorization: `Bearer ${token}` }
            })
            console.log('Selected costs archived successfully');
            fetchCosts();
            setCosts(prevCosts => prevCosts.filter(cost => !selected.includes(cost._id)));
            setSelected([]);
            setIsArchiveDialogOpen(false);
        } catch (error) {
            console.error('Error archiving items:', error);
        }
    };

    const fetchCosts = async () => {
        try {
            const response = await axios.post(`${baseURL}/cost/all/by-status`, {
                status: "Ready"
            },
                {
                    headers: { Authorization: `Bearer ${token}` }
                });

            const dataArray = Array.isArray(response.data) ? response.data : response.data.data;

            setCosts(Array.isArray(dataArray) ? dataArray : []);
            // console.log(response);
            // setCosts(response.data);
        } catch (error) {
            console.error('Error fetching costs:', error);
        }
    };
    return (
        <>
            <EnhancedTableToolbar rowsData={costs} numSelected={selected.length} onMoveToReady={handleMoveToReady} onArchiveClick={handleArchiveClick} onExportClick={handleExportClick} />
            <TableComponent data={costs} selected={selected} setSelected={setSelected} />
            <ArchiveDialog
                open={isArchiveDialogOpen}
                onClose={handleArchiveCancel}
                selectedCount={selected.length}
                onConfirm={handleArchiveConfirm}
            />
            <ExportDialog
                open={isExportDialogOpen}
                onClose={handleExportCancel}
                data={selected.length > 0 ? selected : costs}
            />
        </>
    )
}

export default CostReady;