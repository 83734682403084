import React, { useState, useEffect } from 'react';
import {
  Button,
  Toolbar,
  Tooltip,
  IconButton,
  Modal,
  Box,
  Typography,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import TableSales from './TableSales';
import axios from 'axios';
import { baseURL } from '../Config';
import TableExtendedToolTip from '../costComponents/utils/TableExtendedToolTip';
import ExportDialog from './ExportDiaolog';
import { TransparentBtn } from '../components/common/TransparentBtn';
const token = localStorage.getItem('token');

const EnhancedTableToolbar = ({
  rowsData,
  numSelected,
  handleOpenDeleteDialog,
  handleOpenUnarchiveDialog,
  onExportClick,
}) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <div className='flex justify-between items-center w-full'>
        <div className='flex items-center gap-2'>
          <TransparentBtn
            border
            // disabled={numSelected === 0}
            onClick={onExportClick}
            label={numSelected === 0 ? 'Export All' : 'Export'}
          />
          {/* <Button
            variant='contained'
            sx={{
              fontSize: '12px',
              padding: '6px 15px',
              marginRight: '10px',
              fontWeight: 'bold',
              backgroundColor: '#1976D2',
              color: '#ffff',
              '&.MuiButton-root:hover': {
                bgcolor: '#1976D2',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
              },
            }}
            onClick={onExportClick}
          >
            {numSelected === 0 ? 'Export All' : 'Export'}
          </Button> */}
          <TransparentBtn
            border
            disabled={numSelected === 0}
            onClick={handleOpenUnarchiveDialog}
            label={'Unarchive'}
          />
          {/* <Button
            disabled={numSelected === 0}
            variant='contained'
            sx={{
              fontSize: '12px',
              padding: '6px 15px',
              marginRight: '10px',
              fontWeight: 'bold',
              backgroundColor: '#1976D2',
              color: '#FFFF',
              '&.MuiButton-root:hover': {
                bgcolor: '#1976D2',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
              },
            }}
            onClick={handleOpenUnarchiveDialog}
          >
            Unarchive
          </Button> */}
          <TransparentBtn
            border
            disabled={numSelected === 0}
            onClick={handleOpenDeleteDialog}
            label={'Delete'}
            startIcon={
              <DeleteOutlineOutlinedIcon color='error' fontSize='small' />
            }
          />
          {/* <Button
            disabled={numSelected === 0}
            variant='contained'
            startIcon={<DeleteOutlineOutlinedIcon />}
            sx={{
              fontSize: '12px',
              padding: '6px 15px',
              marginRight: '10px',
              fontWeight: 'bold',
              backgroundColor: '#1976D2',
              color: 'white',
              '&.MuiButton-root:hover': {
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
              },
            }}
            onClick={handleOpenDeleteDialog}
          >
            Delete
          </Button> */}
        </div>
        <TableExtendedToolTip rowsData={rowsData} />
      </div>
    </Toolbar>
  );
};

const SaleArchive = () => {
  const [selected, setSelected] = useState([]);
  const [sales, setSales] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUnarchiveDialog, setOpenUnarchiveDialog] = useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  console.log(sales, 'sales');

  useEffect(() => {
    fetchArchivedSales();
  }, []);

  const fetchArchivedSales = async () => {
    try {
      const response = await axios.get(`${baseURL}/sales`, {
        headers: { Authorization: `Bearer ${token}`, status: 'archive' },
      });
      const archivedSales = response.data.filter(
        (sale) => sale.status === 'archive'
      );

      const saleDetailsPromises = archivedSales.map((sale) =>
        axios.get(`${baseURL}/sales/${sale._id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      );
      const detailedSalesResponses = await Promise.all(saleDetailsPromises);
      const detailedSales = detailedSalesResponses.map(
        (response) => response.data
      );
      console.log(detailedSales, 'Detailed Sales');

      setSales(detailedSales);
      // setArchivedCosts(response.data);
    } catch (error) {
      console.error('Error fetching archived sales:', error);
    }
  };

  const handleDeleteSale = async () => {
    try {
      const deletePromises = selected.map((salesId) =>
        axios.delete(`${baseURL}/sales/${salesId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );
      await Promise.all(deletePromises);
      console.log('Selected sales deleted successfully');
      setSales((prevSales) =>
        prevSales.filter((sale) => !selected.includes(sale._id))
      );

      // fetchCosts();
      // fetchArchivedSales();
      setSelected([]);
    } catch (error) {
      console.error('Error deleting sales:', error);
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleOpenUnarchiveDialog = () => {
    setOpenUnarchiveDialog(true);
  };

  const handleCloseUnarchiveDialog = () => {
    setOpenUnarchiveDialog(false);
  };

  const handleExportClick = () => {
    setIsExportDialogOpen(true);
  };

  const handleExportCancel = () => {
    setIsExportDialogOpen(false);
  };

  const handleUnarchiveSale = async () => {
    try {
      const unarchivePromises = selected.map((costId) =>
        axios.patch(
          `${baseURL}/sales/${costId}`,
          { status: 'Review' },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
      );
      await Promise.all(unarchivePromises);
      setSales((prevCosts) =>
        prevCosts.filter((sales) => !selected.includes(sales._id))
      );
      setSelected([]);
    } catch (error) {
      console.error('Error unarchiving costs:', error);
    } finally {
      setOpenUnarchiveDialog(false);
    }
  };

  return (
    <>
      <EnhancedTableToolbar
        rowsData={sales}
        numSelected={selected.length}
        handleOpenDeleteDialog={handleOpenDeleteDialog}
        handleOpenUnarchiveDialog={handleOpenUnarchiveDialog}
        onExportClick={handleExportClick}
      />
      <ExportDialog
        open={isExportDialogOpen}
        onClose={handleExportCancel}
        data={
          selected.length > 0
            ? sales.filter((sale) => {
                const isMatched = selected?.some((item) => item == sale._id);
                return isMatched;
              })
            : sales
        }
      />
      <TableSales data={sales} selected={selected} setSelected={setSelected} />

      <Modal
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby='delete-modal-title'
        aria-describedby='delete-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id='delete-modal-title' variant='h6' component='h2'>
            Delete Confirmation
          </Typography>
          <Typography id='delete-modal-description' sx={{ mt: 2 }}>
            {`Are you sure you want to delete ${selected.length} item(s)?`}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              sx={{ mr: 2 }}
              onClick={handleCloseDeleteDialog}
              variant='contained'
              color='inherit'
            >
              Cancel
            </Button>
            <Button
              // onClick={handleDeleteCost}
              variant='contained'
              color='error'
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openUnarchiveDialog}
        onClose={handleCloseUnarchiveDialog}
        aria-labelledby='unarchive-modal-title'
        aria-describedby='unarchive-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id='unarchive-modal-title' variant='h6' component='h2'>
            Unarchive Confirmation
          </Typography>
          <Typography id='unarchive-modal-description' sx={{ mt: 2 }}>
            {`Are you sure you want to unarchive ${selected.length} item(s)?`}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              sx={{ mr: 2 }}
              onClick={handleCloseUnarchiveDialog}
              variant='contained'
              color='inherit'
            >
              Cancel
            </Button>
            <Button
              onClick={handleUnarchiveSale}
              variant='contained'
              color='primary'
            >
              Unarchive
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SaleArchive;
