import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    TextField,
    MenuItem,
    Checkbox,
    TablePagination
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default function StickyColumnsTable() {

    const [description, setDescription] = useState()
    const [taxRate, setTaxRate] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const [unitPrice, setUnitPrice] = useState(0)
    const [Net, setNet] = useState(0)
    const [tax, setTax] = useState(0)
    const [total, setTotal] = useState(0)

    const row = {
        id: '',
        description: `Description`,
        category: `Category `,
        customer: `Customer `,
        taxRate: '1.0000',
        quantity: '0.00',
        UnitPrice: '0.00',
        net: '0.00',
        tax: '0.00',
        total: '0.00'
    }


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState([]);

    const [selected, setSelected] = useState([]);




    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleAddNewLine = () => {
        setRows((prev) => [...prev, row])
    }

    // Handle select all click
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    return (
        <TableContainer component={Paper} sx={{ overflowX: 'auto' }} >
            <Table stickyHeader sx={{ minWidth: 1000 }}>
                <TableHead >
                    <TableRow sx={{ backgroundColor: '#878787' }}>
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                indeterminate={selected.length > 0 && selected.length < rows.length}
                                checked={rows.length > 0 && selected.length === rows.length}
                                onChange={handleSelectAllClick}
                            />
                        </TableCell>
                        {/* Other headers */}
                        <TableCell>Description</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Tax Rate</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell >Unit Price (Exc. Tax)</TableCell>
                        {/* Sticky columns */}
                        <TableCell >Net</TableCell>
                        <TableCell >Tax</TableCell>
                        <TableCell >Total</TableCell>
                        <TableCell ></TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                        <TableRow key={index}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selected.indexOf(index) !== -1}
                                    onChange={() => {
                                        const selectedIndex = selected.indexOf(index);
                                        let newSelected = [];

                                        if (selectedIndex === -1) {
                                            newSelected = newSelected.concat(selected, index);
                                        } else if (selectedIndex === 0) {
                                            newSelected = newSelected.concat(selected.slice(1));
                                        } else if (selectedIndex === selected.length - 1) {
                                            newSelected = newSelected.concat(selected.slice(0, -1));
                                        } else {
                                            newSelected = newSelected.concat(
                                                selected.slice(0, selectedIndex),
                                                selected.slice(selectedIndex + 1)
                                            );
                                        }

                                        setSelected(newSelected);
                                    }}
                                />
                            </TableCell>
                            <TableCell><TextField variant="outlined" size="small" value={description} onChange={(e) => setDescription(e.target.value)} /></TableCell>
                            <TableCell>
                                <TextField select variant="outlined" size="small" fullWidth>
                                    <MenuItem value="Category 1">Category 1</MenuItem>
                                    <MenuItem value="Category 2">Category 2</MenuItem>
                                </TextField>
                            </TableCell>

                            <TableCell>
                                <TextField select variant="outlined" size="small" fullWidth>
                                    <MenuItem value="Category 1">Category 1</MenuItem>
                                    <MenuItem value="Category 2">Category 2</MenuItem>
                                </TextField>
                            </TableCell>
                            <TableCell><TextField variant="outlined" size="small" onChange={(e) => setTaxRate(e.target.value)} value={taxRate} /></TableCell>
                            <TableCell><TextField variant="outlined" size="small" onChange={(e) => setQuantity(e.target.value)} value={quantity} /></TableCell>
                            <TableCell><TextField variant="outlined" size="small" onChange={(e) => setUnitPrice(e.target.value)} value={unitPrice} /></TableCell>
                            <TableCell ><TextField variant="outlined" size="small" onChange={(e) => setNet(e.target.value)} value={Net} /></TableCell>
                            <TableCell ><TextField variant="outlined" size="small" onChange={(e) => setTax(e.target.value)} value={tax} /></TableCell>
                            <TableCell><TextField variant="outlined" size="small" onChange={(e) => setTotal(e.target.value)} value={total} /></TableCell>
                            <TableCell style={{ position: 'sticky', right: 0, background: 'white', zIndex: 3 }}>
                                <IconButton color="error" onClick={() => {
                                    setRows((prevRows) => prevRows.filter((_, i) => i !== index));
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>

                    ))}

                </TableBody>
            </Table>
            <div className='text-center'>
                <button className='m-3 border border-1 bg-blue-700 text-white py-1 px-5 rounded-md ' variant="outlined" onClick={handleAddNewLine}>+ Add new line item</button>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
}
