import React, { useState, useEffect } from 'react';
import CostInbox from '../costComponents/Inbox';
import CostArchive from '../costComponents/Archive';
import CostProcessing from '../costComponents/Processing';
import CostReview from '../costComponents/Review';
import CostReady from '../costComponents/Ready';
import { Route, Routes } from 'react-router-dom';
import CostEdit from '../costComponents/Edit';
import axios from 'axios';
import { baseURL } from '../Config';
const token = localStorage.getItem('token');

const Costs = ({ fileURL }) => {
    const [costs, setCosts] = useState([]);

    const fetchCosts = async () => {
        try {
            const response = await axios.get(`${baseURL}/cost`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setCosts(response.data);
            // console.log('Costs fetched successfully:', response.data);
        } catch (error) {
            console.error('Error fetching costs:', error);
        }
    };

    const handleAddCost = async () => {
        try {
            const data = {
                fileURL: fileURL,
            };

            const response = await axios.post(`${baseURL}/cost`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Cost added successfully:', response.data);
            fetchCosts();
        } catch (error) {
            console.error('Error adding cost:', error);
        }
    };

    const handleEditCost = async (costId, updatedData) => {
        try {
            const response = await axios.patch(`${baseURL}/cost/${costId}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Cost updated successfully:', response.data);
            fetchCosts();
        } catch (error) {
            console.error('Error updating cost:', error);
        }
    };

    useEffect(() => {
        fetchCosts();
    }, []);

    useEffect(() => {
        if (fileURL) {
            handleAddCost();
        }
    }, [fileURL]);
    // useEffect(() => {
    //     if (fileURL) {
    //         handleEditCost();
    //     }
    // }, [fileURL]);

    return (
        <div className="p-0 h-full ">
            <Routes>
                <Route path="archive" element={<CostArchive />} />
                <Route path="inbox" element={<CostInbox costs={costs} />} />
                <Route path="processing" element={<CostProcessing />} />
                <Route path="review" element={<CostReview />} />
                <Route path="ready" element={<CostReady />} />
                <Route path="edit/:id" element={<CostEdit />} />
            </Routes>
        </div>
    );
};

export default Costs;
