import React from 'react';
import { Container, Grid, Breadcrumbs, Typography, Divider, Tooltip, IconButton, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import HeaderBusiness from './HeaderBusiness';
import Footer from '../components/Footer';

const ExportData = () => {
    return (
        <>
            <HeaderBusiness />
            <Container style={{ width: '100%', marginTop: '100px' }}>
                {/* Breadcrumb */}
                <Box mb={4} display="flex">
                    <Breadcrumbs
                        separator=">"
                        aria-label="breadcrumb"
                        sx={{
                            maxWidth: '100%',
                            marginTop: "10px",
                            marginLeft: "5px",
                            '& ol': {
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap',
                                width: '100%',
                            },
                        }}
                    >
                        {['Home', 'Dext Prepare', 'Getting Started', 'Business Owners', 'Introduction To Dext Prepare', 'How to Edit, Publish and Export Items'].map((label, index) => (
                            <Typography
                                key={index}
                                sx={{
                                    color: '#4991e9',
                                    textDecoration: 'underline',
                                    cursor: 'default',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    '&:hover': {
                                        textDecoration: 'none',
                                        color: '#4991e9 !important',
                                        backgroundColor: "transparent"
                                    }
                                }}
                            >
                                {label}
                            </Typography>
                        ))}
                    </Breadcrumbs>
                </Box>

                <Grid container spacing={5}>
                    {/* Left Section */}
                    <Grid item xs={3}>
                        <Box display="flex" alignItems="center" mb={2}>
                            <img className='w-[80px]' src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
                            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                            <Typography variant="h6" fontWeight="bold" sx={{ fontSize: '2rem' }}>Prepare</Typography>
                        </Box>
                        <Typography variant="body1" fontWeight="bold" mb={2} mt={3} sx={{ fontSize: '1.30rem' }}>Business Owners</Typography>

                        {[
                            {
                                title: 'Introduction To Dext Prepare',
                                lines: ['What Dext Prepare Products are Available?', 'How to Sign Up to Dext Prepare as a Small Business or Sole Trader', 'How Does Dext Prepare Work?', 'Get Started with Dext Prepare', 'Your New Dext Prepare Account', 'Welcome to the Dashboard for Business Accounts', 'Why Link Your Business to Accountin Software?', 'How To Add A User To Your Team', 'How to Add Documents', 'How to Use the Mobile App to Upload Paperwork', 'How to Edit, Publish and Export Items'],
                            },
                            {
                                title: 'Your Account',
                                lines: ['Using Intuit Single Sign On with Dext', 'Managing Multiple Business Accounts with ONe Email Address', 'How to Check Your Extraction Limit', 'How to Change Your Plan', 'How to Cancel Your Dext Prepare Account', 'How to invite Your Accountant or Bookkeeper to Access Your Dext Prepare Account ', "How to Remove or Reallocate a Bookkeeper's or Accountant's Access to Your Account"],
                            }
                        ].map((section, index) => (
                            <Accordion key={index} elevation={0} sx={{ border: 'none', marginTop: '7px' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography fontWeight="bold">{section.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                        {section.lines.map((line, i) => (
                                            <li key={i}>
                                                <Typography
                                                    sx={{
                                                        padding: '12px 0',
                                                        '&:hover': {
                                                            color: '#4991e9',
                                                            cursor: 'pointer'
                                                        }
                                                    }}
                                                >
                                                    {line}
                                                </Typography>
                                            </li>
                                        ))}
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        ))}

                    </Grid>

                    {/* Right Section */}
                    <Grid item xs={9}>
                        <Typography variant="h4" mb={2} style={{ fontSize: '2rem', textAlign: 'justify', fontWeight: "bold" }}>
                            How to Edit, Publish and Export Items
                        </Typography>
                        <Typography variant="body1" mb={3} mt={4} style={{ fontSize: '1.5rem', textAlign: 'justify', fontWeight: "bold" }}>
                            This is a simple guide about how to perform these 3 basic actions within Dext Prepare.
                        </Typography>

                        <Typography variant="body1" mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            <ul>
                                <li style={{ margin: "10px", color: "#4991e9", textDecoration: 'underline', listStyleType: "disc" }}><a href="#"> How to View Your Items in Dext Prepare</a></li>
                                <li style={{ margin: "10px", color: "#4991e9", textDecoration: 'underline', listStyleType: "disc" }}><a href="#"> How to Publish Your Items to a Connected Accounting Software</a></li>
                                <li style={{ margin: "10px", color: "#4991e9", textDecoration: 'underline', listStyleType: "disc" }}><a href="#"> What do I need to publish an item?</a></li>
                                <li style={{ margin: "10px", color: "#4991e9", textDecoration: 'underline', listStyleType: "disc" }}><a href="#"> How to Export Your Items to Download or Share</a></li>
                            </ul>
                        </Typography>

                        <Typography variant="h4" mb={3} mt={3} style={{ fontSize: '1.3rem', textAlign: 'justify', fontWeight: "bold" }}>
                            Viewing Your Items in Dext Prepare
                        </Typography>

                        <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            To view your items:
                            <ul style={{ marginTop: "30px" }}>
                                <li style={{ margin: "12px" }}>1. Navigate to the <strong>Costs</strong> Inbox.</li>
                                <li style={{ margin: "12px", }}>2. Click on any item to see more information about the purchase and an image of the supporting document.</li>
                            </ul>
                        </Typography>
                        <Typography mb={7} mt={3} style={{ textAlign: 'justify' }}>
                            <Box border={1} padding={2} borderRadius={1} marginBottom={1} style={{ display: 'flex', alignItems: 'center', color: '#546478' }}>
                                <Tooltip title="Help">
                                    <IconButton style={{ marginBottom: '10px' }}>
                                        <ErrorIcon />
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="body2" style={{ fontSize: '1rem' }}>
                                    Note: Information about the purchase has been automatically extracted by Dext Prepare. You can edit any of those fields.
                                </Typography>
                            </Box>
                        </Typography>
                        <Typography variant="h4" mb={3} mt={3} style={{ fontSize: '1.5rem', textAlign: 'justify', fontWeight: "bold", }}>
                            Publishing Items to a Connected Accounting Software
                        </Typography>
                        <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>

                            If you’ve integrated your Dext Prepare account with another software, you can send submitted documents directly from the Inbox to a specified location in your accounting software.
                        </Typography>
                        <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            To publish your item:
                            <ul style={{ marginTop: "30px" }}>
                                <li style={{ margin: "12px" }}>1. Click into an item from the <strong>Costs</strong> Inbox.</li>
                                <li style={{ margin: "12px", }}>2. (Optional) Add or edit the Item Details.</li>
                                <li style={{ margin: "12px", }}>3. Select a <strong>Category</strong>.</li>
                                <li style={{ margin: "12px", }}>4. Choose where and how to publish the document using the <strong>Publish</strong> as and <strong>Publish to</strong> fields</li>
                                <li style={{ margin: "12px", }}>5. Click the green <strong>Publish</strong> button to publish the document.</li>
                            </ul>
                        </Typography>

                        <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            We’ll keep a copy of the item in the <a href="#" style={{ color: '#4991e9', textDecoration: "underline" }}>Archive</a> in case you ever need to find it again.
                        </Typography>
                        <img src="/Screenshot5.png" alt="Screenshot" style={{ border: '3px solid #ff5a03', marginBottom: '16px', width: "700px" }} />



                        <Typography variant="h6" mb={2} mt={6} mr={53} style={{ fontSize: '1.7rem', textAlign: 'center', fontWeight: "bold" }}>
                            What do I need to publish an item?
                        </Typography>
                        <Box display="flex" justifyContent="center" mb={4} >
                            <video width="920" height="660" controls>
                                <source src="video2.mp4" type="video/mp4" />
                            </video>
                        </Box>

                        <Typography variant="h4" mb={3} mt={3} style={{ fontSize: '1.5rem', textAlign: 'justify', fontWeight: "bold", }}>
                            Exporting Your Documents to Download or Share
                        </Typography>

                        <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            If you’re not integrated with an accounting software, you can export your items by:
                            <ul style={{ marginTop: "30px" }}>
                                <li style={{ margin: "12px" }}>1. Navigating to the<strong>Costs</strong> Inbox.</li>
                                <li style={{ margin: "12px", }}>2. Selecting each of the items you’d like to export using the checkboxes on the left-hand side..</li>
                                <li style={{ margin: "12px", }}>3. Clicking <strong>Export</strong>.</li>
                            </ul>
                        </Typography>
                        <Typography mb={7} mt={3} style={{ textAlign: 'justify' }}>
                            <Box border={1} padding={2} borderRadius={1} marginBottom={1} style={{ display: 'flex', alignItems: 'center', color: '#546478' }}>
                                <Tooltip title="Help">
                                    <IconButton style={{ marginBottom: '10px' }}>
                                        <ErrorIcon />
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="body2" style={{ fontSize: '1rem' }}>
                                    Note: You’ll be able to choose whether you’d like to export those items as a CSV data file — great for uploading to other software or saving to a spreadsheet or export as a PDF image file — great for sharing with other people.                                </Typography>
                            </Box>
                        </Typography>

                        <img src="/Screenshot3.png" alt="Screenshot" style={{ border: '3px solid #ff5a03', marginBottom: '16px', width: "400px" }} />

                    </Grid>
                </Grid>
            </Container>

            <Footer />
        </>
    );
}

export default ExportData;
