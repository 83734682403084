import { Button, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';

const SignupStepTwo = ({ prevStep, onComplete }) => {
  const [formData, setFormData] = useState({
    country: 'United Kingdom',
    practiceName: '',
  });
  const [errors, setErrors] = useState({});
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.practiceName)
      newErrors.practiceName = 'Practice name is required.';
    if (!acceptedTerms)
      newErrors.terms = 'You must accept the terms and conditions.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) onComplete(formData);
  };

  return (
    <div className='min-h-screen bg-gray-50 p-10'>
      <div className='text-[#0d417b] text-3xl font-black uppercase mb-6'>
        Zoom Account
      </div>
      <div className='flex gap-5 justify-center items-center p-10 bg-white shadow-lg rounded-lg w-full'>
        <div className='w-1/3'>
          <h2 className='text-3xl font-semibold mb-6 text-center'>
            Start your free trial
          </h2>
          <p className='text-lg font-medium text-gray-500 mb-8 text-center'>
            No payment details required.
          </p>
        </div>
        <div className='w-2/3'>
          <form>
            <div className='mb-4'>
              <Select
                // className='w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500'
                fullWidth
                value={formData.country}
                onChange={(e) =>
                  setFormData({ ...formData, country: e.target.value })
                }
              >
                <MenuItem value='United Kingdom'>United Kingdom</MenuItem>
                {/* <MenuItem value='United States'>United States</MenuItem> */}
              </Select>
            </div>
            <div className='mb-4'>
              {/* <label className='block text-sm font-medium text-gray-700 mb-1'>
                Practice Name
              </label> */}
              <TextField
                type='text'
                placeholder='Practice Name'
                fullWidth
                // className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 ${
                //   errors.practiceName ? 'border-red-500' : 'border-gray-300'
                // }`}
                error={errors.practiceName}
                helperText={errors.practiceName}
                value={formData.practiceName}
                onChange={(e) =>
                  setFormData({ ...formData, practiceName: e.target.value })
                }
              />
            </div>
            <div className='mb-4 flex items-start'>
              <input
                type='checkbox'
                className='h-4 w-4 text-prm border-gray-300 rounded'
                checked={acceptedTerms}
                onChange={(e) => setAcceptedTerms(e.target.checked)}
              />
              <label className='ml-2 text-sm text-gray-700'>
                I accept the{' '}
                <a href='#' className='text-sec hover:underline'>
                  Terms and Conditions
                </a>{' '}
                &{' '}
                <a href='#' className='text-sec hover:underline'>
                  Privacy Policy
                </a>
              </label>
            </div>
            {errors.terms && (
              <p className='text-sm text-red-500'>{errors.terms}</p>
            )}
            <div className='flex justify-between'>
              <Button
                type='button'
                variant='contained'
                className='!bg-gray-200 !text-gray-700 py-2 px-4 rounded-lg !hover:bg-gray-300'
                onClick={prevStep}
              >
                Back
              </Button>
              <Button type='button' onClick={handleSubmit}>
                Sign Up
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupStepTwo;
