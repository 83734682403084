import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import axios from 'axios';
import { baseURL } from '../Config';
const ResetPassword = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState()
    const [error, setError] = useState()
    const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


const location =useLocation()
const queryParams = new URLSearchParams(location.search)
  const sendForgotPasswordApiCall = async () => {
    // const token = localStorcosnage.getItem('token')
    // const userToken = localStorage.getItem('userToken');
    const token = queryParams.get('token')
    try {
      const response = await axios.post(`${baseURL}/auth/reset-password`, {
        token:token,
newPassword:password
      });
      console.log("response",response)
      // if(response.status){
        alert(response?.data?.message)
        navigate('/')
      // }else{
        // set error here
        // alert(response?.data?.message)
      // }
      //  console.log(response.data.token+"sass")
      //  setSupplierData(response.data);
    //   localStorage.setItem("token", response.data.token)
    //   console.log("Change Suppliers Data:", response.data); // Console log the fetched data
    //   window.location.reload();
    } catch (error) {
      alert(error?.response?.data?.message)
      console.error("Error fetching data:", error);
    }
  };

    const handleSend = () => {
        const isValid = validateSend();
        if (isValid) {
            sendForgotPasswordApiCall()
            // alert(`Soon! You will recieve the password on the ${email} `)
          

        }
        else {
            alert('Password and confirm not matched.')
            setEmail("")
        }

    }

    const handleChange = (e) => {
        setEmail(e.target.value)
    }
    const validateSend = () => {
       return password==confirmPassword
    };
    return (
        <div className='realtive h-screen w-screen bg-[#fcfcfc]'>
            <div className='flex flex-col items-center absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%]'>
                <img className='w-[35%] h-[200px] ' src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
                <div className='  z-10 px-6 py-7 bg-white border-2 border-[#d1d1d1] flex flex-col items-center rounded-md '>
                    <div className='w-[15%]'><img src="./Lock.jpeg" alt="Forgot Password" /></div>
                    <span className='mt-4 text-lg font-bold'>Reset Your Password?</span>
                    <p className='text-center text-[#575757] text-[15px]'>Please enter the new Zoom Password,<br /> to reset your accout.</p>
                     {/* <input className='mt-6 w-3/4 rounded-sm' type="text" placeholder='Password' value={email} onChange={handleChange} />
                    <input className='mt-6 w-3/4 rounded-sm' type="text" placeholder='Confirm Password' value={email} onChange={handleChange} />  */}

                    <div className="flex flex-col items-center w-full">
      {/* Password Input */}
      <div className="relative w-3/4">
        <input
          className="mt-6 w-full px-3 py-2 rounded-sm border border-gray-300 focus:outline-none"
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <span
          className="absolute right-3 top-9 cursor-pointer text-gray-500"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
        </span>
      </div>

      {/* Confirm Password Input */}
      <div className="relative w-3/4">
        <input
          className="mt-6 w-full px-3 py-2 rounded-sm border border-gray-300 focus:outline-none"
          type={showPassword ? "text" : "password"}
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <span
          className="absolute right-3 top-9 cursor-pointer text-gray-500"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
        </span>
      </div>
    </div>
                    <button onClick={handleSend} className='flex items-center gap-3 mt-14 bg-[#1976d2] rounded-md text-white shadow-md px-4 py-2 text-lg active:translate-y-1 hover:shadow-2xl'><LockOpenIcon className='p-0' /> Send</button>
                </div>

            </div>



        </div>
    )
}

export default ResetPassword

