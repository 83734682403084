import { baseURL } from "../Config";

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  TextField,
  MenuItem,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Autocomplete,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  Error as ErrorIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import bankname from "./banks-list";
import AddBankPopup from "../components/addBankPopUp";

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

export default function BankAccounts() {
  const [bankInfo, setBankInfo] = useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
    currency: "",
  });
  const [bankAccounts, setBankAccounts] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [manuallyAccordionExpanded, setManuallyAccordionExpanded] =
    useState(false);

  const [banks, setBanks] = useState([]);

  // Fetch bank accounts when the component mounts
  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${baseURL}/bank`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBankAccounts(response.data);
      } catch (error) {
        console.error(
          "Error fetching bank accounts:",
          error.response ? error.response.data : error.message
        );
        alert(
          "There was an error fetching the bank accounts. Please try again."
        );
      }
    };
    const fetchBankNames = async () => {
      // let newBankName = []
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${baseURL}/bankName/accept`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // setBankAccounts(response.data);
        console.log('fetching bank name',response.data.data)
        const newBankNames = response.data.data
        const names = []
        newBankNames.forEach(item => {
          // Extract the first property (which could be "0", "1", etc.)
          names.push(item.bankName)
        });
        setBanks([...bankname.banks,...names]);
      } catch (error) {
        
      }
     
    };
    fetchBankAccounts();
    fetchBankNames();
  }, []); // Runs once when the component mounts

  // Handle input changes
  const handleInputChange = (event, type) => {
    console.log("event", event, type);
    setBankInfo({ ...bankInfo, [type]: event });
  };
  console.log("dasdasdsadsdas", bankInfo);
  // Handle selecting a bank from the dropdown
  const handleBankSelect = (event) => {
    const selectedBankName = event.target.value;
    const selectedBank = bankAccounts.find(
      (account) => account.bankName === selectedBankName
    );

    if (selectedBank) {
      setBankInfo({
        ...bankInfo,
        bank: selectedBank.bankName,
        currency: selectedBank.currency, // Automatically set currency based on bank selection
      });
    } else {
      setBankInfo({ ...bankInfo, bank: selectedBankName, currency: "" });
    }
  };

  // Handle adding a new bank account
  const handleAddBankAccount = async () => {
    if (
      !bankInfo.bankName ||
      !bankInfo.accountName ||
      !bankInfo.accountNumber ||
      !bankInfo.currency
    ) {
      alert("Please fill in all details.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${baseURL}/bank`,
        {
          bankName: bankInfo.bankName,
          accountName: bankInfo.accountName,
          accountNumber: bankInfo.accountNumber,
          currency: bankInfo.currency,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update state and clear form
      setBankAccounts([...bankAccounts, response.data]);
      setBankInfo({
        bankName: "",
        accountName: "",
        accountNumber: "",
        currency: "",
      });
    } catch (error) {
      console.error(
        "Error adding bank account:",
        error.response ? error.response.data : error.message
      );
      alert("There was an error adding the bank account. Please try again.");
    }
  };

  const handleEditBankAccount = async () => {
    if (editIndex === -1) return;

    try {
      const token = localStorage.getItem("token");
      const accountToEdit = bankAccounts[editIndex];

      const payload = {
        bankName: bankInfo.bankName,
        accountName: bankInfo.accountName,
        accountNumber: bankInfo.accountNumber,
        currency: bankInfo.currency,
      };

      const response = await axios.put(
        `${baseURL}/bank/${accountToEdit._id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedAccounts = bankAccounts.map((account, index) =>
        index === editIndex ? response.data : account
      );
      setBankAccounts(updatedAccounts);
      setBankInfo({
        bank: "",
        accountName: "",
        accountNumber: "",
        currency: "",
      });
      setEditIndex(-1);
    } catch (error) {
      console.error(
        "Error editing bank account:",
        error.response?.data || error.message
      );
      alert("Error editing bank account. Please try again.");
    }
  };

  const handleDeleteBankAccount = async () => {
    if (deleteIndex !== -1) {
      try {
        const token = localStorage.getItem("token");
        const accountToDelete = bankAccounts[deleteIndex];

        await axios.delete(`${baseURL}/bank/${accountToDelete._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const updatedAccounts = bankAccounts.filter(
          (_, index) => index !== deleteIndex
        );
        setBankAccounts(updatedAccounts);
        setDeleteIndex(-1);
        setDeleteDialogOpen(false);
      } catch (error) {
        console.error(
          "Error deleting bank account:",
          error.response ? error.response.data : error.message
        );
        alert(
          "There was an error deleting the bank account. Please try again."
        );
      }
    }
    setBankInfo({
      bankName: "",
      accountName: "",
      accountNumber: "",
      currency: "",
    });
    setEditIndex(-1);
  };

  const handleOpenDeleteDialog = (index) => {
    setDeleteIndex(index);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteIndex(-1);
    setDeleteDialogOpen(false);
  };

  const handleAccordionChange = (isExpanded) =>
    setAccordionExpanded(isExpanded);
  const handleManuallyAccordionChange = (isExpanded) =>
    setManuallyAccordionExpanded(isExpanded);

  return (
    <Box sx={{ width: "100%" }}>
      <CustomTabPanel value={0} index={0} className="flex flex-col gap-2">
        <Typography
          gutterBottom
          className="!bg-white"
          style={{border: '1px solid #d7cfcf'}}
          sx={{ border: 1, padding: 1, borderRadius: 1, fontSize: 14 }}
        >
          <Tooltip title="Help">
            <IconButton>
              <ErrorIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          </Tooltip>
          You can manage bank accounts for Bank Statement Extraction here.
        </Typography>

        <Accordion
          expanded={accordionExpanded}
          onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}
          sx={{ width: "100%", padding: 1 }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "bold" }}>
              Add Bank Account       <AddBankPopup />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ display: "flex" , alignItems: "center"}}>
                <Typography
                  sx={{ minWidth: 140, fontSize: 12, textAlign: "left", pr: 2 }}
                >
                  Bank Name
                </Typography>
                <Autocomplete
                  sx={{
                    width: "20%",
                    fontSize: 12,
                   
                    "& .MuiOutlinedInput-root": {
                      height: "30px !important",
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
               
                  options={banks}
                  value={bankInfo.bankName}
                  onChange={(event, newValue) =>
                    handleInputChange(newValue, "bankName")
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" />
                  )}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center"}}>
                <Typography
                  sx={{ minWidth: 140, fontSize: 12, textAlign: "left", pr: 2 }}
                >
                  Currency
                </Typography>
                <TextField
                  sx={{
                    width: "20%",
                    fontSize: 12,
                    "& .MuiOutlinedInput-root": {
                      height: "30px !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "30px !important",
                      "&:hover fieldset": {
                        borderColor: "black", // Remove border on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent", // Remove blue outline when focused
                      },
                    },
                  }}
                  value={bankInfo.currency}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "currency")
                  }
                  variant="outlined"
                  size="small"
                  // disabled // Disable the currency field as it's auto-filled based on the selected bank
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ minWidth: 140, fontSize: 12, textAlign: "left", pr: 2 }}
                >
                  Account Name
                </Typography>
                <TextField
                  sx={{
                    width: "20%",
                    fontSize: 12,
                    "& .MuiOutlinedInput-root": {
                      height: "30px !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "30px !important",
                      "&:hover fieldset": {
                        borderColor: "black", // Remove border on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent", // Remove blue outline when focused
                      },
                    },
                  }}
                  value={bankInfo.accountName}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "accountName")
                  }
                  variant="outlined"
                  placeholder="Enter Account Name"
                  size="small"
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center"}}>
                <Typography
                  sx={{ minWidth: 140, fontSize: 12, textAlign: "left", pr: 2 }}
                >
                  Account Number
                </Typography>
                <TextField
                  sx={{
                    width: "20%",
                    fontSize: 14,
                    "& .MuiOutlinedInput-root": {
                      height: "30px !important",
                    },
                    "& .MuiOutlinedInput-root": {
                       height: "30px !important",
                      "&:hover fieldset": {
                        borderColor: "black", // Remove border on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent", // Remove blue outline when focused
                      },
                    },
                  }}
                  value={bankInfo.accountNumber}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "accountNumber")
                  }
                  variant="outlined"
                  placeholder="Enter Account Number"
                  size="small"
                />
              </Box>
              {editIndex === -1 ? (
                <div className="flex space-x-2">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddBankAccount}
                  // sx={{ mt: 1, ml: 18, padding: '0.5rem 1.5rem' }}
                  >
                  Add
                </Button>
          
                  </div>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEditBankAccount}
                  sx={{ ml: 18, mt: 1, padding: "0.5rem 1.5rem" }}
                >
                  Update
                </Button>
              )}
            </Box>
            <hr className="mt-6" />
            <Box sx={{ mt: 5 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Manually Added Accounts
              </Typography>
              <TableContainer sx={{ width: "100%", mt: 5 }}>
                <Table size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Bank</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Account Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Account Number
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Currency
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bankAccounts.map((account, index) => (
                      <TableRow key={index}>
                        <TableCell>{account.bankName}</TableCell>
                        <TableCell>{account.accountName}</TableCell>
                        <TableCell>{account.accountNumber}</TableCell>
                        <TableCell>{account.currency}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              setEditIndex(index);
                              setBankInfo({
                                bankName: account.bankName,
                                accountName: account.accountName,
                                accountNumber: account.accountNumber,
                                currency: account.currency,
                              });
                            }}
                            size="small"
                          >
                            <EditIcon
                              fontSize="small"
                              sx={{ color: "#1975d2" }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleOpenDeleteDialog(index)}
                            size="small">
                            <DeleteIcon
                              fontSize="small"
                              sx={{ color: "#ff5a02" }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion expanded={manuallyAccordionExpanded} onChange={(event, isExpanded) => handleManuallyAccordionChange(isExpanded)} sx={{ width: '100%', mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "bold" }}>Manually Added Accounts</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} sx={{ width: '100%' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Bank</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Account Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Account Number</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Currency</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bankAccounts.map((account, index) => (
                    <TableRow key={index}>
                      <TableCell>{account.bankName}</TableCell>
                      <TableCell>{account.accountName}</TableCell>
                      <TableCell>{account.accountNumber}</TableCell>
                      <TableCell>{account.currency}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => {
                          setEditIndex(index);
                          setBankInfo({
                            bank: account.bankName,
                            accountName: account.accountName,
                            accountNumber: account.accountNumber,
                            currency: account.currency,
                          });
                        }} size="small">
                          <EditIcon fontSize="small" sx={{ color: "#1975d2" }} />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteDialog(index)} size="small">
                          <DeleteIcon fontSize="small" sx={{ color: "#ff5a02" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion> */}
      </CustomTabPanel>

      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Bank Account</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this bank account?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button
            className="hover:bg-[#1976D2]"
            onClick={handleDeleteBankAccount}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
