import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  FormControl,
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { baseURL } from '../Config';
import { FaChartPie, FaBuilding } from 'react-icons/fa';
import { IoMdArrowRoundBack } from 'react-icons/io';
import SignupStepOne from '../components/register/SignupStepOne';
import SignupStepTwo from '../components/register/SignupStepTwo';
const WelcomeRolePage = ({ setFormData, handleNext }) => {
  const navigate = useNavigate()
  const handleRoleChange = (role) => {
    setFormData((prev) => ({ ...prev, role: role }));
    handleNext();
  };

  const navigateToLogin = () => {
    // This function would navigate to the login page
    // Replace with your actual login navigation logic
    console.log("Navigating to login page");
  navigate("/");
  };

  return (
    <div className='min-h-screen bg-gray-50 p-10'>
      {/* Logo */}
      <div className='text-[#0d417b] text-3xl font-black uppercase mb-6'>
        Zoom Account
      </div>
      <div className='flex flex-col justify-center items-center p-10'>
        {/* Heading */}
        <div className='text-center mb-8'>
          <h1 className='text-2xl font-bold mb-2'>Welcome to Zoom accounts</h1>
          <p className='text-gray-600'>
            Choosing the right software is paramount. To help you with that,
            please tell us how you would describe your company.
          </p>
        </div>

        {/* Options */}
        <div className='flex gap-5'>
          <div
            className='flex flex-col items-center gap-3 w-1/2 justify-center p-6 border rounded-lg hover:shadow-lg cursor-pointer group'
            onClick={() => handleRoleChange('account')}
          >
            <FaChartPie className='text-prm group-hover:text-sec text-5xl mb-4' />
            <div className='text-prm font-medium group-hover:text-sec text-center p-5'>
              An accounting or bookkeeping practice
            </div>
          </div>
          <div
            className='flex flex-col items-center gap-3 w-1/2 justify-center p-6 border rounded-lg hover:shadow-lg cursor-pointer group'
            onClick={() => handleRoleChange('business')}
          >
            <FaBuilding className='text-[#0d417b] group-hover:text-sec text-5xl mb-4' />
            <div className='text-prm font-medium group-hover:text-sec text-center p-5'>
              A business e.g. retail, construction, florist etc.
            </div>
          </div>
        </div>

        {/* Login option */}
        <div className='mt-8 text-center'>
          <p className='text-gray-600'>
            Already have an account?{' '}
            <button 
              onClick={navigateToLogin}
              className='text-[#0d417b] font-medium hover:underline focus:outline-none'
            >
              Log in
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

const Register = () => {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({});
  const [erros, setErrors] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const handleNext = (data) => {
    setFormData({ ...formData, ...data });
    setPage(2);
  };

  const handleBack = () => {
    setPage(1);
  };

  const handleSubmit = async (data) => {
    console.log({ ...formData, ...data });
    // alert("Sign up successful!");
    const payload = {
      name: `${formData.firstName} ${formData?.lastName}`,
      email: formData.email,
      password: formData.password,
      phone: formData.phoneNumber,
      role: formData.role,
      countryCode: formData.countryCode,
      country: data.country,
      companyName: data.practiceName,
      currency: '',
      invAccountant: token || null,
    };

    try {
      const response = await fetch(`${baseURL}/auth/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        const userInfo = {
          name: formData.name,
          email: formData.email,
          ...data,
        };
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        navigate('/email-sent');
      } else {
        const errorData = await response.json();
        setErrors({ apiError: errorData.message || 'Signup failed' });
        console.error('Signup failed:', errorData);
      }
    } catch (error) {
      setErrors({
        apiError: 'An error occurred while signing up. Please try again.',
      });
      console.error('Error during signup:', error);
    }
  };

  return (
    <div>
      {page === 0 && (
        <WelcomeRolePage
          setFormData={setFormData}
          handleNext={() => setPage(1)}
        />
      )}
      {page === 1 && <SignupStepOne nextStep={handleNext} />}
      {page === 2 && (
        <SignupStepTwo prevStep={handleBack} onComplete={handleSubmit} />
      )}
    </div>
  );
};

// const SignUpPageOne = ({ onNext }) => {
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     password: "",
//     phone: "",
//   });
//   const [errors, setErrors] = useState({});

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const validate = () => {
//     const newErrors = {};
//     if (!formData.firstName) newErrors.firstName = "First name cannot be blank";
//     if (!formData.lastName) newErrors.lastName = "Last name cannot be blank";
//     if (!formData.email.includes("@")) newErrors.email = "Please enter a valid email";
//     if (!formData.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9@#$%^&+=]).{8,}$/)) {
//       newErrors.password = "Password must meet the criteria";
//     }
//     // if (!formData.phone.match(/^\+\d{1,4}\s\d{6,}$/)) newErrors.phone = "Please enter a valid phone number";
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleNext = () => {
//     if (validate()) onNext(formData);
//   };

//   return (
//     <div className="signup-container">
//       <h1>Start your free trial</h1>
//       <p>No payment details required.</p>
//       <form className="signup-form">
//         <div className="form-group">
//           <label>First Name *</label>
//           <input
//             type="text"
//             name="firstName"
//             value={formData.firstName}
//             onChange={handleChange}
//           />
//           {errors.firstName && <span className="error">{errors.firstName}</span>}
//         </div>

//         <div className="form-group">
//           <label>Last Name *</label>
//           <input
//             type="text"
//             name="lastName"
//             value={formData.lastName}
//             onChange={handleChange}
//           />
//           {errors.lastName && <span className="error">{errors.lastName}</span>}
//         </div>

//         <div className="form-group">
//           <label>Work Email *</label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//           />
//           {errors.email && <span className="error">{errors.email}</span>}
//         </div>

//         <div className="form-group">
//           <label>Password *</label>
//           <input
//             type="password"
//             name="password"
//             value={formData.password}
//             onChange={handleChange}
//           />
//           {errors.password && <span className="error">{errors.password}</span>}
//           <small>Password must: contain lowercase letters, be at least 8 characters long, and have 2 uppercase letters/numbers/symbols.</small>
//         </div>

//         <div className="form-group">
//           <label>Mobile Number *</label>
//           <input
//             type="text"
//             name="phone"
//             value={formData.phone}
//             onChange={handleChange}
//             placeholder="+44"
//           />
//           {errors.phone && <span className="error">{errors.phone}</span>}
//         </div>

//         <button type="button" onClick={handleNext} className="next-btn">
//           Next
//         </button>
//       </form>
//     </div>
//   );
// };

// const SignUpPageTwo = ({ onBack, onSubmit }) => {
//   const [formData, setFormData] = useState({
//     country: "United Kingdom",
//     practiceName: "",
//     termsAccepted: false,
//   });
//   const [errors, setErrors] = useState({});

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setFormData({
//       ...formData,
//       [name]: type === "checkbox" ? checked : value,
//     });
//   };

//   const validate = () => {
//     const newErrors = {};
//     if (!formData.practiceName) newErrors.practiceName = "Please enter a company name";
//     if (!formData.termsAccepted) newErrors.termsAccepted = "Please accept our Terms and Conditions and Privacy Policy";
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = () => {
//     if (validate()) onSubmit(formData);
//   };

//   return (
//     <div className="signup-container">
//       <h1>Start your free trial</h1>
//       <p>No payment details required.</p>
//       <form className="signup-form">
//         <div className="form-group">
//           <label>Country *</label>
//           <select name="country" value={formData.country} onChange={handleChange}>
//             <option value="United Kingdom">United Kingdom</option>
//             <option value="United States">United States</option>
//             <option value="Canada">Canada</option>
//           </select>
//         </div>

//         <div className="form-group">
//           <label>Practice Name *</label>
//           <input
//             type="text"
//             name="practiceName"
//             value={formData.practiceName}
//             onChange={handleChange}
//           />
//           {errors.practiceName && <span className="error">{errors.practiceName}</span>}
//         </div>

//         <div className="form-group checkbox-group">
//           <input
//             type="checkbox"
//             name="termsAccepted"
//             checked={formData.termsAccepted}
//             onChange={handleChange}
//           />
//           <label>
//             I accept Dext’s <a href="#">Terms and Conditions</a> & <a href="#">Privacy Policy</a>
//           </label>
//           {errors.termsAccepted && <span className="error">{errors.termsAccepted}</span>}
//         </div>

//         <div className="form-buttons">
//           <button type="button" onClick={onBack} className="back-btn">
//             Back
//           </button>
//           <button type="button" onClick={handleSubmit} className="submit-btn">
//             Sign Up
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

export default Register;
