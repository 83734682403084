import React, { useEffect, useState } from "react";
import { Button, TextField, FormControl, Box, IconButton, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { baseURL } from '../Config';

const EmailSent = () => {

  const logoContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    padding: '20px',
  };

  const outerCircleStyle = {
    backgroundColor: '#1976d2',
    width: '170px',
    height: '170px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  };

  const innerCircleStyle = {
    backgroundColor: '#ffff',
    width: '140px',
    height: '140px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  };

  const checkSymbolStyle = {
    color: '#1976d2',
    fontSize: '120px',
    animation: 'scale 1.2s ease-in-out infinite',
    animationDelay: '0.4s',
  };

  return (
    <>
      <div className="px-3 pt-0 pb-0 bg-[#b0b1b0] ">
        <div>
          <img className="w-[100px]" src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
        </div>

      </div>
      <div className="p-32  w-[100vw]   flex flex-col justify-center items-center">
        <div style={logoContainerStyle}>
          <div style={outerCircleStyle}>
            <div style={innerCircleStyle}>
              <span style={checkSymbolStyle}>&#10003;</span>
            </div>
          </div>
          <style>{`
        @keyframes scale {
          0%,100% {
            transform: scale(0.6);
          }
         50% {
            transform: scale(1.2);
          }
        }
      `}</style>
        </div>
        <h1 style={{ color: "#1976d2", fontWeight: 'bold', fontSize: "24px", marginBottom: '9px', textAlign: "center", marginTop: "40px" }}>Verification mail sent to your email, please verify your account within next 48 hours!!</h1>
      </div>
    </>
  );
};

export default EmailSent;
