import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import About from './business/userinfo/About';
import Role from './business/userinfo/Role';
import Problem from './business/userinfo/Problem';
import AccountSoft from './business/userinfo/AccountSoft';
import Welcome from './business/userinfo/Welcom';
import DashboardPanel from './components/DashboardPanel';
import Suppliers from './listComponents/Suppliers';
import AllSupplier from './listComponents/suppliers/Allsuppliers';
import AllCustomer from './listComponents/customers/AllCustomer';
import Customers from './listComponents/Customers';
import AddAccounts from './business/AddAccounts';
import UploadInvoice from './business/UploadInvoice';
import ExportData from './business/ExportData';
import AddClient from './business/AddClient';
import VerifyEmail from './pages/VerifyEmail';
import EmailSent from './pages/EmailSent';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';


const AppRoutes = () => {
    // useEffect(() => {
    //     localStorage.removeItem('userInfo');
    // }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/register" element={<Register />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/email-sent" element={<EmailSent />} />
                <Route path="/about" element={<About />} />
                <Route path="/role" element={<Role />} />
                <Route path="/problem" element={<Problem />} />
                {/* <Route path='accountSoft' element={<AccountSoft/>}/> */}
                <Route path='welcome' element={<Welcome />} />
                <Route path="/dashboard/*" element={<DashboardPanel />} />

                {/* <Route path="/suppliers/*" element={<AllSupplier />}>
                    <Route path=":id" element={<Suppliers />} />
                </Route> */}
                {/* <Route path="customers/*" element={<AllCustomer />}>
                    <Route path=":id" element={<Customers />} />
                </Route> */}
                <Route path="/add-accounts" element={<AddAccounts />} />
                <Route path="/uploadinvoice" element={<UploadInvoice />} />
                <Route path="/exportdata" element={<ExportData />} />
                <Route path="/exportdata" element={<ExportData />} />
                <Route path="/addclient" element={<AddClient />} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;
