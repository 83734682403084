// import React, { useState } from 'react';
// import { Document, Page } from 'react-pdf';

// const MyPdfViewer = ({ fileUrl }) => {
//     const [page, setPage] = useState(1);
//     const [pages, setPages] = useState(null);

//     const onDocumentLoad = ({ numPages }) => {
//         setPages(numPages);
//     };

//     return (
//         <div>
//             <Document file={fileUrl} onLoadSuccess={onDocumentLoad}>
//                 <Page pageNumber={page} />
//             </Document>
//         </div>
//     );
// };

// export default MyPdfViewer;





// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// const PDFViewer = ({ pdfUrl }) => {


//     return (
//         <div style={{ height: '500px' }}>
//             {/* <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js`}>
//                 <Viewer fileUrl={pdfUrl} />
//             </Worker> */}
//             <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js`}>
//                 <Viewer fileUrl={pdfUrl} />
//             </Worker>
//         </div>
//     );
// };

// export default PDFViewer;





import React, { useState } from 'react';
import { FiZoomIn, FiZoomOut, FiDownload } from 'react-icons/fi';

const PdfViewer = ({ fileUrl }) => {
  const [zoom, setZoom] = useState(100);

  const handleZoomIn = () => setZoom((prev) => Math.min(prev + 10, 200));
  const handleZoomOut = () => setZoom((prev) => Math.max(prev - 10, 50));
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'document.pdf';
    link.click();
  };

  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: '#f4f4f9', display: 'flex', flexDirection: 'column' }}>
      {/* Header */}
      <div style={{ padding: '10px', backgroundColor: '#3f51b5', color: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3 style={{ margin: 0, fontSize: '18px' }}>PDF Viewer</h3>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {/* <button onClick={handleZoomOut} style={buttonStyle}><FiZoomOut size={18} /></button>
          <button onClick={handleZoomIn} style={buttonStyle}><FiZoomIn size={18} /></button> */}
          <button onClick={handleDownload} style={buttonStyle}><FiDownload size={18} /></button>
        </div>
      </div>

      {/* PDF Display */}
      <div style={{ flexGrow: 1, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <iframe
          src={`${fileUrl}#toolbar=0`}
          width={`${zoom}%`}
          height="100%"
          title="PDF Viewer"
          style={{
            border: 'none',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            overflow: 'hidden',
            scrollbarWidth: 'none',
          }}
        />
      </div>

      {/* Custom Scrollbar Styling */}
      <style>
        {`
          iframe {
            scrollbar-width: thin;
            scrollbar-color: #3f51b5 #e0e0e0;
          }
          iframe::-webkit-scrollbar {
            width: 8px;
          }
          iframe::-webkit-scrollbar-track {
            background: #e0e0e0;
            border-radius: 4px;
          }
          iframe::-webkit-scrollbar-thumb {
            background-color: #3f51b5;
            border-radius: 4px;
            border: 2px solid #e0e0e0;
          }
        `}
      </style>
    </div>
  );
};

// Button Styling
const buttonStyle = {
  background: 'none',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
  padding: '5px',
  transition: 'color 0.2s',
};

export default PdfViewer;



// import React, { useState, useEffect } from 'react';
// import { FiZoomIn, FiZoomOut, FiDownload } from 'react-icons/fi';

// const PdfViewer = ({ fileUrl }) => {
//   const [zoom, setZoom] = useState(100);
//   const [isViewable, setIsViewable] = useState(true);

//   const handleZoomIn = () => setZoom((prev) => Math.min(prev + 10, 200));
//   const handleZoomOut = () => setZoom((prev) => Math.max(prev - 10, 50));
//   const handleDownload = () => {
//     const link = document.createElement('a');
//     link.href = fileUrl;
//     link.download = 'document.pdf';
//     link.click();
//   };

//   // Check if the PDF is viewable
//   useEffect(() => {
//     const checkPdfViewable = async () => {
//       try {
//         const response = await fetch(fileUrl, { method: 'HEAD' });
//         if (response.status !== 200) {
//           setIsViewable(true);
//         }
//       } catch (error) {
//         setIsViewable(false);
//       }
//     };
//     checkPdfViewable();
//   }, [fileUrl]);

//   return (
//     <div style={{ width: '100%', height: '100%', backgroundColor: '#f4f4f9', display: 'flex', flexDirection: 'column' }}>
//       {/* Header */}
//       <div style={{ padding: '10px', backgroundColor: '#3f51b5', color: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//         <h3 style={{ margin: 0, fontSize: '18px' }}>PDF Viewer</h3>
//         <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//           {isViewable && (
//             <>
//               <button onClick={handleZoomOut} style={buttonStyle}>
//                 <FiZoomOut size={18} />
//               </button>
//               <button onClick={handleZoomIn} style={buttonStyle}>
//                 <FiZoomIn size={18} />
//               </button>
//             </>
//           )}
//           <button onClick={handleDownload} style={buttonStyle}>
//             <FiDownload size={18} />
//           </button>
//         </div>
//       </div>

//       {/* PDF Display or Fallback Message */}
//       <div
//         style={{
//           flexGrow: 1,
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           flexDirection: 'column',
//         }}
//       >
//         {isViewable ? (
//           <iframe
//             src={`${fileUrl}#toolbar=0`}
//             width={`${zoom}%`}
//             height="100%"
//             title="PDF Viewer"
//             style={{
//               border: 'none',
//               boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
//             }}
//           />
//         ) : (
//           <div style={{ textAlign: 'center' }}>
//             <p style={{ fontSize: '16px', color: '#555' }}>No preview available</p>
//             <button
//               onClick={handleDownload}
//               style={{
//                 backgroundColor: '#3f51b5',
//                 color: '#fff',
//                 border: 'none',
//                 padding: '10px 20px',
//                 borderRadius: '4px',
//                 cursor: 'pointer',
//               }}
//             >
//               Download PDF
//             </button>
//           </div>
//         )}
//       </div>

//       {/* Custom Scrollbar Styling */}
//       <style>
//         {`
//           iframe {
//             scrollbar-width: thin;
//             scrollbar-color: #3f51b5 #e0e0e0;
//           }
//           iframe::-webkit-scrollbar {
//             width: 8px;
//           }
//           iframe::-webkit-scrollbar-track {
//             background: #e0e0e0;
//             border-radius: 4px;
//           }
//           iframe::-webkit-scrollbar-thumb {
//             background-color: #3f51b5;
//             border-radius: 4px;
//             border: 2px solid #e0e0e0;
//           }
//         `}
//       </style>
//     </div>
//   );
// };

// // Button Styling
// const buttonStyle = {
//   background: 'none',
//   border: 'none',
//   color: '#fff',
//   cursor: 'pointer',
//   padding: '5px',
//   transition: 'color 0.2s',
// };

// export default PdfViewer;
