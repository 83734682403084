import React, { useState, useMemo, useEffect } from "react";
import { Table, TablePagination, Button, Select, MenuItem, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TableSortLabel, Box, Toolbar, Typography, Tooltip, IconButton } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from "react-router-dom";
import { baseURL } from '../Config';
const token = localStorage.getItem('token');

// function createData(id, name, item, extractlineitems, category, customer) {
//     return { id, name, item, extractlineitems, category, customer };
// }

// const rows = [createData(1, 'Zylker Thread & Weave N', '3', 'No', '', '')];
const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'item', numeric: true, disablePadding: false, label: 'Item' },
    { id: 'extract-supplier-statements', numeric: true, disablePadding: false, label: 'Extract Line Items' },
    { id: 'category', numeric: true, disablePadding: false, label: 'Category' },
    { id: 'customer', numeric: true, disablePadding: false, label: 'Customer' }
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
    const { tableColumns, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className="bg-[#ebf0ec]">
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    tableColumns[headCell.id] && <TableCell
                        key={headCell.id}
                        align='left'
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ fontSize: '13px', fontWeight: 'bold' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const TableSup = ({ tableColumns, data, selected, setSelected }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [category, setCategory] = useState('None');
    const navigate = useNavigate();


    useEffect(() => {

    }, [data])


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // const handleSelectAllClick = (event) => {
    //     if (event.target.checked) {
    //         const newSelected = data.map((n) => n.id);
    //         setSelected(newSelected);
    //         return;
    //     }
    //     setSelected([]);
    // };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleNameClick = (event, id, row) => {
        event.stopPropagation();
        navigate(`/dashboard/suppliers/${row?._id}`);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.includes(id);
    // const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const visibleRows = useMemo(
        () => stableSort(data, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        ),
        [order, orderBy, page, rowsPerPage, data],
    );

    return (
        <div>
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={() => { }}
                        onRequestSort={handleRequestSort}
                        rowCount={data?.length}
                        tableColumns={tableColumns}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            const isItemSelected = isSelected(row._id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row._id}
                                    selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            onClick={(event) => handleClick(event, row._id)}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        padding="none"
                                        align="left"
                                        onClick={(event) => handleNameClick(event, row.id, row)}
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="left">{row.items}</TableCell>
                                    {tableColumns["extract-supplier-statements"] && <TableCell align="left">{row.extractlineitems}</TableCell>}
                                    {tableColumns["category"] && <TableCell align="left">
                                        <Select
                                            sx={{ height: "30px", width: "200px", fontSize: "13px" }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={row.category}
                                            label="Age"
                                            onChange={(e) => setCategory(e.target.value)}
                                        >
                                            <MenuItem value={""}>None</MenuItem>
                                        </Select>
                                    </TableCell>}
                                    {tableColumns["customer"] && <TableCell align="left">
                                        <Select
                                            sx={{ height: "30px", width: "200px", fontSize: "13px" }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={row.category}
                                            label="Age"
                                            onChange={(e) => setCategory(e.target.value)}
                                        >
                                            <MenuItem value={""}>None</MenuItem>
                                        </Select>
                                    </TableCell>}
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default TableSup;
