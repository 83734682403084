import { createContext, useState } from "react";

export const TooltipContext = createContext()

export const TooltipProvider = ({ children }) => {
    const [formData, setFormData] = useState({});
    const [tableColumns, setTableColumns] = useState({
        'status': true,
        'date': true,
        'supplier': true,
        'category': true,
        'total': true,
        'tax': true,
        'taxRate': true,
        'match': null,
        'submittedBy': null,
        'dueDate': null,
        'documentReference': null,
        'baseTotal': null,
        'description': null,
        'paid': null,
        'payment': null,
        'type': null,
        'itemId': null,
        'submissionMethod': null,
        'note': null,
        'uploadDate': null,

    })
    const [filters, setFilters] = useState({
        // 'Ready': null,
        'Tax': null,
        'Category': null,
        'Currency': null,
        'Read': null,
        'Flag': null,
        'Paid': null,

    })
    return (
        <TooltipContext.Provider value={{ tableColumns, setTableColumns, filters, setFilters, formData, setFormData }}>
            {children}
        </TooltipContext.Provider>
    )

}