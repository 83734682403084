// import React from 'react';
// import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,TextField, InputAdornment, IconButton, MenuItem, Select, FormControl, InputLabel, Box, Typography} from '@mui/material';
// import Autocomplete from '@mui/material/Autocomplete';
// import { Search, Image, GetApp, InsertDriveFile } from '@mui/icons-material';
// import {baseURL} from '../Config';
// const top100Films = [
//   { label: 'The Shawshank Redemption', year: 1994 },
//   { label: 'The Godfather', year: 1972 },
//   { label: 'The Godfather: Part II', year: 1974 },
//   { label: 'The Dark Knight', year: 2008 },
//   { label: '12 Angry Men', year: 1957 },
//   { label: "Schindler's List", year: 1993 },
//   { label: 'Pulp Fiction', year: 1994 },
//   {
//     label: 'The Lord of the Rings: The Return of the King',
//     year: 2003,
//   },
//   { label: 'The Good, the Bad and the Ugly', year: 1966 },
//   { label: 'Fight Club', year: 1999 },
//   {
//     label: 'The Lord of the Rings: The Fellowship of the Ring',
//     year: 2001,
//   },
//   {
//     label: 'Star Wars: Episode V - The Empire Strikes Back',
//     year: 1980,
//   },
//   { label: 'Forrest Gump', year: 1994 },
//   { label: 'Inception', year: 2010 },
//   {
//     label: 'The Lord of the Rings: The Two Towers',
//     year: 2002,
//   },
//   { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
//   { label: 'Goodfellas', year: 1990 },
//   { label: 'The Matrix', year: 1999 },
//   { label: 'Seven Samurai', year: 1954 },
//   {
//     label: 'Star Wars: Episode IV - A New Hope',
//     year: 1977,
//   },
//   { label: 'City of God', year: 2002 },
//   { label: 'Se7en', year: 1995 },
//   { label: 'The Silence of the Lambs', year: 1991 },
//   { label: "It's a Wonderful Life", year: 1946 },
//   { label: 'Life Is Beautiful', year: 1997 },
//   { label: 'The Usual Suspects', year: 1995 },
//   { label: 'Léon: The Professional', year: 1994 },
//   { label: 'Spirited Away', year: 2001 },
//   { label: 'Saving Private Ryan', year: 1998 },
//   { label: 'Once Upon a Time in the West', year: 1968 },
//   { label: 'American History X', year: 1998 },
//   { label: 'Interstellar', year: 2014 },
//   { label: 'Casablanca', year: 1942 },
//   { label: 'City Lights', year: 1931 },
//   { label: 'Psycho', year: 1960 },
//   { label: 'The Green Mile', year: 1999 },
//   { label: 'The Intouchables', year: 2011 },
//   { label: 'Modern Times', year: 1936 },
//   { label: 'Raiders of the Lost Ark', year: 1981 },
//   { label: 'Rear Window', year: 1954 },
//   { label: 'The Pianist', year: 2002 },
//   { label: 'The Departed', year: 2006 },
//   { label: 'Terminator 2: Judgment Day', year: 1991 },
//   { label: 'Back to the Future', year: 1985 },
//   { label: 'Whiplash', year: 2014 },
//   { label: 'Gladiator', year: 2000 },
//   { label: 'Memento', year: 2000 },
//   { label: 'The Prestige', year: 2006 },
//   { label: 'The Lion King', year: 1994 },
//   { label: 'Apocalypse Now', year: 1979 },
//   { label: 'Alien', year: 1979 },
//   { label: 'Sunset Boulevard', year: 1950 },
//   {
//     label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
//     year: 1964,
//   },
//   { label: 'The Great Dictator', year: 1940 },
//   { label: 'Cinema Paradiso', year: 1988 },
//   { label: 'The Lives of Others', year: 2006 },
//   { label: 'Grave of the Fireflies', year: 1988 },
//   { label: 'Paths of Glory', year: 1957 },
//   { label: 'Django Unchained', year: 2012 },
//   { label: 'The Shining', year: 1980 },
//   { label: 'WALL·E', year: 2008 },
//   { label: 'American Beauty', year: 1999 },
//   { label: 'The Dark Knight Rises', year: 2012 },
//   { label: 'Princess Mononoke', year: 1997 },
//   { label: 'Aliens', year: 1986 },
//   { label: 'Oldboy', year: 2003 },
//   { label: 'Once Upon a Time in America', year: 1984 },
//   { label: 'Witness for the Prosecution', year: 1957 },
//   { label: 'Das Boot', year: 1981 },
//   { label: 'Citizen Kane', year: 1941 },
//   { label: 'North by Northwest', year: 1959 },
//   { label: 'Vertigo', year: 1958 },
//   {
//     label: 'Star Wars: Episode VI - Return of the Jedi',
//     year: 1983,
//   },
//   { label: 'Reservoir Dogs', year: 1992 },
//   { label: 'Braveheart', year: 1995 },
//   { label: 'M', year: 1931 },
//   { label: 'Requiem for a Dream', year: 2000 },
//   { label: 'Amélie', year: 2001 },
//   { label: 'A Clockwork Orange', year: 1971 },
//   { label: 'Like Stars on Earth', year: 2007 },
//   { label: 'Taxi Driver', year: 1976 },
//   { label: 'Lawrence of Arabia', year: 1962 },
//   { label: 'Double Indemnity', year: 1944 },
//   {
//     label: 'Eternal Sunshine of the Spotless Mind',
//     year: 2004,
//   },
//   { label: 'Amadeus', year: 1984 },
//   { label: 'To Kill a Mockingbird', year: 1962 },
//   { label: 'Toy Story 3', year: 2010 },
//   { label: 'Logan', year: 2017 },
//   { label: 'Full Metal Jacket', year: 1987 },
//   { label: 'Dangal', year: 2016 },
//   { label: 'The Sting', year: 1973 },
//   { label: '2001: A Space Odyssey', year: 1968 },
//   { label: "Singin' in the Rain", year: 1952 },
//   { label: 'Toy Story', year: 1995 },
//   { label: 'Bicycle Thieves', year: 1948 },
//   { label: 'The Kid', year: 1921 },
//   { label: 'Inglourious Basterds', year: 2009 },
//   { label: 'Snatch', year: 2000 },
//   { label: '3 Idiots', year: 2009 },
//   { label: 'Monty Python and the Holy Grail', year: 1975 },
// ];

// const SubmissionHistory = () => {
//   const rows = [
//     { sNo: 1, itemId: '334844', submissionDate: '16 June 2024', submissionBy: 'John Doe', submissionMethod: 'via Web', ownedBy: 'Jane Smith', date: '18 May', supplier: '---', totalAmount: 'USD 489' },
    
//   ];

//   return (
//     <Box sx={{ p: 3, backgroundColor:"#f9fafb" }}>
//       <Typography variant="h1" gutterBottom style={{ fontSize: "24px", fontWeight: "bold" }}>
//       Submission History
//       </Typography>
//       <Box display="flex" justifyContent="flex-end" alignItems="center" mb={1}>
//   <IconButton>
//     <Search/>
//   </IconButton>
//   <Autocomplete
//     disablePortal
//     id="combo-box-demo"
//     options={top100Films}
//     sx={{ width: 300 }}
//     renderInput={(params) => (
//       <TextField 
//         {...params} 
//         label="" 
//         InputProps={{ ...params.InputProps, sx: { height: 40, width:280 } }} 
//       />
//     )}
//   />
// </Box>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell></TableCell>
//               <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Item ID</strong></TableCell>
//               <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Submission Date</strong></TableCell>
//               <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Submission By</strong></TableCell>
//               <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Submission Method</strong></TableCell>
//               <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Owned By</strong></TableCell>
//               <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Date</strong></TableCell>
//               <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Supplier</strong></TableCell>
//               <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Total Amount</strong></TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {rows.map((row) => (
//               <TableRow key={row.sNo}>
//                 <TableCell>
//                   <IconButton><Image /></IconButton>
//                   <IconButton><GetApp /></IconButton>
//                   <IconButton><InsertDriveFile /></IconButton>
//                 </TableCell>
//                 <TableCell>{row.itemId}</TableCell>
//                 <TableCell>{row.submissionDate}</TableCell>
//                 <TableCell>{row.submissionBy}</TableCell>
//                 <TableCell>{row.submissionMethod}</TableCell>
//                 <TableCell>{row.ownedBy}</TableCell>
//                 <TableCell>{row.date}</TableCell>
//                 <TableCell>{row.supplier}</TableCell>
//                 <TableCell>{row.totalAmount}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   );
// };

// export default SubmissionHistory;

import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField,
  IconButton, Box, Typography, Autocomplete
} from '@mui/material';
import { Search, Image, GetApp, InsertDriveFile } from '@mui/icons-material';
import { baseURL } from '../Config';
import axios from 'axios';

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  // ...other films
];

const SubmissionHistory = () => {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const fetchSubmissionHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        console.log('Token:', token);
        const response = await axios.get(`${baseURL}/user/submission-history`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        console.log('Fetched Data:', data);
        setRows(data);
      } catch (error) {
        console.error('Error fetching submission history:', error);
      }
    };
    fetchSubmissionHistory();
  }, []);

  return (
    <Box sx={{ p: 3, backgroundColor:"#f9fafb" }}>
      <Typography variant="h1" gutterBottom style={{ fontSize: "24px", fontWeight: "bold" }}>
        Submission History
      </Typography>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={1}>
        <IconButton>
          <Search />
        </IconButton>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={top100Films}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField 
              {...params} 
              label="" 
              InputProps={{ ...params.InputProps, sx: { height: 40, width:280 } }} 
            />
          )}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Item ID</strong></TableCell>
              <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Submission Date</strong></TableCell>
              <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Submission By</strong></TableCell>
              <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Submission Method</strong></TableCell>
              <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Owned By</strong></TableCell>
              <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Date</strong></TableCell>
              <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Supplier</strong></TableCell>
              <TableCell><strong style={{color:"#546478", fontSize:"13px"}}>Total Amount</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <IconButton><Image /></IconButton>
                  <IconButton><GetApp /></IconButton>
                  <IconButton><InsertDriveFile /></IconButton>
                </TableCell>
                <TableCell>{row.itemId}</TableCell>
                <TableCell>{new Date(row.created_at).toLocaleDateString()}</TableCell>
                <TableCell>{row.submissionBy || 'N/A'}</TableCell>
                <TableCell>{row.submissionMethod || 'N/A'}</TableCell>
                <TableCell>{row.ownedBy || 'N/A'}</TableCell>
                <TableCell>{new Date(row.created_at).toLocaleDateString()}</TableCell>
                <TableCell>{row.supplier || '---'}</TableCell>
                <TableCell>{row.totalAmount ? `USD ${row.totalAmount}` : '---'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SubmissionHistory;

