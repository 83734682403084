import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Select, MenuItem, TableBody, TableCell, TableContainer, TableRow, Typography, Tooltip, IconButton, Switch } from "@mui/material";
import { HelpOutline } from '@mui/icons-material';
import { Textarea } from 'flowbite-react';
import SupAddModal from './SupAddModal';
import { baseURL } from '../../Config';
import { useParams } from 'react-router-dom';

  const token = localStorage.getItem('token');

const TableAllSup = () => {
  const {id:slugId} = useParams();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [category, setCategory] = useState('');
  const [taxRate, setTaxRate] = useState('GBP');
  const [paymentData, setPaymentData] = useState('');
  const [customerFinalData, setCustomerFinalData] = useState('');
  const [open, setOpen] = useState(false);
  const [paidSwitch, setPaidSwitch] = useState(false);
  const [lineItemSwitch, setLineItemSwitch] = useState(false);
  const [supplierData,setSupplierData] = useState(null);
  const [categoriesData,setCategoriesData] = useState(null);
  const [customersData,setCustomersData] = useState(null);
  const [paymentMethodsData,setPaymentMethodsData] = useState(null);
  const [costData,setCostData] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get(`${baseURL}/supplier/${slugId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSupplierData(response.data);
            console.log("Fetched Suppliers Data:", response.data); // Console log the fetched data
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchAllCategories = async () => {
      try {
          const response = await axios.get(`${baseURL}/category/getAll`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          });
          setCategoriesData(response.data);
          console.log("Fetched Categories Data:", response.data); // Console log the fetched data
      } catch (error) {
          console.error("Error fetching data:", error);
      }
  };
  const fetchAllCustomers = async () => {
    try {
        const response = await axios.get(`${baseURL}/customer`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setCustomersData(response.data);
        console.log("Fetched customers Data:", response.data); // Console log the fetched data
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
const fetchAllPaymentMethods = async () => {
  try {
      const response = await axios.get(`${baseURL}/payment-method`, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      setPaymentMethodsData(response.data);
      console.log("Fetched payments Data:", response.data); // Console log the fetched data
  } catch (error) {
      console.error("Error fetching data:", error);
  }
};

    fetchData();
    fetchAllCategories();
    fetchAllCustomers();
    fetchAllPaymentMethods();
}, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDropdownOpen = (dropdown) => {
    setOpenDropdown(dropdown);
  };

  const handleDropdownClose = () => {
    setOpenDropdown(null);
  };

  const handlePatchApiCall = async () => {
    const data = {
      category,
      markAsPaid:false,
      extractLineItems:true,
      taxRate,
      paidSwitch,
      lineItemSwitch
    };

    
  }
  return (
    <TableContainer
      component="div"
      sx={{
        boxShadow: 'none',
        padding: 2,
        marginTop: -4,
      }}
    >
      <Table sx={{ borderCollapse: 'collapse', width: '100%' }}>
        <TableBody>
          <TableRow>
            <TableCell colSpan={2} sx={{ border: 'none' }}>
              <h3 style={{ color: '#1976d2', fontSize: "18px", fontWeight: "bold" }}>{supplierData?.name}</h3>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} sx={{ border: 'none' }}>
              <Typography sx={{ fontSize: "13px", marginTop: '-12px' }}>
                Total spent: GBP 52.17 | Total Items: 3 | Inbox: <span style={{ textDecoration: 'underline', color: '#1976d2', cursor: 'pointer' }}>3 View</span> | Archive: <span style={{ textDecoration: 'underline', color: '#1976d2', cursor: 'pointer' }}>0 View</span>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{ marginTop: '-15px' }}>
            <TableCell style={{ width: '22%', border: 'none' }}>
              <span>Name <span style={{ color: "red" }}>*</span></span>
            </TableCell>
            <TableCell sx={{ border: 'none', marginLeft: -150 }}>
              <input type="text" value={supplierData?.name} readOnly style={{ width: "220px", fontSize: "13px", borderRadius: "5px" }} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} sx={{ border: 'none' }}>
              <h3 style={{ fontWeight: "bold", marginTop: '-7px' }}>Supplier Rules</h3>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} sx={{ border: 'none' }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: "13px", marginTop: '-20px' }}>
                  Duplicate Cost Item
                  <Tooltip title="Explanation of Duplicate Cost Item">
                    <IconButton size="small">
                      <HelpOutline fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Select
                    sx={{ height: "30px", width: "250px", fontSize: "13px", marginLeft: 2 }}
                    value={costData}
                    onChange={(e) => setCostData(e.target.value)}
                    open={openDropdown === 'cost'}
                    onClose={handleDropdownClose}
                    onOpen={() => handleDropdownOpen('cost')}
                    displayEmpty
                  >
                    <MenuItem value="">
                      Follow Systems Settings
                    </MenuItem>
                    <MenuItem value={10}>Follow Systems Settings</MenuItem>
                    <MenuItem value={20}>Automatic</MenuItem>
                    <MenuItem value={30}>Review</MenuItem>
                    <MenuItem value={40}>Off</MenuItem>
                  </Select>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: "13px", marginTop: '-20px', marginRight: 3 }}>
                  Tax Rate
                  <Tooltip title="Explanation of Tax Rate">
                    <IconButton size="small">
                      <HelpOutline fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Select
                    sx={{ height: "30px", width: "250px", fontSize: "13px", marginLeft: 3 }}
                    value={taxRate}
                    onChange={(e) => setTaxRate(e.target.value)}
                    open={openDropdown === 'tax'}
                    onClose={handleDropdownClose}
                    onOpen={() => handleDropdownOpen('tax')}
                    displayEmpty
                  >
                    <MenuItem value="">
                      Extracted Amount
                    </MenuItem>
                  </Select>
                </div>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} sx={{ border: 'none' }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: "13px", marginTop: '-20px' }}>
                  Category
                  <Select
                    sx={{ height: "30px", width: "250px", fontSize: "13px", marginLeft: 13.5 }}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    open={openDropdown === 'category'}
                    onClose={handleDropdownClose}
                    onOpen={() => handleDropdownOpen('category')}
                    displayEmpty
                  >
                    {
                        categoriesData?.map((item) => (
                          <MenuItem value={item._id}>{item.name}</MenuItem>
                        ))
                      }
                    
                  </Select>
                </div>
                <div style={{ display: 'flex', fontSize: "13px", marginRight: 1.5, marginTop: -14 }}>
                  Currency
                  <Select
  sx={{ height: "30px", width: "250px", fontSize: "13px", marginLeft: 7 }}
  value={taxRate} // Set to taxRate variable
  onChange={(e) => setTaxRate(e.target.value)}
  open={openDropdown === 'currency'}
  onClose={handleDropdownClose}
  onOpen={() => handleDropdownOpen('currency')}
  displayEmpty
>
  <MenuItem value="GBP">GBP</MenuItem> {/* Set the display text for GBP */}
  <MenuItem value={5}>5%</MenuItem>
  <MenuItem value={10}>10%</MenuItem>
  <MenuItem value={20}>20%</MenuItem>
</Select>

                  {/* <input style={{height:"30px",width:"250px",border:"1px solid gray",borderRadius:"4px", marginLeft: 13.5}}/> */}
                  
                </div>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} sx={{ border: 'none' }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: "13px", marginTop: '-20px' }}>
                  Customers
                  <Select
                    sx={{ height: "30px", width: "250px", fontSize: "13px", marginLeft: 12 }}
                    value={customerFinalData}
                    onChange={(e) => setCustomerFinalData(e.target.value)}
                    open={openDropdown === 'customers'}
                    onClose={handleDropdownClose}
                    onOpen={() => handleDropdownOpen('customers')}
                    displayEmpty
                  >
                    {
                      customersData?.map((item)=>(
                        <MenuItem value={item?._id}>{item?.name}</MenuItem>
                      ))
                    }
                    
                  </Select>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: "13px", marginTop: '-16px', marginLeft: 13 }}>
                  Payment Method
                  <Select
                    sx={{ height: "30px", width: "250px", fontSize: "13px", marginLeft: 2 }}
                    value={paymentData}
                    onChange={(e) => setPaymentData(e.target.value)}
                    open={openDropdown === 'paymentMethod'}
                    onClose={handleDropdownClose}
                    onOpen={() => handleDropdownOpen('paymentMethod')}
                    displayEmpty
                  >
                    {
                      paymentMethodsData?.map((item)=>{
                        return (
                          <MenuItem value={item?._id}>{item?.name}</MenuItem>

                        )
                      })
                    }
                   
                  </Select>
                </div>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} sx={{ border: 'none' }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: "13px", marginTop: '-20px' }}>
                  Due Date
                  <Select
                    sx={{ height: "30px", width: "250px", fontSize: "13px", marginLeft: 13 }}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    open={openDropdown === 'dueDate'}
                    onClose={handleDropdownClose}
                    onOpen={() => handleDropdownOpen('dueDate')}
                    displayEmpty
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value={10}>Category 1</MenuItem>
                    <MenuItem value={20}>Category 2</MenuItem>
                    <MenuItem value={30}>Category 3</MenuItem>
                  </Select>
                </div>
                

<div style={{ display: 'flex', alignItems: 'center', fontSize: "13px", marginTop: '-16px', marginRight: 180 }}>
                  Mark as Paid
                  <Switch
                    checked={paidSwitch}
                    onChange={(e) => setPaidSwitch(e.target.checked)}
                    sx={{
                      marginLeft: 4,
                      '& .MuiSwitch-switchBase.Mui-checked': { color: '#1976d2' },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#1976d2' },
                      width: 60,
                      height: 40,
                    }}
                  />
                  {paidSwitch ? 'on' : 'off'}
                </div>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} sx={{ border: 'none' }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: "13px", marginTop: '-20px' }}>
                  Description
                  <Textarea style={{ marginLeft: 5, width: '250px', height: '50px', marginLeft: '90px' }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: "13px", marginTop: '-50px', marginRight: 180 }}>
                  Extract Line Items
                  <Switch
                    checked={lineItemSwitch}
                    onChange={(e) => setLineItemSwitch(e.target.checked)}
                    sx={{
                      marginLeft: 2,
                      '& .MuiSwitch-switchBase.Mui-checked': { color: '#1976d2' },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#1976d2' },
                      width: 60,
                      height: 40,
                    }}
                  />
                  {lineItemSwitch ? 'on' : 'off'}
                </div>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} sx={{ border: 'none' }}>
              <h3 style={{ fontWeight: "bold" }}>Smart Split Rules</h3>
              <p>Create line item rules which will be automatically applied to all items for this Supplier in the Inbox and for future submissions.
                <Tooltip title="">
                  <IconButton size="small">
                    <HelpOutline fontSize="small" />
                  </IconButton>
                </Tooltip>
              </p>
            </TableCell>
          </TableRow>
        </TableBody>
        <SupAddModal open={open} handleClose={handleClose} handleOpen={handleOpen} sx={{marginLeft:40}}/>
        <Button
          variant="contained"
          sx={{
            fontSize: '12px',
            padding: '4px 13px',
            marginLeft: '13px',
            border: "none",
            fontWeight: "bold",
            color: '#FFFF',
            backgroundColor: '#1976d2',
            boxShadow: "none",
            marginBottom: '10px',
          }}
          onClick={handleOpen}
        >
          Add Smart Split Rule
        </Button>
      </Table>
    </TableContainer>
  );
};

export default TableAllSup;
