import { useState, createContext } from "react";

export const BusinessContext = createContext()

export const BusinessProvider = ({ children }) => {
    const [selectedBusiness, setSelectedBusiness] = useState("my practice");
    const [isStatsChangedToggle,setIsStatsChangedToggle] = useState();
    const [list,setList] = useState(false);
    const [selectedItem,setSelectedItem]=useState(0)
    const [backRoute,setBackRoute]=useState('/')


    return (
        <BusinessContext.Provider value={{ selectedBusiness, 
        setSelectedBusiness ,
        setIsStatsChangedToggle,
        isStatsChangedToggle,
        list,
        setList,
        selectedItem,
        setSelectedItem,
backRoute,
setBackRoute,
        }}
        >
            {children}
        </BusinessContext.Provider>
    )

}

