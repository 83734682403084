import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { baseURL } from '../Config';
import { Button, FormControl, TextField } from '@mui/material';

const EditUser = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('-');
  const [practiceNumber, setPracticeNumber] = useState();
  const [manager, setManager] = useState('');
  const [emailIn, setEmailIn] = useState('');

  const [businessData, setBusinessData] = useState();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const fetchData = async () => {
    const token = localStorage.getItem('token');
    const id = queryParams.get('id');
    const userToken = localStorage.getItem('userToken');
    try {
      const response = await axios.get(`${baseURL}/business/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      //  console.log(response.data.token+"sass")
      //  setSupplierData(response.data);
      //   localStorage.setItem("token", response.data.token)
      console.log('Change Suppliers Data:', response.data); // Console log the fetched data
      //   window.location.reload();
      setBusinessData(response?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updateDetails = async () => {
    const token = localStorage.getItem('token');
    const id = queryParams.get('id');
    const userToken = localStorage.getItem('userToken');
    const payload = {
      email: email,
      name: firstName,
      crnNumber: practiceNumber,
    };
    try {
      const response = await axios.patch(`${baseURL}/business/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      //  console.log(response.data.token+"sass")
      //  setSupplierData(response.data);
      //   localStorage.setItem("token", response.data.token)
      console.log('Change Suppliers Data:', response.data); // Console log the fetched data
      //   window.location.reload();
      // setBusinessData(response?.data)
      alert('Updated successfully');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (businessData) {
      setFirstName(businessData?.name);
      setEmail(businessData?.email);
      setPracticeNumber(businessData?.crnNumber);
    }
  }, [businessData]);

  return (
    <div className='p-6 bg-gray-100 min-h-screen'>
      <div className='max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md'>
        <h2 className='text-xl font-semibold mb-4'>
          Details for {businessData?.name}
        </h2>
        <div className='space-y-4'>
          <FormControl fullWidth>
            <div className='text-sm font-bold'>First Name</div>
            <TextField
              type='text'
              value={firstName}
              fullWidth
              onChange={(e) => setFirstName(e.target.value)}
              // className="mt-1 p-2 w-full border rounded"
            />
          </FormControl>
          {/* <div>
            <label className="block text-sm font-medium">Last Name</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="mt-1 p-2 w-full border rounded"
            />
          </div> */}
          <FormControl fullWidth>
            <div className='block text-sm font-bold'>Email</div>
            <TextField
              type='email'
              value={email}
              disabled
              // className='mt-1 p-2 w-full border rounded bg-gray-100'
            />
          </FormControl>
          <div>
            <label className='text-sm font-bold'>Practice Number</label>
            <input
              type='text'
              value={practiceNumber}
              onChange={(e) => setPracticeNumber(e.target.value)}
              className='mt-1 p-2 w-full border rounded'
            />
          </div>
          <div>
            <label className='block text-sm font-medium'>Mobile Number</label>
            <input
              type='text'
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className='mt-1 p-2 w-full border rounded'
            />
          </div>
        </div>
        {/* <h2 className="text-xl font-semibold mt-6 mb-4">Details affecting your business</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium">Manager</label>
            <input
              type="text"
              value={manager}
              onChange={(e) => setManager(e.target.value)}
              className="mt-1 p-2 w-full border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Email-In Address</label>
            <input
              type="text"
              value={emailIn}
              onChange={(e) => setEmailIn(e.target.value)}
              className="mt-1 p-2 w-full border rounded"
            />
          </div>
        </div>
        <h2 className="text-lg font-semibold mt-6">Costs</h2>
        <p className="text-sm text-gray-600">Give these email addresses to your suppliers.</p>
        <div className="mt-2 text-sm">
          <p>Single documents: <span className="font-mono">charan.singh.charan@dext.cc</span></p>
          <p>Multiple documents: <span className="font-mono">charan.singh.charan@multiple.dext.cc</span></p>
        </div>
        <h2 className="text-lg font-semibold mt-4">Sales</h2>
        <div className="mt-2 text-sm">
          <p>Single documents: <span className="font-mono">charan.singh.charan+sales@dext.cc</span></p>
          <p>Multiple documents: <span className="font-mono">charan.singh.charan+sales@multiple.dext.cc</span></p>
        </div> */}
      </div>
      <Button onClick={updateDetails}>Update</Button>
    </div>
  );
};

export default EditUser;
