import React, { useState } from 'react';
import { Button, Modal, Box, Grid, Alert, Snackbar, colors, Tooltip, IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  p: 3,
  color: '#3f4b5a',
};

const SupAddModal = ({ open, handleClose, handleOpen }) => {
  const [val, setVal] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [errors, setErrors] = useState({});
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const handleAdd = () => {
    const newInputRow = ['', '', '', '', '', ''];
    setVal([...val, newInputRow]);
  };

  const handleChange = (e, rowIndex, inputIndex) => {
    const updatedVal = [...val];
    updatedVal[rowIndex][inputIndex] = e.target.value;
    setVal(updatedVal);

    
    const updatedErrors = { ...errors };
    delete updatedErrors[`val-${rowIndex}-${inputIndex}`];
    setErrors(updatedErrors);
  };

  const handleDelete = (rowIndex) => {
    const updatedVal = [...val];
    updatedVal.splice(rowIndex, 1);
    setVal(updatedVal);
  };

  const handleAddRow = () => {
    const newDataRow = ['', '', '', '', '', ''];
    setDataRows([...dataRows, newDataRow]);
  };

  const handleChangeInput = (e, rowIndex, inputIndex) => {
    const updatedDataRows = [...dataRows];
    updatedDataRows[rowIndex][inputIndex] = e.target.value;
    setDataRows(updatedDataRows);


    const updatedErrors = { ...errors };
    delete updatedErrors[`dataRows-${rowIndex}-${inputIndex}`];
    setErrors(updatedErrors);
  };

  const handleDeleteRow = (rowIndex) => {
    const updatedDataRows = [...dataRows];
    updatedDataRows.splice(rowIndex, 1);
    setDataRows(updatedDataRows);
  };

  const validate = () => {
    const newErrors = {};

    val.forEach((rowData, rowIndex) => {
      rowData.forEach((inputValue, inputIndex) => {
        if (!inputValue) {
          newErrors[`val-${rowIndex}-${inputIndex}`] = '';
        }
      });
    });

    dataRows.forEach((rowData, rowIndex) => {
      rowData.forEach((inputValue, inputIndex) => {
        if (!inputValue) {
          newErrors[`dataRows-${rowIndex}-${inputIndex}`] = '';
        }
      });
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      console.log('Form submitted successfully:', { val, dataRows });
      handleClose(); 
    } else {
      setShowErrorPopup(true); 
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description" sx={{marginLeft:13}}>
      <Box sx={style}>
        <div style={{ color: '#1976d2', boxShadow: '0 4px 2px -2px #F5F5F5', padding: 5, fontSize: '20px', fontWeight: 'bold' }}>
          Smart Split: Zylker Thread & Weave N
        </div>
        <div
          style={{
            border: '1px solid grey',
            width: '100%',
            marginTop: 10,
            padding: 10,
            borderRadius: 2,
            fontSize: '12px',
          }}
        >
            <IconButton>
                <ErrorIcon/>
            </IconButton>
          Use <a href='https://help.dext.com/en/s/article/how-to-use-smart-split'
          style={{
            color:"blue"
          }}>Smart Split</a> to Create <a href='https://help.dext.com/en/s/article/how-to-use-smart-split'
          style={{
            color:"blue"
          }}>Line Item</a> on new documents from this supplier according to a fixed amount or percentage of the total
        </div>

       
        <div style={{ fontWeight: 'bold', marginTop: 8 }}>FIXED AMOUNT</div>
        <table style={{ width: '100%', marginTop: 10, boxShadow: '0 4px 2px -2px #F5F5F5' }}>
          <thead>
            <tr>
              <th colSpan="2">Description</th>
              <th colSpan="2">Category</th>
              <th colSpan="2">Tax rate</th>
              <th colSpan="2">Customer</th>
              <th colSpan="2">Quantity</th>
              <th colSpan="2">Total Amount</th>
            </tr>
          </thead>
        </table>
        <table>
          <tbody>
            {val.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {rowData.map((inputValue, inputIndex) => (
                  <td key={inputIndex} style={{ paddingRight: '10px' }}>
                    <input
                      type="text"
                      value={inputValue}
                      onChange={(e) => handleChange(e, rowIndex, inputIndex)}
                      style={{ width: '100%', boxSizing: 'border-box', padding: '8px', marginTop: '10px' }}
                    />
                    {errors[`val-${rowIndex}-${inputIndex}`] && (
                      <div style={{ color: 'red', fontSize: '12px' }}>{errors[`val-${rowIndex}-${inputIndex}`]}</div>
                    )}
                  </td>
                ))}
                <td>
                  <button onClick={() => handleDelete(rowIndex)} style={{ fontWeight: 'bold', color: 'red' }}>
                    X
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button
          variant="contained"
          sx={{
            fontSize: '12px',
            padding: '4px 13px',
            marginRight: '10px',
            border: 'none',
            fontWeight: 'bold',
            color: '#ffff',
            backgroundColor:'#1976d2',
            boxShadow: 'none',
            marginTop: 5,
          }}
          onClick={handleAdd}
        >
          + Add a fixed amount rule
        </Button>

      
        <div style={{ fontWeight: 'bold', marginTop: 20 }}>PERCENTAGE AMOUNT</div>
        <table style={{ width: '100%', marginTop: 10, boxShadow: '0 4px 2px -2px #F5F5F5' }}>
          <thead>
            <tr>
              <th colSpan="2">Description</th>
              <th colSpan="2">Category</th>
              <th colSpan="2">Tax rate</th>
              <th colSpan="2">Customer</th>
              <th colSpan="2">Quantity</th>
              <th colSpan="2">Total Amount</th>
            </tr>
          </thead>
        </table>
        <table>
          <tbody>
            {dataRows.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {rowData.map((inputValue, inputIndex) => (
                  <td key={inputIndex} style={{ paddingRight: '10px' }}>
                    <input
                      type="text"
                      value={inputValue}
                      onChange={(e) => handleChangeInput(e, rowIndex, inputIndex)}
                      style={{ width: '100%', boxSizing: 'border-box', padding: '8px', marginTop: '10px' }}
                    />
                    {errors[`dataRows-${rowIndex}-${inputIndex}`] && (
                      <div style={{ color: 'red', fontSize: '12px' }}>{errors[`dataRows-${rowIndex}-${inputIndex}`]}</div>
                    )}
                  </td>
                ))}
                <td>
                  <button onClick={() => handleDeleteRow(rowIndex)} style={{ fontWeight: 'bold', color: 'red' }}>
                    X
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button
          variant="contained"
          sx={{
            fontSize: '12px',
            padding: '4px 13px',
            marginRight: '10px',
            border: 'none',
            fontWeight: 'bold',
            color: '#ffff',
            backgroundColor: '#1976d2',
            boxShadow: 'none',
            marginTop: 5,
          }}
          onClick={handleAddRow}
        >
          + Add a percentage amount rule
        </Button>

        <Grid container justifyContent="flex-end" style={{ marginTop: 20 }}>
          <Button
            variant="contained"
            sx={{
              fontSize: '12px',
              padding: '4px 13px',
              marginRight: '10px',
              border: 'none',
              fontWeight: 'bold',
              color: '@ff5a02',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              '&:hover': {
      backgroundColor: 'lightgrey', 
     
    }
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              fontSize: '12px',
              padding: '4px 13px',
              marginRight: '10px',
              border: 'none',
              fontWeight: 'bold',
              color: '#ffff',
              backgroundColor: '#1976d2',
              
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
        
        <Snackbar
          open={showErrorPopup}
          autoHideDuration={3000}
          onClose={() => setShowErrorPopup(false)}
        >
          <Alert onClose={() => setShowErrorPopup(false)} severity="error" sx={{ width: '100%' }}>
            Please fill in all the required fields.
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default SupAddModal;
