
import React, { useState, useEffect } from 'react';
import { Button, TextField, FormControl, Box, IconButton, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { baseURL } from '../Config';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      navigate('/dashboard/costs/inbox');
    }
  }, [navigate]);

  const handleLogin = async () => {
    console.log("Logging in with:", { email, password });
    const isValid = validateLoginForm();
    if (isValid) {
      try {
        const response = await axios.post(`${baseURL}/auth/login`, {
          email,
          password,
        });

        console.log("Login response:", response);

        const userInfo = {
          name: response?.data?.practiceName || response.data.name,
          userId: response.data.userId,
        };

        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userToken', response.data.userToken);

        localStorage.removeItem('user-completed-steps');

        navigate('/dashboard/costs/inbox');
      } catch (error) {
        console.error('Login error:', error);
        setErrors({ email: '', password: error.response?.data?.message || 'Login failed. Please try again.' });
      }
    }
  };

  const validateLoginForm = () => {
    let emailError = '';
    let passwordError = '';

    if (!email) {
      emailError = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailError = 'Email address is invalid';
    }

    if (!password) {
      passwordError = 'Password is required';
    } else if (password.length < 6) {
      passwordError = 'Password must be at least 6 characters';
    }

    if (emailError || passwordError) {
      setErrors({ email: emailError, password: passwordError });
      return false;
    }
    return true;
  };

  const handleClickShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <div className='w-full flex'>
      <Box className="p-8 h-[100vh]  shadow rounded w-[50%] flex justify-center items-center">
        <div className='w-[50%] flex flex-col'>
          <img className='w-[35%] ' src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
          <div className=" text-[25px] mb-8 font-bold text-[#1976d2]">
            Login with your email Address
          </div>
          <div style={{ marginBottom: '1.5rem' }}>
            <input
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                width: '100%',
                padding: '8px',
                borderRadius: '4px',
                border: errors.email ? '1px solid red' : '1px solid #ccc',
                fontSize: '16px',
              }}
            />
            {errors.email && (
              <span style={{ color: 'red', fontSize: '12px' }}>{errors.email}</span>
            )}
          </div>


          <div style={{ marginBottom: '10px' }}>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  width: '100%',
                  padding: '8px',
                  paddingRight: '40px',
                  borderRadius: '4px',
                  border: errors.password ? '1px solid red' : '1px solid #ccc',
                  fontSize: '16px',
                }}
              />
              <button
                onClick={handleClickShowPassword}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '16px',
                  color: '#244af0',
                }}
                aria-label={showPassword ? 'Hide password' : 'Show password'}
              >
                {showPassword ? <VisibilityOutlinedIcon className='text-[#1976d2]' /> : <VisibilityOffOutlinedIcon className='text-[#1976d2]' />}
              </button>
            </div>
            {errors.password && (
              <span style={{ color: 'red', fontSize: '12px' }}>{errors.password}</span>
            )}
          </div>

          <button onClick={() => { navigate('/forgot-password') }} className='w-fit p-0 text-sm text-[#1976d2] hover:underline cursor-pointer font-semibold'>Forgot Password?</button>

          <div className=' mt-4 flex  justify-between'>
            <button


              onClick={handleLogin}
              className=" bg-[#1976d2] text-[#ffff] px-5 py-2 hover:shadow-md  rounded-md "
            >
              Login
            </button>
            <Button
              variant="text"
              sx={{
                color: '#515251',
                fontSize: '14px',
                padding: '0',
                '&:hover': {
                  color: '',
                },
              }}
              href='/register'
              className=" text-xs"
            >
              Don't have an account? Sign Up
            </Button>
          </div>
        </div>
      </Box>
      <Box className="p-8 h-[100vh]  shadow rounded w-[50%] flex flex-col justify-center items-center bg-[#b0b1b0]">
        <img className='w-[45%] mb-6' src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Product Demo" />
        <div className="text-center text-[#1508c9] text-[18px] font-semibold">
          Product Demo
        </div>
        <div className="text-center text-[16px] mb-5 font-normal">
          Hello from Zoom Accountants
        </div>
        <Button
          variant="outlined"
          sx={{

            backgroundColor: "#b0b1b0",
            border: '1px solid #1508c9',
            fontWeight: "bold",
            fontSize: "14px",
            color: "#1508c9",
            textTransform: "none",
            '&:hover': {
              backgroundColor: "#1508c9",
              color: 'white'
            }
          }}
        >
          Read More
        </Button>
      </Box>
    </div>
  );
};

export default Login;
