// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1p68g1u-MuiButtonBase-root-MuiIconButton-root svg{
    font-size: 27px !important;
}
.MuiPaper-root.MuiPaper-elevation {
    /* height: auto;
    width: 15%;
    margin: 7rem 0rem 0rem 1rem;
    border-radius: 20px;
    opacity: 1;
    transform: none;
    transition: opacity 359ms cubic-bezier(0.4, 0, 0.2, 1), transform 239ms cubic-bezier(0.4, 0, 0.2, 1);
    top: -30px !important;
    left: 1129px !important;
    transform-origin: 320px 16.5469px; */
}
.css-1ozqarp {
width: 202px !important; 
background-color: #fff;
box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
padding: 0 !important;
font-size: 15px;
}
/* */`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;AACA;IACI;;;;;;;;;wCASoC;AACxC;AACA;AACA,uBAAuB;AACvB,sBAAsB;AACtB,8HAA8H;AAC9H,qBAAqB;AACrB,eAAe;AACf;AACA,IAAI","sourcesContent":[".css-1p68g1u-MuiButtonBase-root-MuiIconButton-root svg{\n    font-size: 27px !important;\n}\n.MuiPaper-root.MuiPaper-elevation {\n    /* height: auto;\n    width: 15%;\n    margin: 7rem 0rem 0rem 1rem;\n    border-radius: 20px;\n    opacity: 1;\n    transform: none;\n    transition: opacity 359ms cubic-bezier(0.4, 0, 0.2, 1), transform 239ms cubic-bezier(0.4, 0, 0.2, 1);\n    top: -30px !important;\n    left: 1129px !important;\n    transform-origin: 320px 16.5469px; */\n}\n.css-1ozqarp {\nwidth: 202px !important; \nbackground-color: #fff;\nbox-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);\npadding: 0 !important;\nfont-size: 15px;\n}\n/* */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
