export const filterFields = [
    // {
    //     'Ready': ['Ready', 'To Review']
    // },
    {
        'Tax': ['With Tax', 'Without Tax']
    },
    // {
    //     'Tax Rate': ['With Tax Rate', 'Without Tax Rate']
    // },
    {
        'Category': ['Category', 'Without Category']
    },
    {
        'Currency': ['Default Currency', 'Foregin Currency']
    },
    {
        'Read': ['Read', 'Unread']
    },
    // {
    //     'Publishing': ['Published', 'Unpublished']
    // },
    {
        'Flag': ['Flagged', 'Unflagged']
    },
    // {
    //     'Merged': ['Merged', 'Not Merged']
    // },
    // {
    //     'Duplicates': ['With Duplicates', 'Without Duplicates']
    // },
    {
        'Paid': ['paid', 'Unpaid']
    },
    // {
    //     'Has Match': ['Match', 'Non-Match']
    // },
    // {
    //     'Auto-categorised': ['Auto-categorised', 'Manually Categorised']
    // },

]