import React, { useState, useEffect } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { jwtDecode } from 'jwt-decode';
import { baseURL } from '../Config';

const TaxRates = () => {
  const [taxRates, setTaxRates] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showDefaultPopup, setShowDefaultPopup] = useState(false);
  const [formData, setFormData] = useState({ name: '', id: '', code: '', rate: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [errors, setErrors] = useState({});
  const [selectedButton, setSelectedButton] = useState('');
  const [hoveredButton, setHoveredButton] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetch(`${baseURL}/tax`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('Fetched Tax Rates:', data); // Log the fetched data
        setTaxRates(Array.isArray(data) ? data : []);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [token]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Please provide a valid name.';
    if (!formData.code) newErrors.code = 'Please provide a valid code.';
    if (!formData.id) newErrors.id = 'Please provide a valid ID.';
    if (!formData.rate) newErrors.rate = 'Please provide a valid rate.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddNewTaxRate = () => {
    setShowPopup(true);
    setIsEditing(false);
    setEditIndex(null);
    setFormData({ name: '', id: '', code: '', rate: '' });
  };

  const handleEditTaxRate = (index) => {
    setFormData(taxRates[index]);
    setIsEditing(true);
    setEditIndex(index);
    setShowPopup(true);
  };

  const handleDeleteTaxRate = (index) => {
    setDeleteIndex(index);
    setShowDeletePopup(true);
  };

  const handleAddOrUpdate = () => {
    if (validateForm()) {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.user._id;

      const url = isEditing
        ? `${baseURL}/tax/${formData._id}`
        : `${baseURL}/tax`;

      const method = isEditing ? 'PATCH' : 'POST';
      const requestData = {
        ...formData,
        percentage: formData.rate,
        user: userId,
      };

      fetch(url, {
        method,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(result => {
          if (isEditing) {
            const updatedTaxRates = [...taxRates];
            updatedTaxRates[editIndex] = result;
            setTaxRates(updatedTaxRates);
          } else {
            setTaxRates([...taxRates, result]);
          }
          setShowPopup(false);
        })
        .catch(error => console.error('Error saving data:', error));
    }
  };

  const handleDeleteConfirm = () => {
    const idToDelete = taxRates[deleteIndex]._id;

    fetch(`${baseURL}/tax/${idToDelete}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        setTaxRates(taxRates.filter((_, i) => i !== deleteIndex));
        setShowDeletePopup(false);
      })
      .catch(error => console.error('Error deleting data:', error));
  };

  const handleCancel = () => {
    setShowPopup(false);
    setShowDefaultPopup(false);
    setShowDeletePopup(false);
  };

  const handleDefaultTaxSetting = () => {
    setShowDefaultPopup(true);
  };

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  const styles = {
    container: {
      width: '80%',
      padding: '16px',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      height: 'auto',
    },
    tableHeader: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 0',
    },
    headerButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '10px',
    },
    headerButtonsLeft: {
      display: 'flex',
      gap: '10px',
    },
    headerButtonsRight: {
      display: 'flex',
    },
    button: (isHovered) => ({
      padding: '6px 15px',
      backgroundColor: '#1976d2',
      color: '#ffff',
      cursor: 'pointer',
      borderRadius: '5px',
      fontSize: '12px',
      fontWeight: 'bold',
      border: 'none',
      outline: 'none',
      transition: 'background 0.3s, box-shadow 0.3s',
      ...(isHovered && {
        backgroundColor: '#1976d2',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      }),
    }),
    buttonContainer: {
      display: 'inline-block',
    },
    costSaleButtonContainer: {
      display: 'inline-flex',
      borderRadius: '5px',
      overflow: 'hidden',
    },
    costSaleButton: (isHovered) => ({
      padding: '6px 15px',
      cursor: 'pointer',
      fontSize: '12px',
      fontWeight: 'bold',
      border: '2px solid #1976d2',
      outline: 'none',
      backgroundColor: 'white',
      transition: 'background 0.3s, box-shadow 0.3s',
      ...(isHovered && {
        backgroundColor: 'white',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }),
    }),
    costSaleButtonLast: {
      borderRight: '2px solid #1976d2',
    },
    selectedButton: {
      backgroundColor: '#1976d2',
      color: 'white',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      '& td, & th': {
        borderBottom: '1px solid #e0e0e0',
        padding: '6px',
        textAlign: 'left',
        fontSize: '14px',
      },
      '& tr:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
    thTd: {
      padding: '10px',
      textAlign: 'left',
      fontSize: '14px',
    },
    th: {
      backgroundColor: '#f2f2f2',
    },
    hidden: {
      display: 'none',
    },
    popup: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    popupContent: {
      background: '#fff',
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      width: '400px',
      maxWidth: '90%',
    },
    formRow: {
      marginBottom: '15px',
    },
    formLabel: {
      display: 'block',
      marginBottom: '5px',
      fontWeight: 'bold',
    },
    formInput: {
      width: '100%',
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #e0e0e0',
    },
    formButton: {
      padding: '10px 20px',
      backgroundColor: '#1976d2',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
      transition: 'background 0.3s',
      '&:hover': {
        backgroundColor: '#1565c0',
      },
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.tableHeader}>
        <div style={styles.headerButtons}>
          <div style={styles.headerButtonsLeft}>
            <Tooltip title="Add New Tax Rate">
              <IconButton
                style={styles.button(hoveredButton === 'addTaxRate')}
                onClick={handleAddNewTaxRate}
                onMouseEnter={() => setHoveredButton('addTaxRate')}
                onMouseLeave={() => setHoveredButton(null)}
              >
                ADD NEW TAX RATE
              </IconButton>
            </Tooltip>
            <Tooltip title="Set Default Tax Rates">
              <IconButton
                style={styles.button(hoveredButton === 'setDefaultTaxRate')}
                onClick={handleDefaultTaxSetting}
                onMouseEnter={() => setHoveredButton('setDefaultTaxRate')}
                onMouseLeave={() => setHoveredButton(null)}
              >
                SET DEFAULT TAX RATE
              </IconButton>
            </Tooltip>
          </div>
          <div style={styles.headerButtonsRight}>
            <Tooltip title="Help">
              <IconButton style={styles.button(hoveredButton === 'help')}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Name</th>
            <th style={styles.th}>Code</th>
            <th style={styles.th}>Rate</th>
            <th style={styles.th}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {taxRates.map((taxRate, index) => (
            <tr key={taxRate._id}>
              <td style={styles.thTd}>{taxRate.name}</td>
              <td style={styles.thTd}>{taxRate.code}</td>
              <td style={styles.thTd}>{taxRate.percentage}</td>
              <td style={styles.thTd}>
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEditTaxRate(index)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDeleteTaxRate(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Popup for Add/Edit Tax Rate */}
      {showPopup && (
        <div style={styles.popup}>
          <div style={styles.popupContent}>
            <h2>{isEditing ? 'Edit Tax Rate' : 'Add New Tax Rate'}</h2>
            <div style={styles.formRow}>
              <label style={styles.formLabel}>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                style={styles.formInput}
              />
              {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
            </div>
            <div style={styles.formRow}>
              <label style={styles.formLabel}>ID:</label>
              <input
                type="text"
                name="id"
                value={formData.id}
                onChange={handleInputChange}
                style={styles.formInput}
              />
              {errors.id && <p style={{ color: 'red' }}>{errors.id}</p>}
            </div>
            <div style={styles.formRow}>
              <label style={styles.formLabel}>Code:</label>
              <input
                type="text"
                name="code"
                value={formData.code}
                onChange={handleInputChange}
                style={styles.formInput}
              />
              {errors.code && <p style={{ color: 'red' }}>{errors.code}</p>}
            </div>
            <div style={styles.formRow}>
              <label style={styles.formLabel}>Rate:</label>
              <input
                type="number"
                name="rate"
                value={formData.rate}
                onChange={handleInputChange}
                style={styles.formInput}
              />
              {errors.rate && <p style={{ color: 'red' }}>{errors.rate}</p>}
            </div>
            <button onClick={handleAddOrUpdate} style={styles.formButton}>
              {isEditing ? 'Update' : 'Add'}
            </button>
            <button onClick={handleCancel} style={{ ...styles.formButton, backgroundColor: '#f44336' }}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Popup for Default Tax Rates */}
      {showDefaultPopup && (
        <div style={styles.popup}>
          <div style={styles.popupContent}>
            <h2>Default Tax Rates</h2>
            <p>Are you sure you want to set the default tax rates?</p>
            <button onClick={() => { /* Handle default tax rates logic here */ }} style={styles.formButton}>
              Confirm
            </button>
            <button onClick={handleCancel} style={{ ...styles.formButton, backgroundColor: '#f44336' }}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Popup for Delete Confirmation */}
      {showDeletePopup && (
        <div style={styles.popup}>
          <div style={styles.popupContent}>
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this tax rate?</p>
            <button onClick={handleDeleteConfirm} style={styles.formButton}>
              Confirm
            </button>
            <button onClick={handleCancel} style={{ ...styles.formButton, backgroundColor: '#f44336' }}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaxRates;
