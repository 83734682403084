export function Switch({ options, selected, handleChange }) {
  return (
    <div className='w-full flex mt-5 border border-prm rounded-l-full rounded-r-full'>
      {options.map((op, ind) => (
        <div
          key={op.name}
          className={`w-1/2 flex justify-center cursor-pointer ${
            op.selected === selected
              ? ind === 0
                ? 'bg-prm rounded-l-full text-white'
                : 'bg-prm rounded-r-full text-white'
              : 'bg-white rounded-full text-black'
          } p-2`}
          onClick={() => handleChange(op.selected)}
        >
          {op.name}
        </div>
      ))}
    </div>
  );
}
