import React, { useState } from 'react';
import { Container, Typography, Box, Paper, FormControl, InputLabel, Select, MenuItem, FormGroup, Switch, IconButton, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
// import { styled } from '@mui/material/styles';


// const CustomSwitch = styled(Switch)({
//     height: 38, 
//     padding: 8,
//     marginLeft:50,
//     '& .MuiSwitch-switchBase.Mui-checked': {
//         color: 'orange',
//         '&:hover': {
//             backgroundColor: 'rgba(255, 165, 0, 0.08)',
//         },
//     },
//     '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
//         backgroundColor: 'orange',
//     },
// });

const SystemSetting = () => {
    const [switchState, setSwitchState] = useState(false);

    const handleSwitchChange = (event) => {
        const checked = event.target.checked;
        setSwitchState(checked);
    };

    return (
        <>
            <Container maxWidth="md" style={{ fontFamily: 'Roboto, sans-serif', paddingTop: '20px', marginLeft: '0', backgroundColor:"#f9fafb"}}>
                <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', fontSize: '24px', textAlign: 'left' }}>
                    System Setting
                </Typography>
                <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px', textAlign: 'left', fontWeight: "bold" ,}}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', fontSize: '13px' }}>
                        PAYMENT STATUS
                    </Typography>
                    <Box border={1} padding={1} borderRadius={1} marginBottom={1} style={{ display: 'flex', alignItems: 'center', width: '56%',  color:'#546478' }}>
                        <Tooltip title="Help"> 
                            <IconButton style={{marginBottom:'10px' , color:"#1976d2"}}>
                                <ErrorIcon />
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body2">
                            Choose whether your documents should be marked as ‘Paid’ or ‘Not paid’ by default
                        </Typography>
                    </Box>
                    <form>
                        <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px', color:'#546478' }}>
                                Receipts:
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="receipts1"
                                    fullWidth
                                    variant="outlined"
                                    style={{ width: '40%', height:"40px", fontSize:'13px', marginLeft:'70px' }}
                                    value={'option1'}
                                >
                                    <MenuItem value="option1">Paid</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px', color:'#546478' }}>
                                Invoices:
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="receipts2"
                                    fullWidth
                                    variant="outlined"
                                    style={{  width: '40%', height:"40px", fontSize:'13px', marginLeft:'70px'}}
                                >
                                    <MenuItem value="option1">Not Paid</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px', color:'#546478' }}>
                                Credit notes:
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="receipts3"
                                    fullWidth
                                    variant="outlined"
                                    style={{ width: '40%', height:"40px", fontSize:'13px', marginLeft:'70px' }}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </form>
                </Paper>

                <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px', textAlign: 'left' }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' , fontSize:"13px"}}>
                        DUE DATES FOR COSTS INVOICES
                    </Typography>
                    <Box border={1} padding={1} marginBottom={1} borderRadius={1} style={{ display: 'flex', alignItems: 'center', width:'56%' , color:'#546478'}}>
                        <Tooltip title="Help">
                            <IconButton style={{marginBottom:"4px", color:"#1976d2"}}>
                                <ErrorIcon />
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body2">
                            Choose a default Due Date for Invoices that are uploaded to your account
                        </Typography>
                    </Box>
                    <form>
                        <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px' , color:'#546478'}}>
                                Default to:
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="receipts4"
                                    fullWidth
                                    variant="outlined"
                                    style={{ width: '40%', height:"40px", fontSize:'13px', marginLeft:'70px' }}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </form>
                </Paper>

                <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px', textAlign: 'left' }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', fontSize:"14px" }}>
                        DUE DATES FOR SALES INVOICES
                    </Typography>
                    <Box border={1} padding={1} marginBottom={1} borderRadius={1} style={{ display: 'flex', alignItems: 'center', width:"56%", color:'#546478' }}>
                        <Tooltip title="Help">
                            <IconButton>
                                <ErrorIcon sx={{color:"#1976d2"}}/>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body2">
                            Choose a default Due Date for Invoices that are uploaded to your account
                        </Typography>
                    </Box>
                    <form>
                        <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px' , color:'#546478'}}>
                                Default to:
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="receipts5"
                                    fullWidth
                                    variant="outlined"
                                    style={{  width: '40%', height:"40px", fontSize:'13px', marginLeft:'70px'}}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </form>
                </Paper>

                <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px', textAlign: 'left' }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', fontSize:"13px" }}>
                        NET AMOUNT
                    </Typography>
                    <Box border={1} padding={1} marginBottom={1} borderRadius={1} style={{ display: 'flex', alignItems: 'center', width: '56%', color:'#546478' }}>
                        <Tooltip title="Help">
                            <IconButton>
                                <ErrorIcon sx={{color:"#1976d2"}}/>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body2">
                            Choose whether to include the net value field in <a href='#' style={{ color: "blue" }}>CSV downloads</a>
                        </Typography>
                    </Box>
                    <FormGroup style={{ margin: '10px 0', display: 'flex' }}>
                        <Typography variant="body1" style={{ marginRight: '10px', color:'#546478',fontSize:'15px'}}>
                           Show net amount:
                            <Switch
                                checked={switchState}
                                onChange={handleSwitchChange}
                                color={switchState ? 'primary' : 'default'}
                            />
                            {switchState ? 'On' : 'Off'}
                        </Typography>
                    </FormGroup>
                </Paper>

                <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px', textAlign: 'left', fontWeight: "bold" }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' , fontSize:"13px"}}>
                        DUPLICATE ITEMS
                    </Typography>
                    <Box border={1} padding={1} marginBottom={1} borderRadius={1} style={{ display: 'flex', alignItems: 'center', width: '56%', color:'#546478' }}>
                        <Tooltip title="Help">
                            <IconButton>
                                <ErrorIcon sx={{color:"#1976d2"}}/>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body2">
                            Confirm <a href='#' style={{ color: "blue" }}>duplicate items</a> automatically, review them yourself or turn this off.
                            
                        </Typography>
                    </Box>
                    <form style={{ marginBottom: '20px' }}>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" style={{ marginRight: '10px', minWidth: '160px', fontSize:'15px' , color:'#546478'}}>
                                Duplicate cost items:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="duplicate-cost-items"></InputLabel>
                                <Select
                                    id="duplicate-cost-items"
                                    fullWidth
                                    variant="outlined"
                                    style={{ width: '40%', height:"40px", fontSize:'13px', marginLeft:'35px' }}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" style={{ marginRight: '10px', minWidth: '160px', color:'#546478',fontSize:'15px' }}>
                                Duplicate sales items:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="duplicate-sales-items"></InputLabel>
                                <Select
                                    id="duplicate-sales-items"
                                    fullWidth
                                    variant="outlined"
                                    style={{width: '40%', height:"40px", fontSize:'13px', marginLeft:'36px'}}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </form>
                </Paper>

                <Paper variant="outlined" style={{ padding: '20px', marginBottom: '20px' }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' , fontSize:"13px"}}>
                        CATEGORIZATION
                    </Typography>
                    <Box border={1} padding={1} marginBottom={1} borderRadius={1} style={{ display: 'flex', alignItems: 'center', width: '56%', color:'#546478' }}>
                        <Tooltip title="Help">
                            <IconButton>
                                <ErrorIcon sx={{color:"#1976d2"}}/>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body2">
Specify how Dext Prepare should apply <a href='#' style={{ color: "blue" }}>categories</a> to your items.
                            
                        </Typography>
                    </Box>
                    <Box border={1} padding={1} marginBottom={1} borderRadius={1} style={{ width: '56%'}}>
                        <Typography variant="h5" gutterBottom style={{fontSize:'15px', fontWeight:"bold", color:'#546478'}}>
                            <Tooltip title="Help">
                                <IconButton>
                                    <ErrorIcon sx={{color:"#1976d2"}}/>
                                </IconButton>
                            </Tooltip>
                            Autocategorization
                            <Typography style={{fontSize:'13px', marginLeft:'30px', color:'#546478'}}>
                            Whenever you see this icon next to an item’s category, it's been set by our Autocategorisation algorithm.
                            </Typography>
                        </Typography>
                       
                    </Box>
                    <FormGroup style={{ margin: '10px 0', display: 'flex' }}>
                        <Typography variant="body1" style={{ marginRight: '10px' , color:'#546478',fontSize:'15px'}}>
                            Autocategorization:
                            <Switch
                                checked={switchState}
                                onChange={handleSwitchChange}
                                color={switchState ? 'primary' : 'default'}
                            />
                            {switchState ? 'On' : 'Off'}
                        </Typography>
                    </FormGroup>
                    <form style={{ marginBottom: '20px' }}>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '160px' , color:'#546478',fontSize:'15px'}}>
                                Default category:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="receipts1"></InputLabel>
                                <Select
                                    id="receipts1"
                                    fullWidth
                                    variant="outlined"
                                    style={{ width: '40%', height:"40px", fontSize:'13px', marginLeft:'28px' }}
                                    value={'option1'}
                                >
                                    <MenuItem value="option1"></MenuItem>
                                    <MenuItem value="option2"></MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '160px', color:'#546478',fontSize:'15px'}}>
                                Category display:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="receipts2"></InputLabel>
                                <Select
                                    id="receipts2"
                                    fullWidth
                                    variant="outlined"
                                    style={{width: '40%', height:"40px", fontSize:'13px', marginLeft:'28px'}}
                                >
                                    <MenuItem value="option1"></MenuItem>
                                    <MenuItem value="option2"></MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '160px', color:'#546478',fontSize:'15px' }}>
                                Category sort:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="receipts3"></InputLabel>
                                <Select
                                    id="receipts3"
                                    fullWidth
                                    variant="outlined"
                                    style={{width: '40%', height:"40px", fontSize:'13px', marginLeft:'28px'}}
                                >
                                    <MenuItem value="option1"></MenuItem>
                                    <MenuItem value="option2"></MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </form>
                </Paper>

                <Paper variant="outlined" style={{ padding: '20px', marginBottom: '20px' }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', fontSize:"13px" }}>
                        MANAGE TAX RATES
                    </Typography>
                    <Box border={1} padding={1} marginBottom={1} borderRadius={1} style={{ display: 'flex', alignItems: 'center', width: '56%', color:'#546478' }}>
                        <Tooltip title="Help">
                            <IconButton>
                                <ErrorIcon sx={{color:"#1976d2"}}/>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body2">
                        Specify how Dext Prepare should apply default tax rates to your items                            
                        </Typography>
                    </Box>
                    <form style={{ marginBottom: '20px' }}>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '150px', color:'#546478',fontSize:'15px'}}>
                                Default Tax Rate for Costs:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="receipts6"></InputLabel>
                                <Select
                                    id="receipts6"
                                    fullWidth
                                    variant="outlined"
                                    style={{width: '40%', height:"40px", fontSize:'13px', marginLeft:'28px'}}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '150px', color:'#546478',fontSize:'15px'}}>
                                Default Tax Rate for Costs:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="receipts7"></InputLabel>
                                <Select
                                    id="receipts7"
                                    fullWidth
                                    variant="outlined"
                                    style={{width: '40%', height:"40px", fontSize:'13px', marginLeft:'29px'}}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </form>
                </Paper>

                <Paper variant="outlined" style={{ padding: '20px', marginBottom: '20px' }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' , fontSize:"13px"}}>
                        DETECT MISSING BANK STATEMENT DATA
                    </Typography>
                    <Box border={1} padding={1} marginBottom={1} borderRadius={1} style={{ display: 'flex', alignItems: 'center', width: '56%', color:'#546478' }}>
                        <Tooltip title="Help">
                            <IconButton>
                                <ErrorIcon sx={{color:"#1976d2"}}/>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body2">
                        Choose if we should notify you about missing bank statement data                 
                        </Typography>
                    </Box>
                    <FormGroup style={{ margin: '10px 0', display: 'flex' }}>
                        <Typography variant="body1" style={{ marginRight: '10px', color:'#546478',fontSize:'15px'}}>
                            Missing periods:
                            <Switch
                                checked={switchState}
                                onChange={handleSwitchChange}
                                color={switchState ? 'primary' : 'default'}
                            />
                            {switchState ? 'On' : 'Off'}
                        </Typography>
                    </FormGroup>
                </Paper>

                <Paper variant="outlined" style={{ padding: '20px', marginBottom: '20px' }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' , fontSize:"13px"}}>
                        ARCHIVING
                    </Typography>
                    <Box border={1} padding={1} marginBottom={1} borderRadius={1} style={{ display: 'flex', alignItems: 'center', width: '56%', color:'#546478' }}>
                        <Tooltip title="Help">
                            <IconButton>
                                <ErrorIcon sx={{color:"#1976d2"}}/>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body2">
                        Choose if we should <a href="#" style={{color:"blue"}}>archive</a> your items after you complete these actions                 
                        </Typography>
                    </Box>
                    <form style={{ marginBottom: '20px' }}>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '150px', color:'#546478',fontSize:'15px' }}>
                                Exported to CSV:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="receipts6"></InputLabel>
                                <Select
                                    id="receipts6"
                                    fullWidth
                                    variant="outlined"
                                    style={{width: '40%', height:"40px", fontSize:'13px', marginLeft:'35px'}}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '150px', color:'#546478',fontSize:'15px'}}>
                                Added to an ER:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="receipts7"></InputLabel>
                                <Select
                                    id="receipts7"
                                    fullWidth
                                    variant="outlined"
                                    style={{width: '40%', height:"40px", fontSize:'13px', marginLeft:'35px'}}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '150px', color:'#546478',fontSize:'15px'}}>
                                Exported to PDF:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="receipts8"></InputLabel>
                                <Select
                                    id="receipts8"
                                    fullWidth
                                    variant="outlined"
                                    style={{width: '40%', height:"40px", fontSize:'13px', marginLeft:'35px'}}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </form>
                </Paper>

                <Paper variant="outlined" style={{ padding: '20px', marginBottom: '20px' }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', fontSize:"13px" }}>
                        DOWNLOADING ITEMS
                    </Typography>
                    <Box border={1} padding={1} marginBottom={1} borderRadius={1} style={{ display: 'flex', alignItems: 'center', width: '56%', color:'#546478' }}>
                        <Tooltip title="Help">
                            <IconButton>
                                <ErrorIcon sx={{color:"#1976d2"}}/>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body2">
                        Choose how your <a href="#" style={{color:"blue"}}>CSV downloads</a> are formatted                 
                        </Typography>
                    </Box>
                    <form style={{ marginBottom: '20px' }}>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '150px', color:'#546478',fontSize:'15px' }}>
                                Receipts & Invoices:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="receipts9"></InputLabel>
                                <Select
                                    id="receipts9"
                                    fullWidth
                                    variant="outlined"
                                   style={{width: '40%', height:"40px", fontSize:'13px', marginLeft:'35px'}}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '150px' , color:'#546478',fontSize:'15px'}}>
                                Bank Statements:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="receipts10"></InputLabel>
                                <Select
                                    id="receipts10"
                                    fullWidth
                                    variant="outlined"
                                    style={{width: '40%', height:"40px", fontSize:'13px', marginLeft:'35px'}}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '150px', color:'#546478',fontSize:'15px'}}>
                                Sales Documents:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="receipts11"></InputLabel>
                                <Select
                                    id="receipts11"
                                    fullWidth
                                    variant="outlined"
                                    style={{width: '40%', height:"40px", fontSize:'13px', marginLeft:'35px'}}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        
                        <FormGroup style={{ margin: '10px 0', display: 'flex' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '100px', color:'#546478',fontSize:'15px' }}>
                                Item headers in PDF export:
                                <Switch
                                    checked={switchState}
                                    onChange={handleSwitchChange}
                                    color={switchState ? 'primary' : 'default'}
                                />
                               
                                {switchState ? 'On' : 'Off'}
                            </Typography>
                        </FormGroup>
                        <FormGroup style={{ margin: '10px 0',display: 'flex'  }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '100px', color:'#546478',fontSize:'15px'}}>
                            Use default PDF <br/>format for Expense Reports:
                                <Switch
                                    checked={switchState}
                                    onChange={handleSwitchChange}
                                    color={switchState ? 'primary' : 'default'}
                                />
                               
                                {switchState ? 'On' : 'Off'}
                            </Typography>
                        </FormGroup>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ marginRight: '10px', minWidth: '100px', color:'#546478', fontSize:'15px'}}>
                                Order items in Expense Report PDF by:
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="receipts12"></InputLabel>
                                <Select
                                    id="receipts12"
                                    fullWidth
                                    variant="outlined"
                                    style={{width: '42%', height:"43px", fontSize:'13px',}}
                                >
                                    <MenuItem value="option1">Option 1</MenuItem>
                                    <MenuItem value="option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </form>
                </Paper>
        </Container >
       </>
    );
};

export default SystemSetting;
