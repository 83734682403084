import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../Config';
const token = localStorage.getItem('token');

const Welcome = () => {
  const navigate = useNavigate();
  const [selectedBoxes, setSelectedBoxes] = useState([]);
  const [errors, setErrors] = useState({});



  const handleContinue = async () => {
    localStorage.setItem('user-completed-steps', true);
    if (selectedBoxes === null) {
      setErrors({ apiError: 'Please select a box.' });
      return;
    }

    const payload = {
      Welcome: '',
    };
    try {
      const response = await fetch(`${baseURL}/user/profile/${JSON.parse(localStorage.getItem('userInfo')).userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        navigate('/dashboard/costs/inbox');
      } else {
        const errorData = await response.json();
        setErrors({ apiError: errorData.message || 'Update failed' });
        console.error('Update failed:', errorData);
      }
    } catch (error) {
      setErrors({ apiError: 'An error occurred while updating. Please try again.' });
      console.error('Error during update:', error);
    }

  };

  const logoContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    padding: '20px',
    marginTop: "140px",
  };

  const outerCircleStyle = {
    backgroundColor: '#244af0',
    width: '170px',
    height: '170px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  };

  const innerCircleStyle = {
    backgroundColor: '#ffff',
    width: '140px',
    height: '140px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  };

  const checkSymbolStyle = {
    color: '#244af0',
    fontSize: '120px',
    animation: 'scale 1.2s ease-in-out infinite',
    animationDelay: '0.4s',
  };


  return (
    <div>
      <div style={logoContainerStyle}>
        <div style={outerCircleStyle}>
          <div style={innerCircleStyle}>
            <span style={checkSymbolStyle}>&#10003;</span>
          </div>
        </div>
        <style>{`
        @keyframes scale {
          0%,100% {
            transform: scale(0.6);
          }
         50% {
            transform: scale(1.2);
          }
        }
      `}</style>
      </div>
      <h1 style={{ fontWeight: 'bold', fontSize: "24px", marginBottom: '9px', textAlign: "center", marginTop: "40px" }}>Welcome!</h1>
      <p style={{ marginBottom: '12px', textAlign: "center", marginTop: "15px" }}>Get Started with your free trial right now</p>
      <Button
        variant="contained"
        sx={{
          marginTop: "35px",
          marginLeft: "46.5%",

          backgroundColor: "white",
          border: '1px solid #244af0',
          fontWeight: "bold",
          fontSize: "14px",
          color: "#244af0",
          textTransform: "none",
          '&:hover': {
            backgroundColor: "#244af0",
            color: 'white'
          }
        }}
        onClick={handleContinue}
      >
        Continue
      </Button>
    </div>
  );
};

export default Welcome;
