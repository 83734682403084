import { baseURL } from '../Config';
import React, { useState, useEffect } from "react";
import {
    Button, Toolbar, Tooltip, IconButton, TextField, InputAdornment, Dialog,
    DialogActions, DialogContent, DialogTitle, Typography, Popover
} from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import TableSup from "./TableSup";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';

const token = localStorage.getItem('token');
console.log('token:', token);  // Log the token value

const reset = {
    'name': true,
    'item': true,
    'extract-supplier-statements': true,
    'category': true,
    'customer': null,
    'tax-rates': null
}

function EnhancedTableToolbar(props) {
    const { setTableColumns, tableColumns, numSelected, onImportCSVClick, onMergeClick, onDeleteClick } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchor, setAnchor] = useState(null);
    const [selected, setSelected] = useState(null)
    const [tempCheckedColumns, setTempCheckedColumns] = useState({});

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openFilter = Boolean(anchorEl);
    const id1 = openFilter ? 'simple-popover' : undefined;

    const SettingHandleClick = (event) => {
        setAnchor(event.currentTarget);
    };

    const SettingHandleClose = () => {
        setAnchor(null);
    };

    const openSetting = Boolean(anchor);
    const id2 = openSetting ? 'simple-popover' : undefined;

    useEffect(() => {
        setTempCheckedColumns(tableColumns);
    }, [tableColumns]);

    const handleCheckBox = (e) => {
        const { id } = e.target;
        setTempCheckedColumns((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    }
    useEffect(() => {
        return setTableColumns(reset)
    }, [])



    return (
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
            <div className="flex justify-between items-center w-full">
                <div>
                    <Button
                        disabled={numSelected < 2}
                        variant="contained"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            background: '#1976d2',
                            color: '#ffff',
                            fontWeight: 'bold',
                            color: "black",
                            '&:hover': {
                                backgroundColor: '#1976d2',
                            }
                        }}
                    >
                        Bulk edit
                    </Button>
                    <Button
                        disabled={numSelected < 2}
                        variant="contained"
                        onClick={onMergeClick}
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            background: '#1976d2',
                            color: '#ffff',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: '#1976d2',
                            }
                        }}
                    >
                        Merge suppliers
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onImportCSVClick}
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            background: '#1976d2',
                            color: '#ffff',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: '#1976d2',
                            }
                        }}
                    >
                        Import from CSV
                    </Button>
                    <Button
                        disabled={numSelected === 0}
                        variant="contained"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            background: '#1976d2',
                            color: '#ffff',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: '#1976d2',
                            }
                        }}
                    >
                        Supplier duplicates
                    </Button>
                    <Button
                        disabled={numSelected === 0}
                        variant="contained"
                        onClick={onDeleteClick}
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            background: '#ff5a02',
                            color: 'black',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: '#ff5a02',
                                color: 'black'
                            }
                        }}
                    >
                        Delete
                    </Button>
                </div>
                <div className='flex items-center gap-2'>
                    <div className='border-4 h-10 border-[#dbdbd9] '>
                        <SearchIcon sx={{ color: '#dbdbd9', width: '40px' }} />
                        <input className=" h-8 border-none focus:outline-none focus:border-none text-[#757574] focus:ring-0 pl-0" type="text" placeholder='Filter by name' />
                    </div>

                    <Tooltip title="Filter list">
                        <div>
                            <button className="relative active:translate-y-1" onClick={handleClick}>
                                <IconButton >
                                    <FilterAltOutlinedIcon sx={{ color: '#647890' }} />
                                </IconButton>
                            </button>
                            <Popover
                                id={id1}
                                open={openFilter}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <div className=' flex flex-col p-5  gap-5'>
                                    <div className='font-semibold' >FILTERS</div>
                                    <div className=' w-full flex justify-between items-center gap-11'>
                                        <span>Filter by</span>
                                        <div className='flex gap-2'>
                                            <button onClick={() => setSelected(1)} className={`border-2  text-[#647890] text-sm rounded-md cursor-pointer hover:bg-[#fae396]  hover:border-[#ffe11f] flex justify-center items-center  p-1 ${selected === 1 ? 'bg-[#f7f4dc] border-4 border-[#ffe11f]' : ''}`}>All Suppliers</button>
                                            <button onClick={() => setSelected(2)} className={`border-2  text-[#647890] text-sm rounded-md cursor-pointer hover:bg-[#fae396]  hover:border-[#ffe11f] flex justify-center items-center  p-1 ${selected === 2 ? 'bg-[#f7f4dc] border-4 border-[#ffe11f]' : ''}`}>Suppliers with items in the box</button>
                                        </div>
                                    </div>
                                    <div className='flex justify-end'>
                                        <Button
                                            variant="contained"
                                            // onClick={onImportCSVClick}
                                            sx={{
                                                fontSize: '12px',
                                                padding: '6px 15px',
                                                marginRight: '10px',
                                                background: '#1976d2',
                                                color: '#ffff',
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    backgroundColor: '#1976d2',
                                                }
                                            }}
                                            onClick={() => { handleClose() }}
                                        >
                                            Apply
                                        </Button></div>
                                </div>

                            </Popover>
                        </div>
                    </Tooltip>
                    <Tooltip title="setting">
                        <div>
                            <button className="relative active:translate-y-1" onClick={SettingHandleClick}>
                                <IconButton >
                                    <SettingsIcon sx={{ color: '#647890' }} />
                                </IconButton>
                            </button>
                            <Popover
                                id={id2}
                                open={openSetting}
                                anchorEl={anchor}
                                onClose={SettingHandleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <div className=' flex flex-col p-5  gap-7'>
                                    <div >
                                        <div className='font-semibold' >PRIMARY COLUMNS</div>
                                        <div className=' w-full flex justify-between items-center gap-11 mt-1'>

                                            <span> <input type="checkbox" id='extract-supplier-statements' name='extract-supplier-statements' checked={Boolean(tempCheckedColumns['extract-supplier-statements'])} onChange={handleCheckBox} /> <label htmlFor="extract-supplier-statements">
                                                Extract Supplier Statements</label></span>
                                            <span>  <input type="checkbox" id='category' name='category' checked={Boolean(tempCheckedColumns['category'])} onChange={handleCheckBox} /> <label htmlFor="category">
                                                Category</label></span>
                                            <span>   <input type="checkbox" id='customer' name='customer' checked={Boolean(tempCheckedColumns['customer'])} onChange={handleCheckBox} /> <label htmlFor="customer">
                                                Customer</label></span>
                                        </div>
                                    </div>
                                    {/* <div >
                                        <div className='font-semibold' >ADDITIONALCOLUMNS</div>
                                        <div className='mt-1'> <input type="checkbox" id='tax-rates' name='tax-rates' checked={Boolean(tempCheckedColumns['tax-rates'])} onChange={handleCheckBox} /> <label htmlFor="tax-rates">
                                            Tax Rate</label></div>
                                    </div> */}
                                    <div className='flex justify-end'>
                                        <Button
                                            variant="contained"
                                            // onClick={onImportCSVClick}
                                            sx={{
                                                fontSize: '12px',
                                                padding: '6px 15px',
                                                marginRight: '10px',
                                                background: '#1976d2',
                                                color: '#ffff',
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    backgroundColor: '#1976d2',
                                                }
                                            }}
                                            onClick={() => { SettingHandleClose(); setTableColumns(tempCheckedColumns); console.log(tableColumns) }}
                                        >
                                            Apply
                                        </Button></div>
                                </div>

                            </Popover>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </Toolbar>
    );
}
const columns = {
    'name': true,
    'item': true,
    'extract-supplier-statements': true,
    'category': true,
    'customer': null,
}

const Suppliers = () => {
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const { id } = useParams();
    const [tableColumns, setTableColumns] = useState(columns)
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/supplier`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setData(response.data);
                console.log("Fetched Suppliers Data:", response.data); // Console log the fetched data
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleImportCSVClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        console.log(file);
        handleCloseDialog();
    };

    const handleMergeClick = () => {
        console.log('Merge suppliers');
    };

    const handleDeleteClick = () => {
        setSelectedSuppliers(selected);
        setOpenDeleteConfirm(true);
    };

    const handleDeleteSuppliers = async () => {
        try {
            const deletePromises = selectedSuppliers.map((supplierId) =>
                axios.delete(`${baseURL}/supplier/${supplierId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            );

            await Promise.all(deletePromises);

            const filteredSuppliers = data.filter(
                (supplier) => !selectedSuppliers.includes(supplier._id)
            );
            setData(filteredSuppliers);
            console.log("Updated Suppliers Data after deletion:", filteredSuppliers); // Console log the updated data
            setSelected([]);
            setOpenDeleteConfirm(false);
        } catch (error) {
            console.error('Error deleting suppliers:', error);
        }
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteConfirm(false);
    };

    const handleAddSupplier = async (newSupplier) => {
        try {
            const response = await axios.post(`${baseURL}/supplier`, newSupplier, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setData([...data, response.data]);
            console.log("Added Supplier Data:", response.data); // Console log the added supplier data
        } catch (error) {
            console.error('Error adding supplier:', error);
        }
    };

    const handleEditSupplier = async (updatedSupplier, supplierId) => {
        try {
            const response = await axios.put(`${baseURL}/supplier/${supplierId}`, updatedSupplier, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const updatedData = data.map(supplier => supplier._id === supplierId ? response.data : supplier);
            setData(updatedData);
            console.log("Edited Supplier Data:", response.data); // Console log the edited supplier data
        } catch (error) {
            console.error('Error editing supplier:', error);
        }
    };

    return (
        <>
            <EnhancedTableToolbar
                numSelected={selected.length}
                onImportCSVClick={handleImportCSVClick}
                onMergeClick={handleMergeClick}
                onDeleteClick={handleDeleteClick}
                tableColumns={tableColumns}
                setTableColumns={setTableColumns}
            />
            <TableSup tableColumns={tableColumns} data={data} selected={selected} setSelected={setSelected} />
            {id && (
                <Button
                    variant="contained"
                    sx={{ marginTop: '20px', borderBottom: '1px solid black' }}
                    onClick={() => navigate('/suppliers')}
                >
                    Back to All Suppliers
                </Button>
            )}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Import CSV</DialogTitle>
                <DialogContent>
                    <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileUpload}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDeleteConfirm} onClose={handleCloseDeleteDialog}>
                <DialogTitle sx={{ color: '#1976d2', fontWeight: "bold" }}>
                    Delete Confirmation
                </DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete {selectedSuppliers.length} supplier(s)?</p>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDeleteDialog}
                        sx={{
                            background: 'none',
                            border: 'none',
                            borderRadius: '5px',
                            '&:hover': {
                                backgroundColor: 'lightgrey'
                            }
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteSuppliers}
                        sx={{
                            backgroundColor: '#ff5a02',
                            color: 'black',
                            border: 'none',
                            borderRadius: '5px',
                            '&:hover': {
                                backgroundColor: '#ff5a02',
                            }
                        }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Suppliers;

