import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Icon,
} from "@mui/material";
import { MdOutlineMonitor } from "react-icons/md";
import axios from "axios";
import * as pdfjsLib from "pdfjs-dist/webpack";
import CostInbox from "../costComponents/Inbox";
import SalesInbox from "../salesComponents/Inbox";
import { Spinner } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../Config";
import { Switch } from "./common/switch";
import "./addmodal.css";
const token = localStorage.getItem("token");

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  p: 3,
  mt: 3,
  color: "#3f4b5a",
  borderRadius: "1rem",
};

const AddModal = ({ open, handleClose, handleOpen }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedOwner, setSelectedOwner] = useState("None");
  const [fileType, setFileType] = useState("file");
  const [fileURL, setFileURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("NONE");
  const navigate = useNavigate();

  useEffect(() => {
    // Get the username from local storage when the component mounts
    const storedUserName = JSON.parse(localStorage.getItem("userInfo"));
    if (storedUserName && storedUserName.name) {
      setUserName(storedUserName.name);
    } else {
      setUserName("NONE");
    }
  }, []);

  const handleChangeOwner = (e) => {
    setSelectedOwner(e.target.value);
  };

  const validateFile = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const fileSizeMB = file.size / (1024 * 1024);
    const validImageExtensions = ["jpg", "jpeg", "png"];
    const validFileExtension = ["pdf", "zip"];

    if (fileType === "file") {
      if (validImageExtensions.includes(fileExtension)) {
        if (fileSizeMB > 6) {
          return {
            valid: false,
            message: "Image files must be 6MB or smaller.",
          };
        }
      } else if (fileExtension === "zip") {
        if (fileSizeMB > 100) {
          return {
            valid: false,
            message: "ZIP files must be 100MB or smaller.",
          };
        }
      } else if (fileExtension !== "pdf") {
        return {
          valid: false,
          message: "Only PDF, JPG, PNG, and ZIP files are allowed.",
        };
      }
    } else if (fileType === "page") {
      if (fileExtension !== "pdf") {
        return { valid: false, message: "Only PDF files are allowed." };
      } else if (fileSizeMB > 40) {
        return { valid: false, message: "PDF files must be 40MB or smaller." };
      } else {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = function () {
            const typedarray = new Uint8Array(this.result);
            pdfjsLib.getDocument(typedarray).promise.then((pdf) => {
              if (pdf.numPages > 200) {
                resolve({
                  valid: false,
                  message: "PDF files must have 200 pages or fewer.",
                });
              } else {
                resolve({ valid: true, message: "" });
              }
            });
          };
          reader.readAsArrayBuffer(file);
        });
      }
    }
    return { valid: true, message: "" };
  };

  // const handleFileChange = async (e) => {
  //     const file = e.target.files[0];
  //     const validation = await validateFile(file);

  //     if (!validation.valid) {
  //         alert(validation.message);
  //         return;
  //     }

  //     const formData = new FormData();
  //     formData.append('file', file);

  //     try {

  //         setLoading(true)

  //         const uploadUrl = `${baseURL}/upload`;

  //         const response = await axios.post(uploadUrl, formData, {
  //             headers: {
  //                 'Content-Type': 'multipart/form-data',
  //                 'Authorization': `Bearer ${token}`
  //             }
  //         });

  //         console.log('File uploaded successfully:', response.data);
  //         const uploadedFileURL = response.data.location;

  //         for (let i = 0; i < uploadedFileURL?.length; i++) {
  //             const createUrl = selectedTab === 1
  //                 ? `${baseURL}/cost`
  //                 : `${baseURL}/sales`;

  //             await axios.post(createUrl, {
  //                 image: uploadedFileURL[i]
  //             }, {
  //                 headers: {
  //                     'Authorization': `Bearer ${token}`
  //                 }
  //             });

  //         }
  //         setTimeout(() => {
  //             window.location.reload();
  //         }, 500);
  //         setLoading(false);

  //         handleClose();
  //     } catch (error) {
  //         console.error('Error uploading file or creating record:', error);
  //     }
  // };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files); // Get all selected files as an array

    // Validate each file and append to FormData
    for (let file of files) {
      const validation = await validateFile(file);

      if (!validation.valid) {
        alert(validation.message);
        return;
      }

      setLoading(true);
      let formData = new FormData();
      formData.append("file", file);

      const uploadUrl = `${baseURL}/upload`;

      // Upload all files at once
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Files uploaded successfully:", response.data);
      const uploadedFileURLs = response.data.locations || [
        response.data.location,
      ]; // Support single or multiple URLs

      // Loop through each uploaded file URL and create a corresponding record
      for (let uploadedFileURL of uploadedFileURLs) {
        const createUrl =
          selectedTab === 1 ? `${baseURL}/cost` : `${baseURL}/sales`;

        await axios.post(
          createUrl,
          {
            image: uploadedFileURL,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    }

    try {
      setTimeout(() => {
        window.location.reload();
      }, 500);

      setLoading(false);
      handleClose();
    } catch (error) {
      console.error("Error uploading file or creating record:", error);
      setLoading(false); // Ensure loading state is reset in case of error
    }
  };

  const handleFileInputClick = () => {
    document.getElementById("file-upload-input").click();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <div className="text-head text-xl uppercase font-bold">
          Add Documents
          {/* <button
      className="absolute top-4 right-4 bg-gray-300 hover:bg-gray-400 text-black px-3 py-1 rounded-full"
    >
      ✕
    </button> */}
        </div>
        <Switch
          options={[
            { name: "Costs", selected: 1 },
            { name: "Sales", selected: 2 },
          ]}
          selected={selectedTab}
          handleChange={(value) => setSelectedTab(value)}
        />
        <div className="text-[14px] mt-5">
          Use this panel to add your{" "}
          <span className="font-semibold">
            bills, receipts and purchase invoices
          </span>
        </div>
        <div className="mt-5 flex gap-5 justify-between items-center">
          <div>
            <div className="text-nowrap text-sm font-normal">
              Document Owner
            </div>
            {/* <a className='text-[#2196f3]' href="#">Add a new user</a> */}
          </div>
          <Select
            // sx={{ height: '30px', width: '250px', fontSize: '13px' }}
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userName}
            onChange={handleChangeOwner}
          >
            <MenuItem value={userName}>
              {userName
                .split(" ")
                .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
                .join(" ")}
            </MenuItem>{" "}
            {/* Display the username */}
            {/* <MenuItem value={"None"}>None</MenuItem>
                        <MenuItem value={"Gautam"}>Gautam</MenuItem>
                        <MenuItem value={"Sarthak"}>Sarthak</MenuItem> */}
          </Select>
        </div>
        <div className="mt-5">
          <div className="flex items-center mb-3">
            <Icon>
              <MdOutlineMonitor />
            </Icon>
            <div className="font-bold ml-2 text-[14px]">
              Upload from computer
            </div>
          </div>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="file"
            name="radio-buttons-group"
            onChange={(e) => setFileType(e.target.value)}
          >
            <div className="flex">
              <FormControlLabel
                sx={{ fontSize: "13px", width: "50%" }}
                value="file"
                control={<Radio />}
                label={
                  <div className="text-[13px] font-bold">
                    One document per file
                    <p style={{ color: "gray" }}>PDF, JPG, PNG, ZIP</p>
                  </div>
                }
              />
              <FormControlLabel
                sx={{ fontSize: "13px", width: "50%" }}
                value="page"
                control={<Radio />}
                label={
                  <div className="text-[13px] font-bold">
                    One document per page
                    <p style={{ color: "gray" }}>PDF files only</p>
                  </div>
                }
              />
            </div>
          </RadioGroup>
        </div>
        <div className="mt-5 w-full h-[200px] border border-emerald-600 bg-[#ebf0ec] flex flex-col justify-center items-center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleFileInputClick}
          >
            {loading ? <Spinner /> : "Select Files"}
            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-upload-input"
              accept={fileType === "page" ? "application/pdf" : ".pdf,image/*"}
              multiple
            />
          </Button>
          <p className=" text-sm" style={{ color: "rgb(5 132 105)" }}>
            Or
          </p>

          <h5 className="text-sm" style={{ color: "rgb(5 132 105)" }}>
            Drag and Drop your files here to upload
          </h5>
          <p className="text-sm  mt-5" style={{ color: "rgb(5 132 105)" }}>
            File limits | <span>6MB for images ,</span>
            <span>100MB for ZIP files</span>
          </p>
        </div>
        {fileURL && (
          <div className="mt-5">
            {selectedTab === 1 ? (
              <CostInbox fileURL={fileURL} />
            ) : (
              <SalesInbox fileURL={fileURL} />
            )}
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default AddModal;
