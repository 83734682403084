const LineItemsTable = ({ lineItemData, itemTotalNet, itemTotalTax, itemTotalTotal }) => {
    const lineItemNetTotal = lineItemData.reduce((sum, item) => sum + (item.net || 0), 0);
    const lineItemTaxTotal = lineItemData.reduce((sum, item) => sum + (item.tax || 0), 0);
    const lineItemTotal = lineItemData.reduce((sum, item) => sum + (item.total || 0), 0);
  
    const outByNet = itemTotalNet - lineItemNetTotal;
    const outByTax = itemTotalTax - lineItemTaxTotal;
    const outByTotal = itemTotalTotal - lineItemTotal;
  
    return (
      <div className='overflow-x-auto'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-gray-50'>
            <tr>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Description</th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Category</th>
              <th scope='col' className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>Net</th>
              <th scope='col' className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>Tax</th>
              <th scope='col' className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>Total</th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {lineItemData && lineItemData.length > 0 ? (
              lineItemData.map((item, index) => (
                <tr key={index} className='hover:bg-gray-50'>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900'>{item.description || '-'}</td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{item.category || '-'}</td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right'>{item.net ? item.net.toFixed(2) : '-'}</td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right'>{item.tax ? item.tax.toFixed(2) : '-'}</td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right'>{item.total ? item.total.toFixed(2) : '-'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className='px-6 py-4 text-center text-sm text-gray-500'>No line items found</td>
              </tr>
            )}
            <tr className='bg-gray-100'>
              <td colSpan='2' className='px-6 py-4 font-medium text-gray-700'>Out by</td>
              <td className='px-6 py-4 text-right text-sm font-medium text-red-500'>{outByNet.toFixed(2)}</td>
              <td className='px-6 py-4 text-right text-sm font-medium text-red-500'>{outByTax.toFixed(2)}</td>
              <td className='px-6 py-4 text-right text-sm font-medium text-red-500'>{outByTotal.toFixed(2)}</td>
            </tr>
            <tr className='font-bold'>
              <td colSpan='2' className='px-6 py-4 text-gray-900'>Item total</td>
              <td className='px-6 py-4 text-right'>{itemTotalNet.toFixed(2)}</td>
              <td className='px-6 py-4 text-right'>{itemTotalTax.toFixed(2)}</td>
              <td className='px-6 py-4 text-right'>{itemTotalTotal.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  export default LineItemsTable