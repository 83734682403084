import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../Config";

const VerifyEmail = () => {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const verifyEmail = async () => {
    try {
      const response = await fetch(`${baseURL}/auth/verify-email?token=${token}`, {
        method: "GET",
      }).then(async (res) => {
        const response = await res.json();

        console.log(response, 'rrrrrrr')
        if (response.name && response.userId) {
          const userInfo = {
            name: response.name,
            userId: response.userId
          };
          
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          localStorage.setItem('token', response.token);
          localStorage.setItem('userToken', response.userToken);
        }
        if (!response.message.includes("Invalid")) {
          navigate("/about");
        } else {
          setMessage(response.message)
        }
      }).catch((err) => {
        setMessage("Email verification failed.");
      });
    } catch (error) {
      setMessage("An error occurred during email verification. Please try again.");
    }
  };

  useEffect(() => {
    if (token) {
      verifyEmail();
    }
  }, []);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-center">
        <h2>{message}</h2>
      </div>
    </div>
  );
};

export default VerifyEmail;
