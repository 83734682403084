import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table,TableBody,TableCell,  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TextField,
  MenuItem,
  Checkbox,
  TablePagination,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { baseURL } from '../Config';

export default function StickyColumnsTable({ costID, lineItems = [] }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  
  const token = localStorage.getItem('token');
  
  useEffect(() => {
    console.log('hello id ', costID);
    fetchCategory();
    fetchCustomer();
    
    // If lineItems are provided, populate the rows state
    if (lineItems && lineItems.length > 0) {
      setRows(lineItems.map(item => ({
        id: item._id,
        description: item.description,
        category: item.category,
        customer: item.customer,
        taxRate: item.taxRate,
        quantity: item.quantity,
        UnitPrice: item.unitPrice,
        net: item.net,
        tax: item.tax,
        total: item.total,
        saleId: costID,
        isExisting: true
      })));
    }
  }, [costID, lineItems]);

  const fetchCategory = async () => {
    try {
      const response = await axios.get(`${baseURL}/category/get-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCategoryData(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchCustomer = async () => {
    try {
      const response = await axios.get(`${baseURL}/customer`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomerData(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddNewLine = () => {
    const row = {
      description: '',
      category: '',
      customer: '',
      taxRate: '',
      quantity: '',
      UnitPrice: '',
      net: '',
      tax: '',
      total: '',
      saleId: costID,
      isExisting: false
    };
    setRows((prev) => [...prev, row]);
  };

  const calculateValues = (updatedRow) => {
    if (updatedRow.quantity && updatedRow.UnitPrice) {
      updatedRow.net = Number(updatedRow.quantity) * Number(updatedRow.UnitPrice);
      
      if (updatedRow.taxRate) {
        updatedRow.tax = Number(updatedRow.net) * (Number(updatedRow.taxRate) / 100);
      } else {
        updatedRow.tax = 0;
      }
      
      updatedRow.total = Number(updatedRow.net) + Number(updatedRow.tax);
    }
    
    return updatedRow;
  };

  const handleChange = (name, value, row, index) => {
    let updatedRow = { ...row, [name]: value };
    updatedRow = calculateValues(updatedRow);
    
    const updatedRows = [...rows];
    updatedRows[index] = updatedRow;
    setRows(updatedRows);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n, index) => index);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDeleteRow = (index) => {
    setRows((prevRows) => prevRows.filter((_, i) => i !== index));
    // Update selected items after deletion
    setSelected(selected.filter(i => i !== index).map(i => i > index ? i - 1 : i));
  };

  const handleSubmit = async () => {
    try {
      // Process new items and updated items
      for (const row of rows) {
        const payload = {
          description: row.description,
          category: row.category,
          customer: row.customer,
          taxRate: row.taxRate,
          quantity: row.quantity,
          unitPrice: row.UnitPrice,
          net: row.net,
          tax: row.tax,
          total: row.total,
          saleId: row.saleId,
        };

        if (row.isExisting) {
          // Update existing item
          await axios.put(`${baseURL}/line-item/update/${row.id}`, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else {
          // Create new item
          await axios.post(`${baseURL}/line-item/create`, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
      }

      alert('Line items saved successfully!');
      
      // Refresh line items from the server would ideally happen here
      // For now, mark all items as existing after successful save
      setRows(rows.map(row => ({ ...row, isExisting: true })));
      
    } catch (error) {
      console.error('Error saving line items:', error);
      alert('Failed to save line items. Please try again.');
    }
  };

  return (
    <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
      <Table stickyHeader sx={{ minWidth: 1000 }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: '#878787' }}>
            <TableCell padding='checkbox'>
              <Checkbox
                color='primary'
                indeterminate={
                  selected.length > 0 && selected.length < rows.length
                }
                checked={rows.length > 0 && selected.length === rows.length}
                onChange={handleSelectAllClick}
              />
            </TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Tax Rate</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Unit Price (Exc. Tax)</TableCell>
            <TableCell>Net</TableCell>
            <TableCell>Tax</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const actualIndex = page * rowsPerPage + index;
              return (
                <TableRow key={actualIndex}>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selected.indexOf(actualIndex) !== -1}
                      onChange={() => {
                        const selectedIndex = selected.indexOf(actualIndex);
                        let newSelected = [];

                        if (selectedIndex === -1) {
                          newSelected = [...selected, actualIndex];
                        } else {
                          newSelected = selected.filter(i => i !== actualIndex);
                        }

                        setSelected(newSelected);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant='outlined'
                      size='small'
                      value={row.description || ''}
                      onChange={(e) =>
                        handleChange('description', e.target.value, row, actualIndex)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      select
                      variant='outlined'
                      size='small'
                      fullWidth
                      value={row.category || ''}
                      onChange={(e) =>
                        handleChange('category', e.target.value, row, actualIndex)
                      }
                    >
                      {categoryData.map((category) => (
                        <MenuItem key={category._id} value={category.name}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <TextField
                      select
                      variant='outlined'
                      size='small'
                      fullWidth
                      value={row.customer || ''}
                      onChange={(e) =>
                        handleChange('customer', e.target.value, row, actualIndex)
                      }
                    >
                      {customerData.map(({ name }, idx) => (
                        <MenuItem key={idx} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant='outlined'
                      size='small'
                      type="number"
                      onChange={(e) =>
                        handleChange('taxRate', e.target.value, row, actualIndex)
                      }
                      value={row.taxRate || ''}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant='outlined'
                      size='small'
                      type="number"
                      onChange={(e) =>
                        handleChange('quantity', e.target.value, row, actualIndex)
                      }
                      value={row.quantity || ''}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant='outlined'
                      size='small'
                      type="number"
                      onChange={(e) =>
                        handleChange('UnitPrice', e.target.value, row, actualIndex)
                      }
                      value={row.UnitPrice || ''}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant='outlined'
                      size='small'
                      type="number"
                      disabled
                      value={row.net || ''}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant='outlined'
                      size='small'
                      type="number"
                      disabled
                      value={row.tax || ''}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant='outlined'
                      size='small'
                      type="number"
                      disabled
                      value={row.total || ''}
                    />
                  </TableCell>
                  <TableCell>
                    {row.isExisting ? 
                      <span style={{ color: 'blue' }}>Existing</span> : 
                      <span style={{ color: 'green' }}>New</span>
                    }
                  </TableCell>
                  <TableCell
                    style={{
                      position: 'sticky',
                      right: 0,
                      background: 'white',
                      zIndex: 3,
                    }}
                  >
                    <IconButton
                      color='error'
                      onClick={() => handleDeleteRow(actualIndex)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <div className='text-center'>
        <button
          className='m-3 border border-1 bg-blue-700 text-white py-1 px-5 rounded-md'
          onClick={handleAddNewLine}
        >
          + Add new line item
        </button>
        <button
          className='m-3 border border-1 bg-green-700 text-white py-1 px-5 rounded-md'
          onClick={handleSubmit}
        >
          Save Changes
        </button>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}