
import { baseURL } from '../Config';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Modal, Select, MenuItem, Grid } from '@mui/material';
import jsPDF from 'jspdf';

const Inbox = () => {
    const [selected, setSelected] = useState([]);
    const [claims, setClaims] = useState([]);
    const [selectedClaim, setSelectedClaim] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({
        preparedFor: '',
        endDate: '',
        name: ''
    });


    useEffect(() => {
        fetchClaims();
    }, []);

    const fetchClaims = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found, please log in again.');
                return;
            }

            const response = await axios.get(`${baseURL}/reports/inbox`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            console.log('API Response:', response.data);
            setClaims(response.data);
        } catch (error) {
            console.error('Error fetching claims:', error);
        }
    };


    const handleRowClick = (event, id) => {
        if (event.target.tagName.toLowerCase() !== 'input') {
            const claim = claims.find(c => c._id === id);
            setSelectedClaim(claim);
        }
    };

    const handleBackClick = () => {
        setSelectedClaim(null);
    };

    const handleCreateClick = () => {
        setOpenModal(true);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = claims.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleCreateExpenseClaim = async () => {
        try {
            const token = localStorage.getItem('token');

            const newClaim = {
                name: formData.name,
                endDate: formData.endDate,
                currency: 'USD',
                paid: false,
                approved: false,
                paymentMethod: 'Credit Card',
                note: '',
                status: 'inbox'
            };

            const response = await axios.post(`${baseURL}/reports`, newClaim, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            setClaims([...claims, response.data]);
            setOpenModal(false);
            setFormData({ preparedFor: '', endDate: '', name: '' });
        } catch (error) {
            console.error('Error creating the claim:', error);
        }
    };

    const handleArchive = async () => {
        if (selectedClaim) {
            try {
                const token = localStorage.getItem('token');

                // Make the PUT request to archive the selected report
                const response = await axios.put(`${baseURL}/reports/archive/${selectedClaim._id}`, null, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                // Filter out the archived claim from the claims list
                setClaims(claims.filter(claim => claim._id !== selectedClaim._id));
                setSelectedClaim(null);  // Reset the selected claim

                console.log('Archived claim:', response.data);
            } catch (error) {
                console.error('Error archiving the report:', error);
            }
        }
    };


    const generatePDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(12);
        doc.text('TEST FOUNDER\'S EXPENSE CLAIM', 105, 10, { align: 'center' });

        doc.setFontSize(10);
        doc.text(`Claim name: ${selectedClaim?.name || ''}`, 10, 20); // Check if selectedClaim exists
        doc.text(`Claim ID: #${selectedClaim?._id || ''}`, 10, 25); // Check if selectedClaim exists
        doc.text(`Claim date: ${new Date(selectedClaim?.endDate || '').toLocaleDateString()}`, 10, 30); // Check if selectedClaim exists

        doc.text('Total: 0.00 GBP (incl. tax: 0.0)', 160, 20);

        doc.setFontSize(12);
        doc.text('SUMMARY BY CATEGORY', 10, 40);

        doc.setFontSize(10);
        doc.text('Category', 10, 50);
        doc.text('Net (GBP)', 80, 50);
        doc.text('Tax (GBP)', 120, 50);
        doc.text('Total (GBP)', 160, 50);

        doc.text('Total', 10, 60);
        doc.text('0.00', 80, 60);
        doc.text('0.00', 120, 60);
        doc.text('0.00', 160, 60);

        doc.setFontSize(12);
        doc.text('TRANSACTIONS', 10, 80);

        doc.setFontSize(10);
        doc.text('Date', 10, 90);
        doc.text('Item ID', 40, 90);
        doc.text('Supplier', 70, 90);
        doc.text('Description', 100, 90);
        doc.text('Category', 140, 90);
        doc.text('Net (GBP)', 160, 90);
        doc.text('Tax (GBP)', 180, 90);
        doc.text('Total (GBP)', 200, 90);

        doc.text('Total', 10, 100);
        doc.text('0.00', 160, 100);
        doc.text('0.00', 180, 100);
        doc.text('0.00', 200, 100);

        doc.setFontSize(12);
        doc.text('APPROVALS', 10, 120);

        doc.setFontSize(10);
        doc.text('Employee signature', 10, 130);
        doc.line(50, 130, 100, 130);
        doc.text('Date', 110, 130);
        doc.line(120, 130, 160, 130);

        doc.text('Approver\'s signature', 10, 140);
        doc.line(50, 140, 100, 140);
        doc.text('Date', 110, 140);
        doc.line(120, 140, 160, 140);

        doc.text('Powered by Dext', 10, 160);
        doc.setFontSize(8);
        doc.text('Page 1 / 1', 105, 170, { align: 'center' });

        doc.save('expense_claim.pdf');
    };

    return (
        <Box sx={{ padding: '20px' }}>
            {selectedClaim ? (
                <Box>
                    <Button onClick={handleBackClick} variant="text" sx={{ marginBottom: '20px' }}>
                        &#8592; Back
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <Typography variant="h5" fontWeight="bold">
                            {selectedClaim?.user?.name || 'Unknown'}'s Expense Claim
                        </Typography>
                        <Box>
                            <Button variant="contained" sx={{ marginRight: '10px' }} onClick={handleArchive}>
                                Archive
                            </Button>
                            <Button variant="contained" sx={{ marginRight: '10px' }} onClick={generatePDF}>
                                PDF Preview
                            </Button>
                            <Button variant="contained" color="secondary">
                                Delete
                            </Button>
                        </Box>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <Button variant="contained">Refresh</Button>
                                <Button variant="contained">PDF Preview</Button>
                            </Box>
                            <Box sx={{ textAlign: 'center', padding: '20px', border: '1px dashed gray' }}>
                                <Typography>No results found.</Typography>
                                <Typography variant="caption">Try a different keyword or filter.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{ padding: '20px', border: '1px solid lightgray' }}>
                                <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: '20px' }}>
                                    Claim Details
                                </Typography>
                                <TextField
                                    fullWidth
                                    label="Claim ID"
                                    value={selectedClaim?._id || ''}
                                    InputProps={{ readOnly: true }}
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="Owned By"
                                    value={selectedClaim?.user?.name || 'Unknown'}
                                    InputProps={{ readOnly: true }}
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="Claim Name"
                                    value={selectedClaim?.name || ''}
                                    InputProps={{ readOnly: true }}
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="End Date"
                                    value={new Date(selectedClaim?.endDate || '').toLocaleDateString()}
                                    InputProps={{ readOnly: true }}
                                    sx={{ marginBottom: 2 }}
                                />
                                <Select
                                    fullWidth
                                    value="GBP - United Kingdom, Pounds"
                                    disabled
                                    sx={{ marginBottom: 2 }}
                                >
                                    <MenuItem value="GBP - United Kingdom, Pounds">
                                        GBP - United Kingdom, Pounds
                                    </MenuItem>
                                </Select>
                                <TextField
                                    fullWidth
                                    label="Claim Description"
                                    placeholder="Enter description"
                                    sx={{ marginBottom: 2 }}
                                />
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                    <Typography variant="body1" sx={{ marginRight: '10px' }}>Paid</Typography>
                                    <Select
                                        value="No"
                                        disabled
                                        sx={{ width: '100px' }}
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </Box>
                                <TextField
                                    fullWidth
                                    label="Payment Method"
                                    placeholder="Enter payment method"
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="Internal Note"
                                    placeholder="Enter internal note"
                                    sx={{ marginBottom: 2 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Box>
                    <Typography variant="h5" fontWeight="bold" mb={2}>
                        Expense claims
                    </Typography>
                    <Box mb={2}>
                        <Button variant="contained" sx={{ marginRight: '10px' }} disabled={selected.length === 0} onClick={handleArchive}>
                            Archive
                        </Button>
                        <Button variant="contained" sx={{ marginRight: '10px' }} onClick={handleCreateClick}>
                            Create
                        </Button>
                        <Button variant="contained" sx={{ marginRight: '10px' }} disabled={selected.length === 0}>
                            Action
                        </Button>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <input
                                            type="checkbox"
                                            onChange={handleSelectAllClick}
                                            checked={selected.length === claims.length}
                                        />
                                    </TableCell>
                                    <TableCell>Prepared for</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>End Date</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>Tax</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {claims.map((row) => {
                                    // Safely access user name using optional chaining
                                    const userName = row.user?.name || 'No user assigned';

                                    return (
                                        <TableRow
                                            key={row._id}
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected(row._id)}
                                            selected={isSelected(row._id)}
                                            onClick={(event) => handleRowClick(event, row._id)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <TableCell padding="checkbox">
                                                <input
                                                    type="checkbox"
                                                    checked={isSelected(row._id)}
                                                    onChange={(event) => handleClick(event, row._id)}
                                                />
                                            </TableCell>
                                            {/* Display the user name or fallback if user is null */}
                                            <TableCell>{userName}</TableCell>
                                            <TableCell>{row.type || 'N/A'}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{new Date(row.endDate).toLocaleDateString()}</TableCell>
                                            <TableCell>{row.total || '--'}</TableCell>
                                            <TableCell>{row.tax || '--'}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>

                    </TableContainer>

                </Box>
            )}

            {/* Modal for creating new claim */}
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box sx={{ width: 400, padding: 4, margin: 'auto', marginTop: '10%', backgroundColor: '#fff', borderRadius: 2, boxShadow: 24 }}>
                    <Typography variant="h6" color="orange" mb={2}>Create expense claim</Typography>
                    <TextField
                        fullWidth
                        label="Claim for"
                        name="preparedFor"
                        value={formData.preparedFor}
                        onChange={handleInputChange}
                        select
                        sx={{ marginBottom: 2 }}
                    >
                        <MenuItem value="Ramratan Jakhar">Ramratan Jakhar</MenuItem>
                        <MenuItem value="test founder">test founder</MenuItem>
                    </TextField>
                    <TextField
                        fullWidth
                        label="End date"
                        name="endDate"
                        value={formData.endDate}
                        onChange={handleInputChange}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Add claim name"
                        sx={{ marginBottom: 2 }}
                    />
                    <Box display="flex" justifyContent="space-between" mt={3}>
                        <Button onClick={() => setOpenModal(false)} sx={{ color: 'gray' }}>Cancel</Button>
                        <Button onClick={handleCreateExpenseClaim} sx={{ backgroundColor: 'lightgreen' }}>Create</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default Inbox;
