import React, { useEffect, useState, useContext } from "react";
import { Table, TablePagination, Button, Modal, Select, MenuItem, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TableSortLabel, Box, Toolbar, Typography, Tooltip, IconButton } from "@mui/material"
import { visuallyHidden } from '@mui/utils';

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../Config';
import { TooltipContext } from "../contexts/TableTooltip";
const token = localStorage.getItem('token');

function descendingComparator(a, b, orderBy) {
    if (!a[orderBy] || !b[orderBy]) return 0;
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}




// const headCells = [
//     {
//         id: 'status',
//         numeric: false,
//         disablePadding: false,
//         label: 'Status',
//     },
//     {
//         id: 'date',
//         numeric: true,
//         disablePadding: false,
//         label: 'Date',
//     },
//     {
//         id: 'customer',
//         numeric: true,
//         disablePadding: false,
//         label: 'customer',
//     },
//     {
//         id: 'category',
//         numeric: true,
//         disablePadding: false,
//         label: 'Category',
//     },
//     {
//         id: 'total',
//         numeric: true,
//         disablePadding: false,
//         label: 'Total',
//     },
//     {
//         id: 'tax',
//         numeric: true,
//         disablePadding: false,
//         label: 'Tax',
//     },
//     {
//         id: 'taxRate',
//         numeric: true,
//         disablePadding: false,
//         label: 'Tax Rate',
//     },
// ];

const headCells = [
    { id: 'status', numeric: false, disablePadding: false, label: ' Status' },
    { id: 'date', numeric: true, disablePadding: false, label: 'Date' },
    { id: 'supplier', numeric: true, disablePadding: false, label: 'Supplier' },
    { id: 'category', numeric: true, disablePadding: false, label: 'Category' },
    { id: 'tax', numeric: true, disablePadding: false, label: 'Tax' },
    { id: 'total', numeric: true, disablePadding: false, label: 'Total' },
    // { id: 'taxRate', numeric: true, disablePadding: false, label: 'Tax Rate' },
    { id: 'match', numeric: false, disablePadding: false, label: 'Match' },
    { id: 'submittedBy', numeric: true, disablePadding: false, label: 'Submitted By' },
    { id: 'dueDate', numeric: true, disablePadding: false, label: 'Due Date' },
    { id: 'documentReference', numeric: true, disablePadding: false, label: 'Document Reference ' },
    { id: 'baseTotal', numeric: true, disablePadding: false, label: 'Base Total' },
    // { id: 'description', numeric: true, disablePadding: false, label: 'Description' },
    { id: 'paid', numeric: true, disablePadding: false, label: 'Paid' },
    // { id: 'payment', numeric: true, disablePadding: false, label: 'Payment' },
    { id: 'type', numeric: true, disablePadding: false, label: 'Type' },
    { id: 'itemId', numeric: true, disablePadding: false, label: 'Item Id' },
    { id: 'submissionMethod', numeric: true, disablePadding: false, label: 'Submission Method' },
    // { id: 'note', numeric: true, disablePadding: false, label: 'Note' },
    { id: 'uploadDate', numeric: true, disablePadding: false, label: 'Upload Date' },

];


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property) => (event) => {
            onRequestSort(event, property);
        };
    const { tableColumns, setTableColumns } = useContext(TooltipContext)

    return (
        <TableHead className="bg-[#ebf0ec] ">
            <TableRow>
                <TableCell padding="checkbox"
                    sx={{ padding: '9.5px' }}>
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    tableColumns[headCell.id] && <TableCell
                        key={headCell.id}
                        align='left'
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const TableSales = ({ data = [], selected, setSelected, fileURL }) => { // Default data to an empty array
    const navigate = useNavigate();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');
    const [page, setPage] = useState(0);
    const [categoryData, setCategoryData] = useState([]);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sales, setSales] = useState([]);
    const [openImage, setOpenImage] = useState(null)
    const [flagged, setFlagged] = useState([])
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const { tableColumns, filters } = useContext(TooltipContext)
    const [filteredRows, setFilteredRows] = useState([])

    const ZoomIn = (evnt) => {

        setScale((previous) => Math.min(previous + 0.2, 3));
        console.log(scale)
        evnt.stopPropagation();

    }

    const ZoomOut = (evnt) => {

        setScale((previous) => Math.max(previous - 0.2, 0.5));
        console.log(scale)
        evnt.stopPropagation();
    }

    const Rotation = (evnt) => {

        setRotate((previous) => previous + 90);
        console.log(rotate)
        evnt.stopPropagation();
    }

    const handleDownload = (imageURL, evnt) => {
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = 'image.png'; // Optional: Specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        evnt.stopPropagation();
    };

    useEffect(() => {
        setSales(data || []); // Ensure data is always an array
        fetchCategory();
        const flaggedIds = data
            .filter(item => item.flagged)
            .map(item => item._id);
        setFlagged(flaggedIds || []);
    }, [data]);

    useEffect(() => {

        let filterFields = Object.keys(filters).filter((key) => filters[key])

        if (filterFields.length !== 0) {
            let filteredRows = sales.filter((row) => {
                let Filt = filterFields.every((field) => {

                    if (field.toLowerCase() === "tax") {

                        if (JSON.parse(filters[field])) {

                            return row.taxAmount !== 0 && row.taxAmount != null;
                        } else {

                            return row.taxAmount === 0 || row.taxAmount == null;
                        }

                    }

                    if (field.toLowerCase() === "currency") {
                        if (JSON.parse(filters[field])) {

                            return row.currency === "GBP"
                        }
                        else {

                            return row.currency !== "GBP" || row.currency == null;
                        }
                    }

                    if (field.toLowerCase() === "read") {
                        if (JSON.parse(filters[field])) {

                            return row.visibleStatus !== "New"
                        }
                        else {
                            return row.visibleStatus === "New"
                        }

                    }

                    if (field.toLowerCase() === "flag") {
                        console.log(row, row.flagged === JSON.parse(filters[field]))

                        return row.flagged === JSON.parse(filters[field]);
                    }

                    if (field.toLowerCase() === "paid") {
                        return row.paid === JSON.parse(filters[field]);
                    }
                    return true;
                });
                // console.log(Filt)
                return Filt
            });
            setFilteredRows(filteredRows);
        }

        // }
        else {
            setFilteredRows([...data] || [])
        }

    },
        [filters, data])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60vw',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        maxHeight: '100vh',
        overflow: 'auto',
        p: 4,
    };

    const handleFlag = async (row, rowId) => {
        const newFlagStatus = !flagged.includes(rowId);


        setFlagged((prev) => newFlagStatus ? [...prev, rowId] : prev.filter((id) => id !== rowId));
        row.flagged = newFlagStatus;
        ;
        try {
            await axios.patch(`${baseURL}/sales/${rowId}`, { flagged: newFlagStatus }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            // console.log('Flag status updated successfully on backend.');
        } catch (error) {
            console.error('Error updating flag status on backend:', error.response ? error.response.data : error.message);
        }
    };

    const handleOpenImage = (imageId) => {
        setOpenImage(imageId);
    };

    const handleCloseImage = (e) => {
        setOpenImage(null);
        setScale(1)
        setRotate(0)
        e.stopPropagation();
    };

    const handleStatusChange = async (id) => {
        try {
            const updatedCosts = sales.map(sales =>
                sales._id === id ? { ...sales, status: "Viewed" } : sales
            );
            setSales(updatedCosts);

            await axios.patch(`${baseURL}/sales/${id}`, { status: "Viewed" }, {
                headers: { Authorization: `Bearer ${token}` }
            });
        } catch (error) {
            console.error('Error updating status:', error.response ? error.response.data : error.message);
        }
    };

    const fetchCategory = async () => {
        try {
            ///${costId}
            const response = await axios.get(`${baseURL}/category/getAll`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setCategoryData(response.data);
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    const handleCategoryChange = async (event, id) => {
        const { value } = event.target;
        try {
            const updatedCosts = sales.map(sale =>
                sale._id === id ? { ...sale, status: "Edited", category: value } : sale
            );
            setSales(updatedCosts);
            await axios.patch(`${baseURL}/sales/${id}`, { category: value, status: "Edited" }, {
                headers: { Authorization: `Bearer ${token}` }
            });
        } catch (error) {
            console.error('Error updating category:', error.response ? error.response.data : error.message);
        }
    };

    const handleCheckboxClick = (event, id) => {
        event.stopPropagation();
        if (selected.includes(id)) {
            setSelected(selected.filter(item => item !== id));
        } else {
            setSelected([...selected, id]);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.includes(id);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

    return (
        <div>
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={() => { }}
                        onRequestSort={handleRequestSort}
                        rowCount={filteredRows?.length}
                    />
                    <TableBody>
                        {stableSort(filteredRows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                const isItemSelected = isSelected(row._id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleCheckboxClick(event, row._id)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row._id}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <div className="w-fit p-1 flex gap-1 items-center ">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onClick={(event) => handleCheckboxClick(event, row._id)}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                                <span className="active:translate-y-1 cursor-pointer" onClick={(e) => { e.stopPropagation(); handleFlag(row, row._id) }}>{flagged.includes(row._id) || row.flagged ? <FlagIcon className="text-[#44a0fc]" /> : <FlagOutlinedIcon className="text-[#b8b9ba]" />}</span>
                                                <span className="active:translate-y-1 cursor-pointer" onClick={(e) => { e.stopPropagation(); handleOpenImage(row._id) }} ><ImageOutlinedIcon className="text-[#b8b9ba]" /></span>
                                                {openImage === row._id && (

                                                    <Modal
                                                        open={Boolean(openImage === row._id)}
                                                        onClose={handleCloseImage}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <Box sx={style}>
                                                            <img style={{
                                                                transform: `scale(${scale}) rotate(${rotate}deg)`,
                                                                transition: "transform 0.3s ease-in-out",
                                                            }} src={row.image} alt="" />
                                                            <button className="fixed top-1 right-1 " onClick={handleCloseImage}><CloseIcon /></button>
                                                            <div className="flex gap-5 absolute z-50 left-1/2 top-[90vh] transform -translate-x-1/2 -translate-y-1/2">
                                                                <button className="bg-black rounded-sm text-white p-2" onClick={(e) => ZoomIn(e)} ><ZoomInOutlinedIcon /></button>
                                                                <button className="bg-black rounded-sm text-white p-2" onClick={(e) => ZoomOut(e)}><ZoomOutOutlinedIcon /></button>
                                                                <button className="bg-black rounded-sm text-white p-2" onClick={(e) => Rotation(e)}><RotateRightOutlinedIcon /></button>
                                                                <button className="bg-black rounded-sm text-white p-2" onClick={(e) => handleDownload(row.image, e)}><FileDownloadOutlinedIcon /></button>
                                                            </div>

                                                        </Box>


                                                    </Modal>


                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>

                                            <span className={`w-fit p-1 ${row?.visibleStatus === 'New' ? 'bg-[#96ffaf]' : 'bg-[#fae396]'}`}>{row?.visibleStatus}</span>

                                        </TableCell>
                                        {tableColumns.date && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {row?.date ? new Date(row?.date).toLocaleDateString() : ""}
                                        </TableCell>}
                                        {tableColumns.supplier && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {row?.supplier ? row.supplier.split(" ").map((n) => n.charAt(0).toUpperCase() + n.slice(1).toLowerCase()).join(" ") : 'Unknown Supplier'}
                                        </TableCell>}
                                        {tableColumns.category && <TableCell align="left">
                                            <Select
                                                sx={{ height: "30px", width: "200px", fontSize: "13px" }}
                                                value={row.category || ""}
                                                onClick={(event) => event.stopPropagation()}
                                                onChange={(event) => handleCategoryChange(event, row._id)}
                                            >
                                                {categoryData.map(({ name }, index) => {
                                                    return <MenuItem value={name}>{name}</MenuItem>
                                                    { return <option sx={{ fontSize: "13px" }} value={name}>{name}</option> }
                                                })}
                                                {/* <MenuItem value="Option 1">Option 1</MenuItem>
                                                <MenuItem value="Option 2">Option 2</MenuItem>
                                                <MenuItem value="Option 3">Option 3</MenuItem> */}
                                            </Select>
                                        </TableCell>}
                                        {tableColumns.tax && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {row?.tax}
                                        </TableCell>}
                                        {tableColumns.taxAmount && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>

                                            {`${row?.currencySymbol} ${row?.taxAmount.toFixed(2)}`}
                                        </TableCell>}
                                        {tableColumns.total && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {`${row?.currencySymbol} ${row?.totalAmount.toFixed(2)}`}
                                        </TableCell>}
                                        {tableColumns.submittedBy && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {row?.submittedBy}

                                        </TableCell>}
                                        {tableColumns.dueDate && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {row?.dueDate ? new Date(row?.dueDate).toLocaleDateString() : ""}
                                        </TableCell>}
                                        {tableColumns.documentReference && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {row?.documentReference}
                                        </TableCell>}
                                        {tableColumns.baseTotal && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {`${row?.currencySymbol} ${(row?.totalAmount - row?.taxAmount).toFixed(2)} `}
                                        </TableCell>}
                                        {tableColumns.paid && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {`${row?.paid ? "Yes" : 'No'}`}
                                        </TableCell>}
                                        {tableColumns.type && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {row?.type}
                                        </TableCell>}
                                        {tableColumns.itemId && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {row?.itemId}
                                        </TableCell>}
                                        {tableColumns.submissionMethod && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {row?.submissionMethod}
                                        </TableCell>}
                                        {tableColumns.uploadDate && <TableCell align="left" onClick={() => navigate(`/dashboard/sales/edit/${row._id}`)}>
                                            {row?.created_at ? new Date(row?.created_at).toLocaleDateString() : ""}
                                        </TableCell>}
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default TableSales