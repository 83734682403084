// import React, { useState } from 'react';
// import {
//     Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Slider, Typography, Radio, RadioGroup, FormControlLabel, Checkbox, Select, IconButton, Menu, MenuItem, FormControl, InputLabel, InputAdornment
// } from '@mui/material';
// import MailIcon from '@mui/icons-material/Mail';
import {baseURL} from '../Config';
// import PhoneIcon from '@mui/icons-material/Phone';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { Search, MoreVert, AdminPanelSettings } from '@mui/icons-material';
// import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';

// const MyTeam = () => {
//     const [open, setOpen] = useState(false);
//     const [step, setStep] = useState(1);
//     const [formData, setFormData] = useState({
//         firstName: '',
//         lastName: '',
//         email: '',
//         mobile: '',
//         userRole: '',
//         verification: false
//     });
//     const [users, setUsers] = useState([]);
//     const [anchorEl, setAnchorEl] = useState(null);

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//         setStep(1);
//         setFormData({
//             firstName: '',
//             lastName: '',
//             email: '',
//             mobile: '',
//             userRole: '',
//             verification: false
//         });
//     };

//     const handleNext = () => {
//         if (step < 3) setStep(step + 1);
//         else {
//             setUsers([...users, formData]);
//             handleClose();
//         }
//     };

//     const handleBack = () => {
//         if (step > 1) setStep(step - 1);
//     };

//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     };

//     const handleMenuClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleMenuClose = () => {
//         setAnchorEl(null);
//     };

//     const marks = [
//         { value: 1, label: 'User Details' },
//         { value: 2, label: 'User Role' },
//         { value: 3, label: 'User Invitation' }
//     ];
//     const [expanded, setExpanded] = useState(false);

//     const toggleExpanded = () => {
//         setExpanded(!expanded);

//     }
//     const initialText = `Gautam Goswami from Uni Acko LLP has invited you to use Dext Prepare — a simple way to organize your receipts, bills and invoices online.
  
//   Create your account and connect with Uni Acko LLP today.
  
//   What is Dext Prepare?
//   Dext Prepare helps small businesses work with accountants and bookkeepers to keep accurate financial records. Use your mobile phone to scan your receipts on the go, and log in online and see all of your business' paperwork in a space you share with your advisor.
  
//   Visit our website to learn more about Dext and how it saves time for thousands of accountants, bookkeepers and businesses every day.
  
//   Many thanks
//   The Dext team`;

//     const trimmedText = initialText.trim();
//     const shortText = trimmedText.slice(0, 200);


//     const [mobData, setmobData] = useState({ mobile: '', countryCode: '+1' });

//     const handleMobChange = (e) => {
//         setmobData({ ...mobData, [e.target.name]: e.target.value });

//     }
//     return (
//         <div style={{ padding: '16px' }}>
//             <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '16px' }}>
//                 <Typography variant="h6" style={{ color: '#ff5a02', fontWeight: "bold", fontSize: "14px" }}>Users</Typography>
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                     <Typography variant="h6" style={{ color: '#1976d2', fontSize: '14px' }}>Sort alphabetically</Typography>
//                     <TextField
//                         variant="outlined"
//                         size="small"
//                         placeholder="Name"
//                         InputProps={{ endAdornment: <Search /> }}
//                         style={{ marginLeft: '16px' }}
//                     />
//                 </div>
//             </header>
//             <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
//                 <Button
//                     variant="contained"

//                     onClick={handleClickOpen}
//                     style={{ fontWeight: 'bold', boxShadow: 'none', fontSize: '12px', color: '#ffff', textTransform: "none", backgroundColor: '#1976d2' }}
//                 >
//                     Add a User
//                 </Button>
//                 <Button
//                     variant="contained"

//                     style={{ fontWeight: 'bold', boxShadow: 'none', fontSize: '12px', color: '#ffff', textTransform: "none", backgroundColor: '#1976d2' }}
//                 >
//                     Add Multiple Users
//                 </Button>
//             </div>

//             <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
//                 <DialogTitle style={{ color: '#1976d2', fontWeight: "bold" }}>Add a user</DialogTitle>
//                 <DialogContent style={{ padding: '24px', overflowX: 'hidden', margin: '10px' }}>
//                     <Slider
//                         value={step}
//                         marks={marks}
//                         step={null}
//                         max={3}
//                         min={1}
//                         disabled
//                         style={{ color: '#1976d2', marginBottom: '30px', width: '50%', marginLeft: "140px", marginTop: '-27px' }}
//                     />
//                     {step === 1 && (
//                         <div>
//                             <Typography variant="h6" style={{ color: "#546478", marginTop: '50x', fontSize: '16px', fontWeight: "bold" }}>1. Enter user details</Typography>
//                             <form style={{ display: 'flex', flexDirection: 'column' }}>
//                                 <div style={{ display: 'flex', marginBottom: '8px', marginTop: "18px" }}>
//                                     <Typography style={{ marginRight: '8px', fontSize: "14px" }}>First Name:</Typography>
//                                     <TextField
//                                         label=""
//                                         name="firstName"
//                                         value={formData.firstName}
//                                         onChange={handleChange}
//                                         variant="outlined"
//                                         size="small"
//                                         style={{ marginBottom: '8px', height: '30px', marginLeft: "130px", width: "55%" }}
//                                     />
//                                 </div>
//                                 <div style={{ display: 'flex', marginTop: "15px" }}>
//                                     <Typography style={{ marginRight: '8px', fontSize: "14px" }}>Last Name:</Typography>
//                                     <TextField
//                                         label=""
//                                         name="lastName"
//                                         value={formData.lastName}
//                                         onChange={handleChange}
//                                         variant="outlined"
//                                         size="small"
//                                         style={{ marginBottom: '8px', height: '30px', marginLeft: "130px", width: "55%" }}
//                                     />
//                                 </div>
//                             </form>
//                         </div>
//                     )}
//                     {step === 2 && (
//                         <div>
//                             <Typography variant="h6" style={{ color: "#546478", marginTop: '50x', fontSize: '17px' }}>2. Choose a role for {`${formData.firstName} ${formData.lastName}`}</Typography>
//                             <RadioGroup
//                                 name="userRole"
//                                 value={formData.userRole}
//                                 onChange={handleChange}
//                                 row
//                                 style={{ marginTop: '14px', color: "#546478", marginLeft: '43px', fontSize: '8px' }}
//                             >
//                                 <FormControlLabel value="admin" control={<Radio />} label="Admin User" />
//                                 <FormControlLabel value="editor" control={<Radio />} label="Expense Approve" />
//                                 <FormControlLabel value="viewer" control={<Radio />} label="Basic User" />
//                             </RadioGroup>
//                             <div>
//                                 {formData.userRole === 'admin' && (
//                                     <div>
//                                         <Typography variant="h6" style={{ color: "#546478", fontWeight: 'bold', fontSize: '13px', marginTop: "20px" }}>Admin users can:</Typography>
//                                         <Typography>
//                                             <ul style={{ listStyleType: "disc", marginLeft: "10px", fontSize: '12px', color: "#546478", padding: "10px" }}>
//                                                 <li>Submit, view, edit and publish other peoples’ items</li>
//                                                 <li>Create and approve expense reports</li>
//                                                 <li>Add and suspend users</li>
//                                                 <li>Change account-wide settings</li>
//                                                 <li>Set <a href="#" style={{ color: "blue" }}>automation rules</a> and other advanced features.</li>
//                                             </ul>
//                                         </Typography>
//                                     </div>
//                                 )}
//                                 {formData.userRole === 'editor' && (
//                                     <div>
//                                         <Typography variant="h6" style={{ color: "#546478", fontWeight: 'bold', fontSize: '13px', marginTop: "20px" }}>Expense approvers can:</Typography>
//                                         <Typography>
//                                             <ul style={{ listStyleType: "disc", marginLeft: "10px", fontSize: '12px', color: "#546478", padding: "10px" }}>
//                                                 <li>Submit, view, edit and publish other peoples’ items</li>
//                                                 <li>Approve expense reports</li>
//                                                 <li>Change their personal settings</li>
//                                             </ul>
//                                         </Typography>
//                                         <Typography variant="h6" style={{ color: "#546478", fontWeight: 'bold', fontSize: '13px', marginTop: "14px" }}>and optionally:</Typography>
//                                         <FormControlLabel
//                                             control={<Checkbox />}
//                                             label="Create expense reports"
//                                             style={{ marginTop: '16px', color: "blue", border: ' 1px solid #546478', padding: "10px", width: "90%", marginLeft: "10px", borderRight: "none", borderLeft: "none", fontSize: "13px" }}
//                                         />
//                                         {/* <FormControlLabel
//                                     control={<Radio />}
//                                     label="Cannot publish to an accounting software"
//                                     style={{ marginTop: '16px', padding:"10px", width:"90%", marginLeft:"10px", borderRight:"none", borderLeft:"none", fontSize:"13px"}}
//                                 />
//                                 <FormControlLabel
//                                     control={<Radio />}
//                                     label="Can publish items and expense reports to an accounting software"
//                                     style={{ marginTop: '16px', padding:"10px", width:"90%", marginLeft:"10px", borderRight:"none", borderLeft:"none", fontSize:"8px"}}
//                                 /> */}
//                                     </div>

//                                 )}
//                                 {formData.userRole === 'viewer' && (
//                                     <div>
//                                         <Typography variant="h6" style={{ color: "#546478", fontWeight: 'bold', fontSize: '13px', marginTop: "20px" }}>Basic users can:</Typography>
//                                         <Typography>
//                                             <ul style={{ listStyleType: "disc", marginLeft: "10px", fontSize: '12px', color: "#546478", padding: "10px" }}>
//                                                 <li>Submit, view, edit their own items</li>
//                                                 <li>Change their personal settings</li>
//                                             </ul>
//                                         </Typography>
//                                         <Typography variant="h6" style={{ color: "#546478", fontWeight: 'bold', fontSize: '13px', marginTop: "14px" }}>and optionally:</Typography>
//                                         <FormControlLabel
//                                             control={<Checkbox />}
//                                             label="Create expense reports"
//                                             style={{ marginTop: '16px', color: "blue", border: ' 1px solid #546478', padding: "10px", width: "90%", marginLeft: "10px", borderRight: "none", borderLeft: "none", fontSize: "13px" }}
//                                         />
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     )}
//                     {step === 3 && (
//                         <div>

//                             <Typography variant="h6" style={{ color: "#546478", marginTop: '50x', fontSize: '17px' }}>3. Invite <strong>{`${formData.firstName} ${formData.lastName}`}</strong> to use Dext Prepare</Typography>
//                             <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
//                                 <Typography style={{ marginRight: '8px', marginTop: '28px', fontSize: '14px', color: "#546478" }}>Email address:</Typography>
//                                 <TextField
//                                     name="email"
//                                     value={formData.email}
//                                     onChange={handleChange}
//                                     variant="outlined"
//                                     size="small"
//                                     style={{ height: '30px', width: '55%', marginLeft: "130px", marginTop: "31px" }}
//                                 />
//                             </div>
//                             <FormControlLabel
//                                 control={
//                                     <Checkbox
//                                         checked={formData.verification}
//                                         onChange={(e) => setFormData({ ...formData, verification: e.target.checked })}

//                                     />
//                                 }
//                                 label="Send an email invitation"
//                             // style={{ fontWeight: 'bold', fontSize: '12px' }}
//                             />
//                             <div style={{ backgroundColor: '#f0f0f0', padding: '16px', marginTop: '16px' }}>
//                                 <Typography variant="body1">Hi {formData.firstName},</Typography>
//                                 <Typography variant="body2" style={{ marginTop: "15px", lineHeight: "22px" }}>
//                                     {expanded ? trimmedText : shortText}
//                                     {!expanded && trimmedText.length > shortText.length && (
//                                         <span style={{ color: 'blue', cursor: 'pointer' }} onClick={toggleExpanded}>
//                                             {' Read more'}
//                                         </span>
//                                     )}
//                                     {expanded && (
//                                         <span style={{ color: 'blue', cursor: 'pointer' }} onClick={toggleExpanded}>
//                                             {' Read less'}
//                                         </span>
//                                     )}
//                                 </Typography>
//                             </div>
//                             <FormControlLabel
//                                 control={<Checkbox />}
//                                 label="Send an text invitation"
//                                 style={{ marginTop: '16px' }}
//                             />

                            
//                         </div>
//                     )}
//                 </DialogContent>
//                 <DialogActions style={{ padding: '16px' }}>
//                     {step > 1 && (
//                         <Button
//                             onClick={handleBack}
//                             style={{ backgroundColor: '#1976d2', color: '#ffff', fontWeight: 'bold', fontSize: "12px" }}
//                         >
//                             Back
//                         </Button>
//                     )}
//                     <Button
//                         onClick={handleNext}
//                         style={{ backgroundColor: '#1976d2', color: '#ffff', fontWeight: 'bold', fontSize: "12px" }}
//                         disabled={step === 3 && !formData.email}
//                     >
//                         {step === 3 ? 'Add User' : 'Next'}
//                     </Button>
//                 </DialogActions>

//             </Dialog>

//             <div style={{ marginTop: '16px' }}>
//                 {users.map((user, index) => (
//                     <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px', borderBottom: '1px solid #ddd' }}>
//                         <div style={{ display: 'flex', alignItems: 'center' }}>

//                             <div>

//                                 <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
//                                     <AccountCircleIcon style={{ marginRight: '8px', color: "#546478" }} />
//                                     <Typography variant="body1" style={{ fontSize: "16px", color: "blue" }}>{`${user.firstName} ${user.lastName}`}</Typography>
//                                 </div>


//                                 <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', color: "#546478" }}>
//                                     <MailIcon style={{ marginRight: '8px' }} />
//                                     <Typography variant="body2">{user.email}</Typography>
//                                 </div>


//                                 <div style={{ display: 'flex', alignItems: 'center', color: "#546478" }}>
//                                     <StayCurrentPortraitIcon style={{ marginRight: '8px' }} />
//                                     <Typography variant="body2">{user.mobile}Never used the mobile app.</Typography>
//                                 </div>
//                             </div>
//                         </div>
//                         <div style={{ marginRight: "20%", fontSize: "12px", color: "#546478" }}>
//                             <AdminPanelSettings />
//                             Adminstrator
//                         </div>


//                         <div>
//                             <Button style={{
//                                 border: "none", backgroundColor: "#D8D8D8", borderRadius: "5px",
//                                 height: "35px", color: "#546478", paddingLeft: "10px", fontWeight: "bold", textTransform: "none"
//                             }}> Manage
//                                 <IconButton onClick={handleMenuClick}>
//                                     <MoreVert />
//                                 </IconButton>
//                                 <Menu
//                                     anchorEl={anchorEl}
//                                     open={Boolean(anchorEl)}
//                                     onClose={handleMenuClose}
//                                 >
//                                     <MenuItem onClick={handleMenuClose}>Edit User</MenuItem>
//                                     <MenuItem onClick={handleMenuClose}>Email Subscription</MenuItem>
//                                     <MenuItem onClick={handleMenuClose}>Change Password</MenuItem>
//                                 </Menu>
//                             </Button>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// // export default MyTeam;
// import React, { useState, useEffect } from 'react';
// import {
//     TextField, Typography, IconButton, Menu, MenuItem
// } from '@mui/material';
// import MailIcon from '@mui/icons-material/Mail';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { Search, MoreVert, AdminPanelSettings } from '@mui/icons-material';
// import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
// import axios from 'axios';

// const MyTeam = () => {
//     const [users, setUsers] = useState([]); // State to store users data
//     const [anchorEl, setAnchorEl] = useState(null);

//     // Fetch user details from the API
//     useEffect(() => {
//         const fetchUserDetails = async () => {
//             try {
//                 const token = localStorage.getItem('authToken'); // Fetch the token from localStorage

//                 if (!token) {
//                     console.error('No auth token found in localStorage.');
//                     return;
//                 }

//                 // Make the GET request to fetch user details
//                 const response = await axios.get('${baseURL}/auth/user-details', {
//                     headers: {
//                         Authorization: `Bearer ${token}`, // Add the token to the request headers
//                     },
//                 });

//                 // Assume the API returns an array of users
//                 setUsers([response.data]); // Set the user data from the response
//             } catch (error) {
//                 console.error('Error fetching user details:', error);
//             }
//         };

//         fetchUserDetails(); // Call the function to fetch user details when the component mounts
//     }, []);

//     const handleMenuClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleMenuClose = () => {
//         setAnchorEl(null);
//     };

//     return (
//         <div style={{ padding: '16px' }}>
//             <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '16px' }}>
//                 <Typography variant="h6" style={{ color: '#ff5a02', fontWeight: "bold", fontSize: "14px" }}>Users</Typography>
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                     <Typography variant="h6" style={{ color: '#1976d2', fontSize: '14px' }}>Sort alphabetically</Typography>
//                     <TextField
//                         variant="outlined"
//                         size="small"
//                         placeholder="Name"
//                         InputProps={{ endAdornment: <Search /> }}
//                         style={{ marginLeft: '16px' }}
//                     />
//                 </div>
//             </header>

//             <div style={{ marginTop: '16px' }}>
//                 {users.map((user, index) => (
//                     <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px', borderBottom: '1px solid #ddd' }}>
//                         <div style={{ display: 'flex', alignItems: 'center' }}>
//                             <div>
//                                 <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
//                                     <AccountCircleIcon style={{ marginRight: '8px', color: "#546478" }} />
//                                     <Typography variant="body1" style={{ fontSize: "16px", color: "blue" }}>{user.name}</Typography>
//                                 </div>

//                                 <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', color: "#546478" }}>
//                                     <MailIcon style={{ marginRight: '8px' }} />
//                                     <Typography variant="body2">{user.email}</Typography>
//                                 </div>

//                                 <div style={{ display: 'flex', alignItems: 'center', color: "#546478" }}>
//                                     <StayCurrentPortraitIcon style={{ marginRight: '8px' }} />
//                                     <Typography variant="body2">{user.mobile}</Typography>
//                                 </div>
//                             </div>
//                         </div>
//                         <div style={{ marginRight: "20%", fontSize: "12px", color: "#546478" }}>
//                             <AdminPanelSettings />
//                             Administrator
//                         </div>

//                         <div>
//                             <IconButton onClick={handleMenuClick}>
//                                 <MoreVert />
//                             </IconButton>
//                             <Menu
//                                 anchorEl={anchorEl}
//                                 open={Boolean(anchorEl)}
//                                 onClose={handleMenuClose}
//                             >
//                                 <MenuItem onClick={handleMenuClose}>Edit User</MenuItem>
//                                 <MenuItem onClick={handleMenuClose}>Email Subscription</MenuItem>
//                                 <MenuItem onClick={handleMenuClose}>Change Password</MenuItem>
//                             </Menu>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default MyTeam;

import React, { useState, useEffect } from 'react';
import {
    TextField, Typography, IconButton, Menu, MenuItem
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Search, MoreVert, AdminPanelSettings } from '@mui/icons-material';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import axios from 'axios';

const MyTeam = () => {
    const [users, setUsers] = useState([]); // State to store users data
    const [anchorEl, setAnchorEl] = useState(null);

    // Function to fetch user details from the API
    const fetchUserDetails = async () => {
        try {
            const token = localStorage.getItem('authToken'); // Fetch the token from localStorage

            if (!token) {
                console.error('No auth token found in localStorage.');
                return;
            }

            // Clear the previous state to avoid showing old data
            setUsers([]);

            // Make the GET request to fetch user details
            const response = await axios.get(`${baseURL}/auth/user-details`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Add the token to the request headers
                },
            });

            console.log('Fetched data:', response.data); // Debugging line

            // Set the user data from the response
            setUsers([response.data]);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    // Fetch user details when the component mounts or when the auth token changes
    useEffect(() => {
        fetchUserDetails(); // Call the function to fetch user details
    }, [localStorage.getItem('authToken')]); // Add the token as a dependency

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{ padding: '16px' }}>
            <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '16px' }}>
                <Typography variant="h6" style={{ color: '#ff5a02', fontWeight: "bold", fontSize: "14px" }}>Users</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" style={{ color: '#1976d2', fontSize: '14px' }}>Sort alphabetically</Typography>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Name"
                        InputProps={{ endAdornment: <Search /> }}
                        style={{ marginLeft: '16px' }}
                    />
                </div>
            </header>

            <div style={{ marginTop: '16px' }}>
                {users.length > 0 ? (
                    users.map((user, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px', borderBottom: '1px solid #ddd' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <AccountCircleIcon style={{ marginRight: '8px', color: "#546478" }} />
                                        <Typography variant="body1" style={{ fontSize: "16px", color: "blue" }}>{user.name}</Typography>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', color: "#546478" }}>
                                        <MailIcon style={{ marginRight: '8px' }} />
                                        <Typography variant="body2">{user.email}</Typography>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', color: "#546478" }}>
                                        <StayCurrentPortraitIcon style={{ marginRight: '8px' }} />
                                        <Typography variant="body2">{user.mobile}</Typography>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginRight: "20%", fontSize: "12px", color: "#546478" }}>
                                <AdminPanelSettings />
                                Administrator
                            </div>

                            <div>
                                <IconButton onClick={handleMenuClick}>
                                    <MoreVert />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={handleMenuClose}>Edit User</MenuItem>
                                    <MenuItem onClick={handleMenuClose}>Email Subscription</MenuItem>
                                    <MenuItem onClick={handleMenuClose}>Change Password</MenuItem>
                                </Menu>
                            </div>
                        </div>
                    ))
                ) : (
                    <Typography variant="body2" style={{ color: '#999' }}>No users found or loading...</Typography>
                )}
            </div>
        </div>
    );
};

export default MyTeam;
