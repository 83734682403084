import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Modal,
  Table,
  TablePagination,
  Button,
  Select,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TableSortLabel,
  Box,
  TextField,
  Autocomplete,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import FlagIcon from "@mui/icons-material/Flag";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { baseURL } from "../Config";
import { TooltipContext } from "../contexts/TableTooltip";
import PDFViewer from "./PDFViewer";
// import MyPdfViewer from "./PDFViewer";

import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { BusinessContext } from "../contexts/Business";
import { Search } from "@mui/icons-material";
import moment from "moment/moment";
import { CusAutocomplete } from "./common/CusAutocomplete";

const token = localStorage.getItem("token");

// Sorting functions
function descendingComparator(a, b, orderBy) {
  if (!a[orderBy] || !b[orderBy]) return 0;
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "status", numeric: false, disablePadding: false, label: " Status" },
  { id: "date", numeric: true, disablePadding: false, label: "Date" },
  { id: "supplier", numeric: true, disablePadding: false, label: "Supplier" },
  { id: "category", numeric: true, disablePadding: false, label: "Category" },
  { id: "tax", numeric: true, disablePadding: false, label: "Tax" },
  { id: "total", numeric: true, disablePadding: false, label: "Total" },
  // { id: 'taxRate', numeric: true, disablePadding: false, label: 'Tax Rate' },
  { id: "match", numeric: false, disablePadding: false, label: "Match" },
  {
    id: "submittedBy",
    numeric: true,
    disablePadding: false,
    label: "Submitted By",
  },
  { id: "dueDate", numeric: true, disablePadding: false, label: "Due Date" },
  {
    id: "documentReference",
    numeric: true,
    disablePadding: false,
    label: "Document Reference ",
  },
  {
    id: "baseTotal",
    numeric: true,
    disablePadding: false,
    label: "Base Total",
  },
  // { id: 'description', numeric: true, disablePadding: false, label: 'Description' },
  { id: "paid", numeric: true, disablePadding: false, label: "Paid" },
  // { id: 'payment', numeric: true, disablePadding: false, label: 'Payment' },
  { id: "type", numeric: true, disablePadding: false, label: "Type" },
  { id: "itemId", numeric: true, disablePadding: false, label: "Item Id" },
  {
    id: "submissionMethod",
    numeric: true,
    disablePadding: false,
    label: "Submission Method",
  },
  // { id: 'note', numeric: true, disablePadding: false, label: 'Note' },
  {
    id: "uploadDate",
    numeric: true,
    disablePadding: false,
    label: "Upload Date",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { tableColumns, setTableColumns } = useContext(TooltipContext);
  // console.log('abcd', tableColumns)

  


  return (
    <TableHead className="bg-[#ebf0ec]">
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{
             // Custom padding value, you can adjust it as needed
          }}
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
            sx={{ml:' 3px'}}
          />
          {/* <span><FlagOutlinedIcon className="text-[#b8b9ba]" /> </span> */}
        </TableCell>
        {headCells.map(
          (headCell) =>
            tableColumns[headCell.id] && (
              <TableCell
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

const TableComponent = ({ data = [], selected, setSelected, fileURL }) => {
  const categoryOptions = [
    { value: "Accomodation", label: "Accomodation" },
    { value: "Food", label: "Food" },
    { value: "IT & Technology", label: "IT & Technology" },
    { value: "Motor Expenses", label: "Motor Expenses" },
    { value: "Office Supplies", label: "Office Supplies" },
    { value: "Other", label: "Other" },
    { value: "Postage", label: "Postage" },
    { value: "Telecoms & Internet", label: "Telecoms & Internet" },
    { value: "To Be Allocated", label: "To Be Allocated" },
    { value: "Training & Education", label: "Training & Education" },
    { value: "Travel", label: "Travel" },
    { value: "Utilites & Bills", label: "Utilites & Bills" },
  ];

  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const [page, setPage] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [costs, setCosts] = useState([]);
  const [openImage, setOpenImage] = useState(null);
  const [flagged, setFlagged] = useState([]);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const { tableColumns, setTableColumns, filters, setFilters, formData } =
    useContext(TooltipContext);
  const [filteredRows, setFilteredRows] = useState([]);

  const { setSelectedItem, setBackRoute } = useContext(BusinessContext);

  const ZoomIn = (evnt) => {
    setScale((previous) => Math.min(previous + 0.2, 3));
    console.log(scale);
    evnt.stopPropagation();
  };

  const ZoomOut = (evnt) => {
    setScale((previous) => Math.max(previous - 0.2, 0.5));
    console.log(scale);
    evnt.stopPropagation();
  };

  const Rotation = (evnt) => {
    setRotate((previous) => previous + 90);
    console.log(rotate);
    evnt.stopPropagation();
  };

  const handleDownload = (imageURL, evnt) => {
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    evnt.stopPropagation();
  };

  useEffect(() => {
    setCosts(data || []);
    const flaggedIds = data
      .filter((item) => item.flagged)
      .map((item) => item._id);
    setFlagged(flaggedIds || []);
    console.log("yum" + flagged);
    fetchCategory();
    setCosts((prev) => [...prev]);
  }, [data]);

  useEffect(() => {
    let filterFields = Object.keys(filters).filter((key) => filters[key]);

    if (filterFields.length !== 0) {
      let filteredRows = costs.filter((row) => {
        let Filt = filterFields.every((field) => {
          if (field.toLowerCase() === "tax") {
            if (JSON.parse(filters[field])) {
              return row.taxAmount !== 0 && row.taxAmount != null;
            } else {
              return row.taxAmount === 0 || row.taxAmount == null;
            }
          }

          if (field.toLowerCase() === "currency") {
            if (JSON.parse(filters[field])) {
              return row.currency === "GBP";
            } else {
              return row.currency !== "GBP" || row.currency == null;
            }
          }

          if (field.toLowerCase() === "read") {
            if (JSON.parse(filters[field])) {
              return row.visibleStatus !== "New";
            } else {
              return row.visibleStatus === "New";
            }
          }

          if (field.toLowerCase() === "flag") {
            console.log(row, row.flagged === JSON.parse(filters[field]));

            return row.flagged === JSON.parse(filters[field]);
          }

          if (field.toLowerCase() === "paid") {
            return row.paid === JSON.parse(filters[field]);
          }
          return true;
        });
        // console.log(Filt)
        return Filt;
      });
      setFilteredRows(filteredRows);
    }

    // }
    else {
      setFilteredRows([...data] || []);
    }
    // console.log("FFFFF", filters)
  }, [filters, data]);

  useEffect(() => {
    let filtered = costs.filter((row) => {
      let passes = true;

      // Check supplier
      console.log(row.supplier, formData.supplier);
      if (formData.supplier && row.supplier !== formData.supplier) {
        console.log("here");

        passes = false;
      }
      // console.log('applying advance feature',formData.supplier,row.supplier,passes)

      // Check amount range
      if (
        formData.amountFrom &&
        parseFloat(row.total) < parseFloat(formData.amountFrom)
      ) {
        passes = false;
      }
      if (
        formData.amountTo &&
        parseFloat(row.total) > parseFloat(formData.amountTo)
      ) {
        passes = false;
      }

      // Check date ranges
      if (
        formData.dateFrom &&
        new Date(row.date) < new Date(formData.dateFrom)
      ) {
        passes = false;
      }
      if (formData.dateTo && new Date(row.date) > new Date(formData.dateTo)) {
        passes = false;
      }

      // Check due date range
      if (
        formData.dueDateFrom &&
        new Date(row.dueDate) < new Date(formData.dueDateFrom)
      ) {
        passes = false;
      }
      if (
        formData.dueDateTo &&
        new Date(row.dueDate) > new Date(formData.dueDateTo)
      ) {
        passes = false;
      }

      // Check upload date range
      if (
        formData.uploadDateFrom &&
        new Date(row.uploadDate) < new Date(formData.uploadDateFrom)
      ) {
        passes = false;
      }
      if (
        formData.uploadDateTo &&
        new Date(row.uploadDate) > new Date(formData.uploadDateTo)
      ) {
        passes = false;
      }

      // Check document reference
      if (
        formData.documentReference &&
        !row.documentReference?.includes(formData.documentReference)
      ) {
        passes = false;
      }

      // Check item ID
      if (formData.itemId && row.itemId !== formData.itemId) {
        passes = false;
      }

      // Check type
      if (formData.type && row.type !== formData.type) {
        passes = false;
      }

      // Check currency code
      if (formData.currencyCode && row.currencyCode !== formData.currencyCode) {
        passes = false;
      }

      // Check submitted by
      if (formData.submittedBy && row.submittedBy !== formData.submittedBy) {
        passes = false;
      }

      // Check submission method
      if (
        formData.submissionMethod &&
        row.submissionMethod !== formData.submissionMethod
      ) {
        passes = false;
      }

      // Check customer
      if (formData.customer && row.customer !== formData.customer) {
        passes = false;
      }

      // Check category
      if (formData.category && row.category !== formData.category) {
        passes = false;
      }

      // Check expense claim
      if (formData.expenseClaim && row.expenseClaim !== formData.expenseClaim) {
        passes = false;
      }

      // Check added to expense claim
      if (formData.addedToExpenseClaim && !row.addedToExpenseClaim) {
        passes = false;
      }

      return passes;
    });

    filtered = filtered.filter((row) => {
      // Search in all text fields
      return Object.keys(row).some((key) => {
        const value = row[key];
        return (
          typeof value === "string" &&
          value.toLowerCase().includes(formData.searchTerm.toLowerCase())
        );
      });
    });

    console.log(filtered);
    setFilteredRows(filtered);
  }, [formData]);

  // useEffect(()=>{
  //    let advancedFilteredRow = costs.filter(row =>{
  //     // Check each field conditionally

  //     // 1. Search Term
  //     if (filterData.searchTerm && !row.searchTerm.includes(filterData.searchTerm)) {
  //       return false;
  //     }

  //     // 2. Supplier
  //     if (filterData.supplier && row.supplier !== filterData.supplier.value) {
  //       return false;
  //     }

  //     // 3. Amount Range
  //     if (filterData.amountFrom && parseFloat(row.amount) < parseFloat(filterData.amountFrom)) {
  //       return false;
  //     }
  //     if (filterData.amountTo && parseFloat(row.amount) > parseFloat(filterData.amountTo)) {
  //       return false;
  //     }

  //     // 4. Date From and Date To
  //     if (filterData.dateFrom && new Date(row.date) < new Date(filterData.dateFrom)) {
  //       return false;
  //     }
  //     if (filterData.dateTo && new Date(row.date) > new Date(filterData.dateTo)) {
  //       return false;
  //     }

  //     // 5. Due Date Range
  //     if (filterData.dueDateFrom && new Date(row.dueDate) < new Date(filterData.dueDateFrom)) {
  //       return false;
  //     }
  //     if (filterData.dueDateTo && new Date(row.dueDate) > new Date(filterData.dueDateTo)) {
  //       return false;
  //     }

  //     // 6. Upload Date Range
  //     if (filterData.uploadDateFrom && new Date(row.uploadDate) < new Date(filterData.uploadDateFrom)) {
  //       return false;
  //     }
  //     if (filterData.uploadDateTo && new Date(row.uploadDate) > new Date(filterData.uploadDateTo)) {
  //       return false;
  //     }

  //     // 7. Document Reference
  //     if (filterData.documentReference && row.documentReference !== filterData.documentReference) {
  //       return false;
  //     }

  //     // 8. Item ID
  //     if (filterData.itemId && row.itemId !== filterData.itemId) {
  //       return false;
  //     }

  //     // 9. Type
  //     if (filterData.type && row.type !== filterData.type.value) {
  //       return false;
  //     }

  //     // 10. Currency Code
  //     if (filterData.currencyCode && row.currencyCode !== filterData.currencyCode.value) {
  //       return false;
  //     }

  //     // 11. Submitted By
  //     if (filterData.submittedBy && row.submittedBy !== filterData.submittedBy) {
  //       return false;
  //     }

  //     // 12. Submission Method
  //     if (filterData.submissionMethod && row.submissionMethod !== filterData.submissionMethod.value) {
  //       return false;
  //     }

  //     // 13. Customer
  //     if (filterData.customer && row.customer !== filterData.customer.value) {
  //       return false;
  //     }

  //     // 14. Category
  //     if (filterData.category && row.category !== filterData.category.value) {
  //       return false;
  //     }

  //     // 15. Expense Claim
  //     if (filterData.expenseClaim && row.expenseClaim !== filterData.expenseClaim) {
  //       return false;
  //     }

  //     // 16. Added to Expense Claim (boolean)
  //     if (filterData.addedToExpenseClaim !== null && row.addedToExpenseClaim !== filterData.addedToExpenseClaim) {
  //       return false;
  //     }

  //     // If all conditions are met, return true for this row
  //     return true;})
  // },[formData])

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: "100vh",
    overflow: "auto",
    p: 4,
  };

  const handleFlag = async (row, rowId) => {
    const newFlagStatus = !flagged.includes(rowId);

    setFlagged((prev) =>
      newFlagStatus ? [...prev, rowId] : prev.filter((id) => id !== rowId)
    );
    row.flagged = newFlagStatus;
    try {
      await axios.patch(
        `${baseURL}/cost/${rowId}`,
        { flagged: newFlagStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log('Flag status updated successfully on backend.');
    } catch (error) {
      console.error(
        "Error updating flag status on backend:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleOpenImage = (imageId) => {
    setOpenImage(imageId);
  };

  const handleCloseImage = (e) => {
    setOpenImage(null);
    setScale(1);
    setRotate(0);
    e.stopPropagation();
  };
  const handleStatusChange = async (id) => {
    try {
      const updatedCosts = costs.map((cost) =>
        cost._id === id ? { ...cost, status: "Viewed" } : cost
      );
      setCosts(updatedCosts);

      await axios.patch(
        `${baseURL}/cost/${id}`,
        { status: "Viewed" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (error) {
      console.error(
        "Error updating status:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const fetchCategory = async () => {
    try {
      ///${costId}
      const response = await axios.get(`${baseURL}/category/get-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log('here called ',response.data)
      const transformedData = response.data.map((item) => ({
        value: item.name,
        label: item.name,
      }));
      setCategoryData(transformedData);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const handleCategoryChange = async (value, id) => {
    try {
      const updatedCosts = costs.map((cost) =>
        cost._id === id ? { ...cost, status: "Edited", category: value } : cost
      );
      setCosts(updatedCosts);
      await axios.patch(
        `${baseURL}/cost/${id}`,
        { category: value, status: "Edited" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (error) {
      console.error(
        "Error updating category:",
        error.response ? error.response.data : error.message
      );
    }
    window.location.reload();
  };

  const handleCheckboxClick = (event, id) => {
    event.stopPropagation();
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.includes(id);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;
  console.log(filteredRows);

  const location = useLocation();
  const handleNavigateEdit = (row, index) => {
    setSelectedItem(index);
    setBackRoute(location.pathname);
    navigate(`/dashboard/costs/edit/${row._id}`);
  };

  return (
    <div>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={() => {
              let ids = [];
              let condition = filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .every((i) => selected.includes(i._id));
              if (!condition) {
                stableSort(filteredRows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    ids.push(row._id);
                  });
              }
              setSelected([...ids]);
            }}
            onRequestSort={handleRequestSort}
            rowCount={filteredRows?.length}
          />
          <TableBody>
            {stableSort(filteredRows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;
                // console.log('check:', row.flagged)

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleCheckboxClick(event, row._id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    sx={[
                      {
                        "&:hover": {
                          cursor: "pointer",
                        },
                      },
                    ]}
                  >
                    <TableCell padding="checkbox">
                      <div className="w-fit p-1 flex gap-1 items-center ">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) =>
                            handleCheckboxClick(event, row._id)
                          }
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                        <span
                          className="active:translate-y-1 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFlag(row, row._id);
                          }}
                        >
                          {flagged.includes(row._id) || row.flagged ? (
                            <FlagIcon className="text-[#44a0fc]" />
                          ) : (
                            <FlagOutlinedIcon className="text-[#b8b9ba]" />
                          )}
                        </span>
                        <span
                          className="active:translate-y-1 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenImage(row._id);
                          }}
                        >
                          <ImageOutlinedIcon className="text-[#b8b9ba]" />
                        </span>
                        {openImage === row._id && (
                          <Modal
                            open={Boolean(openImage === row._id)}
                            onClose={handleCloseImage}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            {
                              row.image.slice(row.image.length - 3) !==
                              "pdf" ? (
                                <Box sx={style}>
                                  <img
                                    style={{
                                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                                      transition: "transform 0.3s ease-in-out",
                                    }}
                                    src={row.image}
                                    alt="Error Loading Image"
                                  />
                                  <button
                                    className="fixed top-1 right-1 "
                                    onClick={handleCloseImage}
                                  >
                                    <CloseIcon />
                                  </button>
                                  <div className="flex gap-5 absolute z-50 left-1/2 top-[90vh] transform -translate-x-1/2 -translate-y-1/2">
                                    <button
                                      className="bg-black rounded-sm text-white p-2"
                                      onClick={(e) => ZoomIn(e)}
                                    >
                                      <ZoomInOutlinedIcon />
                                    </button>
                                    <button
                                      className="bg-black rounded-sm text-white p-2"
                                      onClick={(e) => ZoomOut(e)}
                                    >
                                      <ZoomOutOutlinedIcon />
                                    </button>
                                    <button
                                      className="bg-black rounded-sm text-white p-2"
                                      onClick={(e) => Rotation(e)}
                                    >
                                      <RotateRightOutlinedIcon />
                                    </button>
                                    <button
                                      className="bg-black rounded-sm text-white p-2"
                                      onClick={(e) =>
                                        handleDownload(row.image, e)
                                      }
                                    >
                                      <FileDownloadOutlinedIcon />
                                    </button>
                                  </div>
                                </Box>
                              ) : (
                                <>
                                  <Box sx={{ ...style, height: "900px" }}>
                                    <button
                                      className="fixed top-1 right-1 "
                                      onClick={handleCloseImage}
                                    >
                                      <CloseIcon />
                                    </button>

                                    <PDFViewer fileUrl={row.image} />
                                  </Box>
                                </>
                              )
                              // <MyPdfViewer fileUrl={row.image} />
                            }
                          </Modal>
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => handleNavigateEdit(row, index)}
                    >
                      <span
                        className={`w-fit p-1 px-3 shadow-md rounded-sm ${
                          row?.visibleStatus === "New"
                            ? "bg-[#96ffaf]"
                            : "bg-[#fae396]"
                        }`}
                      >
                        {row?.visibleStatus}
                      </span>
                    </TableCell>
                    {tableColumns.date && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {row?.date
                          ? moment(row.date).format("DD MMM YYYY")
                          : ""}
                      </TableCell>
                    )}
                    {tableColumns.supplier && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {row?.supplier || "Unknown Supplier"}
                      </TableCell>
                    )}
                    {tableColumns.category && (
                      <TableCell align="left" width={"300px"}>
                        {/* {row?.category || 'Unknown Category'} */}
                        <Autocomplete
                          fullWidth
                          className={"!w-full"}
                          value={row?.category}
                          onClick={(event) => event.stopPropagation()}
                          onChange={(event, value) => {
                            handleCategoryChange(value.value, row._id);
                          }}
                          options={categoryData}
                          getOptionLabel={(option) =>
                            option.label ? option.label : option
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                              className={"!w-full"}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Search />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                          disableClearable
                        />
                      </TableCell>
                    )}
                    {tableColumns.tax && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {`GBP ${
                          row.taxAmount ? row?.taxAmount?.toFixed(2) : 0
                        }`}
                      </TableCell>
                    )}
                    {tableColumns.total && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {`GBP ${row?.totalAmount?.toFixed(2)}`}
                      </TableCell>
                    )}

                    {/* {tableColumns.taxRate &&  <TableCell align="left" onClick={() => handleNavigateEdit(row,index)}>
                                            {`${row?.currencySymbol} ${row?.taxAmount}`}
                                        </TableCell>} */}
                    {tableColumns.submittedBy && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {row?.submittedBy}
                      </TableCell>
                    )}
                    {tableColumns.dueDate && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {row?.dueDate
                          ? new Date(row?.dueDate).toLocaleDateString()
                          : ""}
                      </TableCell>
                    )}

                    {tableColumns.documentReference && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {row?.documentReference}
                      </TableCell>
                    )}
                    {tableColumns.baseTotal && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {`${row?.currencySymbol} ${(
                          row?.totalAmount - row?.taxAmount
                        ).toFixed(2)} `}
                      </TableCell>
                    )}
                    {tableColumns.paid && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {`${row?.paid ? "Yes" : "No"}`}
                      </TableCell>
                    )}
                    {tableColumns.type && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {row?.type}
                      </TableCell>
                    )}
                    {tableColumns.itemId && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {row?.itemId}
                      </TableCell>
                    )}
                    {tableColumns.submissionMethod && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {row?.submissionMethod}
                      </TableCell>
                    )}
                    {tableColumns.uploadDate && (
                      <TableCell
                        align="left"
                        onClick={() => handleNavigateEdit(row, index)}
                      >
                        {row?.created_at
                          ? new Date(row?.created_at).toLocaleDateString()
                          : ""}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TableComponent;
