import React, { useContext, useEffect, useState } from 'react';
import { baseURL } from '../Config';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Menu,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  Search,
  MoreVert,
  AdminPanelSettings,
  CloudUpload,
} from '@mui/icons-material';
import { data } from 'autoprefixer';
import { BusinessContext } from '../contexts/Business';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const token = localStorage.getItem('token');
const userToken = localStorage.getItem('userToken');

const MyAccountant = () => {
  const [open, setOpen] = useState(false);
  const [businessId, setBusinessId] = useState('');
  const [formData, setFormData] = useState({
    email: '',
  });
  const [users, setUsers] = useState();
  const businessType = JSON.parse(
    localStorage.getItem('userInfo')
  ).businessType;

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormData({
      email: '',
    });
    setOpen(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchData = async () => {
    const response = await fetch(`${baseURL}/business`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    });

    let data = await response.json();
    setBusinessId(data[0]);
    fetchAccountantData(data[0]?._id);
  };

  const fetchAccountantData = async (id) => {
    const response = await fetch(`${baseURL}/business/accountant/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    });

    let data = await response.json();
    setUsers(data);
  };

  const handleInvitation = async () => {
    formData.businessId = businessId._id;

    // console.log(formData)
    await fetch(`${baseURL}/business/acc-invitation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(formData),
    });
    // fetchData();
    setFormData({
      email: '',
    });
    fetchAccountantData(businessId._id);
    handleClose();
  };

  return (
    businessType === 'business' && (
      <div style={{ padding: '16px' }}>
        <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
          <Button
            variant='contained'
            onClick={handleClickOpen}
            style={{
              fontWeight: 'bold',
              boxShadow: 'none',
              fontSize: '12px',
              color: '#ffff',
              textTransform: 'none',
              backgroundColor: '#1976d2',
            }}
          >
            Add Accountant
          </Button>
        </div>
        <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
          <DialogTitle>Invite Someone</DialogTitle>
          <DialogContent className='py-5'>
            <div className='text-sm font-medium text-left py-5'>
              Enter your accountant or bookkeepers email
            </div>
            <div className='flex gap-5 justify-between items-center'>
              <div className='text-sm '>Email Address:</div>
              <TextField
                required
                type='email'
                value={formData.email}
                onChange={(e, value) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                variant='outlined'
                placeholder='example@email.com'
                sx={{
                  '& .MuiInputBase-root': {
                    height: '35px',
                  },
                  width: '400px',
                }}
              />
            </div>
          </DialogContent>
          <DialogActions style={{ padding: '16px' }}>
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={handleInvitation}
                // style={{
                //   backgroundColor: '#1976d2',
                //   color: '#ffff',
                //   fontWeight: 'bold',
                //   fontSize: '12px',
                //   boxShadow: 'none',
                // }}
              >
                Send Invite
              </Button>
            </>
          </DialogActions>
        </Dialog>
      </div>
    )
  );
};
export default MyAccountant;
