import React, { useState, useEffect, useContext } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HeaderUserInfo from './HeaderUserInfo';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ChecklistIcon from '@mui/icons-material/Checklist';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import EditNoteIcon from '@mui/icons-material/EditNote';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import img3 from './../assets/img3.jpg';
import { baseURL } from '../../Config';
import { HeaderStepContext } from './context';
const token = localStorage.getItem('token');

const Problem = () => {
  const navigate = useNavigate();
  const [selectedBoxes, setSelectedBoxes] = useState([]);
  const [errors, setErrors] = useState({});
  const { setActiveStep } = useContext(HeaderStepContext);

  useEffect(() => {
    setActiveStep(3)
  }
    , []);



  const handleContinue = async () => {
    if (selectedBoxes === null) {
      setErrors({ apiError: 'Please select a box.' });
      return;
    }

    const payload = {
      problem_solved: selectedBoxes?.map((item)=>{
        return boxes?.[item]?.text
      }),
    };

   
    try {
      const response = await fetch(`${baseURL}/user/profile/${JSON.parse(localStorage.getItem('userInfo')).userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        navigate('/welcome');
      } else {
        const errorData = await response.json();
        setErrors({ apiError: errorData.message || 'Update failed' });
        console.error('Update failed:', errorData);
      }
    } catch (error) {
      setErrors({ apiError: 'An error occurred while updating. Please try again.' });
      console.error('Error during update:', error);
    }
  };


  const handleBack = () => {
    navigate('/role');
  };

  const handleBoxClick = (index) => {
    setSelectedBoxes((prevSelectedBoxes) =>
      prevSelectedBoxes.includes(index)
        ? prevSelectedBoxes.filter((i) => i !== index)
        : [...prevSelectedBoxes, index]
    );
  };
  console.log('seelcted boddd',selectedBoxes)

  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  };

  const leftSectionStyle = {
    flex: 1,
    padding: '20px',
    marginLeft: '50px',
    zIndex: '10',
  };

  const rightSectionStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    position: 'relative',
    zIndex: '5',
    marginTop: '-173px',
  };

  const imgStyle = {
    maxWidth: '100%',
    height: '91vh',
    objectFit: 'cover',
    borderRadius: '10px',
  };

  const boxes = [
    { content: '', icon: <LibraryBooksIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: 'Set up bookkeeping for my practice' },
    { content: '', icon: <LibraryBooksIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: 'Set up bookkeeping for a client' },
    { content: '', icon: <ManageAccountsIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: "Capture my client's costs & sales data" },
    { content: '', icon: <ManageAccountsIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: 'Categorize and export costs & sales data' },
    { content: '', icon: <ChecklistIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: "Create workflows to mirror my practice's processes" },
    { content: '', icon: <WarningAmberIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: "Use my client's deadlines to check upcoming tasks" },
    { content: '', icon: <FavoriteBorderIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: 'Review the health of client data across my practice' },
    { content: '', icon: <EditNoteIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: 'Create a quote for new or renewing clients' },
    { content: '', icon: <HelpOutlineIcon sx={{ color: '#ffe300', fontSize: 30 }} />, text: 'Not sure' },
  ];

  const boxStyle = (index) => ({
    height: '120px',
    width: '195px',
    border: `2px solid #bababa `,
    backgroundColor: selectedBoxes.includes(index) ? '#f7efad' : '#f5f5f2',
    margin: '8px',
    padding: '10px',
    fontSize: '14px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    textAlign: 'center',
  });

  const textStyle = {
    fontSize: '12px',
    marginTop: '3px',
    wordWrap: 'break-word',
  };

  return (
    <div>
      <HeaderUserInfo />
      <div style={containerStyle}>
        <div style={leftSectionStyle}>
          <h1 style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '6px' }}>Which problem are you trying to solve with Zoom?</h1>
          <p>Select as many as you like</p>

          <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '-4px' }}>
            {boxes.map((box, index) => (
              <div
                key={index}
                style={boxStyle(index)}
                onClick={() => handleBoxClick(index)}
              >
                {box.icon}
                <span>{box.content}</span>
                <span style={textStyle}>{box.text}</span>
              </div>
            ))}
          </div>
          <Button variant="contained" sx={{
            marginTop: "25px",
            backgroundColor: "#fff",
            fontWeight: "bold",
            fontSize: "12px",
            color: "black",
            textTransform: "none",
            '&:hover': {
              backgroundColor: "#1976d2",
              color: 'white'
            }
          }} onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            sx={{
              marginTop: "25px",
              marginLeft: "10px",
              backgroundColor: "#1976d2",
              fontWeight: "bold",
              fontSize: "12px",
              color: "white",
              textTransform: "none",

            }}
            onClick={handleContinue}
          >
            Continue
          </Button>
        </div>
        <div style={rightSectionStyle}>
          {/* <img src={img3} alt="img3" style={imgStyle} /> */}
        </div>
      </div>
    </div>
  );
};

export default Problem;
