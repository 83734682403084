import React, {useState,useEffect} from 'react';
import SalesInbox from '../salesComponents/Inbox';
import SalesArchive from '../salesComponents/Archive';
import SalesProcessing from '../salesComponents/Processing';
import SalesReview from '../salesComponents/Review';
import SalesReady from '../salesComponents/Ready';
import { Route, Routes } from 'react-router-dom';
import SaleEdit from '../salesComponents/Edit';
import axios from 'axios';
import {baseURL} from '../Config';
const token = localStorage.getItem('token');

const Sales = ({ fileURL }) => {
    const [sales, setSales] = useState([]);
  
    const fetchSales = async () => {
        try {
            const response = await axios.get(`${baseURL}/sales`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setSales(response.data);
            // console.log('Sales fetched successfully:', response.data);
        } catch (error) {
            console.error('Error fetching sales:', error);
        }
    };

    const handleAddSale = async () => {
        try {
            const data = {
                fileURL: fileURL,
            };

            const response = await axios.post(`${baseURL}/sales`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Sales added successfully:', response.data);
            fetchSales();
        } catch (error) {
            console.error('Error adding sales:', error);
        }
    };

    const handleEditSales = async (saleId, updatedData) => {
        try {
            const response = await axios.patch(`${baseURL}/sales/${saleId}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Sales updated successfully:', response.data);
            fetchSales();
        } catch (error) {
            console.error('Error updating cost:', error);
        }
    };

    useEffect(() => {
        fetchSales();
    }, []);

    useEffect(() => {
        if (fileURL) {
            handleAddSale();
        }
    }, [fileURL]);



    return (
        <div className="p-4">
            <Routes>
                <Route path="archive" element={<SalesArchive />} />
                <Route path="inbox" element={<SalesInbox sales={sales} />} />
                <Route path="processing" element={<SalesProcessing />} />
                <Route path="review" element={<SalesReview />} />
                <Route path="ready" element={<SalesReady />} />
                {/* <Route path="edit" element={<SalesEdit />} /> */}
                <Route path="edit/:id" element={<SaleEdit />} />
            </Routes>
        </div>
    );
};

export default Sales;