import React, { useState } from "react";

const SignupStepTwo = ({ prevStep, onComplete }) => {
  const [formData, setFormData] = useState({ country: "United Kingdom", practiceName: "" });
  const [errors, setErrors] = useState({});
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.practiceName) newErrors.practiceName = "Practice name is required.";
    if (!acceptedTerms) newErrors.terms = "You must accept the terms and conditions.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) onComplete(formData);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-4xl">
        <h2 className="text-2xl font-semibold mb-6 text-center">Start your free trial</h2>
        <p className="text-sm text-gray-500 mb-8 text-center">No payment details required.</p>
        <form>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Country</label>
            <select
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              value={formData.country}
              onChange={(e) => setFormData({ ...formData, country: e.target.value })}
            >
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Practice Name</label>
            <input
              type="text"
              className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 ${
                errors.practiceName ? "border-red-500" : "border-gray-300"
              }`}
              value={formData.practiceName}
              onChange={(e) =>
                setFormData({ ...formData, practiceName: e.target.value })
              }
            />
            {errors.practiceName && (
              <p className="text-sm text-red-500 mt-1">{errors.practiceName}</p>
            )}
          </div>
          <div className="mb-4 flex items-start">
            <input
              type="checkbox"
              className="h-4 w-4 text-blue-500 border-gray-300 rounded"
              checked={acceptedTerms}
              onChange={(e) => setAcceptedTerms(e.target.checked)}
            />
            <label className="ml-2 text-sm text-gray-700">
              I accept the{" "}
              <a href="#" className="text-blue-500 hover:underline">
                Terms and Conditions
              </a>{" "}
              &{" "}
              <a href="#" className="text-blue-500 hover:underline">
                Privacy Policy
              </a>
            </label>
          </div>
          {errors.terms && <p className="text-sm text-red-500">{errors.terms}</p>}
          <div className="flex justify-between">
            <button
              type="button"
              className="bg-gray-200 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-300"
              onClick={prevStep}
            >
              Back
            </button>
            <button
              type="button"
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
              onClick={handleSubmit}
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupStepTwo;
