import { baseURL } from '../Config';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Modal,
  Select,
  MenuItem,
  Grid,
  FormControl,
  Tab,
  Tabs,
  Checkbox,
} from '@mui/material';
import jsPDF from 'jspdf';
import { TransparentBtn } from '../components/common/TransparentBtn';
import TableComponent from './TableClaim';
import { ArrowBackIos, DeleteOutline } from '@mui/icons-material';
import TableExtendedToolTip from '../costComponents/utils/TableExtendedToolTip';



const CostSummary = ({ costs }) => {
  // Calculate total amounts
  const totalAmount = costs.reduce((sum, item) => sum + item.totalAmount, 0);
  const totalTax = costs.reduce((sum, item) => sum + item.taxAmount, 0);
  const grandTotal = totalAmount + totalTax;

  return (
    <div className="max-w-lg mx-auto bg-white shadow-lg rounded-lg p-6">
      <table className="w-full border-collapse border border-gray-200 text-center">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2">Total Amount</th>
            <th className="border border-gray-300 px-4 py-2">Tax Amount</th>
            <th className="border border-gray-300 px-4 py-2 text-green-600">Grand Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">₹ {totalAmount.toFixed(2)}</td>
            <td className="border border-gray-300 px-4 py-2">₹ {totalTax.toFixed(2)}</td>
            <td className="border border-gray-300 px-4 py-2 text-green-600 font-bold">₹ {grandTotal.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};


const Inbox = () => {
  const [selected, setSelected] = useState([]);
  const [claims, setClaims] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    preparedFor: '',
    endDate: '',
    name: '',
  });
  const [selectedTab, setSelectedTab] = useState(1);
  const [costs, setCosts] = useState([])

  useEffect(() => {
    fetchClaims();
    fetchUserProfile()
  }, []);

  const fetchClaims = async () => {
    try {
      const userToken = localStorage.getItem('userToken');
      if (!userToken) {
        console.error('No token found, please log in again.');
        return;
      }

      const response = await axios.get(`${baseURL}/reports/inbox`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });

      console.log('API Response:', response.data);
      let data = response.data;


      data = data.map(report => {
        let total = 0;
        let tax = 0;
        report.reportsData.forEach(data => {
          if (data.id) { // Ensure id is populated
            total += data.id.totalAmount || 0;
            tax += data.id.taxAmount || 0;
          }

        });
        return { ...report, total, tax };
      });
      console.log(data)
      setClaims(data);
    } catch (error) {
      console.error('Error fetching claims:', error);
    }
  };

    const [showAddClinet,setShowAddClient] = useState('')

    const fetchUserProfile = async () => {
      try {
          const userId = JSON.parse(localStorage.getItem('userInfo')).userId;
          const token = localStorage.getItem('userToken');
  
          const response = await axios.get(`${baseURL}/user/profile/${userId}`, {
              headers: {
                  'Authorization': `Bearer ${token}`,
              },
          });
  
          console.log('User Profile:', response.data);

          // return response.data;
          setShowAddClient(response.data.name)
          
      } catch (error) {
          console.error('Error fetching user profile:', error);
          return null;
      }
  };
  


  const fetchArchivedCosts = async () => {
    try {


      const userToken = localStorage.getItem('userToken');
      const selectedCost = selectedClaim.reportsData
      const costDetailsPromises = selectedCost.map((cost) =>
        axios.get(`${baseURL}/cost/${cost.id._id}`, {
          headers: { Authorization: `Bearer ${userToken}` },
        })
      );

      const detailedCostsResponses = await Promise.all(costDetailsPromises);
      const detailedCosts = detailedCostsResponses.map(
        (response) => response.data
      );

      console.log(detailedCosts)

      setCosts(Array.isArray(detailedCosts) ? detailedCosts : []);
    } catch (error) {
      console.error('Error fetching archived costs:', error);
    }


  };

  useEffect(() => {
    if (selectedClaim) {
      fetchArchivedCosts();
      // console.log(selectedClaim)

    }
  }, [selectedClaim])

  const handleRowClick = (event, id) => {
    if (event.target.tagName.toLowerCase() !== 'input') {
      const claim = claims.find((c) => c._id === id);
      setSelectedClaim(claim);
    }
  };

  const handleBackClick = () => {
    setSelectedClaim(null);
  };

  const handleCreateClick = () => {
    setOpenModal(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = claims.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleCreateExpenseClaim = async () => {
    try {
      const userToken = localStorage.getItem('userToken');

      const newClaim = {
        name: formData.name,
        endDate: formData.endDate,
        currency: 'GBP',
        paid: false,
        approved: false,
        paymentMethod: 'Credit Card',
        note: '',
        status: 'inbox',
      };

      const response = await axios.post(`${baseURL}/reports`, newClaim, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data)
      setClaims([...claims, response.data]);
      setOpenModal(false);
      setFormData({ preparedFor: '', endDate: '', name: '' });
    } catch (error) {
      console.error('Error creating the claim:', error);
    }
  };

  const handleArchive = async () => {
 
    if (selectedClaim) {
      try {
        const userToken = localStorage.getItem('userToken');

        // Make the PUT request to archive the selected report
        const obj = {
          status:'archive'
        }
        const response = await axios.patch(
          `${baseURL}/reports/${selectedClaim._id}`,
          obj,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        // Filter out the archived claim from the claims list
        setClaims(claims.filter((claim) => claim._id !== selectedClaim._id));
        setSelectedClaim(null); // Reset the selected claim

        console.log('Archived claim:', response.data);
      } catch (error) {
        console.error('Error archiving the report:', error);
      }
    }
  };


  
    const handleArchiveConfirm = async () => {
      console.log('archive', selected);
      try {
        const userToken = localStorage.getItem('userToken');
        await axios.patch(
          `${baseURL}/claims/status/${selected}`,
          { status: 'archive', id: selected },
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        );
        console.log('Selected report archived successfully');
        // await fetchCosts();
        // await getStatsFunction()
        // setIsStatsChangedToggle((prev) => !prev);
         
        
        setClaims(claims.filter((claim) => !selected.includes(claim._id)));
        setSelectedClaim(null); // Reset the selected claim

        // setIsArchiveDialogOpen(false);
      } catch (error) {
        console.error('Error archiving items:', error);
      }
    };
  const onDeleteClick = async () => {
    if (selectedClaim) {
      try {
        const userToken = localStorage.getItem('userToken');

     
        const response = await axios.delete(
          `${baseURL}/reports/${selectedClaim._id}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        // Filter out the archived claim from the claims list
        setClaims(claims.filter((claim) => claim._id !== selectedClaim._id));
        setSelectedClaim(null); // Reset the selected claim

        console.log('Archived claim:', response.data);
      } catch (error) {
        console.error('Error archiving the report:', error);
      }
    }
  };

  const handleEdit = async (e, fieldName) => {
    try {
      const value = e.target.value;
      const userToken = localStorage.getItem('userToken');
  
      setSelectedClaim((prevValue) => ({
        ...prevValue,
        [fieldName]: value,
      }));
  
      const response = await axios.patch(
        `${baseURL}/reports/${selectedClaim?._id}`,
        { [fieldName]: value },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
  
      // console.log('reports data',response.data);
    //   setClaims(claims.map((claim) =>{

    //     if(claim._id === selectedClaim._id)
    //       return response.data
    //   } 
    // ));
      setSelectedClaim(response.data)
    } catch (error) {
      console.error('Error updating claim:', error);
    }
  };
  

  const generatePDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(12);
    doc.text("TEST FOUNDER'S EXPENSE CLAIM", 105, 10, { align: 'center' });

    doc.setFontSize(10);
    doc.text(`Claim name: ${selectedClaim?.name || ''}`, 10, 20); // Check if selectedClaim exists
    doc.text(`Claim ID: #${selectedClaim?._id || ''}`, 10, 25); // Check if selectedClaim exists
    doc.text(
      `Claim date: ${new Date(
        selectedClaim?.endDate || ''
      ).toLocaleDateString()}`,
      10,
      30
    ); // Check if selectedClaim exists

    doc.text('Total: 0.00 GBP (incl. tax: 0.0)', 160, 20);

    doc.setFontSize(12);
    doc.text('SUMMARY BY CATEGORY', 10, 40);

    doc.setFontSize(10);
    doc.text('Category', 10, 50);
    doc.text('Net (GBP)', 80, 50);
    doc.text('Tax (GBP)', 120, 50);
    doc.text('Total (GBP)', 160, 50);

    doc.text('Total', 10, 60);
    doc.text('0.00', 80, 60);
    doc.text('0.00', 120, 60);
    doc.text('0.00', 160, 60);

    doc.setFontSize(12);
    doc.text('TRANSACTIONS', 10, 80);

    doc.setFontSize(10);
    doc.text('Date', 10, 90);
    doc.text('Item ID', 40, 90);
    doc.text('Supplier', 70, 90);
    doc.text('Description', 100, 90);
    doc.text('Category', 140, 90);
    doc.text('Net (GBP)', 160, 90);
    doc.text('Tax (GBP)', 180, 90);
    doc.text('Total (GBP)', 200, 90);

    doc.text('Total', 10, 100);
    doc.text('0.00', 160, 100);
    doc.text('0.00', 180, 100);
    doc.text('0.00', 200, 100);

    doc.setFontSize(12);
    doc.text('APPROVALS', 10, 120);

    doc.setFontSize(10);
    doc.text('Employee signature', 10, 130);
    doc.line(50, 130, 100, 130);
    doc.text('Date', 110, 130);
    doc.line(120, 130, 160, 130);

    doc.text("Approver's signature", 10, 140);
    doc.line(50, 140, 100, 140);
    doc.text('Date', 110, 140);
    doc.line(120, 140, 160, 140);

    doc.text('Powered by Dext', 10, 160);
    doc.setFontSize(8);
    doc.text('Page 1 / 1', 105, 170, { align: 'center' });

    doc.save('expense_claim.pdf');
  };
  const [selectedCost, setSelectedCost] = useState([]);

  return (
    <section sx={{ padding: '5px' }}>
      {selectedClaim ? (
        <section className='flex flex-col gap-5'>
          <div className='flex gap-2'>
            <TransparentBtn
              onClick={handleBackClick}
              label='Back'
              startIcon={<ArrowBackIos color='disabled' fontSize='small' />}
            />
            <TransparentBtn onClick={handleArchive} label='Archive' border />
            {/* <TransparentBtn onClick={generatePDF} label='PDF Preview' border /> */}
            <TransparentBtn
              border
              onClick={onDeleteClick}
              label={'Delete'}
              // startIcon={<DeleteOutline color='error' fontSize='small' />}
            />
          </div>
          <section>
            <section className='flex gap-2'>

              {
                costs.length != 0 ?

                  <div className='mt-5'>
                    <div className='flex justify-between mb-5'>
                      <div></div>
                      <TableExtendedToolTip rowsData={costs} />
                    </div>
                    
                    <TableComponent
                      data={costs}
                      selected={selectedCost}
                      setSelected={setSelectedCost}
                      fileURL={''}
                    />
                    <div>
                    <CostSummary costs={costs} />
                    </div>
                  </div>

                  :
                  <section className='!bg-white w-3/4 p-2'>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '5px',
                        marginBottom: '20px',
                        backgroundColor: 'white',
                      }}
                    >
                      <Typography variant='h5' fontWeight='bold'>
                        {selectedClaim?.user?.name || 'Unknown'}'s Expense Claim
                      </Typography>
                      <Box></Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <Button variant='contained'>Refresh</Button>
                      <Button variant='contained'>PDF Preview</Button>
                    </Box>
                    <Box
                      sx={{
                        textAlign: 'center',
                        padding: '20px',
                        border: '1px dashed gray',
                      }}
                    >
                      <Typography>No results found.</Typography>
                      <Typography variant='caption'>
                        Try a different keyword or filter.
                      </Typography>
                    </Box>
                  </section>
              }

              <section className='bg-white h-full w-2/4'>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    onChange={(e, value) => setSelectedTab(value)}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      backgroundColor: '#ebf0ec',
                    }}
                    aria-label='basic tabs example'
                  >
                    <Tab
                      sx={{
                        fontSize: '13px',
                        fontWeight: 'bold',
                        backgroundColor: selectedTab === 1 ? 'white' : '',
                      }}
                      value={1}
                      label='Claim Details'
                    />
                    <Tab
                      sx={{
                        fontSize: '13px',
                        fontWeight: 'bold',
                        backgroundColor: selectedTab === 2 ? 'white' : '',
                      }}
                      value={2}
                      label='Audit Trail'
                    />
                  </Tabs>
                  {selectedTab === 1 && (
                  <div className='p-4 overflow-y-scroll max-h-[88%]'>
                  <div class='flex items-center justify-between mb-2'>
                    <div className='text-sm font-semibold mb-2'>Claim ID</div>
                    <TextField
                      fullWidth
                      value={selectedClaim?.itemId || ''}
                      InputProps={{ readOnly: true }}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '35px',
                        },
                        width: '220px',
                      }}
                      size='small'
                    />
                  </div>
                
                  <div class='flex items-center justify-between mb-2'>
                    <div className='text-sm font-semibold mb-2'>Owned By</div>
                    <TextField
                      fullWidth
                      value={selectedClaim?.user?.name || 'Unknown'}
                      InputProps={{ readOnly: true }}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '35px',
                        },
                        width: '220px',
                      }}
                      size='small'
                    />
                  </div>
                
                  <div class='flex items-center justify-between mb-2'>
                    <div className='text-sm font-semibold mb-2'>Claim Name</div>
                    <TextField
                      fullWidth
                      value={selectedClaim?.name || ''}
                      InputProps={{ readOnly: true }}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '35px',
                        },
                        width: '220px',
                      }}
                      size='small'
                    />
                  </div>
                
                  <div class='flex items-center justify-between mb-2'>
                    <div className='text-sm font-semibold mb-2'>End Date</div>
                    <TextField
                      fullWidth
                      value={new Date(selectedClaim?.endDate || '').toLocaleDateString()}
                      InputProps={{ readOnly: true }}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '35px',
                        },
                        width: '220px',
                      }}
                      size='small'
                    />
                  </div>
                
                  <div class='flex items-center justify-between mb-2'>
                    <div className='text-sm font-semibold mb-2'>Currency</div>
                    <Select
                      fullWidth
                      value='GBP - United Kingdom, Pounds'
                      disabled
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '35px',
                        },
                        width: '220px',
                      }}
                      size='small'
                    >
                      <MenuItem value='GBP - United Kingdom, Pounds'>
                        GBP - United Kingdom, Pounds
                      </MenuItem>
                    </Select>
                  </div>
                
                  <div class='flex items-center justify-between mb-2'>
                    <div className='text-sm font-semibold mb-2'>Claim Description</div>
                    <TextField
                      fullWidth
                      placeholder='Enter description'
                      value={selectedClaim?.description || ''}
                      onChange={(e) => handleEdit(e, 'description')}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '35px',
                        },
                        width: '220px',
                      }}
                      size='small'
                    />
                  </div>
                
                  <div class='flex items-center justify-between mb-2'>
                    <div className='text-sm font-semibold mb-2'>Paid</div>
                    <Select value='No' disabled sx={{ width: '220px' }}>
                      <MenuItem value='Yes'>Yes</MenuItem>
                      <MenuItem value='No'>No</MenuItem>
                    </Select>
                  </div>
                
                  <div class='flex items-center justify-between mb-2'>
                    <div className='text-sm font-semibold mb-2'>Payment Method</div>
                    <TextField
                      fullWidth
                      label='Payment Method'
                      placeholder='Enter payment method'
                      value={selectedClaim?.paymentMethod || ''}
                      onChange={(e) => handleEdit(e, 'paymentMethod')}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '35px',
                        },
                        width: '220px',
                      }}
                      size='small'
                    />
                  </div>
                
                  <div class='flex items-center justify-between mb-2'>
                    <div className='text-sm font-semibold mb-2'>Internal Note</div>
                    <TextField
                      fullWidth
                      placeholder='Enter internal note'
                      value={selectedClaim?.internalNote || ''}
                      onChange={(e) => handleEdit(e, 'internalNote')}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '35px',
                        },
                        width: '220px',
                      }}
                      size='small'
                    />
                  </div>
                </div>
                
                  ) 
                }
                  {selectedTab === 2 && 
                  (
                  <div className='p-4 h-5/6 overflow-auto'>
                                {selectedClaim?.history?.slice().reverse().map((data) => {
                                  return (
                                    <div className='flex mb-4' key={data}>
                                      <div className='w-[20px] h-[20px] bg-blue-200 relative rounded-full mr-3'>
                                        <div className='w-[10px] h-[10px] bg-blue-500 absolute top-[25%] left-[25%] rounded-full'></div>
                                      </div>
                                      <div>
                                        <div className='text-sm font-normal font-semibold'>
                                          {data.message} by {JSON.parse(localStorage.getItem('userInfo')).name}
                                        </div>
                                        <div className='text-slate-500 text-sm font-normal'>
                                          {data.created_at}
                                        </div>
                                        <div className='text-slate-500 text-sm font-normal'>
                                          
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div> 
                      )

                            
                
                
                }
                </Box>
              </section>
            </section>
          </section>
        </section>
      ) : (
        <Box>
          <Typography variant='h5' fontWeight='bold' mb={2}>
            Expense claims
          </Typography>
          <Box mb={2} className='flex items-center gap-2'>
            <TransparentBtn
              label='Archive'
              onClick={handleArchiveConfirm}
              border
              disabled={selected.length === 0}
            />
            <TransparentBtn label='Create' onClick={handleCreateClick} border />
            <TransparentBtn
              label='Action'
              border
              disabled={selected.length === 0}
            />
            {/* <Button
              variant='contained'
              sx={{ marginRight: '10px' }}
              disabled={selected.length === 0}
              onClick={handleArchive}
            >
              Archive
            </Button> */}
            {/* <Button
              variant='contained'
              sx={{ marginRight: '10px' }}
              onClick={handleCreateClick}
            >
              Create
            </Button>
            <Button
              variant='contained'
              sx={{ marginRight: '10px' }}
              disabled={selected.length === 0}
            >
              Action
            </Button> */}
          </Box>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby='tableTitle'
              size={'medium'}
            >
              <TableHead className='bg-[#ebf0ec]'>
                <TableRow className='items-center'>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      type='checkbox'
                      onChange={handleSelectAllClick}
                      checked={selected.length === claims.length}
                    />
                  </TableCell>
                  <TableCell padding={'normal'}>Prepared for</TableCell>
                  <TableCell padding={'normal'}>Type</TableCell>
                  <TableCell padding={'normal'}>Name</TableCell>
                  <TableCell padding={'normal'}>End Date</TableCell>
                  <TableCell padding={'normal'}>Total</TableCell>
                  <TableCell padding={'normal'}>Tax</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {claims.map((row) => {
                  // Safely access user name using optional chaining
                  const userName = row.user?.name || 'No user assigned';

                  return (
                    <TableRow
                      key={row._id}
                      hover
                      role='checkbox'
                      aria-checked={isSelected(row._id)}
                      selected={isSelected(row._id)}
                      onClick={(event) => handleRowClick(event, row._id)}
                      sx={[
                        {
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        },
                      ]}
                    >
                      <TableCell padding='checkbox'>
                        <div className='w-fit p-1 flex gap-1 items-center '>
                          <Checkbox
                            type='checkbox'
                            checked={isSelected(row._id)}
                            onChange={(event) => handleClick(event, row._id)}
                          />
                        </div>
                      </TableCell>
                      {/* Display the user name or fallback if user is null */}
                      <TableCell>{userName}</TableCell>
                      <TableCell>{row.type || 'N/A'}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {new Date(row.endDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{row.total || '--'}</TableCell>
                      <TableCell>{row.tax || '--'}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* Modal for creating new claim */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          className='flex flex-col gap-3'
          sx={{
            width: 400,
            padding: 4,
            margin: 'auto',
            marginTop: '10%',
            backgroundColor: '#fff',
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <div className='text-lg font-bold'>Create expense claim</div>
          <FormControl fullWidth>
            <div className='text-base'> Claim For</div>
            <Select
              fullWidth
              name='preparedFor'
              value={formData.preparedFor}
              onChange={handleInputChange}
              select
            >
              {/* <MenuItem value='Ramratan Jakhar'>Ramratan Jakhar</MenuItem> */}
              {/* <MenuItem value='test founder'>test founder</MenuItem> */}
              <MenuItem value={showAddClinet}>{showAddClinet}</MenuItem>

            </Select>
          </FormControl>
          <FormControl fullWidth>
            <div className='text-base'> End Date</div>
            <TextField
              fullWidth
              name='endDate'
              value={formData.endDate}
              onChange={handleInputChange}
              type='date'
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl>
            <div className='text-base'>Name</div>
            <TextField
              fullWidth
              name='name'
              value={formData.name}
              onChange={handleInputChange}
              placeholder='Add claim name'
            />
          </FormControl>
          <Box display='flex' justifyContent='space-between' mt={3}>
            <Button variant='outlined' onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={handleCreateExpenseClaim}>Create</Button>
          </Box>
        </Box>
      </Modal>
    </section>
  );
};

export default Inbox;
