import React from 'react';
import { Container, Grid, Breadcrumbs, Typography, Divider, Tooltip, IconButton, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import HeaderBusiness from './HeaderBusiness';
import Footer from '../components/Footer';

const UploadInvoice = () => {
  return (
    <>
      <HeaderBusiness />
      <Container style={{ width: '100%', marginTop: '100px' }}>
        {/* Breadcrumb */}
        <Box mb={4} display="flex">
          <Breadcrumbs
            separator=">"
            aria-label="breadcrumb"
            sx={{
              maxWidth: '100%',
              marginTop: "10px",
              marginLeft: "5px",
              '& ol': {
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'nowrap',
                width: '100%',
              },
            }}
          >
            {['Home', 'Dext Prepare', 'Getting Started', 'Business Owners', 'Introduction To Dext Prepare', 'How to add Documents'].map((label, index) => (
              <Typography
                key={index}
                sx={{
                  color: '#4991e9',  
                  textDecoration: 'underline',
                  cursor: 'default',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '&:hover': {
                    textDecoration: 'none',
                    color: '#4991e9 !important',
                    backgroundColor: "transparent"
                  }
                }}
              >
                {label}
              </Typography>
            ))}
          </Breadcrumbs>
        </Box>

        <Grid container spacing={5}>
          {/* Left Section */}
          <Grid item xs={3}>
            <Box display="flex" alignItems="center" mb={2}>
              <img className='w-[80px]' src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png" alt="Logo" />
              <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
              <Typography variant="h6" fontWeight="bold" sx={{ fontSize: '2rem' }}>Prepare</Typography>
            </Box>
            <Typography variant="body1" fontWeight="bold" mb={2} mt={3} sx={{ fontSize: '1.30rem' }}>Business Owners</Typography>

            {[
              {
                title: 'Introduction To Dext Prepare',
                lines: ['What Dext Prepare Products are Available?', 'How to Sign Up to Dext Prepare as a Small Business or Sole Trader', 'How Does Dext Prepare Work?', 'Get Started with Dext Prepare', 'Your New Dext Prepare Account', 'Welcome to the Dashboard for Business Accounts', 'Why Link Your Business to Accountin Software?', 'How To Add A User To Your Team', 'How to Add Documents', 'How to Use the Mobile App to Upload Paperwork', 'How to Edit, Publish and Export Items'],
              },
              {
                title: 'Your Account',
                lines: ['Using Intuit Single Sign On with Dext', 'Managing Multiple Business Accounts with ONe Email Address', 'How to Check Your Extraction Limit', 'How to Change Your Plan', 'How to Cancel Your Dext Prepare Account', 'How to invite Your Accountant or Bookkeeper to Access Your Dext Prepare Account ', "How to Remove or Reallocate a Bookkeeper's or Accountant's Access to Your Account"],
              }
            ].map((section, index) => (
              <Accordion key={index} elevation={0} sx={{ border: 'none', marginTop: '7px' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight="bold">{section.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    {section.lines.map((line, i) => (
                      <li key={i}>
                        <Typography
                          sx={{
                            padding: '12px 0',
                            '&:hover': {
                              color: '#4991e9',
                              cursor: 'pointer'
                            }
                          }}
                        >
                          {line}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
            ))}

          </Grid>

          {/* Right Section */}
          <Grid item xs={9}>
            <Typography variant="h4" mb={2} mt={4} mr={60} style={{ fontSize: '2rem', textAlign: 'justify', fontWeight: "bold" }}>
              How to Add Documents
            </Typography>
            <Typography variant="body1" mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify', fontWeight: "bold" }}>
              Use the submission method that best suits your needs to Add Costs & Sales items or Bank transaction data to your Dext Prepare account.
            </Typography>
            <Typography variant="body1" mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              See the sections below for an overview of the submission methods available for each type of document:
            </Typography>
            <Typography variant="body1" mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              Learn how to set up your Category Lists:
              <ul>
                <li style={{ margin: "10px", color: "#4991e9" ,textDecoration: 'underline',listStyleType:"disc"}}><a href="#"> Costs Documents</a></li>
                <li style={{ margin: "10px", color: "#4991e9" ,textDecoration: 'underline',listStyleType:"disc"}}><a href="#"> Sales Documents</a></li>
                <li style={{ margin: "10px", color: "#4991e9" ,textDecoration: 'underline',listStyleType:"disc"}}><a href="#"> Bank Transactions</a></li>
                <li style={{ margin: "10px", color: "#4991e9" ,textDecoration: 'underline',listStyleType:"disc"}}><a href="#"> Where Do Items Go After They’re Added?</a></li>
                <li style={{ margin: "10px", color: "#4991e9" ,textDecoration: 'underline',listStyleType:"disc"}}><a href="#"> Video</a></li>
              </ul>
            </Typography>

            <Typography variant="h4" mb={3} mt={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              After adding your documents, Dext Prepare will extract the data you need. See more about how long extraction takes here. Once extraction is complete an item can be found in the inbox of the Costs or Sales workspaces or the Processed Statements section of the Bank workspace.
            </Typography>
            <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              Items can then be edited to ensure they include all the relevant details, before being published to your accounting software.
            </Typography>
            <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              <Box border={1} padding={2} borderRadius={1} marginBottom={1} style={{ display: 'flex', alignItems: 'center', color: '#546478', fontSize: '1rem' }}>
                <Tooltip title="Help">
                  <IconButton style={{ marginBottom: '10px' }}>
                    <ErrorIcon />
                  </IconButton>
                </Tooltip>
                <Typography variant="body2">
                  Note: To save time, set up supplier, customer & auto-publishing rules to automate the process of preparing items and publishing them to your accounting software.
                </Typography>
              </Box>
            </Typography>
            <hr style={{ marginTop: "16px" }} />
            <Typography variant="h4" mb={3} mt={3} style={{ fontSize: '1.5rem', textAlign: 'justify', fontWeight: "bold", color: "#4991e9", textDecoration: 'underline' }}>
              <a href="#">Costs Documents</a>
            </Typography>
            <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              You can use the following submission methods to add Cost items to Dext Prepare:
              <ul style={{ marginTop: "30px" }}>
                <li style={{ margin: "12px" }}><a href="#" style={{ color: "#4991e9", textDecoration: 'underline',listStyleType:"disc" }}>Upload</a> from your computer</li>
                <li style={{ margin: "12px", }}><a href="#" style={{ color: "#4991e9", textDecoration: 'underline',listStyleType:"disc" }}>Email</a> via your dedicated Dext Prepare Extract by Email address</li>
                <li style={{ margin: "12px", }}>Submit via <a href="#" style={{ color: "#4991e9", textDecoration: 'underline' ,listStyleType:"disc"}}>Mobile</a> app</li>
                <li style={{ margin: "12px", }}>Setting up a <a href="#" style={{ color: "#4991e9", textDecoration: 'underline' ,listStyleType:"disc"}}>Fetch</a> Fetch connection with a supplier</li>
              </ul>
            </Typography>
            <Typography variant="h4" mb={3} mt={3} style={{ fontSize: '1.5rem', textAlign: 'justify', fontWeight: "bold", color: "#4991e9", textDecoration: 'underline' }}>
              <a href="#">Sales Documents</a>
            </Typography>
            <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              You can use the following submission methods to add Sales items to Dext Prepare:
              <ul style={{ marginTop: "30px" }}>
                <li style={{ margin: "12px" }}><a href="#" style={{ color: "#4991e9", textDecoration: 'underline', listStyleType:"disc" }}>Upload</a> from your computer</li>
                <li style={{ margin: "12px", }}><a href="#" style={{ color: "#4991e9", textDecoration: 'underline', listStyleType:"disc" }}>Email</a> via your dedicated Dext Prepare Extract by Email address</li>
                <li style={{ margin: "12px", }}>Submit via <a href="#" style={{ color: "#4991e9", textDecoration: 'underline', listStyleType:"disc" }}>Mobile</a> app</li>
              </ul>
            </Typography>
            <Typography variant="h4" mb={3} mt={3} style={{ fontSize: '1.5rem', textAlign: 'justify', fontWeight: "bold", color: "#4991e9", textDecoration: 'underline' }}>
              <a href="#">Bank Statements</a>
            </Typography>
            <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              There are two ways you can add bank transaction data to Dext Prepare:
              <ul style={{ marginTop: "30px" }}>
                <li style={{ margin: "12px" }}><a href="#" style={{ color: "#4991e9", textDecoration: 'underline', listStyleType:"disc" }}>Upload a bank statement</a> from your computer</li>
                <li style={{ margin: "12px", }}>Connect to your bank via a<a href="#" style={{ color: "#4991e9", textDecoration: 'underline', listStyleType:"disc" }}> Connect to your bank via a Bank Feed</a></li>
              </ul>
            </Typography>
            <Typography variant="h4" mb={3} mt={3} style={{ fontSize: '1.5rem', textAlign: 'justify', fontWeight: "bold", color: "#4991e9", textDecoration: 'underline' }}>
              <a href="#">Where Do Items Go After They’re Added?</a>
            </Typography>
            <Typography mb={3} mt={2} style={{ fontSize: '1rem', textAlign: 'justify', fontWeight: "bold", textDecoration: 'underline' }}>
              During extraction
            </Typography>
            <Typography mb={3} mt={2} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              Costs & Sales items can be found in the In <strong>Processing</strong> section of the Costs or Sales workspaces.
            </Typography>
            <Typography mb={3} mt={2} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              Bank statements can be found in the <strong>Collected Statements</strong> section of the Bank workspace.
            </Typography>
            <img src="/Screenshot7.png" alt="Screenshot" style={{ border: '7px solid grey', marginBottom: '16px', height: '300px', width: 'auto', marginLeft: "90px" }} />
            <Typography mb={3} mt={4} style={{ fontSize: '1rem', textAlign: 'justify', fontWeight: "bold", textDecoration: 'underline' }}>
              Once extraction is completed
            </Typography>
            <Typography mb={3} mt={2} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              Costs & Sales items can be found in the In <strong>Inbox</strong> section of the Costs or Sales workspaces.
            </Typography>
            <Typography mb={3} mt={2} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              Bank statements can be found in the <strong> Processed Statements</strong> section of the Bank workspace. While Bank transactions from a bank feed can be found in the <strong>Transactions </strong>section of the Bank workspace.
            </Typography>
            <Typography mb={3} style={{ fontSize: '1rem', textAlign: 'justify' }}>
              <Box border={1} padding={2} borderRadius={1} marginBottom={1} style={{ display: 'flex', alignItems: 'center', color: '#546478', fontSize: '1rem' }}>
                <Tooltip title="Help">
                  <IconButton style={{ marginBottom: '10px' }}>
                    <ErrorIcon />
                  </IconButton>
                </Tooltip>
                <Typography variant="body2">
                  Note: If you can’t find a document after it has been uploaded, it may mean that the item is a duplicate of an item already added to your Dext Prepare account and been automatically deleted. This will happen when the automatic duplicate setting is switched ON. If this is the case the item can be found by going to My Profile in the sidebar Submission History. See more about duplicate documents here. And see how to use the Submission History page here.
                </Typography>
              </Box>
            </Typography>
            <img src="/Screenshot6.png" alt="Screenshot" style={{ border: '7px solid grey', marginBottom: '16px', height: '300px', width: 'auto', marginLeft: "90px" }} />
            <hr style={{ marginTop: "16px" }} />
          </Grid>
        </Grid>


        <Typography variant="h6" mb={2} mt={3} mr={60} style={{ fontSize: '1.7rem', textAlign: 'center', fontWeight: "bold" }}>
          Video
        </Typography>
        <Box display="flex" justifyContent="center" mb={4} ml={38}>
          <video width="920" height="660" controls>
            <source src="video.mp4" type="video/mp4" />
          </video>
        </Box>
      </Container>

      <Footer />
    </>
  );
}

export default UploadInvoice;
