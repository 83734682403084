import { Search } from '@mui/icons-material';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';

export function CusAutocomplete({
  options,
  optionKey,
  classes,
  textClass,
  value,
  handleChange,
}) {
  console.log(options);
  return (
    <Autocomplete
      fullWidth
      className={classes}
      value={value}
      onClick={(event) => event.stopPropagation()}
      onChange={(event, value) => {
        handleChange(event, value);
      }}
      options={options}
      getOptionLabel={(option) => (optionKey ? option[optionKey] : option)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          fullWidth
          className={textClass}
          startAdornment={
            <InputAdornment>
              <Search />
            </InputAdornment>
          }
        />
      )}
      disableClearable
    />
  );
}
