// import React, { useState, useEffect } from 'react';
// import { Button, Dialog, DialogActions, Tooltip, IconButton, DialogContent, DialogTitle, TextField, Switch, Checkbox, FormControlLabel, Select, MenuItem } from '@mui/material';
// import HelpIcon from '@mui/icons-material/Help';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import { styled } from '@mui/system';
// import {baseURL} from '../Config';
// const token = localStorage.getItem('token');

// const Container = styled('div')({
//   padding: '16px',
//   backgroundColor: '#f9f9f9',
//   borderRadius: '8px',
//   height:'auto'
// });

// const ButtonGroup = styled('div')({
//   marginBottom: '16px',
//   display: 'flex',
//   gap: '8px',
// });

// const CustomButton = styled(Button)(({ active }) => ({
//   fontSize: '12px',
//   padding: '6px 15px',
//   marginRight: '10px',
//   background: '#1976d2',
//   color: '#ffff',
//   fontWeight: 'bold',
//   '&:hover': {
//     background: '#1976d2',
//   },

// }));

// const AddProjectButton = styled(CustomButton)(({ active }) => ({
//   backgroundColor: active ? '#1976d2' : '#1976d2',
// }));

// const ImportCsvButton = styled(CustomButton)(({ active }) => ({
//   backgroundColor: active ? '#1976d2' : '#1976d2',
// }));

// const DeleteButton = styled(CustomButton)(({ active }) => ({
//   backgroundColor: '#ff5a02',

//   '&:hover': {
//     background: '#ff5a02',
//   },

// }));

// const StyledTable = styled('table')({
//   width: '100%',
//   borderCollapse: 'collapse',
//   '& td, & th': {
//     borderBottom: '1px solid #e0e0e0',
//     padding: '6px',
//     textAlign: 'left',
//     fontSize: '14px',
//   },
//   '& tr:hover': {
//     backgroundColor: '#f5f5f5', 
//   },
// });


// const TableHead = styled('thead')({
//   backgroundColor: '#f2f2f2',
// });

// const TableCell = styled('td')({
//   padding: '6px',
// });

// const CsvUploadContainer = styled('div')({
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'flex-start',
// });

// const CsvUploadButton = styled(Button)({
//   marginBottom: '10px',
//   fontSize: '10px',
//   padding: '6px 12px',
// });

// const CsvUploadNote = styled('div')({
//   marginTop: '8px',
//   fontSize: '10px',
// });

// const DialogTitleStyled = styled(DialogTitle)({
//   color: '#ff5ao2',
//   fontSize: '16px',
// });

// const PopupButton = styled(Button)({
//   fontSize: '12px',
//   fontWeight: 'bold',
//   padding: '6px 12px',
//    borderRadius: '5px',
//    color:'black'
// });

// const StyledTextField = styled(TextField)({
//   minWidth: '250px', 
//   marginBottom:'2px',
//   marginTop:"10px",
//   '& .MuiOutlinedInput-root': {
//     '& input': {
//       padding: '8px 10px', 
//     },
//   },
//   borderRadius: '8px',
//   '& .MuiInputAdornment-root': {
//     display: 'none',
//   },
//   '&.show-adornment .MuiInputAdornment-root': {
//     display: 'flex',
//   },
// });

// function Projects() {
//   const [projects, setProjects] = useState([]);
//   const [selectedProjects, setSelectedProjects] = useState([]);
//   const [openAddProject, setOpenAddProject] = useState(false);
//   const [openImportCSV, setOpenImportCSV] = useState(false);
//   const [openSetUsers, setOpenSetUsers] = useState(false);
//   const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
//   const [newProjectName, setNewProjectName] = useState('');
//   const [userDefaultProject, setUserDefaultProject] = useState('');
//   const [activeButton, setActiveButton] = useState('');
//   const [showCheckIcon, setShowCheckIcon] = useState(null);

//   useEffect(() =>{
//     fetch(`${baseURL}/project`, {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     })
//     .then(response => response.json())
//     .then(data => {
//       if (Array.isArray(data) && data.length > 0) {
//         setProjects(data);
//       } else {
//         console.error('Project data is invalid or missing _id:', data);
//       }
//     })
//     .catch(error => console.error('Error fetching projects:', error));
//   }, []);

//   const handleAddProject = () => {
//     fetch(`${baseURL}/project`, {
//       method: 'POST',
//       headers:{
//         'Content-Type' : 'application/json',
//         'Authorization': `Bearer ${token}`
//       },
//       body: JSON.stringify({ name: newProjectName })
//     })
//     .then(response => response.json())
//     .then(data => {
//     setProjects([...projects, data]);
//     setNewProjectName('');
//     setOpenAddProject(false);
//     setActiveButton('');
//     })
//     .catch(error => console.error('Error adding project:', error))

//   };

//   // const handleDeleteProjects = () =>{
//   //   fetch(`http://server.codeaxes.shop/api/project/`, {
//   //     method: 'DELETE',
//   //     headers:{
//   //       'Content-Type' : 'application/json',
//   //       'Authorization': `Bearer ${token}`
//   //     },
//   //     body: JSON.stringify({ ids: selectedProjects })
//   //   })
//   //   .then(() => {
//   //     const filteredProjects = projects.filter((project) => !selectedProjects.includes(project._id));
//   //     setProjects(filteredProjects);
//   //     setSelectedProjects([]);
//   //     setActiveButton('');
//   //     setOpenDeleteConfirm(false);
//   //   })
//   //   .catch(error => console.error('Error deleting projects:', error));
//   // }

//   const handleDeleteProjects = () => {
//     const deletePromises = selectedProjects.map((project_Id) =>
//       fetch(`${baseURL}/project/${project_Id}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         }
//       })
//     );

//     Promise.all(deletePromises)
//       .then((responses) => {
//         // Check if all responses are OK
//         const allOk = responses.every((response) => response.ok);
//         if (!allOk) {
//           throw new Error('Error deleting one or more projects');
//         }
//         return Promise.all(responses.map((response) => response.json()));
//       })
//       .then(() => {
//         const filteredProjects = projects.filter(
//           (project) => !selectedProjects.includes(project._id)
//         );
//         setProjects(filteredProjects);
//         setSelectedProjects([]);
//         setActiveButton('');
//         setOpenDeleteConfirm(false);
//       })
//       .catch((error) => console.error('Error deleting projects:', error));
//   };


//   const handleProjectSelect = (projectId) => {
//     if (selectedProjects.includes(projectId)) {
//       setSelectedProjects(selectedProjects.filter(id => id !== projectId));
//     } else {
//       setSelectedProjects([...selectedProjects, projectId]);
//     }
//   };
//   const handleEditProjectName = (index, newName) => {
//     const updatedProjects = [...projects];
//     const project = updatedProjects[index];

//     if (!project._id) {
//       console.error('Project ID is missing');
//       return;
//     }

//     project.name = newName;
//     setProjects(updatedProjects);

//     fetch(`${baseURL}/project/${project._id}`, {
//       method: 'PATCH',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//       },
//       body: JSON.stringify({ name: newName }),
//     })
//     .then(response => {
//       if (!response.ok) {
//         return response.json().then(errorData => {
//           throw new Error(`Error: ${errorData.message}`);
//         });
//       }
//       setShowCheckIcon(index);
//       setTimeout(() => setShowCheckIcon(null), 500); 
//       return response.json();
//     })
//     .catch(error => console.error('Error updating project name:', error));
//   };

//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       const text = e.target.result;
//       const lines = text.split('\n');
//       const parsedData = lines.map((line) => ({ name: line.trim() }));
//       setProjects([...projects, ...parsedData]);
//     };
//     reader.readAsText(file);
//     setOpenImportCSV(false);
//     setActiveButton('');
//   };



//   return (
//     <Container>
//       <h1 style={{ fontWeight: 'bold', fontSize: '20px' }}>
//         Project
//         <Tooltip title="Help" dir='right'>
//           <IconButton size="small">
//             <HelpIcon fontSize="small" />
//           </IconButton>
//         </Tooltip>
//       </h1>
//       <div style={{
//   border: '1px solid #e0e0e0',
//   boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', width:"60%", padding:"15px", marginTop:"17px"}}>
//       <ButtonGroup style={{ marginTop: '8px' }}>
//         <AddProjectButton
//           variant="contained"
//           onClick={() => { setOpenAddProject(true); setActiveButton('add'); }}
//           active={activeButton === 'add'}
//         >
//           Add Project
//         </AddProjectButton>
//         <ImportCsvButton
//           variant="contained"
//           onClick={() => { setOpenImportCSV(true); setActiveButton('import'); }}
//           active={activeButton === 'import'}
//         >
//           Import from CSV
//         </ImportCsvButton>
//         <DeleteButton
//           variant="contained"
//           disabled={!selectedProjects.length}
//           onClick={() => setOpenDeleteConfirm(true)}
//           active={activeButton === 'delete'}
//         >
//           Delete
//         </DeleteButton>
//         <CustomButton
//           variant="contained"
//           onClick={() => { setOpenSetUsers(true); setActiveButton('setUsers'); }}
//           active={activeButton === 'setUsers'}
//         >
//           Set Users
//         </CustomButton>
//       </ButtonGroup>
//       <StyledTable>
//         <TableHead >
//           <tr>
//             <th>Project Name</th>
//             <th>Visible</th>
//           </tr>
//         </TableHead>
//         <tbody>
//           {projects.length === 0 ? (
//             <tr>
//               <td colSpan="2" style={{ textAlign: 'center', fontSize: '16px' }}>No Results</td>
//             </tr>
//           ) : (
//             projects.map((project, index) => (
//               <tr key={project._id}>
//                 <TableCell>
//                   <Checkbox sx={{marginTop:'9px'}}
//                     checked={selectedProjects.includes(project._id)}
//                     onChange={() => handleProjectSelect(project._id)}
//                   />
//                    <StyledTextField
//                     value={project.name}
//                     onChange={(e) => handleEditProjectName(index, e.target.value)}
//                     onKeyDown={(e) => {
//                       if (e.key === 'Enter') {
//                         handleEditProjectName(index, e.target.value);
//                       }
//                     }}
//                     InputProps={{ style: { fontSize: '14px' },  endAdornment: (
//                       showCheckIcon === index && (
//                         <CheckCircleIcon
//                           style={{ color: 'lightgreen', marginLeft: '5px' }}
//                         />
//                       )
//                     ),
//                   }}
//                   className={showCheckIcon === index ? 'show-adornment' : ''} 
//                   />
//                 </TableCell>
//                 <TableCell>
//                   <FormControlLabel control={
//                       <Switch
//                         checked={project.visible || false}
//                         onChange={(e) => {
//                           const updatedProjects = [...projects];
//                           updatedProjects[index].visible = e.target.checked;
//                           setProjects(updatedProjects);
//                         }}
//                       />
//                     }
//                     label="Yes" />
//                 </TableCell>
//               </tr>
//             ))
//           )}
//         </tbody>
//       </StyledTable>
//       </div>


//       <Dialog open={openAddProject} onClose={() => { setOpenAddProject(false); setActiveButton(''); }}>
//         <DialogTitleStyled><h1  style={{color:'#1976d2', fontWeight:'bold'}}>Add Project</h1></DialogTitleStyled>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="Project Name"
//             sx={{minWidth: '250px',
//               '& .MuiOutlinedInput-root': {
//                 '& input': {
//                   padding: '8px 10px', 
//                 }, borderRadius: '8px',
//                 '& .MuiInputAdornment-root': {
//                   display: 'none',
//                 },}}}
//             value={newProjectName}
//             onChange={(e) => setNewProjectName(e.target.value)}
//             // InputProps={{ style: { fontSize: '14px' } }}
//             // InputLabelProps={{ style: { fontSize: '14px' } }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <PopupButton onClick={() => { setOpenAddProject(false); setActiveButton(''); }} color="primary" sx={{'&:hover':{background:'lightgrey'}}}>
//             Cancel
//           </PopupButton>
//           <PopupButton onClick={handleAddProject} color="primary" sx={{
//     background: '#1976d2',
//     fontWeight: 'bold',
//     borderRadius: '5px',
//     '&:hover': {
//       background: '#1976d2',
//       boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
//     },
//   }}>
//             Add
//           </PopupButton>
//         </DialogActions>
//       </Dialog>


//       <Dialog open={openImportCSV} onClose={() => { setOpenImportCSV(false); setActiveButton(''); }}>
//         <DialogTitleStyled>Import from CSV</DialogTitleStyled>
//         <DialogContent>
//           <CsvUploadContainer>
//             <p style={{ fontSize: '14px' }}>Upload CSV file with project names</p>
//             <CsvUploadButton variant="contained" component="label">
//               Upload
//               <input type="file" accept=".csv" style={{ display: 'none', marginTop:'2px' }} onChange={handleFileUpload} />
//             </CsvUploadButton>
//             <CsvUploadNote style={{ fontSize: '12px' }}>Max file size: 2MB</CsvUploadNote>
//           </CsvUploadContainer>
//         </DialogContent>
//         <DialogActions>
//           <PopupButton onClick={() => { setOpenImportCSV(false); setActiveButton(''); }} color="primary">
//             Cancel
//           </PopupButton>
//         </DialogActions>
//       </Dialog>


//       <Dialog open={openSetUsers} onClose={() => { setOpenSetUsers(false); setActiveButton(''); }}>
//         <DialogTitleStyled>Set User Default</DialogTitleStyled>
//         <DialogContent>
//           <StyledTable>
//             <TableHead>
//               <tr>
//                 <th>User</th>
//                 <th>Default Project</th>
//               </tr>
//             </TableHead>
//             <tbody>
//               <tr>
//                 <TableCell style={{ fontSize: '14px' }}>Test User</TableCell>
//                 <TableCell>
//                   <Select
//                     value={userDefaultProject}
//                     onChange={(e) => setUserDefaultProject(e.target.value)}
//                     displayEmpty
//                     fullWidth
//                     inputProps={{ style: { fontSize: '14px' } }}
//                   >
//                     <MenuItem value="" disabled style={{ fontSize: '14px' }}>
//                       Select a project
//                     </MenuItem>
//                     {projects.map((project, index) => (
//                       <MenuItem key={index} value={project.name} style={{ fontSize: '14px' }}>
//                         {project.name}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </TableCell>
//               </tr>
//             </tbody>
//           </StyledTable>
//         </DialogContent>
//         <DialogActions>
//           <PopupButton onClick={() => { setOpenSetUsers(false); setActiveButton(''); }} color="primary">
//             Cancel
//           </PopupButton>
//         </DialogActions>
//       </Dialog>
//       <Dialog open={openDeleteConfirm} onClose={() => { setOpenDeleteConfirm(false); setActiveButton(''); }} sx={{margin: '10px'}}>
//         <DialogTitleStyled><h1  style={{color:'#1976d2', fontWeight:'bold'}}>Confirm Delete</h1></DialogTitleStyled>
//         <DialogContent>
//           <p style={{fontSize:'16px'}}>Are you sure you want to delete these {selectedProjects.length} items?</p>
//         </DialogContent>
//         <DialogActions>
//           <PopupButton onClick={() => { setOpenDeleteConfirm(false); setActiveButton(''); }} color="primary" sx={{'&:hover':{background:'lightgrey'}}}>
//             Cancel
//           </PopupButton>
//           <PopupButton onClick={handleDeleteProjects} color="primary" sx={{
//     background:'#1976d2',
//     fontWeight: '#ffff',
//     borderRadius: '5px',
//     '&:hover': {
//       background: '#1976d2',
//       boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
//     },
//   }}>
//             OK
//           </PopupButton>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// }

// export default Projects;

import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, Tooltip, IconButton, DialogContent, DialogTitle, TextField, Switch, Checkbox, FormControlLabel, Select, MenuItem } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/system';
import { baseURL } from '../Config';

const token = localStorage.getItem('token');

const Container = styled('div')({
  padding: '16px',
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  height: 'auto'
});

const ButtonGroup = styled('div')({
  marginBottom: '16px',
  display: 'flex',
  gap: '8px',
});

const CustomButton = styled(Button)(({ active }) => ({
  fontSize: '12px',
  padding: '6px 15px',
  marginRight: '10px',
  background: '#1976d2',
  color: '#fff',
  fontWeight: 'bold',
  '&:hover': {
    background: '#1976d2',
  },
}));

const AddProjectButton = styled(CustomButton)(({ active }) => ({
  backgroundColor: active ? '#1976d2' : '#1976d2',
}));

const ImportCsvButton = styled(CustomButton)(({ active }) => ({
  backgroundColor: active ? '#1976d2' : '#1976d2',
}));

const DeleteButton = styled(CustomButton)(({ active }) => ({
  backgroundColor: '#ff5a02',
  '&:hover': {
    background: '#ff5a02',
  },
}));

const StyledTable = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
  '& td, & th': {
    borderBottom: '1px solid #e0e0e0',
    padding: '6px',
    textAlign: 'left',
    fontSize: '14px',
  },
  '& tr:hover': {
    backgroundColor: '#f5f5f5',
  },
});

const TableHead = styled('thead')({
  backgroundColor: '#f2f2f2',
});

const TableCell = styled('td')({
  padding: '6px',
});

const CsvUploadContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const CsvUploadButton = styled(Button)({
  marginBottom: '10px',
  fontSize: '10px',
  padding: '6px 12px',
});

const CsvUploadNote = styled('div')({
  marginTop: '8px',
  fontSize: '10px',
});

const DialogTitleStyled = styled(DialogTitle)({
  color: '#ff5a02',
  fontSize: '16px',
});

const PopupButton = styled(Button)({
  fontSize: '12px',
  fontWeight: 'bold',
  padding: '6px 12px',
  borderRadius: '5px',
  color: 'black'
});

const StyledTextField = styled(TextField)({
  minWidth: '250px',
  marginBottom: '2px',
  marginTop: "10px",
  '& .MuiOutlinedInput-root': {
    '& input': {
      padding: '8px 10px',
    },
  },
  borderRadius: '8px',
  '& .MuiInputAdornment-root': {
    display: 'none',
  },
  '&.show-adornment .MuiInputAdornment-root': {
    display: 'flex',
  },
});

function Projects() {
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [openAddProject, setOpenAddProject] = useState(false);
  const [openImportCSV, setOpenImportCSV] = useState(false);
  const [openSetUsers, setOpenSetUsers] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [userDefaultProject, setUserDefaultProject] = useState('');
  const [activeButton, setActiveButton] = useState('');
  const [showCheckIcon, setShowCheckIcon] = useState(null);

  useEffect(() => {
    fetch(`${baseURL}/project`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data) && data.length > 0) {
          setProjects(data);
        } else {
          console.error('Project data is invalid or missing _id:', data);
        }
      })
      .catch(error => console.error('Error fetching projects:', error));
  }, []);

  const handleAddProject = () => {
    fetch(`${baseURL}/project`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ name: newProjectName })
    })
      .then(response => response.json())
      .then(data => {
        setProjects([...projects, data]);
        setNewProjectName('');
        setOpenAddProject(false);
        setActiveButton('');
      })
      .catch(error => console.error('Error adding project:', error));
  };

  const handleDeleteProjects = () => {
    const deletePromises = selectedProjects.map((project_Id) =>
      fetch(`${baseURL}/project/${project_Id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
    );

    Promise.all(deletePromises)
      .then((responses) => {
        const allOk = responses.every((response) => response.ok);
        if (!allOk) {
          throw new Error('Error deleting one or more projects');
        }
        return Promise.all(responses.map((response) => response.json()));
      })
      .then(() => {
        const filteredProjects = projects.filter(
          (project) => !selectedProjects.includes(project._id)
        );
        setProjects(filteredProjects);
        setSelectedProjects([]);
        setActiveButton('');
        setOpenDeleteConfirm(false);
      })
      .catch((error) => console.error('Error deleting projects:', error));
  };

  const handleProjectSelect = (projectId) => {
    if (selectedProjects.includes(projectId)) {
      setSelectedProjects(selectedProjects.filter(id => id !== projectId));
    } else {
      setSelectedProjects([...selectedProjects, projectId]);
    }
  };

  const handleEditProjectName = (index, newName) => {
    const updatedProjects = [...projects];
    const project = updatedProjects[index];

    if (!project._id) {
      console.error('Project ID is missing');
      return;
    }

    project.name = newName;
    setProjects(updatedProjects);

    fetch(`${baseURL}/project/${project._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ name: newName }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(errorData => {
            throw new Error(`Error: ${errorData.message}`);
          });
        }
        setShowCheckIcon(index);
        setTimeout(() => setShowCheckIcon(null), 500);
        return response.json();
      })
      .catch(error => console.error('Error updating project name:', error));
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const lines = text.split('\n');
      const parsedData = lines.map((line) => ({ name: line.trim() }));
      setProjects([...projects, ...parsedData]);
    };
    reader.readAsText(file);
    setOpenImportCSV(false);
    setActiveButton('');
  };

  return (
    <Container>
      <h1 style={{ fontWeight: 'bold', fontSize: '26px' }}>
        Projects
        <Tooltip title="Help" dir='right'>
          <IconButton size="small">
            <HelpIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </h1>
      <div className='min-h-[77vh]' style={{
        border: '1px solid #e0e0e0',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', width: "80%", padding: "15px", marginTop: "17px"
      }}>
        <ButtonGroup style={{ marginTop: '8px' }}>
          <AddProjectButton
            variant="contained"
            onClick={() => { setOpenAddProject(true); setActiveButton('add'); }}
            active={activeButton === 'add'}
          >
            Add Project
          </AddProjectButton>
          <ImportCsvButton
            variant="contained"
            onClick={() => { setOpenImportCSV(true); setActiveButton('import'); }}
            active={activeButton === 'import'}
          >
            Import from CSV
          </ImportCsvButton>
          <DeleteButton
            variant="contained"
            disabled={!selectedProjects.length}
            onClick={() => setOpenDeleteConfirm(true)}
            active={activeButton === 'delete'}
          >
            Delete
          </DeleteButton>
          <CustomButton
            variant="contained"
            onClick={() => { setOpenSetUsers(true); setActiveButton('setUsers'); }}
            active={activeButton === 'setUsers'}
          >
            Set Users
          </CustomButton>
        </ButtonGroup>

        {projects.length === 0 ? (
          <div className=' flex flex-col gap-2 items-center mt-32 '><svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" fill="none"><path stroke="#C3CBD5" stroke-dasharray="8 8" stroke-width="4" d="M96 140H40a8 8 0 01-8-8V28a8 8 0 018-8h60.686a8 8 0 015.657 2.343l19.314 19.314A8 8 0 01128 47.314V108" /><path stroke="#C3CBD5" stroke-dasharray="8 8" stroke-width="4" d="M96 25v19a8 8 0 008 8h19" /><path fill="#fff" stroke="#3F4B5A" stroke-width="4" d="M93.081 102.703l2.622-2.622 27.149 27.149-2.622 2.622-27.149-27.149z" /><path fill="#FFCDB3" stroke="#3F4B5A" stroke-width="4" d="M118.919 131.267a3.782 3.782 0 015.347-5.347l13.627 13.626a3.782 3.782 0 01-5.348 5.347l-13.626-13.626z" /><path fill="#E9F1FB" d="M112.055 80.902c10.535 10.535 10.535 27.617 0 38.153-10.536 10.535-27.618 10.535-38.153 0-10.536-10.536-10.536-27.618 0-38.153 10.535-10.536 27.617-10.536 38.153 0z" /><path fill="#fff" d="M90 88c-.552 3.866-4.134 7.447-8 8-3.866.552-6.552-2.134-6-6 .552-3.866 4.134-7.448 8-8 3.866-.553 6.552 2.134 6 6z" /><path stroke="#3F4B5A" stroke-linecap="round" stroke-width="4" d="M112.055 80.902c10.535 10.535 10.535 27.617 0 38.153-10.536 10.535-27.618 10.535-38.153 0-10.536-10.536-10.536-27.618 0-38.153 8.868-8.869 22.375-10.272 32.712-4.21" /></svg>
            <span className='font-bold text-lg'>No Results Found.</span></div>
        ) : (<StyledTable>
          <TableHead sx={{ padding: 2 }} >
            <tr>
              <th>Project Name</th>
              <th>Visible</th>
            </tr>
          </TableHead>
          <tbody>
            {projects.map((project, index) => (
              <tr key={project._id}>
                <TableCell>
                  <Checkbox sx={{ marginTop: '9px' }}
                    checked={selectedProjects.includes(project._id)}
                    onChange={() => handleProjectSelect(project._id)}
                  />
                  <StyledTextField
                    value={project.name}
                    onChange={(e) => handleEditProjectName(index, e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleEditProjectName(index, e.target.value);
                      }
                    }}
                    InputProps={{
                      style: { fontSize: '14px' }, endAdornment: (
                        showCheckIcon === index && (
                          <CheckCircleIcon
                            style={{ color: 'lightgreen', marginLeft: '5px' }}
                          />
                        )
                      ),
                    }}
                    className={showCheckIcon === index ? 'show-adornment' : ''}
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel control={
                    <Switch
                      checked={project.visible || false}
                      onChange={(e) => {
                        const updatedProjects = [...projects];
                        updatedProjects[index].visible = e.target.checked;
                        setProjects(updatedProjects);
                      }}
                    />
                  }
                    label="Yes" />
                </TableCell>
              </tr>
            ))
            } </tbody>
        </StyledTable>)}

      </div>

      <Dialog open={openAddProject} onClose={() => { setOpenAddProject(false); setActiveButton(''); }}>
        <DialogTitleStyled><h1 style={{ color: '#1976d2', fontWeight: 'bold', fontSize: '1.1rem' }}>Add Project</h1></DialogTitleStyled>
        <DialogContent>
          <TextField
            required
            autoFocus
            margin="dense"
            label="Project Name"

            sx={{
              minWidth: '400px',
              '& .MuiOutlinedInput-root': {
                '& input': {
                  // padding: '10px',
                }, borderRadius: '8px',
                '& .MuiInputAdornment-root': {
                  display: 'none',
                },

                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',

                  },
                },
              }
            }}
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <PopupButton onClick={() => { setOpenAddProject(false); setActiveButton(''); }} color="primary" sx={{ '&:hover': { background: 'lightgrey' } }}>
            Cancel
          </PopupButton>
          <PopupButton onClick={handleAddProject} color="primary" sx={{
            background: '#1976d2',
            fontWeight: 'bold',
            borderRadius: '5px',
            color: 'white',
            '&:hover': {
              background: '#1976d2',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            },
          }}>
            Add
          </PopupButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openImportCSV} onClose={() => { setOpenImportCSV(false); setActiveButton(''); }}>
        <DialogTitleStyled sx={{ fontSize: '1.1rem' }}>Import from CSV</DialogTitleStyled>
        <DialogContent>
          <CsvUploadContainer>
            <p style={{ fontSize: '1rem', padding: 4 }}>Upload CSV file with project names</p>
            <CsvUploadButton variant="contained" component="label" sx={{ padding: '0.5rem 1rem', mt: 2 }}>
              Upload
              <input type="file" accept=".csv" style={{ display: 'none', marginTop: '2px' }} onChange={handleFileUpload} />
            </CsvUploadButton>
            <CsvUploadNote style={{ fontSize: '12px' }}>Max file size: 2MB</CsvUploadNote>
          </CsvUploadContainer>
        </DialogContent>
        <DialogActions>
          <PopupButton onClick={() => { setOpenImportCSV(false); setActiveButton(''); }} color="primary">
            Cancel
          </PopupButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openSetUsers} onClose={() => { setOpenSetUsers(false); setActiveButton(''); }}>
        <DialogTitleStyled>Set User Default</DialogTitleStyled>
        <DialogContent>
          <StyledTable>
            <TableHead>
              <tr>
                <th>User</th>
                <th>Default Project</th>
              </tr>
            </TableHead>
            <tbody>
              <tr>
                <TableCell style={{ fontSize: '14px' }}>Test User</TableCell>
                <TableCell>
                  <Select
                    value={userDefaultProject}
                    onChange={(e) => setUserDefaultProject(e.target.value)}
                    displayEmpty
                    fullWidth
                    inputProps={{ style: { fontSize: '14px' } }}
                  >
                    <MenuItem value="" disabled style={{ fontSize: '14px' }}>
                      Select a project
                    </MenuItem>
                    {projects.map((project, index) => (
                      <MenuItem key={index} value={project.name} style={{ fontSize: '14px' }}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </tr>
            </tbody>
          </StyledTable>
        </DialogContent>
        <DialogActions>
          <PopupButton onClick={() => { setOpenSetUsers(false); setActiveButton(''); }} color="primary">
            Cancel
          </PopupButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteConfirm} onClose={() => { setOpenDeleteConfirm(false); setActiveButton(''); }} sx={{ margin: '10px' }}>
        <DialogTitleStyled><h1 style={{ color: '#1976d2', fontWeight: 'bold' }}>Confirm Delete</h1></DialogTitleStyled>
        <DialogContent>
          <p style={{ fontSize: '16px' }}>Are you sure you want to delete these {selectedProjects.length} items?</p>
        </DialogContent>
        <DialogActions>
          <PopupButton onClick={() => { setOpenDeleteConfirm(false); setActiveButton(''); }} color="primary" sx={{ '&:hover': { background: 'lightgrey' } }}>
            Cancel
          </PopupButton>
          <PopupButton onClick={handleDeleteProjects} color="primary" sx={{
            background: '#1976d2',
            fontWeight: '#ffff',
            borderRadius: '5px',
            '&:hover': {
              background: '#1976d2',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            },
          }}>
            OK
          </PopupButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Projects;

