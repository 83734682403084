// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .dropdown:hover {
    background-color: rgb(173, 59, 59) !important;
}
*/
/* nav .active {
    background-color: rgb(173, 59, 59);
} */

/* .active {
    color: rgb(173, 59, 59) !important;
    width: 100% !important;
    background-color: aliceblue;
} */

.mm {
    margin-left: 0 !important;
    padding-left: 0 !important;
}
.h-full.overflow-y-auto{
padding: 0 !important;
}
.overflow-y-auto {
    /* height: 90vh !important; */
    padding: 0 !important;
    margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar.css"],"names":[],"mappings":"AAAA;;;CAGC;AACD;;GAEG;;AAEH;;;;GAIG;;AAEH;IACI,yBAAyB;IACzB,0BAA0B;AAC9B;AACA;AACA,qBAAqB;AACrB;AACA;IACI,6BAA6B;IAC7B,qBAAqB;IACrB,oBAAoB;AACxB","sourcesContent":["/* .dropdown:hover {\n    background-color: rgb(173, 59, 59) !important;\n}\n*/\n/* nav .active {\n    background-color: rgb(173, 59, 59);\n} */\n\n/* .active {\n    color: rgb(173, 59, 59) !important;\n    width: 100% !important;\n    background-color: aliceblue;\n} */\n\n.mm {\n    margin-left: 0 !important;\n    padding-left: 0 !important;\n}\n.h-full.overflow-y-auto{\npadding: 0 !important;\n}\n.overflow-y-auto {\n    /* height: 90vh !important; */\n    padding: 0 !important;\n    margin: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
