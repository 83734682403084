import React, { useContext, useEffect, useState } from 'react';
import { Sidebar } from "flowbite-react";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { MdOutlineRocketLaunch, MdOutlineLocalShipping, MdOutlineShoppingCart, MdOutlineCreditCard, MdOutlineGroup, MdOutlineGroups, MdOutlineCategory, MdOutlinePercent, MdOutlineDocumentScanner, MdOutlineAccountBalance, MdOutlineAccountBalanceWallet, MdOutlinePersonOutline, MdOutlinePeopleAlt, MdOutlineSummarize, MdCheckCircleOutline, MdUploadFile, MdFileOpen, MdSettings } from "react-icons/md";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { NavLink, Link, useLocation } from 'react-router-dom'
import { BusinessContext } from '../contexts/Business';
import './sidebar.css'
import { baseURL } from '../Config';
import axios from 'axios';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '36%',
    padding: '20px',
    height: 'fit-content',
    transition: 'trans 0.3s ease',
  }

};

const countStyles= {
  backgroundColor: 'gray', // Light background color
  borderRadius: '50%',        // Makes the div fully rounded
  padding: '2px',            // Adds space inside the div
  display: 'flex',            // Center-aligns content
  justifyContent: 'center', 
  alignItems: 'center',
  minWidth: '27px',           // Ensures a consistent width/height
  minHeight: '20px',
  color:'white'

}

const parnetCountDiv={ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }


const SidebarComponent = () => {
  const location = useLocation();  // Get the current location (URL)

  const isActive = (path) => location.pathname === path;
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const { selectedBusiness, setSelectedBusiness,
    isStatsChangedToggle } = useContext(BusinessContext)
  const [stats,setStats] = useState({});
  const excludedStatuses = ["archive", "Expense Claim"];



  
  const fetchStats = async () => {
    const token = localStorage.getItem('token');
    try {
          const response = await axios.get(`${baseURL}/cost/all/stats`, {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
          });
          console.log(response,'response ')
          setStats(response.data);
          // console.log('Costs fetched successfully:', response.data);
      } catch (error) {
          console.error('Error fetching costs:', error);
      }
  };



  useEffect(()=>{
    fetchStats()
  },[isStatsChangedToggle])
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }


  return (
    <>
      <Sidebar >
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            <Sidebar.Item href="#" icon={MdOutlineRocketLaunch} onClick={openModal}>
              <div className='w-full flex justify-between'>
                <div>
                  Get Started
                </div>
                <div>
                  {/* 4/7 */}
                </div>
              </div>
            </Sidebar.Item>
            <div className='w-full flex justify-center px-[0.75rem]'>
              <div className=' w-[90%] relative'>
                <div className='w-full h-[5px] bg-slate-200'></div>
                <div className='w-[50%] h-[5px] bg-[#2196f3] top-0 left-0 absolute'></div>
              </div>
            </div>
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            <div className='font-bold text-[12px] ml-3 text-[#546478]'>WORKSPACES</div>

            <Sidebar.Collapse icon={MdOutlineShoppingCart} label="Costs" >

              <Sidebar.Item as={Link} to='/dashboard/costs/inbox' active={isActive('/dashboard/costs/inbox') ? true : false}>
            <div style={parnetCountDiv}>
              <div>

              Inbox 
              </div>
              <div style={countStyles} >  
              {
                 stats.costs
                 ?.filter((c) => !excludedStatuses.includes(c.status)) // Exclude specified statuses
                 .reduce((sum, c) => sum + (c.count || 0), 0)
              }
              </div>
                </div>
             </Sidebar.Item>
            
              {/* <Sidebar.Item as={Link} to="/dashboard/costs/processing" active={isActive('/dashboard/costs/processing') ? true : false} >In Processing</Sidebar.Item> */}
              

              <Sidebar.Item as={Link} to="/dashboard/costs/review" active={isActive('/dashboard/costs/review') ? true : false} >
              <div style={parnetCountDiv}>
                <div>

              To Review
                </div>
                <div style={countStyles} >  
              {stats.costs?.find(c => c.status === 'Review')?.count || 0}
                </div>
              
                </div>
                </Sidebar.Item>
             
              
              <Sidebar.Item as={Link} to="/dashboard/costs/ready" active={isActive('/dashboard/costs/ready') ? true : false} >
                <div style={parnetCountDiv}>
              
              <div>
                
              Ready
              </div>
              <div style={countStyles} >  
              {stats.costs?.find(c => c.status === 'Ready')?.count || 0}
                </div>
              
                  </div>
                </Sidebar.Item>
             


              <Sidebar.Item as={Link} to="/dashboard/costs/archive" active={isActive('/dashboard/costs/archive') ? true : false} >
                <div style={parnetCountDiv}>
                  <div>

              Archive
                  </div>
              <div style={countStyles} > 
              {stats.costs?.find(c => c.status == 'archive')?.count || 0}
                  </div>
</div>
              </Sidebar.Item>
                

            </Sidebar.Collapse>
            <Sidebar.Collapse icon={MdOutlineCreditCard} label="Sales" >

              <Sidebar.Item as={Link} to="/dashboard/sales/inbox" active={isActive('/dashboard/sales/inbox') ? true : false} >
            <div style={parnetCountDiv}>
              <div>

              Inbox
              </div>
              <div style={countStyles} >


{
   stats.sales
   ?.filter((c) => !excludedStatuses.includes(c.status)) // Exclude specified statuses
   .reduce((sum, c) => sum + (c.count || 0), 0)
}
</div>
                </div>
              </Sidebar.Item>
             
              {/* <Sidebar.Item as={Link} to="/dashboard/sales/processing" active={isActive('/dashboard/sales/processing') ? true : false}>In Processing</Sidebar.Item> */}

              <Sidebar.Item as={Link} to="/dashboard/sales/review" active={isActive('/dashboard/sales/review') ? true : false}>
              <div style={parnetCountDiv}>
              <div>

              To Review
              </div>
              
              <div style={countStyles} >
              {stats.sales?.find(c => c.status === 'Review')?.count || 0}


              </div>
                </div>
                  </Sidebar.Item>

              <Sidebar.Item as={Link} to="/dashboard/sales/ready" active={isActive('/dashboard/sales/ready') ? true : false} >
                <div style={parnetCountDiv}>
                  <div>

              Ready
                  </div>
              <div style={countStyles} >
              {stats.sales?.find(c => c.status === 'Ready')?.count || 0}
                </div>
                </div>
                 </Sidebar.Item>




              <Sidebar.Item as={Link} to="/dashboard/sales/archive" active={isActive('/dashboard/sales/archive') ? true : false} >
                <div style={parnetCountDiv}>
                  <div>

              Archive
                  </div>
              <div style={countStyles} >
              {stats.sales?.find(c => c.status == 'archive')?.count || 0}
</div>
                </div>
                  </Sidebar.Item>

            </Sidebar.Collapse>
            <Sidebar.Collapse icon={MdOutlineSummarize} label="Expense Reports" >

              <Sidebar.Item as={Link} to="/dashboard/expensereports/inbox" active={isActive('/dashboard/expensereports/inbox') ? true : false} >Inbox</Sidebar.Item>
              <Sidebar.Item as={Link} to="/dashboard/expensereports/archive" active={isActive('/dashboard/expensereports/archive') ? true : false} >Archive</Sidebar.Item>
            </Sidebar.Collapse>
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            <div className='font-bold text-[12px] ml-3 text-[#546478]'>LISTS</div>
            <Sidebar.Item as={Link} to='/dashboard/suppliers' icon={MdOutlineLocalShipping} active={isActive('/dashboard/suppliers') ? true : false}>
              Suppliers
            </Sidebar.Item>
            <Sidebar.Item as={Link} to='/dashboard/customers' icon={MdOutlineGroups} active={isActive('/dashboard/customers') ? true : false}>
              Customers
            </Sidebar.Item>
            <Sidebar.Item as={Link} to='/dashboard/categories' icon={MdOutlineCategory} active={isActive('/dashboard/categories') ? true : false}>
              Categories
            </Sidebar.Item>
            {/* <Sidebar.Item as={Link} to='/dashboard/taxrates' icon={MdOutlinePercent} active={isActive('/dashboard/taxrates') ? true : false}>
              Tax Rates
            </Sidebar.Item> */}
            <Sidebar.Item as={Link} to='/dashboard/projects' icon={MdOutlineDocumentScanner} active={isActive('/dashboard/projects') ? true : false}>
              Projects
            </Sidebar.Item>
            <Sidebar.Item as={Link} to='/dashboard/bankaccounts' icon={MdOutlineAccountBalance} active={isActive('/dashboard/bankaccounts') ? true : false}>
              Bank Accounts
            </Sidebar.Item>
            <Sidebar.Item as={Link} to='/dashboard/paymentmethods' icon={MdOutlineAccountBalanceWallet} active={isActive('/dashboard/paymentmethods') ? true : false}>
              Payment Methods
            </Sidebar.Item>
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            <div className='font-bold text-[12px] ml-3 text-[#546478]'>MANAGE</div>
            <Sidebar.Collapse icon={MdOutlinePersonOutline} label="My Profile">



              <Sidebar.Item as={Link} to="/dashboard/accountdetails" active={isActive('/dashboard/accountdetails') ? true : false}>Account Details</Sidebar.Item>
              <Sidebar.Item as={Link} to="/dashboard/subscription" active={isActive('/dashboard/subscription') ? true : false}>Subscription</Sidebar.Item>
              <Sidebar.Item as={Link} to="/dashboard/submissionhistory" active={isActive('/dashboard/submissionhistory') ? true : false}>Submission History</Sidebar.Item>


            </Sidebar.Collapse>
            <Sidebar.Item href="#" icon={MdOutlinePeopleAlt} onClick={() => navigate('/dashboard/myclients')}>
              My Clients
            </Sidebar.Item>
            {/* {selectedBusiness.toLowerCase() == "my practice" ? <Sidebar.Item href="#" icon={MdOutlinePeopleAlt} onClick={() => navigate('/dashboard/myclients')}>
              My Clients
            </Sidebar.Item> : ""} */}
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Get Started Modal"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 style={{ fontWeight: "bold" }}>Get Started</h2>
          <MdSettings size={24} />
        </div>
        <p style={{ color: "#647890", fontSize: "12px", fontWeight: "bold" }}>Organise your paperwork in 4 easy steps:</p>
        <div className="mt-4">
          <div>
            <h6 style={{ fontWeight: "bold", marginLeft: '66px', color: "#3f4b5a", fontSize: "13px" }}>
              Import or create a Chart of Accounts
            </h6>
            <div className="flex items-center mt-2">
              <MdCheckCircleOutline size={20} className="mr-2 mb-3" style={{ color: "#647890" }} />
              <MdFileOpen size={28} className="mr-2 mb-3" style={{ color: "#1976d2" }} />
              <div className="flex items-center">
                <div>
                  <p style={{ fontSize: "11px", color: "#647890", fontWeight: "bold" }}>
                    Connect to an accounting software. Create a manual list,<br />
                    or upload a CSV file to get all your nominal ledger codes
                    into Dext.
                  </p>
                  <p style={{ color: "#1976d2", fontSize: "12px", fontWeight: "bold" }}>
                    <a href="#" onClick={() => navigate('/add-accounts')}>How to add a Charts of Accounts</a>
                  </p>
                </div>
                <button style={{ background: '#d3d3d3', fontWeight: 'bold', border: 'none', padding: '4px 8px', cursor: 'pointer', borderRadius: '5px', marginLeft: '10px', fontSize: "12px", marginBottom: "15px" }}
                  onMouseEnter={(e) => e.target.style.background = '#b0b0b0'}
                  onMouseLeave={(e) => e.target.style.background = '#d3d3d3'}
                >Start</button>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h6 style={{ fontWeight: "bold", marginLeft: '66px', color: "#3f4b5a", fontSize: "13px" }}>
              Upload Receipts and Invoices
            </h6>
            <div className="flex items-center mt-2">
              <MdCheckCircleOutline size={20} className="mr-2 mb-3" style={{ color: "#647890" }} />
              <MdUploadFile size={28} className="mr-2 mb-3" style={{ color: "#1976d2" }} />
              <div className="flex items-center">
                <div>
                  <p style={{ fontSize: "11px", color: "#647890", fontWeight: "bold" }}>
                    Upload or email documents straight to Dext. Our<br />
                    machines will read and extract the key information
                    for you.
                  </p>
                  <p style={{ color: "#1976d2", fontSize: "12px", fontWeight: "bold" }}>
                    <a href="#" onClick={() => navigate('/uploadinvoice')}>How to Upload Receipts and Invoices</a>
                  </p>
                </div>
                <button style={{ background: '#d3d3d3', fontWeight: 'bold', border: 'none', padding: '4px 8px', cursor: 'pointer', borderRadius: '5px', marginLeft: '51px', fontSize: "12px", marginBottom: "15px" }}
                  onMouseEnter={(e) => e.target.style.background = '#b0b0b0'}
                  onMouseLeave={(e) => e.target.style.background = '#d3d3d3'}

                >Start</button>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h6 style={{ fontWeight: "bold", marginLeft: '66px', color: "#3f4b5a", fontSize: "13px" }}>
              Export or publish data
            </h6>
            <div className="flex items-center mt-2">
              <MdCheckCircleOutline size={20} className="mr-2 mb-3" style={{ color: "#647890" }} />
              <DriveFileMoveIcon size={28} className="mr-2 mb-3" style={{ color: "#1976d2" }} />
              <div className="flex items-center">
                <div>
                  <p style={{ fontSize: "11px", color: "#647890", fontWeight: "bold" }}>
                    Send the data to your accounting software or download
                    the data.
                  </p>
                  <p style={{ color: "#1976d2", fontSize: "12px", fontWeight: "bold" }}>
                    <a href="#" onClick={() => navigate('/exportdata')}>How to export or publish data</a>
                  </p>
                </div>
                <button style={{ background: '#d3d3d3', fontWeight: 'bold', border: 'none', padding: '4px 8px', cursor: 'pointer', borderRadius: '5px', marginLeft: '25px', fontSize: "12px", marginBottom: "15px" }}
                  onMouseEnter={(e) => e.target.style.background = '#b0b0b0'}
                  onMouseLeave={(e) => e.target.style.background = '#d3d3d3'}
                >Start</button>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h6 style={{ fontWeight: "bold", marginLeft: '66px', color: "#3f4b5a", fontSize: "13px" }}>
              Add your clients
            </h6>
            <div className="flex items-center mt-2">
              <MdCheckCircleOutline size={20} className="mr-2 mb-3" style={{ color: "#647890" }} />
              <PersonAddAlt1Icon size={28} className="mr-2 mb-3" style={{ color: "#1976d2" }} />
              <div className="flex items-center">
                <div>
                  <p style={{ fontSize: "11px", color: "#647890", fontWeight: "bold" }}>
                    Set up a space for each of your bookkeeping clients and<br />
                    add their nominal ledger codes and financial paperwork.
                  </p>
                  <p style={{ color: "#1976d2", fontSize: "12px", fontWeight: "bold" }}>
                    <a href="#" onClick={() => navigate('/addclient')}>How to add clients</a>
                  </p>
                </div>
                <button style={{ background: '#d3d3d3', fontWeight: 'bold', border: 'none', padding: '4px 8px', cursor: 'pointer', borderRadius: '5px', marginLeft: '67px', fontSize: "12px", marginBottom: "15px" }}
                  onMouseEnter={(e) => e.target.style.background = '#b0b0b0'}
                  onMouseLeave={(e) => e.target.style.background = '#d3d3d3'}
                >Start</button>
              </div>
            </div>
          </div>
          <div className="flex items-center mt-4" style={{ backgroundColor: '#ffefe6', width: '100%', padding: "15px", borderRadius: "2px" }}>
            <TipsAndUpdatesIcon size={24} className="mr-2" style={{ color: "#ff5a02" }} />

            <p style={{ fontSize: "13px", color: "#647890", fontWeight: "bold" }}>
              Learn more ways to use Dext Prepare in <a href="#" style={{ color: "#1976d2", fontWeight: "bold" }}>Resources</a>
            </p>

          </div>
        </div>
      </Modal>
      <style>
        @keyframe
      </style>


    </>
  );
};

export default SidebarComponent;
