import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  IconButton,
  TextField,
  InputAdornment,
  FilledInput,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import { baseURL } from "../Config";
import ForgotPassword from "./ForgotPassword";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [openForget, setOpenForget] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      navigate("/dashboard/costs/inbox");
    }
  }, [navigate]);

  const handleLogin = async () => {
    if (validateLoginForm()) {
      try {
        const response = await axios.post(`${baseURL}/auth/login`, {
          email,
          password,
        });

        console.log("Login response:", response);

        const userInfo = {
          name: response?.data?.practiceName || response.data.name,
          userId: response.data.userId,
          businessType: response.data.role,
        };

        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userToken", response.data.userToken);
        localStorage.removeItem("user-completed-steps");
        navigate("/dashboard/costs/inbox");
      } catch (error) {
        setErrors({
          email: "",
          password:
            error.response?.data?.message || "Login failed. Please try again.",
        });
      }
    }
  };

  const validateLoginForm = () => {
    let emailError = !email
      ? "Email is required"
      : /\S+@\S+\.\S+/.test(email)
      ? ""
      : "Invalid email";
    let passwordError = !password
      ? "Password is required"
      : password.length < 6
      ? "Password must be at least 6 characters"
      : "";
    setErrors({ email: emailError, password: passwordError });
    return !emailError && !passwordError;
  };

  return (
    <div className="flex flex-col md:flex-row h-screen w-full">
      {/* Login Section */}
      <Box className="w-full h-screen md:w-1/2 flex items-center justify-center p-6 rounded-s-lg">
        <div className="w-full max-w-md bg-white p-8 shadow-2xl rounded-xl flex-col gap-5">
          <img
            className="w-24 mx-auto"
            src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png"
            alt="Logo"
          />
          <div className="text-2xl font-bold text-[#0d417b] text-center mb-6">
            LOGIN
          </div>

          {/* Email Input */}
          <div className="mb-4">
            <TextField
             variant="filled"
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={errors.email}
              className="w-full"
              // className={`w-full p-3 border ${
              //   errors.email ? 'border-red-500' : 'border-gray-300'
              // } rounded-md`}
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>

          {/* Password Input */}
          <div className="mb-4">
            <FilledInput
              variant="filled"
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={errors.password}
              className="w-full"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      !showPassword
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={() => setShowPassword(!showPassword)}
                    // className='absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-600'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }

              // className={`w-full p-3 border ${
              //   errors.password ? 'border-red-500' : 'border-gray-300'
              // } rounded-md pr-10`}
            />

            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>

          <div className="flex justify-between text-sm mb-6">
            <button
              onClick={() => setOpenForget(true)}
              className="text-[#0d417b] hover:text-[#1565c0] hover:underline font-semibold"
            >
              Forgot Password?
            </button>
            <button
              onClick={() => navigate("/register")}
              className="text-[#0d417b] hover:text-[#1565c0] hover:underline font-semibold"
            >
              Sign Up
            </button>
          </div>

          {/* Login Button */}
          <Button onClick={handleLogin} className="w-full uppercase">
            Login
          </Button>
        </div>
        {openForget && <ForgotPassword close={() => setOpenForget(false)} />}
      </Box>

      {/* Demo Section */}
      <Box className=" h-screen md:flex w-1/2 flex-col items-center justify-center bg-gray-200 p-6 rounded-s-lg">
        <img
          className="w-40 mb-4"
          src="https://dext-documents.s3.eu-west-2.amazonaws.com/documents/anonymous/Zoom+accountant.png"
          alt="Product Demo"
        />
        <h3 className="text-lg font-semibold text-[#0d417b] uppercase">
          Product Demo
        </h3>
        <p className="text-gray-700 text-center mb-4">
          Hello from Zoom Accountants
        </p>
        <Button
          variant="outlined"
          className="border-blue-700 text-blue-700 hover:bg-blue-700 hover:text-white"
        >
          Read More
        </Button>
      </Box>
    </div>
  );
};

export default Login;
