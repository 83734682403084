import React, { useState, useEffect } from "react";
import { Button, Toolbar, Tooltip, IconButton, Modal, Box, Typography } from "@mui/material";

import TableComponent from "../components/Table";
import { baseURL } from '../Config';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axios from 'axios';
import TableExtendedToolTip from "./utils/TableExtendedToolTip";
import ExportDialog from "./ExportDiaolog";
const token = localStorage.getItem('token');


const EnhancedTableToolbar = ({ rowsData, numSelected, handleOpenDeleteDialog, handleOpenUnarchiveDialog, onExportClick }) => {
    return (
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
            <div className="flex justify-between items-center w-full">
                <div>
                    <Button
                        variant="contained"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            fontWeight: "bold",
                            backgroundColor: "#1976D2",
                            color: "#ffff",
                            '&.MuiButton-root:hover': { bgcolor: "#1976D2", boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' },
                        }}
                        onClick={onExportClick}
                    >
                        {numSelected === 0 ? 'Export All' : 'Export'}
                    </Button>
                    <Button
                        disabled={numSelected === 0}
                        variant="contained"
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            fontWeight: "bold",
                            backgroundColor: "#1976D2",
                            color: "#ffff",
                            '&.MuiButton-root:hover': { bgcolor: "#1976D2", boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' },
                        }}
                        onClick={handleOpenUnarchiveDialog}
                    >
                        Unarchive
                    </Button>
                    <Button
                        disabled={numSelected === 0}
                        variant="contained"
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        sx={{
                            fontSize: '12px',
                            padding: '6px 15px',
                            marginRight: '10px',
                            fontWeight: "bold",
                            backgroundColor: "#1976D2",
                            color: "white",
                            '&.MuiButton-root:hover': { boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' },
                        }}
                        onClick={handleOpenDeleteDialog}
                    >
                        Delete
                    </Button>
                </div>
                <TableExtendedToolTip rowsData={rowsData} />

            </div>
        </Toolbar>
    );
}

const CostArchive = () => {
    const [selected, setSelected] = useState([]);
    const [costs, setCosts] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
    const [openUnarchiveDialog, setOpenUnarchiveDialog] = useState(false);
    useEffect(() => {
        fetchArchivedCosts();
    }, []);

    const fetchArchivedCosts = async () => {
        try {
            const response = await axios.get(`${baseURL}/cost`, {
                headers: { Authorization: `Bearer ${token}`, status: "archive" }
            });

            const archivedCosts = response.data.filter(cost => cost.status === "archive");

            const costDetailsPromises = archivedCosts.map(cost =>
                axios.get(`${baseURL}/cost/${cost._id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                })
            );

            const detailedCostsResponses = await Promise.all(costDetailsPromises);
            const detailedCosts = detailedCostsResponses.map(response => response.data);


            setCosts(Array.isArray(detailedCosts) ? detailedCosts : []);
        } catch (error) {
            console.error('Error fetching archived costs:', error);
        }
    };


    const handleDeleteCost = async () => {
        try {
            const deletePromises = selected.map(costId =>
                axios.delete(`${baseURL}/cost/${costId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            );
            await Promise.all(deletePromises);
            setCosts(prevCosts => prevCosts.filter(cost => !selected.includes(cost._id)));
            setSelected([]);
        } catch (error) {
            console.error('Error deleting costs:', error);
        } finally {
            setOpenDeleteDialog(false);
        }
    };

    const handleUnarchiveCost = async () => {
        try {
            const unarchivePromises = selected.map(costId =>
                axios.patch(`${baseURL}/cost/${costId}`,
                    { status: "Review" },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
            );
            await Promise.all(unarchivePromises);
            setCosts(prevCosts => prevCosts.filter(cost => !selected.includes(cost._id)));
            setSelected([]);
        } catch (error) {
            console.error('Error unarchiving costs:', error);
        } finally {
            setOpenUnarchiveDialog(false);
        }
    };

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleOpenUnarchiveDialog = () => {
        setOpenUnarchiveDialog(true);
    };

    const handleCloseUnarchiveDialog = () => {
        setOpenUnarchiveDialog(false);
    };

    const handleExportCancel = () => {
        setIsExportDialogOpen(false);
    };
    const handleExportClick = () => {
        setIsExportDialogOpen(true);
    };

    return (
        <>
            <EnhancedTableToolbar
                rowsData={costs}
                numSelected={selected.length}
                handleOpenDeleteDialog={handleOpenDeleteDialog}
                handleOpenUnarchiveDialog={handleOpenUnarchiveDialog}
                onExportClick={handleExportClick}
            />
            <ExportDialog
                open={isExportDialogOpen}
                onClose={handleExportCancel}
                data={selected.length > 0 ?
                    costs.filter((cost)=>{
                        const isMatched = selected?.some((item)=>item==cost._id)
                        return isMatched
                      })
                      : costs}
            />
            <TableComponent data={costs} selected={selected} setSelected={setSelected} />
            <Modal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: '1rem',
                        p: 4,
                    }}
                >
                    <Typography id="delete-modal-title" variant="h6" sx={{ fontWeight: 'bold' }} component="h2">
                        Delete Confirmation
                    </Typography>
                    <Typography id="delete-modal-description" sx={{ mt: 2 }}>
                        {`Are you sure you want to delete ${selected.length} item(s)?`}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}>
                        <Button
                            sx={{ mr: 2 }}
                            onClick={handleCloseDeleteDialog}
                            variant="contained"
                            color="inherit"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDeleteCost}
                            variant="contained"
                            color="error"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                        >
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={openUnarchiveDialog}
                onClose={handleCloseUnarchiveDialog}
                aria-labelledby="unarchive-modal-title"
                aria-describedby="unarchive-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: '1rem',
                        p: 4,
                    }}
                >
                    <Typography id="unarchive-modal-title" variant="h6" sx={{ fontWeight: 'bold' }} component="h2">
                        Unarchive Confirmation
                    </Typography>
                    <Typography id="unarchive-modal-description" sx={{ mt: 2 }}>
                        {`Are you sure you want to unarchive ${selected.length} item(s)?`}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}>
                        <Button
                            sx={{ mr: 2 }}
                            onClick={handleCloseUnarchiveDialog}
                            variant="contained"
                            color="inherit"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleUnarchiveCost}
                            variant="contained"
                            color="primary"
                        >
                            Unarchive
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default CostArchive;
