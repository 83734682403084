// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .MuiBackdrop-root.MuiModal-backdrop{
height: 125vh;
} */
.css-kp9ftd-MuiIcon-root svg{
    font-size: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/addmodal.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;IACI,0BAA0B;AAC9B","sourcesContent":["/* .MuiBackdrop-root.MuiModal-backdrop{\nheight: 125vh;\n} */\n.css-kp9ftd-MuiIcon-root svg{\n    font-size: 20px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
