import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Inbox from '../expenseReportsComponents/Inbox';
import Archive from '../expenseReportsComponents/Archive';

const ExpenseReports = () => {
    return (
        <div className="p-4">
            <Routes>
                <Route path="archive" element={<Archive />} />
                <Route path="inbox" element={<Inbox />} />
            </Routes>
        </div>
    );
};

export default ExpenseReports;
